@import "../../../../static/styles/mixins";
@import "../../../../static/styles/variables";

.ob-input {
    &__wrapper {
        display: block;
        width: 100%;
        margin: 0 0 2.6rem;

        @include media-up(sm) {
            margin: 0;
        }

        @include media-up(sm) {
            &[data-half-width="true"] {
                width: 50%;
            }

            &[data-quarter-width="true"] {
                width: 25%;
            }
        }

    }

    &__password-wrapper {
        position: relative;
        margin: .8rem 0 0.6rem;
    }

    &__show-hide {
        position: absolute;
        top: 50%;
        right: 1.6rem;
        bottom: auto;
        left: auto;
        transform: translateY(-50%);
        transition: all 0.35s ease;

        &:focus,
        &:hover {
            transform: translateY(-50%) scale(1.15);
        }

        &.--show {
            &:after {
                content: "";
                width: 2px;
                height: 200%;
                display: block;
                background: $base-color-isolate-grey;
                position: absolute;
                top: 50%;
                right: 1.8rem;
                bottom: auto;
                left: auto;
                transform: translateY(-50%) rotate(-45deg);
            }
        }

        .ob-icon.ob-icon--showHide {
            position: relative;
            width: 3.7rem;
            height: 1.9rem;
            fill: $base-color-isolate-grey;
        }
    }

    &__input {
        width: 100%;
        min-width: 95%;
        height: 50px;
        border: 2px solid $base-color-dark-grey;
        padding: 0 1.6rem;
        font-size: 1.6rem;

        &[data-isvalid=false] {
            border: $base-color__error 1px solid;
        }

        &:focus {
            @include form-focus-state;
        }
    }

    &__error {
        @include font-family('semi-bold');
        margin: .9rem 0 0 0;
        font-size: 1.6rem;
        color: $base-color__error;
    }

    &__label {
        font-size: 2rem;
        color: $base-color-dark-grey;
    }
}
.ob-form__wrapper{
    .ob-form-select__select-ctn{
        .ob-form-select__select{
            border: 2px solid $base-color-dark-grey;
        }
    }
}