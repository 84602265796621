@import '../../static/styles/mixins';
@import "./spotlight-custom-animations";

$mobileGlowBrushImgWidthStep1: 50.4rem;
$mobileGlowBrushImgWidthStep2: 32.87rem;
$desktopGlowBrushImgWidthStep1: 85.25rem;
$desktopGlowBrushImgWidthStep2: 58.6rem;

$mobilePerspectiveBrushImgWidth: 57rem;
$desktopPerspectiveBrushImgWidth: 106.56rem;

$mobileInteractiveImgWidthStep1: 43.85rem;
$mobileInteractiveImgWidthStep2: 64.5rem;
$tabletInteractiveImgWidthStep1: 44.25rem;
$tabletInteractiveImgWidthStep2: 80rem;
$desktopInteractiveImgWidthStep1: 53.1rem;
$desktopInteractiveImgWidthStep2: 96rem;
$mobileBrushImgWidth: 48.32rem;
$mobileBrushImgWidthDefault: 37.27rem;
$tabletBrushImgWidth: 58rem;
$desktopBrushImgWidth: 80rem;

$mobileMagneticBrushImgWidthStep1: 40.69rem;
$mobileMagneticBaseImgWidthStep1: 34.7rem;
$desktopMagneticBrushImgWidthStep1: 66.6rem;
$desktopMagneticBrushImgWidthStep2: 49.34rem;
$desktopMagneticBaseImgWidthStep1: 63.3rem;
$desktopMagneticBaseImgWidthStep2: 42.2rem;
$gradientBackgroundHeight: 150px;

%spotlight-gradient-background {

    @include media-down(sm) {
        &:before {
            background: linear-gradient(transparent 0px, rgba(0,0,0,0.6) #{$gradientBackgroundHeight});
            content: "";
            height: calc(100vh + #{$gradientBackgroundHeight});
            left: 50%;
            position: absolute;
            top: -$gradientBackgroundHeight;
            transform: translateX(-50%);
            width: 200vw;
            z-index: -1;
        }
    }
}

.scroller-container {

    .scroller-content {
        position: relative;
        z-index: 1;

        &:focus {
            outline: none;
        }
    }

    .ob-spotlightVideo {

        .ob-spotlightContentBlock-wrapper {
            pointer-events: none;

            > * {
                pointer-events: all;
            }
        }

        &-container {
            opacity: 0;
            transition: all 0.5s ease-in-out;

            .ob-custom-video-control {
                bottom: 2rem;
                display: none;
                left: 2rem;
                position: fixed;

                .ob-icon {
                    height: 4rem;
                    width: 4rem;
                }
            }
        }
    }

    .ob-spotlightContentBlock {

        &-background {
            opacity: 0;
        }

        &-mainAsset-container,
        &-secondaryAsset-container,
        &-additionalAssetList-container {
            align-items: center;
            display: flex;
            opacity: 0;
            transition: all 0.5s ease-in-out;

            img {
                transform: translateZ(0);
            }

            .ob-mainAsset-wrapper {
                display: block;
                height: auto;
                overflow: visible;
                transform: none !important;
                transition: all 0.5s ease-in-out;
                width: 100%;

                img {
                    height: auto;
                    max-height: none;
                    width: 100%;
                }
            }
        }

        &-textContent {
            flex-wrap: wrap;
            opacity: 0;
            transition: all 0.5s ease-in-out;

            h2,
            .ob-bodyText {
                transition: all 0.5s ease-in-out;
                width: 100%;
            }

            .ob-heading {
                font-size: 1.8rem;
                line-height: 2.4rem;
                @include font-family('hurme-regular');

                @include media-up(sm) {
                    font-size: 3.5rem;
                    line-height: 4rem;
                    @include font-family('hurme-light');
                }
            }

            .ob-bodyText {
                font-size: 1.6rem;
                line-height: 2.4rem;
                @include font-family('hurme-regular');

                @include media-up(sm) {
                    @include font-family('hurme-light');
                    font-size: 3rem;
                    line-height: 4rem;
                }

                @include media-down(md) {
                    strong {
                        @include font-family('semi-bold');
                    }
                }
            }
        }
    }
}

.spotlight-frame-1 {
    .ob-spotlightVideo-container {
        .step-1 & {
            opacity: 1;

            .ob-custom-video-control {
                display: block;
            }
        }
    }

    .ob-spotlightVideo__intro {
        padding: 0 1.5rem;
    }
}

.spotlight-breakthrough-technology {
    .ob-spotlightVideo-container {
        .step-1 & {
            opacity: 1;

            .ob-custom-video-control {
                display: block;
            }
        }
    }
}

.spotlight-brush-comparison-1,
.spotlight-brush-comparison-2 {
    .ob-spotlightContentBlock {

        &-textContent {
            margin-left: 1.5rem;
            margin-right: auto;
            margin-top: 111%;
            position: static;
            width: 36%;

            @include media-up(md) {
                margin-left: 50%;
                margin-right: 0;
                width: 36%;
            }

            .ob-heading.ob-display-2-xl {
                @include font-family('hurme-regular');

                font-size: 2.2rem;
                left: 50%;
                line-height: 2.4rem;
                margin-left: 25rem;
                position: absolute;
                text-align: center;
                top: 6rem;
                width: 23rem;

                .spotlight-brush-compare-small {
                    @include font-family('hurme-light');
                    display: block;
                    font-size: 1.8rem;
                }

                strong {
                    @include font-family('semi-bold');
                }

                @include media-up(sm) {
                    font-size: 3rem;
                    line-height: 3.5rem;
                    top: 4rem;

                    .spotlight-brush-compare-small {
                        font-size: 2.8rem;
                    }
                }

                @include media-up(md) {
                    left: 4%;
                    margin-left: 25.8rem;
                    text-align: right;
                    top: 18rem;
                }
            }
        }
    }
}

.spotlight-brush-comparison-1 {
    .ob-spotlightContentBlock {

        &-mainAsset-container,
        &-secondaryAsset-container {
            animation: none;
            display: block;
            position: absolute;
            -webkit-backface-visibility: hidden;

            .step-1 &,
            .step-2 & {
                opacity: 1;
            }

            .ob-mainAsset-wrapper {
                width: auto;
            }
        }

        &-mainAsset-container {
            left: 120%;
            top: 25.9rem;
            width: 22rem;
            z-index: 1;

            @include media-up(md) {
                left: 8%;
                margin-left: 81rem;
                top: 23rem;
                width: 36rem;
                z-index: 1;
            }

            .pre-step & {
                left: 50%;
                margin-left: -16.8rem;
                top: 100%;
                width: 40.6rem;
                z-index: 2;

                @include media-up(md) {
                    left: 8%;
                    margin-left: -7.2rem;
                    width: 65rem;
                }
            }

            .step-1 & {
                left: 50%;
                margin-left: -16.8rem;
                opacity: 1;
                top: 12rem;
                width: 40.6rem;
                z-index: 2;

                @include media-up(md) {
                    left: 8%;
                    margin-left: -7.2rem;
                    top: 2.7rem;
                    width: 65rem;
                }
            }

            .scroller-frame.scroll-backwards .step-1 & {
                animation: back-to-front-mobile 0.75s ease-in-out 1;
                transition: none;

                @include media-up(md) {
                    animation: back-to-front 0.75s ease-in-out 1;
                }
            }

            .scroller-frame:not(.scroll-backwards) .step-2 & {
                animation: front-to-back-mobile 0.75s ease-in-out 1;
                transition: none;

                @include media-up(md) {
                    animation: front-to-back 0.75s ease-in-out 1;
                }
            }

            .step-2 & {
                left: 50%;
                margin-left: 0.5rem;
                top: 25.9rem;
                width: 22rem;
                z-index: 1;

                @include media-up(md) {
                    left: 8%;
                    margin-left: 19.5rem;
                    opacity: 1;
                    top: 23rem;
                    width: 36rem;
                }
            }
        }

        &-secondaryAsset-container {
            left: 87%;
            top: 12rem;
            width: 40.6rem;
            z-index: 2;

            @include media-up(md) {
                left: 8%;
                margin-left: 46rem;
                top: 2.7rem;
                width: 65rem;
            }

            .pre-step & {
                left: 50%;
                margin-left: 0.5rem;
                top: 100%;
                width: 22rem;
                z-index: 1;

                @include media-up(md) {
                    left: 8%;
                    margin-left: 19.5rem;
                    width: 36rem;
                }
            }

            .step-1 & {
                left: 50%;
                margin-left: 0.5rem;
                top: 25.9rem;
                width: 22rem;
                z-index: 1;

                @include media-up(md) {
                    left: 8%;
                    margin-left: 19.5rem;
                    opacity: 1;
                    top: 23rem;
                    width: 36rem;
                }
            }

            .scroller-frame.scroll-backwards .step-1 & {
                animation: front-to-back-mobile 0.75s ease-in-out 1;
                transition: none;

                @include media-up(md) {
                    animation: front-to-back 0.75s ease-in-out 1;
                }
            }

            .scroller-frame:not(.scroll-backwards) .step-2 & {
                animation: back-to-front-mobile 0.75s ease-in-out 1;
                transition: none;

                @include media-up(md) {
                    animation: back-to-front 0.75s ease-in-out 1;
                }
            }

            .step-2 & {
                left: 50%;
                margin-left: -16.8rem;
                top: 12rem;
                width: 40.6rem;
                z-index: 2;

                @include media-up(md) {
                    left: 8%;
                    margin-left: -7.2rem;
                    top: 2.7rem;
                    width: 65rem;
                }
            }
        }

        &-textContent {
            margin-top: -50%;
            opacity: inherit;

            .pre-step & {
                margin-top: 60%;
            }

            .step-1 &,
            .step-2 & {
                margin-top: 0;
            }

            .ob-heading.ob-display-2-xl {
                left: 50%;
                margin-left: 25rem;
                opacity: 0;

                @include media-up(md) {
                    left: 4%;
                    margin-left: 25.8rem;
                }

                .pre-step & {
                    left: 50%;
                    margin-left: -45rem;

                    @include media-up(md) {
                        left: 4%;
                        margin-left: -25.8rem;
                    }
                }

                .step-1 & {
                    left: 50%;
                    margin-left: -9.5rem;
                    opacity: 1;

                    @include media-up(md) {
                        left: 4%;
                        margin-left: 0.8rem;
                    }
                }
            }

            .ob-bodyText {
                opacity: 0;

                .step-1 &,
                .step-2 & {
                    opacity: 1;
                }
            }
        }
    }
}

.spotlight-brush-comparison-2 {
    .ob-spotlightContentBlock {

        &-textContent {
            opacity: inherit;

            .ob-heading.ob-display-2-xl {
                opacity: 0;

                .pre-step & {
                    left: 50%;
                    margin-left: -45rem;

                    @include media-up(md) {
                        left: 4%;
                        margin-left: -25.8rem;
                    }
                }

                .step-1 & {
                    left: 50%;
                    margin-left: -9.5rem;
                    opacity: 1;

                    @include media-up(md) {
                        left: 4%;
                        margin-left: 0.8rem;
                    }
                }
            }
        }
    }
}

.spotlight-pressure-sensor-red,
.spotlight-pressure-sensor-green {

    .ob-spotlightContentBlock {

        &-mainAsset-container {

            .step-1 & {
                opacity: 1;
            }
        }

        &-textContent {
            margin-left: 50%;
            margin-top: -50%;
            width: 39%;

            .pre-step & {
                margin-top: 60%;
            }

            .step-1 & {
                margin-top: 0;
                opacity: 1;

                .spotlight-red-flash {
                    animation: spotlight-red-flash 1.5s ease-in-out 1 0.5s;
                }

                .spotlight-green-flash {
                    animation: spotlight-green-flash 1.5s ease-in-out 1 0.5s;
                }
            }

            @include media-down(sm) {
                align-self: flex-start;
                margin-left: 43%;
                margin-right: auto;
                padding-top: 4rem;
                width: 50%;

                .ob-bodyText {
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                }
            }

            @include media-down(xtiny) {
                .spanish-spotlight & {
                    margin-left: 43%;
                    width: 50%;
                }
            }
        }
    }
}

.spotlight-pressure-sensor-red {

    .ob-spotlightContentBlock {

        &-mainAsset-container {
            margin-left: 30%;
            margin-top: 0;
            transform: translateX(-50%);
            width: $desktopGlowBrushImgWidthStep2;

            .pre-step & {
                margin-left: 0;
                margin-top: 13%;
                width: $desktopGlowBrushImgWidthStep1;
            }

            .step-1 & {
                margin-left: 36%;
                margin-top: 13%;
                width: $desktopGlowBrushImgWidthStep1;
            }

            @include media-down(sm) {
                margin-left: calc(30% + 83px);
                margin-top: 16.2rem;
                width: $mobileGlowBrushImgWidthStep2;

                .pre-step & {
                    margin-top: 23.6rem;
                    width: $mobileGlowBrushImgWidthStep1;
                }

                .step-1 & {
                    margin-left: calc(30% + 105px);
                    margin-top: 23.6rem;
                    width: $mobileGlowBrushImgWidthStep1;
                }
            }

            @include media-down(xtiny) {
                margin-left: calc(30% + 45px);

                .step-1 & {
                    margin-left: calc(30% + 40px);
                }
            }
        }

        &-textContent {
            .ob-mainAsset-wrapper {
                margin-left: -1.6rem;
                width: 15.5rem;

                .german-spotlight & {
                    margin-left: -3.3rem;
                    margin-right: -3.8rem;
                    margin-top: -3.4rem;
                    width: 22rem;
                }

                .spanish-spotlight & {
                    margin-right: -3.4rem;
                    width: 20rem;
                }

                img {
                    height: auto;
                    width: 100%;
                }

                @include media-up(sm) {
                    margin-left: -16%;
                    margin-right: -15%;
                    width: 130%;

                    .german-spotlight &,
                    .spanish-spotlight &,
                    .ob-spotlight-nl & {
                        margin-left: -6%;
                        margin-right: -15%;
                        width: 120%;
                    }

                    .german-spotlight & {
                        margin-top: 0;
                        max-width: 45rem;
                    }
                }
            }
        }
    }
}

.spotlight-pressure-sensor-green {

    .ob-spotlightContentBlock {

        &-mainAsset-container {
            margin-left: 30%;
            margin-top: 0;
            transform: translateX(-50%);
            width: $desktopGlowBrushImgWidthStep2;

            .pre-step & {
                margin-left: 36%;
                margin-top: 13%;
                width: $desktopGlowBrushImgWidthStep1;
            }

            @include media-down(sm) {
                margin-left: -$mobileGlowBrushImgWidthStep2;
                margin-top: 16.2rem;
                width: $mobileGlowBrushImgWidthStep2;

                .pre-step & {
                    margin-left: calc(30% + 105px);
                    margin-top: 23.6rem;
                    width: $mobileGlowBrushImgWidthStep1;
                }

                .step-1 & {
                    margin-left: 52%;
                    margin-left: calc(30% + 83px);
                    margin-top: 16.2rem;
                }
            }

            @include media-down(xtiny) {
                .pre-step & {
                    margin-left: calc(30% + 40px);
                }

                .step-1 & {
                    margin-left: calc(30% + 45px);
                }
            }
        }
    }
}

.spotlight-interactive-display {
    .ob-spotlightContentBlock {

        &-wrapper {
            align-items: flex-end;
            @include media-up(sm) {
                align-items: center;
            }
        }

        &-secondaryAsset-container {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: all 0.5s ease-out, opacity 0.1s ease-out;

            .step-1 & {
                transition: all 0.5s ease-out, opacity 0.5s ease-out 0.25s;
            }
        }

        &-mainAsset-container,
        &-secondaryAsset-container {
            margin-left: 50%;
            margin-top: -11.3rem;
            transform: translateX(-50%);
            width: $mobileInteractiveImgWidthStep1;

            .step-1 & {
                opacity: 1;
                width: $mobileInteractiveImgWidthStep2;
            }

            @include media-up(sm) {
                margin-left: 80%;
                margin-top: 0;
                width: $tabletInteractiveImgWidthStep1;

                .step-1 & {
                    width: $tabletInteractiveImgWidthStep2;
                }
            }

            @include media-up(md) {
                width: $desktopInteractiveImgWidthStep1;

                .step-1 & {
                    width: $desktopInteractiveImgWidthStep2;
                }
            }
        }

        &-textContent {
            margin-bottom: 65px;
            margin-left: 0;
            max-width: 360px;
            padding: 0 1rem;
            text-align: center;
            transition: all 0.5s ease-out;
            width: 100%;
            z-index: map-get($zindex, one);

            .pre-step & {
                margin-top: 300px;
            }

            .step-1 & {
                @extend %spotlight-gradient-background;
                margin-top: 0;
                opacity: 1;
            }

            .step-2 & {
                margin-top: -300px;
            }

            .ob-heading.ob-display-2-xl {
                font-size: 1.8rem;
                line-height: 2.6rem;
                letter-spacing: 0.014em;
                opacity: 0;
                transition: opacity 1.2s ease-out;

                .step-1 & {
                    opacity: 1;
                }
            }

            .ob-mainAsset-wrapper  {
                margin: 5px 0 0;
                opacity: 0;
                transition: opacity 1.5s ease-in-out;
                width: 100%;

                .step-1 & {
                    opacity: 1;
                }

                img {
                    height: auto;
                    position: relative;
                    width: 100%;
                }

                @include media-up(sm) {
                    margin: 10px -14% 0;
                    width: 128%;
                }
            }

            @include media-up(sm) {
                max-width: 48%;
                margin-left: -38%;
                margin-top: 111%;
                margin-bottom: 0;

                .ob-heading.ob-display-2-xl {
                    font-size: 3rem;
                    letter-spacing: 0.05em;
                    line-height: 4rem;
                }
            }

            @include media-up(md) {
                max-width: 58%;
            }

            @include media-down(sm) {
                bottom: 0;
                position: fixed;
            }
        }
    }
}

.spotlight-wakes-up {

    .ob-spotlightContentBlock {

        &-background {
            opacity: 0;
            .step-1 & {
                opacity: 1;
                transition: opacity 2s cubic-bezier(1,-0.03, 0.46, 0.96);
            }
            .step-2 & {
                transition: opacity 0s cubic-bezier(1,-0.03, 0.46, 0.96);
                opacity: 0;
            }
        }

        &-wrapper {
            align-items: start;
            @include media-up(md) {
                align-items: center;
            }
        }

        &-mainAsset-container,
        &-secondaryAsset-container {
            margin-left: 30%;
            margin-top: -4.2rem;
            transform: translateX(-50%);
            width: $mobilePerspectiveBrushImgWidth;

            .ob-mainAsset-wrapper {
                transform: rotate(5deg) !important;

                @include media-up(md) {
                    margin-top: 0;
                }
            }

            @include media-up(md) {
                width: $desktopPerspectiveBrushImgWidth;
            }
        }

        &-mainAsset-container {
            .step-1 &,
            .step-2 &,
            .step-3 & {
                opacity: 1;
            }
        }

        &-secondaryAsset-container {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;

            .step-1 & {
                opacity: 1;
            }
        }

        &-textContent {
            left: 40%;
            margin-left: 6.5rem;
            margin-right: auto;
            margin-top: 100px;
            min-width: 120px;
            opacity: 0;
            width: 33%;

            @include media-up(sm) {
                align-self: center;
                left: auto;
                margin-left: 45%;
                margin-right: 0;
                margin-top: -300px;
                width: 50%;

                .german-spotlight & {
                    margin-left: 35%;
                    width: 40%;
                }

                .step-1 & {
                    margin-top: 0;
                }
            }

            @include media-up(md) {
                width: 40%;
                margin-left: 60%;

                .german-spotlight & {
                    margin-left: 42%;
                    width: 28%;
                }
            }

            @include media-up(lg) {

                .german-spotlight & {
                    margin-left: 45%;
                    width: 25%;
                }
            }

            .pre-step & {
                margin-top: 300px;
            }

            .step-1 & {
                opacity: 1;
            }

            .step-2 & {
                margin-top: -300px;
            }

            .ob-heading {
                text-transform: uppercase;

                .step-1 & {
                    color: #3D3D41;
                    transition: color 2s cubic-bezier(1,-0.03, 0.46, 0.96), opacity 1s ease-in-out;
                }
                .step-2 & {
                    transition: color 0s cubic-bezier(1,-0.03, 0.46, 0.96);
                }
                .pre-step & {
                    transition: color 0s cubic-bezier(1,-0.03, 0.46, 0.96);
                }
            }
        }
    }
}

.spotlight-countdown {

    .ob-spotlightContentBlock {

        &-background {
            opacity: 0;
            transition: opacity 0.5s ease;

            .step-1 & {
                opacity: 1;
                transition: opacity 1.5s ease 2s;
            }

            @include media-down(sm) {
                display: none;
            }
        }

        &-wrapper {
            align-items: start;
            justify-content: flex-start;

            @include media-up(md) {
                align-items: center;
            }
        }

        &-mainAsset-container,
        &-secondaryAsset-container {
            margin-left: 30%;
            margin-top: -4.2rem;
            transform: translateX(-50%);
            width: $mobilePerspectiveBrushImgWidth;

            .ob-mainAsset-wrapper {
                transform: rotate(5deg) !important;

                @include media-up(md) {
                    margin-top: 0;
                }
            }

            @include media-up(md) {
                width: $desktopPerspectiveBrushImgWidth;
            }
        }

        &-mainAsset-container {
            .step-1 &,
            .step-2 & {
                opacity: 1;
            }
        }

        &-secondaryAsset-container {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;

            .step-1 & {
                opacity: 1;
            }
        }

        &-textContent {
            left: 40%;
            margin-left: 6.5rem;
            margin-right: auto;
            margin-top: -80%;
            min-width: 120px;
            opacity: 0;
            width: 33%;

            .pre-step & {
                margin-top: 37%;
            }

            .step-1 & {
                margin-top: 100px;
                opacity: 1;
            }

            .ob-heading {
                text-transform: uppercase;
            }

            @include media-down(sm) {
                align-self: flex-start;
            }

            @include media-up(sm) {
                .step-1 & {
                    margin-top: 0;
                }
            }

            @include media-down(md) {
                .spanish-spotlight & {
                    left: 35%;
                    width: 42%;
                }
            }


            @include media-up(md) {
                left: auto;
                margin-left: 60%;
                width: 40%;
            }
        }
    }
}

.spotlight-smile {

    .ob-spotlightContentBlock {

        &-wrapper {
            align-items: start;
            justify-content: flex-start;

            @include media-up(md) {
                align-items: center;
            }
        }

        &-mainAsset-container {
            margin-left: 30%;
            margin-top: -4.2rem;
            transform: translateX(-50%);
            width: $mobilePerspectiveBrushImgWidth;

            .step-1 & {
                opacity: 1;
            }

            .ob-mainAsset-wrapper {
                transform: rotate(5deg) !important;
            }

            @include media-up(md) {
                margin-left: 30%;
                width: $desktopPerspectiveBrushImgWidth;
            }
        }

        &-textContent {
            left: 40%;
            margin-left: 6.5rem;
            margin-right: auto;
            margin-top: -80%;
            min-width: 120px;
            opacity: 0;
            width: 33%;

            .step-1 & {
                margin-top: 100px;
                opacity: 1;
            }

            @include media-down(sm) {
                align-self: flex-start;
            }

            @include media-up(sm) {
                .step-1 & {
                    margin-top: 0;
                }
            }

            @include media-up(md) {
                left: auto;
                margin-left: 60%;
                width: 40%;
            }

            .pre-step & {
                margin-top: 37%;
            }

            .ob-heading {
                text-transform: uppercase;
            }
        }
    }
}

.spotlight-modes-display {

    .ob-spotlightContentBlock {
        &-wrapper {
            justify-content: flex-start;
        }

        &-mainAsset-container,
        &-additionalAssetList-container {
            transform: translateX(-50%);

            @include media-down(sm) {
                margin-top: -26%;
                width: $mobileBrushImgWidthDefault;

                .pre-step & {
                    margin-left: 10%;
                    margin-top: -15%;
                    width: $mobileBrushImgWidth;
                }

                .step-1 & {
                    margin-left: 25%;
                    margin-top: -15%;
                    width: $mobileBrushImgWidth;
                }

                .step-2 &,
                .step-3 & {
                    margin-left: 50%;
                }

                .step-4 & {
                    margin-left: 30%;
                }
            }

            @include media-up(sm) {
                margin-left: 30%;
                margin-top: -10%;
                width: $tabletBrushImgWidth;
            }

            @include media-up(md) {
                width: $desktopBrushImgWidth;
            }
        }

        &-mainAsset-container {
            .step-1 &,
            .step-2 &,
            .step-4 & {
                opacity: 1;
            }

            .step-3 & {
                opacity: 0.35;
            }
        }

        &-additionalAssetList-container {

            .ob-mainAsset-wrapper {
                opacity: 0;
                position: absolute;

                &:nth-child(3) {
                    opacity: 1;
                }
            }

            .scroller-frame.scroll-backwards .pre-step & {
                .ob-mainAsset-wrapper {
                    &:nth-child(3) {
                        opacity: 1;
                    }
                }
            }

            .pre-step & {
                .ob-mainAsset-wrapper {
                    opacity: 0;
                    position: absolute;
                }
            }

            .step-1 & {
                opacity: 1;

                .ob-mainAsset-wrapper {

                    &:first-child {
                        animation: mode-display-icon-01 1.5s ease-in-out 1 0.25s both;
                        opacity: 0;
                    }

                    &:nth-child(2) {
                        animation: mode-display-icon-02 1.5s ease-in-out 1 0.25s both;
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        animation: mode-display-icon-03 1.5s ease-in-out 1 0.25s both;
                        opacity: 0;
                    }
                }
            }

            @include media-ie() {
                img {
                    transform: translateZ(0px) translateY(-50%);
                }

            }
        }

        &-textContent {
            left: 40%;
            margin-left: 3.5rem;
            margin-right: auto;
            margin-top: -80%;
            width: 48%;

            .pre-step & {
                margin-top: 37%;
            }

            .step-1 & {
                margin-top: 0;
                opacity: 1;
            }

            .spotlight-mode-count {
                display: block;
                opacity: 0;

                &-large {
                    @include font-family('hurme-light');
                    display: block;
                    font-size: 3.2rem;
                    line-height: 4rem;
                    text-transform: uppercase;

                    .ob-spotlight-nl & {
                        @media screen and (max-width: 570px) {
                            font-size: 2rem;
                        }
                    }

                    @include media-up(sm) {
                        font-size: 6.4rem;
                        line-height: 7.2rem;

                        .ob-spotlight-nl & {
                            font-size: 4.3rem;
                            line-height: 5rem;
                            + span {
                                margin-top: -1rem;
                                position: relative;
                                display: block;
                            }
                        }
                    }
                }

                &-wrapper {
                    display: inline-block;
                    position: relative;
                }

                .step-1 & {
                    opacity: 0;

                    &-step-1 {
                        animation: mode-counter-step-1 1.5s ease-in-out 1 0.25s both;
                    }

                    &-step-2 {
                        animation: mode-counter-step-2 1.5s ease-in-out 1 0.25s both;
                    }

                    &-step-3 {
                        animation: mode-counter-step-3 1.5s ease-in-out 1 0.25s both;
                    }

                    &-step-4 {
                        animation: mode-counter-step-4 1.5s ease-in-out 1 0.25s both;
                    }

                    &-step-5 {
                        animation: mode-counter-step-5 1.5s ease-in-out 1 0.25s both;
                    }

                    &-step-6 {
                        animation: mode-counter-step-6 1.5s ease-in-out 1 0.25s both;
                    }

                    &-step-7 {
                        animation: mode-counter-step-7 1.5s ease-in-out 1 0.25s both;
                    }
                }

                &:last-child {
                    opacity: 1;
                }

                &:not(:last-child) {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transform-origin: 0 0;
                }
            }

            @include media-down(sm) {
                text-align: center !important;
            }

            @include media-up(md) {
                left: auto;
                margin-left: 50%;
                margin-right: 0;
                width: 45%;
            }
        }
    }
}

.spotlight-bluetooth {

    .ob-spotlightContentBlock {

        &-additionalAssetList-container {
            transform: translateX(-50%);

            .step-1 & {
                opacity: 1;
            }

            @include media-down(sm) {
                margin-left: 50%;
                margin-top: -26%;
                width: $mobileBrushImgWidthDefault;

                .pre-step & {
                    margin-left: 25%;
                    margin-top: -15%;
                    width: $mobileBrushImgWidth;
                }

                .step-1 & {
                    margin-left: 50%;
                }
            }

            @include media-up(sm) {
                margin-left: 30%;
                margin-top: -10%;
                width: $tabletBrushImgWidth;
            }

            @include media-up(md) {
                width: $desktopBrushImgWidth;
            }
        }

        &-textContent {
            margin-left: 50%;
            margin-top: -80%;
            transition: all 0.5s ease-in-out;
            width: 36%;

            .pre-step & {
                margin-top: 37%;
            }

            .step-1 & {
                @extend %spotlight-gradient-background;
                margin-top: 0;
                opacity: 1;
            }

            @include media-down(sm) {

                .ob-bodyText {
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                }
            }

            @include media-down(sm) {
                align-self: flex-end;
                bottom: 0;
                margin: 0 auto 6rem;
                max-width: 31rem;
                position: fixed;
                text-align: center !important;
                transform: translateX(100%);
                width: 70%;

                .step-1 & {
                    transform: none;
                }

                .pre-step & {
                    transform: translateX(-100%);
                }
            }
        }
    }
}

.spotlight-3d-tracking {

    .ob-spotlightContentBlock {

        &-mainAsset-container,
        &-additionalAssetList-container {
            transform: translateX(-50%);

            .step-1 & {
                opacity: 1;
            }

            @include media-down(sm) {
                margin-left: 30%;
                margin-top: -26%;
                width: $mobileBrushImgWidthDefault;

                .pre-step &,
                .step-1 & {
                    margin-left: 50%;
                }
            }

            @include media-up(sm) {
                margin-left: 30%;
                margin-top: -10%;
                width: $tabletBrushImgWidth;
            }

            @include media-up(md) {
                width: $desktopBrushImgWidth;
            }
        }

        &-mainAsset-container {

            .step-1 & {
                .ob-mainAsset-wrapper {
                    animation: spotlight-tracking-icon 0.5s ease-in-out 1 0.75s both;
                }
            }
        }

        &-additionalAssetList-container {

            .step-1 & {
                .ob-mainAsset-wrapper {
                    &.io-spotlight-3d-tracking-copy01 {
                        animation: spotlight-tracking-copy-01 4s ease-in-out 1 1s both;
                    }

                    &.io-spotlight-3d-tracking-copy02 {
                        animation: spotlight-tracking-copy-02 4s ease-in-out 1 1s both;
                    }

                    &.io-spotlight-3d-tracking-copy03 {
                        animation: spotlight-tracking-copy-03 4s ease-in-out 1 1s both;
                    }

                    &.io-spotlight-3d-tracking-ring-inner {
                        animation: spotlight-tracking-ring-inner 4s ease-in-out 1 1s both;
                    }

                    &.io-spotlight-3d-tracking-ring-middle {
                        animation: spotlight-tracking-ring-middle 4s ease-in-out 1 1s both;
                    }

                    &.io-spotlight-3d-tracking-ring-outer {
                        animation: spotlight-tracking-ring-outer 4s ease-in-out 1 1s both;
                    }

                    &.io-spotlight-3d-tracking-label01 {
                        animation: spotlight-tracking-label-01 4s ease-in-out 1 1s both;
                    }

                    &.io-spotlight-3d-tracking-label02 {
                        animation: spotlight-tracking-label-02 4s ease-in-out 1 1s both;
                    }

                    &.io-spotlight-3d-tracking-label03 {
                        animation: spotlight-tracking-label-03 4s ease-in-out 1 1s both;
                    }
                }
            }

            .ob-mainAsset-wrapper {
                -webkit-backface-visibility: hidden;
                opacity: 0;
                position: absolute;
            }

            @media only screen and (-ms-high-contrast:active), (-ms-high-contrast:none) {
                img {
                    transform: translateZ(0px) translateY(-50%);
                }
            }
        }

        &-textContent {
            margin-left: 50%;
            margin-top: -80%;
            width: 36%;
            z-index: 1;

            .pre-step & {
                margin-top: 37%;
            }

            .step-1 & {
                @extend %spotlight-gradient-background;
                margin-top: 0;
                opacity: 1;
            }

            @include media-down(sm) {
                bottom: 0;
                align-self: flex-end;
                margin: 0 auto 6rem;
                max-width: 31rem;
                position: fixed;
                text-align: center !important;
                transform: translateX(-100%);
                width: 70%;

                .spanish-spotlight & {
                    width: 88%;
                }

                .step-1 & {
                    transform: none;
                }

                .ob-bodyText {
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                }
            }
        }
    }
}

html[lang="pt"] {
  .spotlight-3d-tracking {
    .ob-mainAsset-wrapper.io-spotlight-3d-tracking-copy01 {
      transform: translateY(30px)!important;
      @include media-up(md) {
        transform: translateY(50px)!important;
      }
    }

    .ob-mainAsset-wrapper.io-spotlight-3d-tracking-copy02,
    .ob-mainAsset-wrapper.io-spotlight-3d-tracking-copy03 {
      transform: translateY(10px)!important;
      @include media-up(md) {
        transform: translateY(25px)!important;
      }
    }

    .ob-mainAsset-wrapper.io-spotlight-3d-tracking-label01,
    .ob-mainAsset-wrapper.io-spotlight-3d-tracking-label02 {
      transform: translateY(10px)!important;
      @include media-up(md) {
        transform: translateY(15px)!important;
      }
    }

    .ob-mainAsset-wrapper.io-spotlight-3d-tracking-label03 {
      transform: translateY(12px)!important;
      @include media-up(md) {
        transform: translateY(24px)!important;
      }
    }
  }
}

.spotlight-breakthrough-intro {
    .ob-spotlightContentBlock {

        &-background {
            margin-top: 0;
            opacity: 0;
            transition: opacity ease 0.5s;

            .step-1 & {
                opacity: 1;
            }
        }

        &-textContent {
            margin-left: auto;
            margin-right: auto;
            margin-top: -80%;
            max-width: 113rem;
            width: 100%;

            .pre-step & {
                margin-top: 37%;
            }

            .step-1 & {
                margin-top: 0;
                opacity: 1;
            }

            .ob-heading.ob-display-2-xl {
                display: none;
                font-size: 3.6rem;
                line-height: 4.4rem;

                @include media-up(sm) {
                    display: none;
                }
            }

            .ob-mainAsset-wrapper {
                margin: 0 auto;
                width: 100%;
                height: 100%;

                @include media-down(sm) {
                    min-height: 100px;
                }

                img {
                    height: auto;
                    width: 100%;
                }

                .spanish-spotlight & {
                    max-width: 80rem;
                }
            }

            .ob-bodyText {
                font-size: 1.8rem;
                line-height: 2.6rem;
            }

            @include media-up(sm) {
                .ob-bodyText {
                    font-size: 3rem;
                    line-height: 4rem;
                    margin-left: auto;
                    margin-right: auto;
                    width: 60%;
                }
            }
        }
    }
}

.spotlight-battery {

    .ob-spotlightContentBlock {

        &-mainAsset-container {
            transform: translateX(-50%);
            transition: all 0.5s ease-out, opacity 0.1s ease-out;

            .step-1 & {
                opacity: 1;
                transition: all 0.5s ease-out, opacity 0.5s ease-out 0.25s;
            }

            @include media-down(sm) {
                margin-top: -26%;
                width: $mobileBrushImgWidthDefault;

                .pre-step & {
                    margin-left: 50%;
                }

                .step-1 & {
                    margin-left: 30%;
                }
            }

            @include media-up(sm) {
                margin-left: 30%;
                margin-top: -10%;
                width: $tabletBrushImgWidth;
            }

            @include media-up(md) {
                width: $desktopBrushImgWidth;
            }
        }

        &-textContent {
            margin-left: 50%;
            margin-top: -80%;
            width: 36%;

            .pre-step & {
                margin-top: 37%;
            }

            .step-1 & {
                margin-top: 0;
                opacity: 1;
            }

            @include media-up(md) {
                .german-spotlight & {
                    margin-left: 45%;
                    width: 31%;
                }
            }
        }
    }
}

.spotlight-clip-with-base-1 {
    .ob-spotlightContentBlock {
        $spotlight-clip-with-base-1-anim-duration: 1.5s;

        &-mainAsset-container,
        &-secondaryAsset-container {

            .step-1 &, .step-2 & {
                opacity: 1;
            }

            .ob-mainAsset-wrapper {
                position: absolute;
            }
        }

        &-mainAsset-container {
            margin-left: 28%;
            transform: translateX(-50%);
            width: $mobileMagneticBrushImgWidthStep1;
            z-index: 1;

            .step-3 &, .step-4 & {
                margin-left: 50%;

                .ob-mainAsset-wrapper {
                    bottom: 50%;
                    margin-bottom: -176px;
                }
            }

            .ob-mainAsset-wrapper {
                bottom: 50%;
                margin-bottom: -24px;

                .scroller-frame:not(.scroll-backwards) .step-1 & {
                    animation-duration: $spotlight-clip-with-base-1-anim-duration;
                    animation-name: brushToBase;
                }

                @keyframes brushToBase {
                    from {
                        bottom: 50%;
                        margin-bottom: 30px
                    }

                    to {
                        bottom: 50%;
                        margin-bottom: -24px;
                    }
                }

                @include media-up(sm) {
                    @keyframes brushToBase {
                        from {
                            bottom: 50%;
                            margin-bottom: 185px;
                        }

                        to {
                            bottom: 50%;
                            margin-bottom: -30px;
                        }
                    }
                }
            }

            @include media-up(sm) {
                margin-left: 30%;
                transform: translateX(-50%);
                width: $desktopMagneticBrushImgWidthStep1;

                .ob-mainAsset-wrapper {
                    bottom: 50%;
                    margin-bottom: -30px;
                }

                .step-3 &, .step-4 & {
                    margin-left: 50%;
                    transform: translateX(-50%);
                    width: $desktopMagneticBrushImgWidthStep2;

                    .ob-mainAsset-wrapper {
                        bottom: 50%;
                        margin-bottom: -220px;
                    }
                }
            }
        }

        &-secondaryAsset-container {
            height: 100%;
            left: 0;
            margin-left: 28%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            width: $mobileMagneticBaseImgWidthStep1;

            .step-3 &, .step-4 & {
                margin-left: 50%;

                .ob-mainAsset-wrapper {
                    margin-top: 139px;
                    top: 50%;
                }
            }

            .ob-mainAsset-wrapper {
                margin-top: -13px;
                top: 50%;

                .scroller-frame:not(.scroll-backwards) .step-1 & {
                    animation-duration: $spotlight-clip-with-base-1-anim-duration;
                    animation-name: baseToBrush;
                }

                @keyframes baseToBrush {
                    from {
                        margin-top: 44px;
                        top: 50%;
                    }

                    to {
                        margin-top: -13px;
                        top: 50%;
                    }
                }

                @include media-up(sm) {
                    @keyframes baseToBrush {
                        from {
                            margin-top: 181px;
                            top: 50%;
                        }

                        to {
                            margin-top: -34px;
                            top: 50%;
                        }
                    }
                }
            }

            @include media-up(sm) {
                margin-left: 30%;
                width: $desktopMagneticBaseImgWidthStep1;

                .ob-mainAsset-wrapper {
                    margin-top: -34px;
                    top: 50%;
                }

                .step-3 &, .step-4 & {
                    width: $desktopMagneticBaseImgWidthStep2;

                    .ob-mainAsset-wrapper {
                        margin-top: 170px;
                        top: 50%;
                    }
                }
            }
        }

        &-textContent {
            width: 40%;
            margin-left: 60%;

          html[lang="pt"] & {
            width: 50%;
          }

            .pre-step & {
                margin-top: 30%;
            }

            .step-1 & {
                margin-top: 0;
                opacity: 1;
            }

            .step-2 & {
                margin-top: -50%;
            }

            .ob-heading.ob-display-2-xl {
                width: auto;
                margin-right: 6rem;
                @include display-3-xl;
                color: $base-color__white;
                @include media-up(sm) {
                    margin-right: 30rem;
                }


                @include media-down(sm) {
                    font-size: 2.2rem;
                    line-height: 2.4rem;
                    margin: 0 auto;
                    text-align: center;
                }
            }

            .spotlight-clip-with-base-1--text-top, .spotlight-clip-with-base-1--text-bottom {
                display: block;
            }

            .spotlight-clip-with-base-1--text-top {
                margin-bottom: 0;
                .step-1 & {
                    animation-duration: $spotlight-clip-with-base-1-anim-duration;
                    animation-name: joinText;
                }
            }

            @keyframes joinText {
                from {
                    margin-bottom: 35rem;
                }

                to {
                    margin-bottom: 0;
                }
            }

            @include media-down(sm) {
                .spanish-spotlight &,
                .german-spotlight & ,
                .ob-spotlight-nl & {
                    margin-left: 48%;
                    margin-top: -30%;
                }

                .german-spotlight & {
                    .ob-heading.ob-display-2-xl {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

.spotlight-clip-with-base-1.spotlight-clip-with-base-1--ja-jp .ob-spotlightContentBlock-textContent .ob-heading.ob-display-2-xl {
    @include media-up(sm) {
        margin-right: 0;
    }
}

.spotlight-clip-with-base-1.spotlight-clip-with-base-1--ja-jp .ob-spotlightContentBlock .ob-spotlightContentBlock-textContent .ob-heading.ob-display-2-xl {
    @include media-up(sm) {
        margin-right: 0;
    }
}

.spotlight-clip-with-base-2 {
    .ob-spotlightContentBlock {
        &-textContent {
            margin-left: auto;
            margin-top: 111%;
            width: 100%;

            @include media-up(sm) {
                margin-left: 60%;
                width: 36%;
            }

            .pre-step & {
                margin-top: 160%;

                @include media-up(sm) {
                    margin-top: 60%;
                }
            }

            .step-1 & {
                margin-top: 24rem;
                opacity: 1;

                html[lang="ja"] & {
                    margin-top: 28rem;

                    @include media-up(xtiny) {
                        margin-top: 32rem;
                    }

                    @include media-up(sm) {
                        margin-top: 0;
                    }
                }

                @include media-up(xtiny) {
                    margin-top: 32rem;
                }

                @include media-up(sm) {
                    margin-top: 0;
                }
            }

            .step-2 & {
                margin-top: -100%;
            }

            .ob-heading.ob-display-2-xl {
                font-size: 1.8rem;
                text-align: center;
                @include media-up(sm) {
                    font-size: 3.5rem;
                    line-height: 5rem;
                    text-align: left;
                }

                strong {
                    @include font-family('semi-bold');
                }
            }
        }
    }
}

.spotlight-clip-with-base-3 {
    .ob-spotlightContentBlock-background {
        opacity: 0;
        transition: all 0.5s ease-in-out;

        img {
            max-width: 100%;
        }

        .step-1 & {
            opacity: 1;
        }
    }
}

.spotlight-travel-case {

    .ob-spotlightContentBlock-wrapper {
        align-items: flex-end;
    }

    .ob-spotlightContentBlock {
        &-background {
            background-position: center bottom;
            margin-top: 0;
            opacity: 0;
            transition: opacity ease 0.5s;

            .step-1 & {
                opacity: 1;
            }
        }

        &-textContent {
            //background-color: rgba(255, 255, 255, 0.85);
            //border-radius: 6px;
            padding: 1.6rem;
            text-align: center;
            width: 100%;

            .pre-step & {
                margin-top: 30%;
            }
            .step-1 & {
                margin-top: 0;
                margin-bottom: 105px;
                opacity: 1;
                @include media-up(sm) {
                    left: 0;
                    margin-bottom: 4rem;
                }
            }
            .step-2 & {
                margin-top: -30%;
            }

            .ob-heading {
                font-size: 2.8rem;
                line-height: 3.6rem;
            }

            .ob-bodyText {
                font-size: 1.8rem;
                line-height: 2.6rem;
            }

            @include media-up(xtiny) {
                width: calc(80% + 3.2rem);
            }

            @include media-up(sm) {
                padding: 2.4rem;
                width: calc(70% + 4.8rem);
                left: 0;

                .ob-heading {
                    font-size: 4.8rem;
                    line-height: 5.6rem;
                }

                .ob-bodyText {
                    font-size: 2.6rem;
                    line-height: 3.4rem;
                }
            }

            @include media-down(sm) {
                flex-direction: column;
                height: 100%;
                margin-bottom: auto;
                margin-top: -100%;

                .pre-step & {
                    margin-bottom: auto;
                    margin-top: 100vh;
                }

                .step-1 & {
                    margin-bottom: auto;
                    margin-top: 0;
                }

                .step-2 & {
                    margin-bottom: auto;
                    margin-top: -100%;
                }

                .ob-heading {
                    margin-bottom: auto;
                    margin-top: 15vh;
                }

                .ob-bodyText {
                    margin-bottom: 10vh;
                    margin-top: auto;
                }

                .ob-spotlight-nl & {
                    .ob-heading {
                        margin-top: 14vh;
                    }
                }
            }

            @media screen and (max-width: #{$breakpoint-medium - 1} ) and (min-aspect-ratio: 8/5) and (orientation: landscape) {
                background: rgba(0, 0, 40, 0.75);
                height: auto;

                .ob-heading {
                    margin: 0;
                }

                .ob-bodyText {
                    margin: 0;
                }

                .step-1 & {
                    margin-top: auto;
                    margin-bottom: 4rem;
                }
            }
        }
    }
}

.spotlight-travel-case-video-block {
    .ob-spotlightVideo-container {
        .video-js .vjs-tech {
            object-position: center bottom;
        }
    }

    .step-1 & {
        .ob-spotlightVideo-container {
            opacity: 1;
        }
    }
}

.ob-spotlight-waitlist {
    opacity: 0;
    transition: all 0.5s ease-in-out;

    @include media-down(sm) {
        .step-1 & {
            background: $base-color__white;
            overflow: auto;
        }
    }

    .step-1 & {
        opacity: 1;
    }

    &__wrapper {
        transform: translateX(100%);
        transition: all ease 0.5s;
        .step-1 & {
            transform: translateX(0);
        }
    }
}

.ob-spotlight-experience {
    opacity: 0;
    transition: all 0.5s ease-in-out;

    @include media-down(sm) {
        .step-1 & {
            background: $base-color__white;
            overflow: auto;
        }
    }

    .step-1 & {
        opacity: 1;
    }

    &__wrapper {
        transform: translateX(100%);
        transition: all ease 0.5s;
        .step-1 & {
            transform: translateX(0);
        }
    }
}

.ob-spotlight-preorder {
    opacity: 0;
    transition: all 0.5s ease-in-out;

    .step-1 & {
        opacity: 1;
    }

    &__wrapper {
        transform: translateX(100%);
        transition: all ease 0.5s;
        .step-1 & {
            transform: translateX(0);
        }
    }
}


