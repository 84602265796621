
@import "../../static/styles/mixins";
@import "../../static/styles/variables";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.ob-start-guide-layout-new {
  .start-guide-header {
    position: unset;

    .ob-SpotlightStickyBar {
      background: $base-color__white;
      position: unset;
    }
  }

  #main {
    margin-top: 0;
  }

  .ob-start-guide-content-block {
    .ob-background-wrapper {
      padding-top: 5%;
    }
  }

  .bold-text {
    font-weight: 700;
    display: inline;
  }

  .ob-SpotlightStickyBar-link.ob-link {
    display: block;
  }

.ob-start-guide-content-block {
  position: relative;

  .background-img {
    img {
      width: 100% !important;
      display: flex;
    }
  }

  .start-guide-text-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 5%;
    
    @include media-down(md) {
      max-width: 60rem;
    }

    p {
      margin: 0px;
    }

    .title {
      font-size: 16px;
      line-height: 30px;
      @include font-family("hurme-bold");
    }

    .subtitle {
      font-size: 45px;
      line-height: 55px;
      @include font-family("hurme-light");

      .bold-text {
        @include font-family('hurme-regular');
        font-weight: 600;
      }
    }

    .description-container {
      display: flex;
      flex-direction: row;
      max-width: 62rem;
      @include media-down(sm){
        margin-bottom: 6%;
      }

      .description {
        color: $base-color__white;
        font-size: 20px;
        line-height: 30px;
        margin: 1.5rem 0;
        @include font-family("regular");
        @media screen and (min-width: 1440px) and (max-width: 1500px) {
          margin: 2.5rem 0;
        }
      }

      .ob-mainAsset-wrapper img {
        width: 55px;
        height: 52px;
      }
    }

    .topicHeader {
      font-size: 20px;
      line-height: 30px;
      @include font-family("hurme-bold");
      font-weight: 300;
    }

    .cta-link {
      border-radius: 21px;
      background-color: $base-color-dark-blue;
      color: $base-color__white;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      padding: 15px 20px;
      text-decoration: none;
      cursor: pointer;

      span {
        font-size: 18px;
        line-height: 20px;
        text-transform: capitalize;
        text-align: center;
        @include font-family("semi-bold");
      }
    }

    .ob-start-guide-topic-container {
      display: flex;
      column-gap: 50px;

      .ob-start-guide__form-list-item {
        .ob-start-guide__brush-type {
          span {
            color: $base-color-dark-blue;
            font-size: 20px;
            line-height: 24px;
            @include font-family("semi-bold");
            text-align: center;
            width: 100%;
            display: inline-block;
            margin-top: 1rem;

            @include media-down(sm) {
              width: 100%;
            }
          }

          .ob-mainAsset-wrapper {
            .ob-mainAsset-wrapper-img {
              height: auto !important;
              width: 139px !important;
              background: $io2-light-blue-color;
              max-width: 100%;
              border-radius: 70px;
              @include media-down(sm){
                width: 100%;
              }
            }
          }
          .ob-mainAsset-wrapper-img:hover{
            background-color: $io2-dark-blue-color;
          }
        }
      }
    }

    .start-guide-carousel-cards-mobile {
      width: 100vw;

      .start-guide-one-card {
        display: grid !important;
        flex-direction: column;
        align-items: center;

        img {
          width: 280px;
          height: 280px;

          @media screen and (max-width: 360px) {
            width: 245px;
            height: 245px;
          }
        }

        .card-title {
          font-size: 16px;
          line-height: 21px;
          @include font-family("hurme-bold");
          margin: 2% auto;
          padding-right: 3%;
        }

        .card-description {
          margin: 2% auto;
          text-align: center;
          @include font-family("hurme-regular");
          font-size: 14px;
          line-height: 22px;
          width: 95%;
          padding-right: 5%;

          .carosel-desc-list {
            padding-inline-start: 25px;
            margin-block-start: 0;
            margin-block-end: 0;
            text-align: left;
          }
        }
      }
    }
  }

  .start-guide-carousel-cards-mobile {
    position: absolute;
    top: 140%;
    width: 100%;
  }
}

.start-guide-carousel-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  margin: 5rem 0;

  @include media-up(md) {
    max-width: 101rem;
    justify-content: space-between;
  }

  @include media-up(lg) {
  }

  @media screen and (min-width: 1440px) {
    max-width: 120rem;
  }

  @media screen and (min-width: 1730px) {
    max-width: 152rem;
  }

  .start-guide-one-card {
    margin: 0 5rem 0 0;
    font-size: 20px;
    line-height: 28px;
    text-align: center;

    @include media-down(md) {
      max-width: 28.2rem;
    }

    @include media-up(md) {
      max-width: 28.2rem;
      margin: 0;
    }

    @media screen and (max-width: 1440px) {
      max-width: 25rem;
    }

    @media screen and (min-width: 1730px) {
      max-width: 34rem;
    }

    img {
      width: 100%;
    }
  }
}

.replacing-brush-heads-gentle-care,
.ultimate-clean-slider {
    width: 100%;
    max-width: max-content;
    max-width: -moz-max-content;    /* Firefox/Gecko */
    max-width: -webkit-max-content;

  .ob-background-wrapper {
    padding: 0 20% 25% 8%;

    @media screen and (min-width: 1200px) {
      padding: 65% 20% 25% 8% !important;
    }

    @media screen and (min-width: 1440px) {
      padding: 70% 20% 25% 8% !important;
    }

    @media screen and (min-width: 1440px) and (max-width: 1445px) {
      padding: 70% 20% 25% 8% !important;
    }

    align-content: center;
    justify-items: center;

    @include media-up(lg) {
      padding: 0px 5% 38% 8%;
    }
  }


  .start-guide-cta-link{
    &.second{
      p{
        background-color: $io2-lighter-blue-color;
        color: $base-color__white;
      }      
    }
    p {
      border-radius: 26px;
      @include font-family("semi-bold");
      color: $base-color-dark-blue;
      font-size: 18px;
      line-height: 1.3;
      min-width: 240px;
      padding: 11px 24px;
      background-color: $base-color-secondary-darker-off-white;
      display: inline-block;
      text-align: center;
      margin: 0 0 12px 0;
      @media screen and (min-width: 1248px) and (max-width: 1280px) {
        padding: 6px 20px;
      }
      @include media-down(sm){
        font-size: 18px;
        line-height: 1.3;
        width: auto;
        padding: 8px 10px;
      }
    }
  }

  .title {
    color: white;
    line-height: 18px;
    font-size: 14px;
    margin: 0;
    text-align: center;
    .bold-text{
      @include font-family("hurme-bold");
    }
  }

  .description {
    width: 70%;
    color: white;
    @include font-family("regular");
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;

    .bold-text {
      @include font-family("hurme-bold");
    }
  }
}

.start-guide-three-step {
  transform: translateY(-7%);
  z-index: 2;

  @include media-down(sm) {
    transform: translateY(-8%);
    margin-top: -8rem;
  }

  @include media-up(lg) {
    transform: translate(0);
  }

  .start-guide-text-container {
    .description-container {
      @include media-up(lg) {
        max-width: 51rem;
      }
    }
  }
}

.start-guide-shuffle-block.Investment {
  transform: translateY(8vw);
  z-index: 1;

  @include media-up(lg) {
    margin-top: unset;
    transform: translate(0);
  }

  @include media-down(sm) {
    margin-top: 0rem;
    transform: unset;
  }

  @media screen and (min-width: 1400px) {
    transform: translate(0);
  }
}

.replacing-brush-heads-section {
  transform: translateY(-17%);
  z-index: 2;
  @media screen and (min-width: 1024px) and (max-width:1030px) {
    transform: translateY(0);
  }
  .ob-start-guide-topic-container {
    @media screen and (max-width:1199px) {
      margin-top: 20px;
    }
  }
  @include media-down(sm) {
    transform: translateY(0%);
    .start-guide-content-container{
      display: flex;
      flex-direction: column-reverse;
      background: linear-gradient(160.71deg, $io2-secondary-blue-color 3.89%, $io2-light-blue-color 87.04%);
      .start-guide-text-container {
        top: 5%;
        width: 100%;
        max-width: 100%;
        text-align: center;
        padding-left: unset;
        align-items: center;
        padding: 0 20%;
        transform: unset;
        position: relative;
        margin-top: 30px;
  
        @include media-down(sm) {
          padding: 0 8%;
          z-index: 3;
        }
  
        .title {
          margin-bottom: 10px;
          font-size: 12px;
          line-height: 26px;
        }
  
        .subtitle {
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 10px;
          color: $base-color-dark-blue;
        }
  
        .description,
        .topicHeader {
          font-size: 17px !important;
          line-height: 23px !important;
          margin: 0 !important;
          color: $base-color-dark-blue;
        }
  
        .bold-text {
          @include font-family("hurme-bold");
        }
      }
    }

    .ob-start-guide-topic-container {
      .ob-start-guide__brush-type {
        img {
          width: 105px !important;
          height: 105px !important;
          margin-bottom: 15px;
        }

        span {
          font-size: 12px !important;
          line-height: 16px !important;
        }
      }
    }
  }

  @include media-up(lg) {
    transform: translateY(0);
    margin-top: -2rem;

    .start-guide-text-container {
      width: 50%;
      max-width: 40%;
      top: 50%;
      color: $base-color-dark-blue !important;
      transform: translate(0%, -50%);
      padding-left: 10%;
      @media screen and (min-width: 1440px) and (max-width: 1500px) {
        max-width: 45%;
      }
      &.activeAnimReplacing{
        .subtitle{
          font-size: 48px;
          line-height: 52px;
        }
        .description-container{
          max-width: max-content;
          .description{
            font-size: 21px;
            line-height: 29px;
          }
        }
        .topicHeader{
          font-size: 21px;
          line-height: 29px;
          margin-top: 30px;
          @include font-family('hurme-regular');
          font-weight: 600;
        }
        .ob-start-guide-topic-container{
          padding-left: 15px;
          @media screen and (min-width: 1440px) and (max-width: 1500px) {
            padding-left: unset;
          }
          .ob-start-guide{
            &__form-list-item{
              .ob-start-guide{
                &__brush-type{
                  span{
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: bold;
                    @media screen and (min-width: 1440px) and (max-width: 1500px) {
                      font-weight: 100;
                      font-family: unset;
                    }                  
                  }
                  .description-brush{
                    font-weight: 400;
                    width: 100%;
                    max-width: 150px;
                    margin: 0 auto;
                    display: block;
                    font-family: 'Hurme Regular';
                  }
                }

              }
            }
          }
        }
      }

      .ob-start-guide-topic-container {
        column-gap: 40px;
        margin-top: 3rem;
      }
    }
  }

  @media screen and (min-width: 1730px) {
    margin-top: -9rem;
  }
}

.use-right-pressure {
  z-index: 1;
  column-gap: 40px;
  margin-top: -1rem;

  @media screen and (max-width: 1400px) {
    margin-top: -6rem;
  }
}

.use-the-pressure-video {
  .ob-heading{
    font-size: 3rem;
    margin-top: -33rem;
    color: $base-color-start-guide-text-blue;
    span{
      position: absolute;
      top: -60px;
      left: 1vw;
      width: 100%;
      color: $io2-blue;
      transform: translateY(-1vw);
      
      @media screen and (min-width:1900px) {
        left: 0vw !important;
      }
      @media screen and (min-width: 1400px) and (max-width: 1890px) {
        left: 8vw !important;
      }
      @include media-up(sm) {
        left: 16vw;
      }
      
    }
  }
  .ob-videoContent-wrapper{
    position: relative;
    .ob-videoContent-textContainer {
      width: 100%;
      .ob-heading{
        font-size: 21px;
        line-height: 29px;
        font-family: "Hurme Semi Bold", sans-serif;
        font-weight: 700;
        font-style: normal;
      }
    }
  }
  .ob-videoContent-image{
    max-width: 100%;
    width: auto;
    margin: 0 auto;
    display: block;
    margin-bottom: -2px;
    @include media-down(sm){
      width: 100%;
    }
    button{
      opacity: 0;
    }
    @include media-up(sm){
      .ob-mainAsset-wrapper{
        img{
          width: 60vw !important;
        }
      }
    }
  }
}

.protection-comfort-section {
  transform: translateY(-27%);
  z-index: 2;

  .slick-slider {
    @media screen and (max-width: 360px) {
      transform: translate(-3%, -5%) !important;
    }
  }

  .start-guide-text-container {
    top: 30%;

    .bold-text {
      font-weight: 700;
    }

    @media screen and (max-width: 1440px) {
      transform: translate(0);
    }

    @include media-up(lg) {
      width: 100%;
      padding: 0 15%;
      top: 10%;

      .description-container {
        max-width: 40rem;
      }
    }

    @media screen and (min-width: 1440px) {
      transform: translate(0);
      margin-top: -9rem;
      top: 25%;
    }

    @include media-down(sm) {
      text-align: left;
      max-width: 60%;
      padding-left: 3%;
    }

    .title {
      color: $base-color-start-guide-text-blue;
      @include font-family("hurme-bold");

      @include media-down(sm) {
        font-size: 12px;
        line-height: 26px;
        font-style: normal;
        margin-bottom: 10px;
      }
    }

    .subtitle {
      color: $base-color-start-guide-text-blue;
      @include font-family("hurme-light");

      @include media-down(sm) {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 10px;
      }
    }

    .cta-link {
      background-color: $base-color-start-guide-text-blue;
      padding: 10px 7px 10px 15px;
      text-decoration: none;
      height: 42px;
      width: 165px;
      border-radius: 21px;
      text-transform: uppercase;
      display: flex;

      span {
        text-align: left;
        background-image: url('/static/images/cta_arrow_icon.png');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 25px;
        font-size: 12px;
        line-height: 15px;
      }

      @include media-down(sm) {
        max-width: 162px;
        max-height: 42px;
        border-radius: 20px;
      }
    }

    .description {
      font-size: 14px;
      line-height: 22px;
      @include font-family("hurme-regular");
      width: 80%;
    }

    .start-guide-carousel-cards {
      @include media-up(lg) {
        width: 100%;
        justify-items: flex-start;
        justify-content: space-between;
        align-items: flex-start;
        display: flex;
      }

      .bold-text {
        display: contents;
      }
    }

    .bold-text {
      @include font-family("hurme-bold");
      display: block;
    }
  }

  .start-guide-carousel-cards-mobile {
    transform: translateX(-3%);
  }

  @include media-down(sm) {
    transform: translateY(-30%);

    .start-guide-text-container {
      top: 10%;
    }
  }

  @include media-up(lg) {
    transform: translate(0);
  }
}

.smart-settings {
  z-index: 1;

  @include media-up(lg) {
    .start-guide-shuffle-block .start-guide-content-container {
      padding: 0 0% !important;
      height: 100% !important;
      width: 100%;
    }
  }

  @media screen and (min-width: 1440px) {
    .start-guide-shuffle-block .start-guide-content-container {
      top: 22px;
    }
  }
}

.maintain-your-clean {
  transform: translateY(-40%);
  z-index: 2;
  margin-top: -2rem !important;
  display: flex;

  @media screen and (min-width: 1024px) and (max-width: 1030px) {
    transform: translateY(0%);
    margin-top: 0rem !important;
  }
  @media screen and (min-width: 1280px) and (max-width: 1285px) {
    margin-top: -7rem !important;
  }
  .start-guide-container{
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100%;
    margin-left: 25%;
    @media screen and (min-width: 1024px) and (max-width: 1030px) {
      margin-left: 0%;
      width: 75%;
      justify-content: flex-start;
    }
    @include media-down(sm) {
      height: auto;
      margin-left: 0;
    }
  }
  .start-guide-text-container {
    width: 100%;
    height: 100%;
    top: 0;

    @include media-down(sm) {
      top: 0%;
      text-align: center;
      max-width: 100%;
      width: 100%;
      height: auto;
      padding-right: 3%;
      padding-left: 3%;
      transform: translateX(0%);
      position: relative;
    }

    .title {
      color: $base-color-start-guide-text-blue;
      @include font-family("semi-bold");

      @include media-down(sm) {
        font-size: 12px;
        line-height: 26px;
        font-weight: bold;
        font-style: normal;
        margin-bottom: 10px;
      }
    }

    .subtitle {
      color: $base-color__white;
      @include font-family("hurme-light");
      font-size: 48px;
      line-height: 52px;
      margin-top: 60px;
      width: 58%;
      margin: 60px auto 0;
      @include media-down(sm) {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 10px;
        width: 100%;
        margin-top: 20px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1030px) {
        margin-top: 30px;
      }
    }
    .description-container{
      .description{
        font-size: 21px;
        line-height: 29px;
        @include media-down(sm) {
          font-size: 17px;
          line-height: 23px;
        }
        @media screen and (min-width: 1440px) and (max-width: 1500px) {
          width: 635px;
          margin-left: 0%;      
        }
      }
    }
    .start-guide-content-cards{
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: calc(100% - 200px);
    }

    .cta-link {
      background-color: $base-color-start-guide-text-blue;

      @include media-down(sm) {
        max-width: 155px;
        max-height: 42px;
        border-radius: 20px;
      }
    }

    .description-container {
      display: block;
      max-width: 58%;
      margin: 0 auto;
      @include media-down(sm) {
        width: 100%;
        max-width: 98%;
      }
    }

    .description {
      font-size: 14px;
      line-height: 22px;
    }

    .start-guide-carousel-cards {
      margin: 3rem 0;

      @include media-up(lg) {
        display: flex;
        max-width: 80rem;
        margin: 3rem 0 3rem -3rem;
      }

      @media screen and (min-width: 1440px) {
        max-width: 75rem;
        margin-left: -3rem;
      }

      .start-guide-one-card {
        max-width: 31.2rem;

        .ob-mainAsset-wrapper {
          margin: 0 3rem 1rem;
          max-width: 28.2rem;
        }

        .carosel-desc-list {
          text-align: left;
          padding-left: 5%;
          margin: 0;
        }

        div {
          text-align: left;
        }

        .bold-text {
          margin-bottom: 1rem;
          text-align: left;
        }
      }
    }

    .bold-text {
      @include font-family("hurme-bold");
    }

    .start-guide-carousel-cards-mobile {
      transform: translate(-50%, -8%);
    }
    .start-guide-content-cards {
      color: $base-color__white;
      margin-left: 0;
      padding-left: 10%;
      text-align: left;
      @media screen and (min-width: 1024px) and (max-width: 1030px) {
        padding-left: 4%;
      }
      .cards{
        display: flex;
        align-items: center;
        width: 50%;
        margin: 0 0;
        @media screen and (min-width: 1024px) and (max-width: 1030px) {
          width: 83%;
        }
        @media screen and (min-width: 1440px) and (max-width: 1500px) {
          width: 55%;
        }
        &:first-child{
          position: relative;
          margin-bottom: 100px;
          @media screen and (min-width: 1024px) and (max-width: 1030px) {
            margin-bottom: 50px;
          }
          @media screen and (min-width: 1271px) and (max-width: 1273px) {
             margin-bottom: 63px;
          }
          &:after{
            border-bottom: 1px solid $io2-navy-blue-color;
            margin: 50px 0;
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -100px;
            content: '';
            @media screen and (min-width: 1024px) and (max-width: 1030px) {
              bottom: -73px;
            }
            @media screen and (min-width: 1272px) and (max-width: 1274px) {
              bottom: -75px;
            }
          }
        }
        .ob-mainAsset-wrapper-img,.ob-mainAsset-wrapper img{
          width: 200px !important;
          @media screen and (min-width: 1230px) and (max-width: 1248px) {
            width: 165px !important;
          }
          @media screen and (min-width: 1280px) and (max-width: 1285px) {
            width: 175px !important;
          }
        }
        .cards-text-container{
          margin-left: 20px;
        }
        .card-title{
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          text-align: left;
          font-family: $hurmesemibold;
        }
        .card-description{
          font-size: 21px;
          font-weight: 400;
          line-height: 29px;
          text-align: left;
          font-family: $hurmeregular;
          .carosel-desc-list{
            display: block;
            padding: 0 0 0 35px;
            list-style: none;
            overflow: hidden;
            counter-reset: numList;
            margin: 0;
            li {
              float: none;
              position: relative;
              margin: 10px 0;
              &:before {
                counter-increment: numList;
                content: counter(numList);
                top: 4px;
                float: left;
                position: absolute;
                left: -30px;          
                font-size: 16px;
                font-family: $hurmebold;
                font-weight: 600;
                text-align: center;
                color: $io2-tertiary-blue-color;
                line-height: 24px;
                width: 24px;
                height: 24px;
                background: $io2-tertiary-light-blue;          
                -moz-border-radius: 999px;
                border-radius: 999px
              }
              @include media-down(sm){
                top: 0px;
              }
            }
          }
        }
      }
      @include media-down(sm) {
        position: absolute;
        top: 24%;
        padding: 0 5%;

        .cards {
          display: flex;
          margin-bottom: 10px;
          align-items: center;

          h1 {
            font-size: 16px;
            margin: 0;
            line-height: 21px;
            @include font-family("hurme-bold");
          }

          p {
            font-size: 12px;
            margin: 0;
            line-height: 19px;
            color: $base-color__white;
          }

          .bold-text {
            @include font-family("hurme-bold");
          }

          .ob-mainAsset-wrapper-img,.ob-mainAsset-wrapper img {
            width: 90px;
            height: 90px;
            margin: 0 23px 0 0;
          }

          @include media-down(sm) {
            h2 {
              margin-block-start: 5px;
              margin-block-end: 5px;
              color: $base-color__white;
            }
          }
        }
      }

      @media screen and (max-width: 360px) {
        .cards {
          margin-bottom: 3px;
        }
      }
    }
  }
  .start-guide-content-cards{
    @include media-down(sm) {      
      color: $base-color__white;  
      .ob-mainAsset-wrapper {
        text-align: center;
        img{
          width: 160px;
          height: 160px;
          margin-top: 50px;
        }
      }
      .cards{        
        .cards-text-container{
          margin: 0 25px;
          .card-title {
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            text-align: left;
            font-family: "Hurme Semi Bold", sans-serif;
          }
          .card-description {
            font-size: 17px;
            font-weight: 400;
            line-height: 23px;
            text-align: left;
            font-family: "Hurme Regular", sans-serif;
            .carosel-desc-list {
              display: block;
              padding: 0 0 0 30px;
              list-style: none;
              overflow: hidden;
              counter-reset: numList;
              li {
                float: none;
                position: relative;
                margin: 10px 0;
                padding-left: 15px;
                padding-bottom: 12px;
                &::before{
                  counter-increment: numList;
                  content: counter(numList);
                  top: 2px;
                  float: left;
                  position: absolute;
                  left: -30px;
                  @include font-family('hurme-bold');
                  text-align: center;
                  color: $io2-tertiary-blue-color;
                  line-height: 30px;
                  width: 30px;
                  height: 30px;
                  background: $io2-tertiary-light-blue;
                  -moz-border-radius: 999px;
                  border-radius: 50%;
                }
              }
            }
          }
        }
        &:first-child{
          position: relative;
          .ob-mainAsset-wrapper {
            img{
              margin-top: 0;
            }
          }
          &::after{
            border-bottom: 1px solid $io2-navy-blue-color;
            margin: 50px 0;
            position: absolute;
            width: calc(100% - 50px);
            height: 1px;
            bottom: -30px;
            content: "";
            left: 25px;
            margin: 0 auto;
          }
        }
        &:last-child{
          .card-title{
            text-align: center;
          }
          .card-description{
            text-align: center;
            margin-bottom: 50px;
          }          
        }
      }                  
    }
  }


  @include media-up(lg) {
    margin-top: -2rem;
    transform: translateY(0);
  }

  @media screen and (min-width: 1440px) {
    transform: translate(0);
    top: 25%;
  }
}

.charging-base {
  transform: translateY(-70%);
  z-index: 1;
  @media screen and (min-width: 1024px) and (max-width:1030px) {
    transform: unset;
  }
  @include media-down(sm) {
    transform: translateY(-17%);

    .start-guide-content-container {
      padding: 0 !important;
      grid-template-columns: 1fr 1fr !important;

      div:first-child {
        width: 100%;
        @include media-down(sm) {
          width: auto;
        }
        .start-guide-animation-asset {
          width: 100%;          
          img {
            width: 100%;
          }
        }
      }

      .start-guide-text-container {
        transform: translateX(0%) !important;
        width: 100% !important;
        margin-top: 0% !important;

        .start-guide-secondary-image {
          display: none;
        }
      }
    }
  }

  @include media-up(lg) {
    transform: translateY(0);
  }

  .start-guide-text-content {
    width: 50%;
    margin: 0 5rem;
  }

  .start-guide-text-container {
    @media screen and (min-width: 2456px) {
      margin-left: 20%;
    }
  }
}

.start-brushing {
  background: linear-gradient(161.3deg, $io2-base-color-slight-dark 0%, $io2-maintain-clean-blue 91.04%);
  z-index: 2;
  margin-bottom: -5px;
  .start-guide-content-container{
    display: flex;
    flex-direction: row-reverse;
    @include media-down(sm){
      flex-direction: column-reverse;
    }
    @media screen and (min-width: 1024px) and (max-width: 1030px) {
      padding: 30px 0 0;
    }
  }
  .background-img {
    width: 50%;
    display: flex;
    @include media-down(sm){
      width: 100%;
    }
    img{
      width: 100%;
      @include media-down(sm){
        width: 100%;
        margin-left: unset;
      }
    }
  }

  .start-guide-container{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-down(sm){
      width: 100%;
    }
    .start-guide-text-container {
      transform: translateX(0%);
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: 0;
      @include media-down(sm) {
        position: unset;
        transform: unset;
        text-align: center;
        margin: 40px auto;
        width: 100%;
        max-width: 100%;
        justify-content: flex-start;
        padding: 0 25px;
      }
  
      .subtitle {
        margin-bottom: 2rem;
        color: $base-color__white;
        font-size: 36px;
        line-height: 40px;
        @include media-up(sm){
          .mobile-x{
            display: none;
          }
        }
        .bold-text {
          display: inline;
        }
  
        @include media-down(sm) {
          font-size: 28px;
          line-height: 36px;
          .desktop-y{
            display: none;
          }
        }
      }
  
      .description-container {
        width: 100%;
        max-width: 100%;
        justify-content: center;
        align-items: center;
  
        @include media-down(sm) {
          justify-content: center;
        }
        .description {
          @include font-family("hurme-bold");
          color: $base-color__white;
          margin-top: 0;
          width: 100%;
          font-size: 21px;
          line-height: 29px;
          @include font-family("hurme-regular");
    
          @include media-down(sm) {
            font-size: 17px;
            line-height: 23px;
            margin: 0;
            text-align: center;
            padding: 0 10px;
          }
        }
      }
  
      .cta-container {
        .cta-link{
          span{
            font-family: $hurmeregular;
            font-weight: 600;
          }
        }
        @include media-down(sm) {
          margin: 0 auto;
  
          .cta-link {
            min-width: 295px;
            width: 295px;
  
            span {
              text-transform: unset;
              font-size: 17px;
              line-height: 23px;
            }
          }
        }
      }
  
      .cta-link {
        display: flex;
        width: max-content;
        max-width: 58rem;
        border-radius: 40px;
        padding: 11px 24px;
        background-color: $base-color__white;
  
        span {
          color: $base-color-dark-blue;
          text-transform: none;
          font-size: 21px;
          line-height: 29px;
        }
  
        @include media-down(sm) {
          min-width: 225px;
        }
      }
    }
  }
  .select-calender {
    width: 100%;
    .calender-option-list-section {
      background: $base-color-dark-blue;
      padding: 45px 8px 10px 8px;
      color: $base-color__white;
      text-align: center;
      width: 100%;
      max-width: 34rem;
      border-radius: 31px;
      margin: 15px auto 0;
      text-transform: capitalize;
      position: relative;

      @media screen and (min-width: 1440px) {
        max-width: 34rem;
      }
      @media screen and (min-width: 1024px) and (max-width: 1030px) {
        margin-bottom: 30px;
      }
      @include media-down(sm) {
        max-width: 100%;
      }

      .calender-title {
        @include font-family("hurme-regular");
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0;
        margin-bottom: 15px;
      }

      .calender-content {
        display: flex;
        justify-content: space-evenly;
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        @include font-family("hurme-regular");
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0;
        text-align: center;
        padding: 2px 0px;

        .calender-img {
          img {
            width: 100%;
            height: 100%;
            max-width: 33px;
            max-height: 33px;
          }
        }
      }

      .gum-bleed-close-button{
        border-radius: 50%;
        background-color: $io2-mid-blue-color;
        right: 20px;
        top: 30px;
        width: 40px;
        height: 40px;
        filter: none;
        -webkit-filter: none;
        .ob-icon{
          fill: white !important;
          width: 1.4rem !important;
        }
        @include media-down(sm){
          right: 7px;
          top: 8px;      
        }
      }

      .calendar-close-button-bottom {
        width: 15px;
        height: 15px;
        padding: 12px;
        border: solid 2px white;
        display: block;
        position: absolute;
        border-radius: 50%;
        left: auto;
        cursor: pointer;
        transform: translateY(0);
        right: 20px;
        top: 10px;

        @include media-down(sm) {
          left: 85%;
        }
      }

      .calendar-close-button-bottom::before,
      .calendar-close-button-bottom::after {
        position: absolute;
        content: "";
        height: 1px;
        width: inherit;
        background: white;
        left: 4px;
        padding: 1px;
        top: 49%;
        transition: all 0.3s ease-in;
      }

      .calendar-close-button-bottom::before {
        transform: rotate(45deg);
      }

      .calendar-close-button-bottom::after {
        transform: rotate(-45deg);
      }
    }
  }
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.start-guide-hero-banner {
  .start-guide-content-container {
    .background-img {
      img {
        width: 100%;
      }
    }

    .start-guide-image-container {
      visibility: hidden;

      @media screen and (min-width: 1280px) and (max-width: 1285px) {
        .ob-start-guide-chargerIcon-container {
          bottom: 0 !important;

          img {
            bottom: 0 !important;
            width: 80% !important;
            margin-right: unset !important;
          }
        }

        .ob-contentBlock-text-image {
          img {
            height: 100vh;
          }
        }
      }
    }
  }

  .start-guide-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 16%;
    width: 100%;
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    color: $base-color__white;
    justify-content: center;
    @include font-family("hurme-regular");

    @include media-down(sm) {
      padding-left: 5%;
      top: 8%;
      justify-content: flex-start;
    }

    @media screen and (max-width: 360px) {
      top: 5%;
    }

    .start-guide-text-container {
      @include media-down(sm) {
        width: 55%;
        margin-top: 30%;
      }

      @media screen and (max-width: 360px) {
        width: 60%;
      }

      @include media-up(lg) {
        width: 40%;
      }

      @media screen and (min-width: 1440px) {
        width: 30%;
      }

      animation: 2s ease 0s 1 slideInFromTop;

      @keyframes slideInFromTop {
        0% {
          transform: translateY(-5%);
          opacity: 0;
        }

        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }

      .start-guide-scroll-content {
        line-height: 22px;
      }
    }

    .start-guide-image-container {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      @include media-down(sm) {
        position: absolute;
        right: 0;
        top: 11%;
      }

      @include media-up(lg) {
        position: relative;
        bottom: 22%;
      }

      @media screen and (max-width: 360px) {
        top: 5%;
      }

      @media screen and (min-width: 1400px) {
        position: relative;
        bottom: 10rem;
      }

      .ob-start-guide-chargerIcon-container {
        @include media-up(lg) {
          position: relative;
          bottom: 24%;
        }

        @media screen and (min-width: 1400px) {
          bottom: 17%;
        }

        @media screen and (min-width: 1730px) {
          bottom: 20%;
        }

        img {
          @include media-up(lg) {
            position: relative;
            bottom: 5.5rem;
            margin-right: 2rem;
          }

          @media screen and (min-width: 1400px) {
            bottom: -10rem;
          }
        }
      }
    }

    .ob-contentBlock-text-image {
      width: 200px;

      @include media-down(sm) {
        width: 150px;
      }
    }

    .title {
      font-size: 65px;
      line-height: 45px;
      padding-bottom: 14px;

      @include media-down(sm) {
        font-size: 37px;
        line-height: 39px;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      @include media-up(lg) {
        margin: 0 0 1rem 0;
      }
    }

    p {
      @include font-family("hurme-regular");
      font-size: 20px;
      line-height: 30px;

      b {
        @include font-family("hurme-bold");
      }

      @include media-down(sm) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .cards {
      display: flex;
      margin-bottom: 33px;
      align-items: center;

      h1 {
        font-size: 22px;
        margin: 0;
        line-height: 30px;
        @include font-family("hurme-bold");
      }

      p {
        font-size: 20px;
        margin: 0;
        line-height: 30px;
        width: 345px;
      }

      .ob-mainAsset-wrapper-img {
        width: 132px;
        height: 132px;
        margin: 0 23px 0 0;
      }
    }

    .start-guide-scroll-content {
      align-items: self-start;
      text-align: center;
      margin-left: 37px;
      display: inline-block;
      cursor: pointer;

      h2 {
        font-size: 20px;
        @include font-family("hurme-bold");
      }

      img {
        @include media-up(lg) {
          width: 6.8rem;
        }
      }

      animation: 2s ease-in 0s 1 slideInScroll;

      @keyframes slideInScroll {
        0% {
          transform: translateY(-5%);
          opacity: 0;
        }

        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  .start-guide-scroll-content {
    @include media-down(sm) {
      position: absolute;
      top: 60%;
      padding-left: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        font-size: 14px;
        line-height: 16px;
        color: $base-color__white;
      }

      img {
        width: 44px;
      }
    }

    @media screen and (max-width: 360px) {
      top: 65%;
    }
  }
}

// Start Guide COntent block css
.start-guide-three-step {
  .start-guide-text-container {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 8%;
    position: absolute;
    top: 24%;
    width: 50%;
    text-align: left;
    right: 23%;

    @media screen and (max-width: 360px) {
      top: 3%;
    }
    @media screen and (min-width: 1024px) and (max-width:1030px) {
      top: -6%;
      .card-description{
        width: 37vw !important;
      }
    }

    @include media-down(sm) {
      width: 100%;
      text-align: center;
      top: 5%;
      padding: 0 2%;
      transform: unset;
      right: unset;

      .description {
        margin: 0 0 1.5rem 0 !important;
      }
    }

    @include media-up(lg) {
      max-width: 60rem;
      top: 5%;
      width: auto;
      padding: 0;
      transform: translateX(25%);
    }

    @media screen and (min-width: 1400px) {
      max-width: 67rem;
      top: 20%;
      padding-left: 0;
    }

    @media screen and (min-width: 1440px) and (max-width: 1445px) {
      top: 7%;
      transform: translateX(19%);
    }

    @media screen and (min-width: 1527px) and (max-width: 1570px) {
      top: 17%;
    }

    .cta-link {
      height: 42px;
      width: 155px;
      padding: 5px 20px;
      text-align: left;
      background-color: $io2-base-color-dark-blue;
      text-decoration: none;
      margin-top: 0;
      cursor: pointer;

      .down-arrow {
        margin-right: 1rem;
      }

      span {
        text-align: justify;
        font-size: 12px;
        line-height: 15px;
        height: 30px;
        width: 106px;
      }
    }

    .title {
      @include media-down(sm) {
        font-size: 16px;
        line-height: 26px;
        @include font-family("semi-bold");
        margin-bottom: 5px;
        color: $base-color__white;
      }
    }

    .subtitle {
      @include font-family("hurme-light");
      font-size: 45px;
      color: $base-color__white;
      line-height: 55px;
      margin-bottom: 20px;

      b {
        @include font-family("hurme-bold");
      }

      @include media-down(sm) {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 5px;
        padding: 0 6%;
      }
    }

    .description {
      @include media-down(sm) {
        font-size: 14px;
        line-height: 22px;
      }
    }

    p {
      @include font-family("hurme-light");
      font-size: 20px;
      color: $base-color__white;
      line-height: 30px;
      margin-bottom: 20px;

      b {
        @include font-family("hurme-bold");
      }
    }

    .cards {
      display: flex;
      margin-bottom: 1.5rem;
      align-items: center;
      border-bottom: 1px solid $io2-navy-blue-color;
      padding-bottom: 30px;
      @media screen and (min-width: 1440px) {
        margin-bottom: 3rem;
      }

      h1,
      h2 {
        font-size: 24px;
        margin: 0;
        line-height: 32px;
        @include font-family("hurme-regular");
        font-weight: 600;
        color: $base-color__white;
        margin-bottom: 10px;
      }

      p {
        font-size: 21px;
        margin: 0;
        line-height: 29px;
        width: 502px;
        @media screen and (min-width: 1440px) and (max-width:1500px){
          margin-top: 2%;
          width: 515px;
        }
      }

      .bold-text {
        @include font-family("hurme-bold");
        font-weight: 600;
      }

      .ob-mainAsset-wrapper{
        img {      
          width: auto;
          max-width: 120px;
          height: 120px;
          margin: 0 23px 0 0;
        }
      }
    }
    .cards:last-child{
      border-bottom: unset;
    }

    .cta-link {
      .down-arrow {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid white;
      }
    }
  }

  .start-guide-content-cards {
    @include media-down(sm) {
      position: relative;
      top: 0;
      padding: 0 5%;
      background-color: $io2-base-color-dark-blue;
      .cards {
        display: flex;
        padding-bottom: 20px;
        margin-bottom: 20px;
        align-items: flex-start;
        border-bottom: 1px solid $io2-navy-blue-color;
        .ob-mainAsset-wrapper {
          margin-top: 10px;
        }
        h1 {
          font-size: 16px;
          margin: 0;
          line-height: 21px;
          @include font-family("hurme-bold");
        }

        p {
          font-size: 12px;
          margin: 0;
          line-height: 19px;
          color: $base-color__white;
          @include media-down(sm){
            font-size: 17px;
            line-height: 23px;
            font-weight: lighter;
          }
        }

        .bold-text {
          @include font-family("hurme-bold");
        }

        .ob-mainAsset-wrapper-img,.ob-mainAsset-wrapper img {
          width: 90px !important;
          height: 90px !important;
          margin: 0 23px 0 0;
        }

        @include media-down(sm) {
          h2 {
            margin-block-start: 5px;
            margin-block-end: 5px;
            color: $base-color__white;
            line-height: 26px;
            font-size: 20px;    
          }
        }
      }
      .cards:last-child{
        border-bottom: unset;
      }
    }

    @media screen and (max-width: 360px) {
      .cards {
        margin-bottom: 30px;
      }
    }
  }

  .cta-link {
    @include media-down(sm) {
      width: 135px;
      height: 42px;
      padding: 5px 7px;
      background-color: $io2-base-color-dark-blue;
      color: $base-color__white;
      text-decoration: none;
      text-align: center;
      margin-top: 0;
      cursor: pointer;
      position: absolute;
      top: 65%;
      left: 8%;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: none;
      column-gap: 5px;

      @media screen and (max-width: 360px) {
        left: 5%;
      }

      .down-arrow {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid white;
      }

      span {
        text-align: left;
        font-size: 14px;
        line-height: 15px;
      }
    }
  }
}

.text-left {
  text-align: left;
}

.start-guide-three-step{
  margin-top: unset !important;
  .background-img{
    background: $io2-base-color-dark-blue;
    img{
      width: 40% !important;
      @include media-up(sm){
        margin-bottom: -3px;
      }
      @media screen and (min-width: 1024px) and (max-width:1030px) {
        margin-bottom: -2px;
      
      }
    }
  }
  @include media-down(sm){
    background: $io2-base-color-dark-blue;
  }
}

.replacing-brush-heads-section{
  .description-container{
    .description{
      color: $base-color-dark-blue !important;
    }
  }
}
.start-guide-hero-banner,
.start-guide-three-step,
.Investment,
.replacing-brush-heads-section,
.protection-comfort-section,
.maintain-your-clean,
.tart-brushing {
  position: relative;

  @include media-up(lg) {
    margin-top: -7rem;
  }

  @media screen and (min-width: 1440px) {
    margin-top: -10rem;
  }

  @media screen and (min-width: 1730px) {
    margin-top: -10rem;
    overflow: hidden;
  }
}

.replacing-brush-heads-section {
  margin-top: 0 !important;
  .ob-mainAsset-wrapper{
    img{
      margin-bottom: -3px;
    }
  }
  @include media-down(sm) {
    margin-top: -35px !important;
  }

  @media screen and (min-width: 390px) and (max-width: 395px) {
    margin-top: -12rem;
  }
  @media screen and (min-width: 1024px) and (max-width:1030px) {
      .start-guide-text-container{
        transform: translateX(10%)
      }
  }
}

.use-right-pressure {
  .start-guide-background img {
    margin: -10rem 0 0;

    @include media-down(sm) {
      margin: 0;
    }
  }

  @include media-down(sm) {
    transform: translateY(0%);
    margin-top: 0;

    .start-guide-content-container {
      img {
        margin: unset !important;
      }
    }
  }

  @media screen and (min-width: 2456px) {
    margin-top: -3rem;
  }
}

.protection-comfort-section {
  @include media-down(sm) {
    transform: translateY(-14%);
  }
}

.maintain-your-clean {
  background: linear-gradient(161.3deg, $io2-base-color-slight-dark 0, $io2-maintain-clean-blue 91.04%);
  @include media-down(sm) {
    transform: translateY(0%);
    background-color: $io2-base-color-slight-dark;
    margin-top: -57% !important;
  }
  .start-guide-content-container{
    width: 100%;
    .background-img{
      @include media-up(sm) {
        width: 25%;
        float: inline-end;
        margin-top: 5%;
      }
      @media screen and (min-width: 1440px) and (max-width:1500px) {
        margin-top: 15%;
        img{
          height: 50vw;
          width: 23vw;
          transform: translateX(2VW);
        }
      }
      @media screen and (min-width: 1024px) and (max-width:1030px) {
        img{
            scale: 0.7;
            transform: translate(5VW,13vw);
        }
      }
      @media screen and (min-width: 1920px) {
        img{
          height: 50vw;
          width: 23vw;
          transform: translateX(2VW);
        }
      }
    }
  }
}

.charging-base {
  @include media-down(sm) {
    transform: translateY(0%);
    margin-top: 0;
  }
}

.start-brushing {
  @include media-down(sm) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.activeAnim {
  animation: 2s ease-in 0s 1 slideInThreeStepFromTop;
}

@keyframes slideInThreeStepFromTop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.activeAnimReplacing {
  animation: 1s ease-in 0s 1 slideInReplacingBrush;
}

@keyframes slideInReplacingBrush {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
}
