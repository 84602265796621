
@import "../../static/styles/mixins";

.ob-spotlightContentBlock {
    background: transparent;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: $ob-sticky-bar-height;

    @include media-down(md) {
        top: $ob-sticky-bar-height-mobile;
    }
    
    &-wrapper {
        @include container-wrapper;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        @include media-down(md) {
            padding: 0 1rem;
        }

        &.vertical-top {
            align-items: start;
        }
        &.vertical-center {
            align-items: center;
        }
        &.vertical-bottom {
            align-items: flex-end;
        }
    }

    &-textContent {
        opacity: 0;
        transition: opacity 2s ease-out;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        
        &.hasPadding {
            padding: 15px;
        }

        &.horizontal-left {
            justify-content: flex-start;
        }
        &.horizontal-center {
            justify-content: center;
        }
        &.horizontal-right {
            justify-content: flex-end;
        }
        .ob-bodyText {
            @include font-family('hurme-light');
        }
    }

    &-mainAsset-container,
    &-additionalAssetList-container {
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        > div {
            height: 100%;
            width: 100%;
        }
        
        .ob-mainAsset-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            &.horizontal-left {
                justify-content: flex-start;
            }
            &.horizontal-center {
                justify-content: center;
            }
            &.horizontal-right {
                justify-content: flex-end;
            }
        }
        
        img {
            max-height: 100%;
        }
    }

    .ob-eyebrow {
        @include media-up(md) {
            margin-bottom: 1.6rem;
        }
    }

    .ob-bodyText {
        margin-top: .5rem;
    }

    .ob-link,
    .ob-button {
        margin-top: 3rem;
        .spotlight-zone-contest & {
            margin-top: 1.5rem;
        }
        @include media-up(md) {
            margin-top: 3.8rem;
            .spotlight-zone-contest & {
                margin-top: 3.8rem;
            }
        }
    }
}
