@import "../../../../../static/styles/mixins";

.ob-form {

    &__forgot-pass,
    &__create-profile {
        @include font-family('semi-bold');
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-align: center;
        display: block;

        @include media-up(sm) {
            @include eyebrow-1-xl;
            text-align: left;
            letter-spacing: 0;
        }

    }

    &__create-profile {
        margin: 0;
        word-break: break-word;
        font-size: 1.6rem;
        text-align: left;

        @include media-up(sm) {
            font-size: 2.2rem;
            margin: 2.2rem 0 0;
            @include font-family('hurme-regular');
        }

        a {
            text-decoration: underline;
            margin-left: 5px;
            &:focus,
            &:hover {
                text-decoration: none;
                color:$base-color-dark-blue-hover;
                border: 1px solid black;
            }
        }
    }

    &__layout.ob-login__wrapper{
        .ob-form__form {
            margin-top: 4rem;
            @include media-up(sm) {
                margin-top: 6rem;
            }
        }
    }

    &__login-submit-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .ob-form__button__submit {
            @include media-down(sm) {
                margin-top: .5rem;
            }
        }

        .ob-form__forgot-pass {
            margin-left: 0;
            margin-bottom: 1rem;
            @include media-up(sm) {
                margin-bottom: 0;
                margin-left: 1.2rem;
            }
            &.--mobile {
                @include media-up(sm) {
                    display: none;
                }
            }

            &.--desktop {
                display: none;
                @include media-up(sm) {
                    display: block;
                }

                a{
                    &:hover{
                        color:$base-color-dark-blue-hover;
                    }
                }
            }
        }

        @include media-up(sm) {
            margin-top: 1rem;
            flex-direction: row;
            align-items: center;
        }
    }
}
