@import "../../../../static/styles/mixins";

.ob-rp {
  &__carousel {
    margin: 7rem 0;
    position: relative;

    .ob-mainAsset-wrapper {
      width: 257px;
      height: 320px;
      margin: 0 auto;
      @include media-down(sm) {
        width: calc(100vw - 4rem);
      }
    }

    .ob-mainAsset-wrapper-img {
      max-width: 100%;
      max-height: 100%;
    }

    &--title {
      color: $base-color-dark-grey;
      font-size: 2.2rem;
    }

    &--control-button {
      width: 100px;
      height: 100px;
      border: 1px solid $base-color-primary-grey;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease 0.35s;
      display: none;
      &:disabled {
        pointer-events: none;
        border-color: $base-color-secondary-grey;
        svg.ob-icon--thinChevronDown  {
          fill: $base-color-secondary-grey;
        }
      }

      @include media-up(lg) {
        display: block;
      }

      svg.ob-icon--thinChevronDown  {
        fill: $base-color-dark-grey;
      }

      &:hover,
      &:focus {
        background-color: $base-color-dark-blue-hover;
        border-color: $base-color-dark-blue-hover;

        svg.ob-icon--thinChevronDown  {
          fill: $base-color__white;
        }
      }

      &[data-control-button="next"] {
        right: -15rem;
      }

      &[data-control-button="prev"] {
        left: -15rem;
      }
    }

    &--list {
      display: flex;
      list-style-type: none;
      padding: 0;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      margin: 3rem auto;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }

      &.is-centered {
        justify-content: center;
      }
    }

    &--list-item {
      margin: 0 1rem;
      text-align: center;
      scroll-snap-align: start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      transition: opacity ease 0.35s;
      padding: 1rem;

      @include media-down(sm) {
        padding: 0;
        margin: 0;
      }

      &:hover, &:focus, &.is-active {
        .ob-rp__carousel--list-button {
          background: $base-color-dark-blue;
          color: $base-color__white;
        }
      }
    }

    &--list-text, &--list-button {
      font-size: 1.6rem;
      @include font-family('semi-bold');
    }

    &--list-text {
      color: $base-color-dark-grey;
    }

    &--list-button {
      color: $base-color-dark-blue;
      padding: 1rem 2rem;
      border: 1px solid $base-color-dark-blue;
      border-radius: 20px;
      transition: all ease 0.35s;

      &:hover,
      &:focus {
        background: $base-color-dark-blue-hover;
        color: $base-color__white;
        text-decoration: none;
      }
    }
  }

  &__dop-container {
    @include media-up(md) {
      display: flex;
      width: 85%;
    }
    .ob-mainAsset-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  &__dop-input-container {
    width: 100%;
    margin-right: 3rem;
  }

  &__dop-disclaimer {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}
