@import "../../static/styles/mixins";
@import "../../static/styles/variables";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.ob-start-guide-layout-new {
  .start-guide-header {
    position: unset;

    .ob-SpotlightStickyBar {
      background: $base-color__white;
      position: unset;
    }
  }

  #main {
    margin-top: 0;
  }

  .ob-start-guide-content-block {
    .ob-background-wrapper {
      padding-top: 5%;
    }
  }

  .bold-text {
    font-weight: 700;
    display: inline;
  }

  .ob-SpotlightStickyBar-link.ob-link {
    display: block;
  }
}

.ob-start-guide-content-block {
  position: relative;

  .background-img {
    img {
      width: 100%;
    }
  }

  .start-guide-text-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 5%;

    @include media-down(md) {
      max-width: 60rem;
    }

    p {
      margin: 0px;
    }

    .title {
      font-size: 16px;
      line-height: 30px;
      @include font-family("hurme-bold");
    }

    .subtitle {
      font-size: 45px;
      line-height: 55px;
      @include font-family("hurme-light");

      .bold-text {
        @include font-family("hurme-bold");
      }
    }

    .description-container {
      display: flex;
      flex-direction: row;
      max-width: 62rem;

      .description {
        color: $base-color__white;
        font-size: 20px;
        line-height: 30px;
        margin: 1.5rem 0;
        @include font-family("regular");

        @include media-down(sm) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .ob-mainAsset-wrapper img {
        width: 55px;
        height: 52px;
      }
    }

    .topicHeader {
      font-size: 20px;
      line-height: 30px;
      @include font-family("hurme-bold");
      font-weight: 300;
    }

    .cta-link {
      border-radius: 21px;
      background-color: $base-color-dark-blue;
      color: $base-color__white;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      padding: 15px 20px;
      text-decoration: none;
      cursor: pointer;

      span {
        font-size: 18px;
        line-height: 20px;
        text-transform: capitalize;
        text-align: center;
        @include font-family("semi-bold");
      }
    }

    .ob-start-guide-topic-container {
      display: flex;
      column-gap: 10px;

      .ob-start-guide__form-list-item {
        .ob-start-guide__brush-type {
          span {
            color: $base-color-dark-blue;
            font-size: 20px;
            line-height: 24px;
            @include font-family("semi-bold");
            text-align: center;
            width: 70%;
            display: inline-block;
            margin-top: 1rem;

            @include media-down(sm) {
              width: 100%;
            }
          }

          .ob-mainAsset-wrapper {
            .ob-mainAsset-wrapper-img {
              height: 139px;
              width: 139px;
            }
          }
        }
      }
    }

    .start-guide-carousel-cards-mobile {
      width: 100vw;

      .start-guide-one-card {
        display: grid !important;
        flex-direction: column;
        align-items: center;

        img {
          width: 280px;
          height: 280px;

          @media screen and (max-width: 360px) {
            width: 245px;
            height: 245px;
          }
        }

        .card-title {
          font-size: 16px;
          line-height: 21px;
          @include font-family("hurme-bold");
          margin: 2% auto;
          padding-right: 3%;
        }

        .card-description {
          margin: 2% auto;
          text-align: center;
          @include font-family("hurme-regular");
          font-size: 14px;
          line-height: 22px;
          width: 95%;
          padding-right: 5%;

          .carosel-desc-list {
            padding-inline-start: 25px;
            margin-block-start: 0;
            margin-block-end: 0;
            text-align: left;
          }
        }
      }
    }
  }

  .start-guide-carousel-cards-mobile {
    position: absolute;
    top: 140%;
    width: 100%;
  }
}

.start-guide-carousel-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  margin: 5rem 0;

  @include media-up(md) {
    max-width: 101rem;
    justify-content: space-between;
  }

  @include media-up(lg) {
  }

  @media screen and (min-width: 1440px) {
    max-width: 120rem;
  }

  @media screen and (min-width: 1730px) {
    max-width: 152rem;
  }

  .start-guide-one-card {
    margin: 0 5rem 0 0;
    font-size: 20px;
    line-height: 28px;
    text-align: center;

    @include media-down(md) {
      max-width: 28.2rem;
    }

    @include media-up(md) {
      max-width: 28.2rem;
      margin: 0;
    }

    @media screen and (max-width: 1440px) {
      max-width: 25rem;
    }

    @media screen and (min-width: 1730px) {
      max-width: 34rem;
    }

    img {
      width: 100%;
    }
  }
}

.replacing-brush-heads-gentle-care,
.ultimate-clean-slider {
  width: 50%;

  .ob-background-wrapper {
    padding: 0 20% 25% 8%;

    @media screen and (min-width: 1200px) {
      padding: 65% 20% 25% 8% !important;
    }

    @media screen and (min-width: 1440px) {
      padding: 70% 20% 25% 8% !important;
    }

    @media screen and (min-width: 1440px) and (max-width: 1445px) {
      padding: 70% 20% 25% 8% !important;
    }

    align-content: center;
    justify-items: center;

    @include media-up(lg) {
      padding: 0px 5% 38% 8%;
    }
  }

  .start-guide-cta-link p {
    border-radius: 26px;
    @include font-family("semi-bold");
    color: $base-color-dark-blue;
    font-size: 18px;
    line-height: 14px;
    padding: 20px 48px;
    background-color: white;
    display: inline-block;
  }

  .title {
    color: white;
    @include font-family("semi-bold");
    font-size: 35px;
    letter-spacing: 0;
    line-height: 55px;
  }

  .description {
    width: 70%;
    color: white;
    @include font-family("regular");
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;

    .bold-text {
      @include font-family("hurme-bold");
    }
  }
}

.start-guide-three-step {
  transform: translateY(-7%);
  z-index: 2;

  @include media-down(sm) {
    transform: translateY(-8%);
    margin-top: -8rem;
  }

  @include media-up(lg) {
    transform: translate(0);
  }

  .start-guide-text-container {
    .description-container {
      @include media-up(lg) {
        max-width: 51rem;
      }
    }
  }
}

.start-guide-shuffle-block.Investment {
  transform: translateY(-7%);
  z-index: 1;

  @include media-up(lg) {
    margin-top: -7rem;
    transform: translate(0);
  }

  @include media-down(sm) {
    margin-top: -5rem;
  }

  @media screen and (min-width: 1400px) {
    transform: translate(0);
  }
}

.replacing-brush-heads-section {
  transform: translateY(-17%);
  z-index: 2;

  @include media-down(sm) {
    transform: translateY(-10%);

    .start-guide-text-container {
      top: 5%;
      width: 100%;
      max-width: 100%;
      text-align: center;
      padding-left: unset;
      align-items: center;
      padding: 0 20%;
      transform: unset;

      @include media-down(sm) {
        padding: 0 15%;
        z-index: 10;
      }

      .title {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 26px;
      }

      .subtitle {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 10px;
      }

      .description,
      .topicHeader {
        font-size: 14px !important;
        line-height: 22px !important;
        margin: 0 !important;
        margin-bottom: 10px !important;
      }

      .bold-text {
        @include font-family("hurme-bold");
      }
    }

    .ob-start-guide-topic-container {
      .ob-start-guide__brush-type {
        img {
          width: 105px !important;
          height: 105px !important;
          margin-bottom: 15px;
        }

        span {
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }
    }
  }

  @include media-up(lg) {
    transform: translateY(0);
    margin-top: -2rem;

    .start-guide-text-container {
      width: 25%;
      top: 30%;
      color: $base-color-dark-blue !important;
      transform: translateX(50%);

      .ob-start-guide-topic-container {
        column-gap: 40px;
        margin-top: 3rem;
      }
    }
  }

  @media screen and (min-width: 1730px) {
    margin-top: -9rem;
  }
}

.use-right-pressure {
  z-index: 1;
  column-gap: 40px;
  margin-top: -1rem;

  @media screen and (max-width: 1400px) {
    margin-top: -6rem;
  }
}

.protection-comfort-section {
  transform: translateY(-27%);
  z-index: 2;

  .slick-slider {
    @media screen and (max-width: 360px) {
      transform: translate(-3%, -5%) !important;
    }
  }

  .start-guide-text-container {
    top: 30%;

    .bold-text {
      font-weight: 700;
    }

    @media screen and (max-width: 1440px) {
      transform: translate(0);
    }

    @include media-up(lg) {
      width: 100%;
      padding: 0 15%;
      top: 10%;

      .description-container {
        max-width: 40rem;
      }
    }

    @media screen and (min-width: 1440px) {
      transform: translate(0);
      margin-top: -9rem;
      top: 25%;
    }

    @include media-down(sm) {
      text-align: left;
      max-width: 60%;
      padding-left: 3%;
    }

    .title {
      color: $base-color-start-guide-text-blue;
      @include font-family("hurme-bold");

      @include media-down(sm) {
        font-size: 12px;
        line-height: 26px;
        font-style: normal;
        margin-bottom: 10px;
      }
    }

    .subtitle {
      color: $base-color-start-guide-text-blue;
      @include font-family("hurme-light");

      @include media-down(sm) {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 10px;
      }
    }

    .cta-link {
      background-color: $base-color-start-guide-text-blue;
      padding: 10px 7px 10px 15px;
      text-decoration: none;
      height: 42px;
      width: 165px;
      border-radius: 21px;
      text-transform: uppercase;
      display: flex;

      span {
        text-align: left;
        background-image: url("https://images.ctfassets.net/iy5ifr0uwt25/NSVk2ExRtIfPTQy4CzTQ3/e2abdf52587990d31e9cf08468ea31bf/cta_arrow_icon.png");
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 25px;
        font-size: 12px;
        line-height: 15px;
      }

      @include media-down(sm) {
        max-width: 162px;
        max-height: 42px;
        border-radius: 20px;
      }
    }

    .description {
      font-size: 14px;
      line-height: 22px;
      @include font-family("hurme-regular");
      width: 80%;
    }

    .start-guide-carousel-cards {
      @include media-up(lg) {
        width: 100%;
        justify-items: flex-start;
        justify-content: space-between;
        align-items: flex-start;
        display: flex;
      }

      .bold-text {
        display: contents;
      }
    }

    .bold-text {
      @include font-family("hurme-bold");
      display: block;
    }
  }

  .start-guide-carousel-cards-mobile {
    transform: translateX(-3%);
  }

  @include media-down(sm) {
    transform: translateY(-30%);

    .start-guide-text-container {
      top: 10%;
    }
  }

  @include media-up(lg) {
    transform: translate(0);
  }
}

.smart-settings {
  z-index: 1;

  @include media-up(lg) {

    .start-guide-shuffle-block .start-guide-content-container {
      padding: 0 5% !important;
      height: 113vh !important;
    }
  }

  @media screen and (min-width: 1440px) {
    .start-guide-shuffle-block .start-guide-content-container {
      top: 22px;
    }
  }
}

.maintain-your-clean {
  transform: translateY(-40%);
  z-index: 2;
  margin-top: -2rem !important;

  @media screen and (min-width: 1280px) and (max-width: 1285px) {
    margin-top: -7rem !important;
  }
  .start-guide-container{
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .start-guide-text-container {
    width: 100%;

    @include media-down(sm) {
      top: 8%;
      text-align: left;
      max-width: 60%;
      width: 60%;
      padding-right: 3%;
      padding-left: 3%;
      transform: translateX(12%);
    }

    .title {
      color: $base-color-start-guide-text-blue;
      @include font-family("semi-bold");

      @include media-down(sm) {
        font-size: 12px;
        line-height: 26px;
        font-weight: bold;
        font-style: normal;
        margin-bottom: 10px;
      }
    }

    .subtitle {
      color: $base-color__white;
      @include font-family("hurme-light");

      @include media-down(sm) {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 10px;
        width: 90%;
      }
    }

    .cta-link {
      background-color: $base-color-start-guide-text-blue;

      @include media-down(sm) {
        max-width: 155px;
        max-height: 42px;
        border-radius: 20px;
      }
    }

    .description-container {
      display: block;
      max-width: 100%;
      @include media-down(sm) {
        width: 85%;
      }

      @media screen and (max-width: 360px) and (max-height: 705px) {
        width: 90%;
      }
    }

    .description {
      font-size: 14px;
      line-height: 22px;
    }

    .start-guide-carousel-cards {
      margin: 3rem 0;

      @include media-up(lg) {
        display: flex;
        max-width: 80rem;
        margin: 3rem 0 3rem -3rem;
      }

      @media screen and (min-width: 1440px) {
        max-width: 75rem;
        margin-left: -3rem;
      }

      .start-guide-one-card {
        max-width: 31.2rem;

        .ob-mainAsset-wrapper {
          margin: 0 3rem 1rem;
          max-width: 28.2rem;
        }

        .carosel-desc-list {
          text-align: left;
          padding-left: 5%;
          margin: 0;
        }

        div {
          text-align: left;
        }

        .bold-text {
          margin-bottom: 1rem;
          text-align: left;
        }
      }
    }

    .bold-text {
      @include font-family("hurme-bold");
    }

    .start-guide-carousel-cards-mobile {
      transform: translate(-50%, -8%);
    }
    .start-guide-content-cards {
      color: $base-color__white;
      margin-left: 5rem ;
      text-align: left;
      .cards{
        display: flex;
        align-items: center;
        width: 55%;
        margin: 76px 0;
        .cards-text-container{
          margin-left: 20px;
        }
        .card-title{
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          text-align: left;
          font-family: $hurmesemibold;
        }
        .card-description{
            font-size: 21px;
            font-weight: 400;
            line-height: 29px;
            text-align: left;
            font-family: $hurmeregular;
            .carosel-desc-list{
                  display: block;
                  padding: 0 0 0 26px;
                  list-style: none;
                  overflow: hidden;
                  counter-reset: numList;
              
                li {
                  float: left;
                  position: relative;
                  margin: 3px 0;
                }
              
                li:before {
                  counter-increment: numList;
                  content: counter(numList);
              
                  float: left;
                  position: absolute;
                  left: -26px;
              
                  font: bold 12px $hurmesemibold;
                  text-align: center;
                  color: $io2-tertiary-blue-color;
                  line-height: 20px;
                  width: 20px;
                  height: 20px;
                  background: $io2-tertiary-light-blue;
              
                  -moz-border-radius: 999px;
                  border-radius: 999px
                }
            }
        }
      }
      @include media-down(sm) {
        position: absolute;
        top: 24%;
        padding: 0 5%;

        .cards {
          display: flex;
          margin-bottom: 10px;
          align-items: center;

          h1 {
            font-size: 16px;
            margin: 0;
            line-height: 21px;
            @include font-family("hurme-bold");
          }

          p {
            font-size: 12px;
            margin: 0;
            line-height: 19px;
            color: $base-color__white;
          }

          .bold-text {
            @include font-family("hurme-bold");
          }

          .ob-mainAsset-wrapper-img {
            width: 90px;
            height: 90px;
            margin: 0 23px 0 0;
          }

          @include media-down(sm) {
            h2 {
              margin-block-start: 5px;
              margin-block-end: 5px;
              color: $base-color__white;
            }
          }
        }
      }

      @media screen and (max-width: 360px) {
        .cards {
          margin-bottom: 3px;
        }
      }
    }
  }

  @include media-up(lg) {
    margin-top: -2rem;
    transform: translateY(0);
  }

  @media screen and (min-width: 1440px) {
    transform: translate(0);
    top: 25%;
  }
}

.charging-base {
  transform: translateY(-70%);
  z-index: 1;

  @include media-down(sm) {
    transform: translateY(-17%);

    .start-guide-content-container {
      padding: 0 !important;
      grid-template-columns: 1fr 1fr !important;

      div:first-child {
        width: 100%;

        .start-guide-animation-asset {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }

      .start-guide-text-container {
        transform: translateX(-10%) !important;
        width: 125% !important;
        margin-top: -30% !important;

        .start-guide-secondary-image {
          display: none;
        }
      }
    }
  }

  @include media-up(lg) {
    transform: translateY(0);
  }

  .start-guide-text-content {
    width: 50%;
    margin: 0 5rem;
  }

  .start-guide-text-container {
    @media screen and (min-width: 2456px) {
      margin-left: 20%;
    }
  }
}

.start-brushing {
  background: linear-gradient(161.3deg, #021648 0%, #040D1F 91.04%);
  z-index: 2;
  margin-bottom: -5px;

  .background-img {
    vertical-align: baseline;
    display: flex;
  }
  .start-guide-text-container {
    transform: translateX(10%);
    align-items: center;
    text-align: center;
    @media screen and (max-width: 1440px) {
      top: 15%;
    }

    @media screen and (min-width: 1440px) {
      top: 25%;
    }

    @media screen and (min-width: 1730px) {
      top: 30%;
      transform: translateX(15%);
    }

    @include media-down(sm) {
      top: 7%;
      transform: unset;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      max-width: 100%;
    }

    .subtitle {
      margin-bottom: 2rem;
      color: $base-color__white;

      .bold-text {
        display: inline;
      }

      @include media-down(sm) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    .description-container {
      width: 100%;
      max-width: 100%;
      justify-content: center;
      align-items: center;

      @include media-down(sm) {
        justify-content: center;
      }
    }

    .description-container .description {
      @include font-family("hurme-bold");
      color: $base-color__white;
      width: 22rem;
      margin-top: 0;

      @include media-down(sm) {
        font-size: 18px;
        line-height: 20px;
        margin: 0;
        text-align: left;
      }
    }

    .cta-container {
      @include media-down(sm) {
        margin: 0 auto;

        .cta-link {
          min-width: 225px;
          width: 250px;

          span {
            text-transform: unset;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    .cta-link {
      display: flex;
      width: max-content;
      min-width: 34rem;
      background-color: $base-color__white;

      span {
        color: $base-color-dark-blue;
        text-transform: none;
      }

      @include media-down(sm) {
        min-width: 225px;
      }
    }
  }

  .select-calender {
    .calender-option-list-section {
      background: $base-color-dark-blue;
      padding: 20px 8px 10px 8px;
      color: $base-color__white;
      text-align: center;
      width: 60%;
      max-width: 100%;
      border-radius: 31px;
      margin-top: 15px;
      text-transform: capitalize;

      @include media-down(sm) {
        width: 100%;
      }

      .calender-title {
        @include font-family("hurme-regular");
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0;
        margin-bottom: 15px;
      }

      .calender-content {
        display: flex;
        justify-content: space-evenly;
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        @include font-family("hurme-regular");
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;
        padding: 2px 0px;

        .calender-img {
          img {
            width: 100%;
            height: 100%;
            max-width: 33px;
            max-height: 33px;
          }
        }
      }

      .calendar-close-button-bottom {
        width: 15px;
        height: 15px;
        padding: 12px;
        border: solid 2px white;
        display: block;
        position: absolute;
        border-radius: 50%;
        left: 50%;
        cursor: pointer;
        transform: translateY(-10px);

        @include media-down(sm) {
          left: 73%;
        }
      }

      .calendar-close-button-bottom::before,
      .calendar-close-button-bottom::after {
        position: absolute;
        content: "";
        height: 1px;
        width: inherit;
        background: white;
        left: 4px;
        padding: 1px;
        top: 49%;
        transition: all 0.3s ease-in;
      }

      .calendar-close-button-bottom::before {
        transform: rotate(45deg);
      }

      .calendar-close-button-bottom::after {
        transform: rotate(-45deg);
      }
    }
  }
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.start-guide-hero-banner {
  .start-guide-content-container {
    .background-img {
      img {
        width: 100%;
      }
    }

    .start-guide-image-container {
      visibility: hidden;

      @media screen and (min-width: 1280px) and (max-width: 1285px) {
        .ob-start-guide-chargerIcon-container {
          bottom: 0 !important;

          img {
            bottom: 0 !important;
            width: 80% !important;
            margin-right: unset !important;
          }
        }

        .ob-contentBlock-text-image {
          img {
            height: 100vh;
          }
        }
      }
    }
  }

  .start-guide-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 16%;
    width: 100%;
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    color: $base-color__white;
    justify-content: center;
    @include font-family("hurme-regular");

    @include media-down(sm) {
      padding-left: 5%;
      top: 8%;
      justify-content: flex-start;
    }

    @media screen and (max-width: 360px) {
      top: 5%;
    }

    .start-guide-text-container {
      @include media-down(sm) {
        width: 55%;
        margin-top: 30%;
      }

      @media screen and (max-width: 360px) {
        width: 60%;
      }

      @include media-up(lg) {
        width: 40%;
      }

      @media screen and (min-width: 1440px) {
        width: 30%;
      }

      animation: 2s ease 0s 1 slideInFromTop;

      @keyframes slideInFromTop {
        0% {
          transform: translateY(-5%);
          opacity: 0;
        }

        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }

      .start-guide-scroll-content {
        line-height: 22px;
      }
    }

    .start-guide-image-container {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      @include media-down(sm) {
        position: absolute;
        right: 0;
        top: 11%;
      }

      @include media-up(lg) {
        position: relative;
        bottom: 22%;
      }

      @media screen and (max-width: 360px) {
        top: 5%;
      }

      @media screen and (min-width: 1400px) {
        position: relative;
        bottom: 10rem;
      }

      .ob-start-guide-chargerIcon-container {
        @include media-up(lg) {
          position: relative;
          bottom: 24%;
        }

        @media screen and (min-width: 1400px) {
          bottom: 17%;
        }

        @media screen and (min-width: 1730px) {
          bottom: 20%;
        }

        img {
          @include media-up(lg) {
            position: relative;
            bottom: 5.5rem;
            margin-right: 2rem;
          }

          @media screen and (min-width: 1400px) {
            bottom: -10rem;
          }
        }
      }
    }

    .ob-contentBlock-text-image {
      width: 200px;

      @include media-down(sm) {
        width: 150px;
      }
    }

    .title {
      font-size: 65px;
      line-height: 45px;
      padding-bottom: 14px;

      @include media-down(sm) {
        font-size: 37px;
        line-height: 39px;
        margin-block-start: 0;
        margin-block-end: 0;
      }

      @include media-up(lg) {
        margin: 0 0 1rem 0;
      }
    }

    p {
      @include font-family("hurme-regular");
      font-size: 20px;
      line-height: 30px;

      b {
        @include font-family("hurme-bold");
      }

      @include media-down(sm) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .cards {
      display: flex;
      margin-bottom: 33px;
      align-items: center;

      h1 {
        font-size: 22px;
        margin: 0;
        line-height: 30px;
        @include font-family("hurme-bold");
      }

      p {
        font-size: 20px;
        margin: 0;
        line-height: 30px;
        width: 345px;
      }

      .ob-mainAsset-wrapper-img {
        width: 132px;
        height: 132px;
        margin: 0 23px 0 0;
      }
    }

    .start-guide-scroll-content {
      align-items: self-start;
      text-align: center;
      margin-left: 37px;
      display: inline-block;
      cursor: pointer;

      h2 {
        font-size: 20px;
        @include font-family("hurme-bold");
      }

      img {
        @include media-up(lg) {
          width: 6.8rem;
        }
      }

      animation: 2s ease-in 0s 1 slideInScroll;

      @keyframes slideInScroll {
        0% {
          transform: translateY(-5%);
          opacity: 0;
        }

        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  .start-guide-scroll-content {
    @include media-down(sm) {
      position: absolute;
      top: 60%;
      padding-left: 5%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        font-size: 14px;
        line-height: 16px;
        color: $base-color__white;
      }

      img {
        width: 44px;
      }
    }

    @media screen and (max-width: 360px) {
      top: 65%;
    }
  }
}

// Start Guide COntent block css
.start-guide-three-step {
  .start-guide-text-container {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 8%;
    position: absolute;
    top: 24%;
    width: 50%;
    text-align: left;
    right: 20%;

    @media screen and (max-width: 360px) {
      top: 3%;
    }

    @include media-down(sm) {
      width: 100%;
      text-align: center;
      top: 5%;
      padding: 0 2%;
      transform: unset;
      right: unset;

      .description {
        margin: 0 0 1.5rem 0 !important;
      }
    }

    @include media-up(lg) {
      max-width: 60rem;
      top: 5%;
      width: auto;
      padding: 0;
      transform: translateX(25%);
    }

    @media screen and (min-width: 1400px) {
      max-width: 63rem;
      top: 24%;
      padding-left: 0;
    }

    @media screen and (min-width: 1440px) and (max-width: 1445px) {
      top: 7%;
    }

    .cta-link {
      height: 42px;
      width: 155px;
      padding: 5px 20px;
      text-align: left;
      background-color: $io2-base-color-dark-blue;
      text-decoration: none;
      margin-top: 0;
      cursor: pointer;

      .down-arrow {
        margin-right: 1rem;
      }

      span {
        text-align: justify;
        font-size: 12px;
        line-height: 15px;
        height: 30px;
        width: 106px;
      }
    }

    .title {
      @include media-down(sm) {
        font-size: 16px;
        line-height: 26px;
        @include font-family("semi-bold");
        margin-bottom: 5px;
        color: $base-color__white;
      }
    }

    .subtitle {
      @include font-family("hurme-light");
      font-size: 45px;
      color: $base-color__white;
      line-height: 55px;
      margin-bottom: 20px;

      b {
        @include font-family("hurme-bold");
      }

      @include media-down(sm) {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 5px;
        padding: 0 6%;
      }
    }

    .description {
      @include media-down(sm) {
        font-size: 14px;
        line-height: 22px;
      }
    }

    p {
      @include font-family("hurme-light");
      font-size: 20px;
      color: $base-color__white;
      line-height: 30px;
      margin-bottom: 20px;

      b {
        @include font-family("hurme-bold");
      }
    }

    .cards {
      display: flex;
      margin-bottom: 1.5rem;
      align-items: center;

      @media screen and (min-width: 1440px) {
        margin-bottom: 3rem;
      }

      h1,
      h2 {
        font-size: 22px;
        margin: 0;
        line-height: 30px;
        @include font-family("hurme-bold");
        color: $base-color__white;
      }

      p {
        font-size: 20px;
        margin: 0;
        line-height: 30px;
        width: 345px;
      }

      .bold-text {
        @include font-family("hurme-bold");
      }

      .ob-mainAsset-wrapper-img {
        width: 132px;
        height: 132px;
        margin: 0 23px 0 0;
      }
    }

    .cta-link {
      .down-arrow {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid white;
      }
    }
  }

  .start-guide-content-cards {
    @include media-down(sm) {
      position: absolute;
      top: 24%;
      padding: 0 5%;

      .cards {
        display: flex;
        margin-bottom: 10px;
        align-items: center;

        h1 {
          font-size: 16px;
          margin: 0;
          line-height: 21px;
          @include font-family("hurme-bold");
        }

        p {
          font-size: 12px;
          margin: 0;
          line-height: 19px;
          color: $base-color__white;
        }

        .bold-text {
          @include font-family("hurme-bold");
        }

        .ob-mainAsset-wrapper-img {
          width: 90px;
          height: 90px;
          margin: 0 23px 0 0;
        }

        @include media-down(sm) {
          h2 {
            margin-block-start: 5px;
            margin-block-end: 5px;
            color: $base-color__white;
          }
        }
      }
    }

    @media screen and (max-width: 360px) {
      .cards {
        margin-bottom: 3px;
      }
    }
  }

  .cta-link {
    @include media-down(sm) {
      width: 135px;
      height: 42px;
      padding: 5px 7px;
      background-color: $io2-base-color-dark-blue;
      color: $base-color__white;
      text-decoration: none;
      text-align: center;
      margin-top: 0;
      cursor: pointer;
      position: absolute;
      top: 65%;
      left: 8%;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: none;
      column-gap: 5px;

      @media screen and (max-width: 360px) {
        left: 5%;
      }

      .down-arrow {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid white;
      }

      span {
        text-align: left;
        font-size: 14px;
        line-height: 15px;
      }
    }
  }
}

.text-left {
  text-align: left;
}

.start-guide-three-step{
  margin-top: unset !important;
  .background-img{
    background: $io2-base-color-dark-blue;
    img{
      width: 40%;
    }
  }
}

.replacing-brush-heads-section{
  .description-container{
    .description{
      color: $base-color-dark-blue !important;
    }
  }
}
.start-guide-hero-banner,
.start-guide-three-step,
.Investment,
.replacing-brush-heads-section,
.protection-comfort-section,
.maintain-your-clean,
.tart-brushing {
  position: relative;

  @include media-up(lg) {
    margin-top: -7rem;
  }

  @media screen and (min-width: 1440px) {
    margin-top: -10rem;
  }

  @media screen and (min-width: 1730px) {
    margin-top: -10rem;
    overflow: hidden;
  }
}

.replacing-brush-heads-section {
  @include media-down(sm) {
    margin-top: -14rem;
  }

  @media screen and (min-width: 390px) and (max-width: 395px) {
    margin-top: -12rem;
  }
}

.use-right-pressure {
  .start-guide-background img {
    margin: -10rem 0 0;

    @include media-down(sm) {
      margin: 0;
    }
  }

  @include media-down(sm) {
    transform: translateY(-8%);
    margin-top: -10rem;

    .start-guide-content-container {
      img {
        margin: unset !important;
      }
    }
  }

  @media screen and (min-width: 2456px) {
    margin-top: -3rem;
  }
}

.protection-comfort-section {
  @include media-down(sm) {
    transform: translateY(-14%);
  }
}

.maintain-your-clean {
  @include media-down(sm) {
    transform: translateY(-8%);
  }
}

.charging-base {
  @include media-down(sm) {
    transform: translateY(-17%);
    margin-top: -4rem;
  }
}

.start-brushing {
  @include media-down(sm) {
    margin-top: -20rem;
    margin-bottom: -2rem;
  }
}

.activeAnim {
  animation: 2s ease-in 0s 1 slideInThreeStepFromTop;
}

@keyframes slideInThreeStepFromTop {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.activeAnimReplacing {
  animation: 1s ease-in 0s 1 slideInReplacingBrush;
}

@keyframes slideInReplacingBrush {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}