@import '../../static/styles/mixins';

@keyframes spotlight-red-flash {
  0% {
    color: #FFFFFF
  }
  50% {
    color: #D0021B;
    text-shadow: 0 0 45px #FF0000, 0 0 30px #FF0000;
  }
  100% {
    color: #FFFFFF;
  }
}

@keyframes spotlight-green-flash {
  0% {
    color: #FFFFFF
  }
  50% {
    color: #7ED321;
    text-shadow: 0 0 55px #00FF00, 0 0 30px #00FF00;
  }
  100% {
    color: #FFFFFF;
  }
}

@keyframes front-to-back-mobile {
  0% {
    left: 50%;
    margin-left: -16.8rem;
    top: 12rem;
    width: 40.6rem;
    z-index: 2;
  }

  50% {
    left: 50%;
    margin-left: 0.5rem;
  }

  100% {
    left: 50%;
    margin-left: 0.5rem;
    opacity: 1;
    top: 25.9rem;
    width: 22rem;
    z-index: 1;
  }
}

@keyframes back-to-front-mobile {
  0% {
    left: 50%;
    margin-left: 0.5rem;
    opacity: 1;
    top: 25.9rem;
    width: 22rem;
    z-index: 1;
  }

  50% {
    left: 50%;
    margin-left: -16.8rem;
  }

  100% {
    left: 50%;
    margin-left: -16.8rem;
    top: 12rem;
    width: 40.6rem;
    z-index: 2;
  }
}

@keyframes front-to-back {
  0% {
    left: 8%;
    margin-left: -7.2rem;
    top: 2.7rem;
    width: 65rem;
    z-index: 2;
  }

  50% {
    left: 8%;
    margin-left: 19.5rem;
  }

  100% {
    left: 8%;
    margin-left: 19.5rem;
    opacity: 1;
    top: 23rem;
    width: 36rem;
    z-index: 1;
  }
}

@keyframes back-to-front {
  0% {
    left: 8%;
    margin-left: 19.5rem;
    opacity: 1;
    top: 23rem;
    width: 36rem;
    z-index: 1;
  }

  50% {
    left: 8%;
    margin-left: -7.2rem;
  }

  100% {
    left: 8%;
    margin-left: -7.2rem;
    top: 2.7rem;
    width: 65rem;
    z-index: 2;
  }
}

@keyframes mode-counter-step-1 {
  0% {
    opacity: 1;
    transform: scale(0.1) translate(-50%, -50%);
  }

  24% {
    opacity: 0;
    transform: scale(3)translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }
}

@keyframes mode-counter-step-2 {
  0% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%);
  }

  11% {
    opacity: 1;
    transform: scale(0.1) translate(-50%, -50%);
  }

  12% {
    opacity: 1;
    transform: scale(0.1) translate(-50%, -50%);
  }

  36% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }
}

@keyframes mode-counter-step-3 {
  0% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%);
  }

  25% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%);
  }

  26% {
    opacity: 1;
    transform: scale(0.1) translate(-50%, -50%);
  }

  49% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }
}

@keyframes mode-counter-step-4 {
  0% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%);
  }

  37% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%);
  }

  38% {
    opacity: 1;
    transform: scale(0.1) translate(-50%, -50%);
  }

  61% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }
}

@keyframes mode-counter-step-5 {
  0% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%);
  }

  50% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%);
  }

  51% {
    opacity: 1;
    transform: scale(0.1) translate(-50%, -50%);
  }

  74% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }
}

@keyframes mode-counter-step-6 {
  0% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%);
  }

  62% {
    opacity: 0;
    transform: scale(0.1) translate(-50%, -50%);
  }

  63% {
    opacity: 1;
    transform: scale(0.1) translate(-50%, -50%);
  }

  86% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }

  100% {
    opacity: 0;
    transform: scale(3) translate(-50%, -50%);
  }
}

@keyframes mode-counter-step-7 {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  75% {
    opacity: 0;
    transform: scale(0.1);
  }

  76% {
    opacity: 1;
    transform: scale(0.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes mode-display-icon-01 {
  0% {
    opacity: 0;
  }

  18% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  51% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes mode-display-icon-02 {
  0% {
    opacity: 0;
  }

  51% {
    opacity: 0;
  }

  66% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes mode-display-icon-03 {
  0% {
    opacity: 0;
  }

  85% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spotlight-tracking-icon {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spotlight-tracking-copy-01 {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spotlight-tracking-copy-02 {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spotlight-tracking-copy-03 {
  0% {
    opacity: 0;
  }

  95% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spotlight-tracking-label-01 {
  0% {
    margin-top: 50px;
    opacity: 0;
  }

  25% {
    margin-top: 50px;
    opacity: 0;
  }

  35% {
    margin-top: 0;
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  75% {
    opacity: 0.2;
  }

  90% {
    opacity: 0.2;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spotlight-tracking-label-02 {
  0% {
    margin-top: 50px;
    opacity: 0;
  }

  65% {
    margin-top: 50px;
    opacity: 0;
  }

  75% {
    margin-top: 0;
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes spotlight-tracking-label-03 {
  0% {
    margin-top: 50px;
    opacity: 0;
  }

  90% {
    margin-top: 50px;
    opacity: 0;
  }

  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes spotlight-tracking-ring-outer {
  0% {
    opacity: 0;
    transform: scale(0.5) translateZ(0) perspective(1px);
  }

  5% {
    transform: scale(1) translateZ(0) perspective(1px);
  }

  10% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  70% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes spotlight-tracking-ring-middle {
  0% {
    opacity: 0;
    transform: scale(0.333) translateZ(0) perspective(1px);
  }

  5% {
    transform: scale(1) translateZ(0) perspective(1px);
  }

  10% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  35% {
    opacity: 0.4;
  }

  65% {
    opacity: 0.4;
  }

  75% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  95% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes spotlight-tracking-ring-inner {
  0% {
    opacity: 0;
    transform: scale(0.1667) translateZ(0) perspective(1px);
  }

  5% {
    transform: scale(1) translateZ(0) perspective(1px);
  }

  10% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  35% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
