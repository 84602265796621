@import "../../../../static/styles/mixins";

.ob-toggle {
  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + .ob-toggle__label {
      background-color: $base-color-secondary-blue;
      color: $base-color__white;
      &:before {
        display: none;
      }
      &:after {
        display: inline-block;
      }
    }
  }

  &__label {
    @include font-family('semi-bold');
    cursor: pointer;
    font-size: 1.6rem;
    border: 1px solid $base-color-secondary-blue;
    color: $base-color-secondary-blue;
    border-radius: 20px;
    padding: 0.6rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 81px;
    transition: background-color ease 0.35s;

    &:before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: $base-color-secondary-blue;
    }

    &:after {
      content: '';
      display: none;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: $base-color__white;
      transition: all ease 0.35s;
    }
  }
}
