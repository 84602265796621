@import '../../static/styles/mixins';

.ob-spotlight-preorder {

    @include media-down(sm) {
        background: $base-color__white;
    }

    &__wrapper {
        padding: 0 1.3rem;
        @include media-up(sm) {
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            padding: 2rem;
            position: absolute;
            background: rgba(255, 255, 255, 0.68);
        }
        
        @include media-up(md) {
            width: 50%;
        }
        @include media-up(lg) {
            padding: 6rem 28rem 6rem 6rem;
        }
    }

    &__title {
        @include display-3-xl;
    }

    &__body {
        @include body-2-xl;
    }

    .ob-button {
        font-size: 1.6rem;
        @include media-down(md) {
            width: 100%;
            display: flex;
            padding: 3rem;
            justify-content: center;
        }
        @include media-up(md) {
            width: auto;
            padding: 3rem 10rem;
        }
    }
}