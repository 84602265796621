@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-article-promo {
    .ob-article-promo-card-container {
        .ob-tile{
            display: flex;
            flex-direction: row;
            padding: 2% 7.5%;
            @media screen and (min-width: 1271px) and (max-width: 1280px) {
                padding: 2% 2%;
            }
            @include media-down(sm){
                flex-direction: column;
                align-items: center;
            }
            .ob-article-dektop-image{
                    height: 100%;
                    width: 400px;
                    .ob-mainAsset-wrapper-img{
                        height: 100%;
                        width: 400px;
                    }
            }
            .ob-render-text{
                padding: 0px 0% 0 4%;
                .ob-category{
                    font-size: 20px;
                    color: $base-color-dark-blue;
                    line-height: 30px;
                    margin-top: 0px;
                    min-height: 2rem;
                    max-height: 4rem;      
                    font-weight: bolder;          
                    @include media-down(sm){
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                    }
                }
                .ob-article-heading{
                    font-size: 30px;
                    font-weight: bold;
                    margin-top: -12px;
                    min-height: 7rem;
                    max-height: 14rem;    
                    @media screen and (min-width: 1271px) and (max-width: 1280px) {
                        max-height: 8rem;
                        font-size: 28px;
                    }            
                    @include media-down(sm){
                        font-size: 28px;
                        text-align: center;
                    }
                }
                .ob-content{
                    font-size: 18px;
                    line-height: 20px;
                    color: $base-color-greyish;
                    margin-bottom: 15px;
                    min-height: 8rem;
                    max-height: 13rem;                
                    @include media-down(sm){
                        font-size: 16px;
                        text-align: center;
                    }
                }
                .ob-article-link{
                    font-size: 20px;
                    text-decoration: underline;
                    text-underline-position: under;
                    color: $base-color-dark-blue;
                    font-weight: bolder;
                }
            }
        }
    }
    button{
        text-decoration: none;
        padding: 0 46%;
        @media screen and (min-width: 1023px) and (max-width: 1286px) {
            padding: 0 42%;
            margin-left: 20px;        
        }
        @media screen and (min-width: 1290px) and (max-width: 1538px) {
            padding: 0 44%;
            margin-left: 20px;        
        }
    .article--btn-icon{
        font-size: 25px;
        color: $base-color-navy-blue;
        font-weight: bolder;
        .ob-icon--chevronRight{
            width: 3rem !important;
            height: 3rem !important;
            rotate: 90deg;
            fill: $base-color-navy-blue;
        }
    }
    }
    button:hover{
        text-decoration: none;
    }

    .ob-article-promo-card-container-mobile{
        .swiper{
            padding-bottom: 50px;
            .swiper-pagination-bullet{
                width: 15px;
                height: 15px;
            }
            .swiper-pagination-bullet-active{
                background: $base-color-dark-blue;
            }
            .swiper-button-prev,.swiper-button-next{
                background-size: cover !important;
                color: transparent;
                width: 4.5rem;
                height: 22rem;
                top: 2%;
            }
              .swiper-button-next:after, .swiper-button-prev:after{
                visibility: hidden;
              }
              .swiper-pagination{
                @include media-down(sm) {
                    bottom: -1px;
                }
              }
        }
        .ob-promo-card{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .ob-render-text{
                padding: 0 4%;
                @include media-down(sm){
                    text-align: center;
                    margin-bottom: 30px;
                }
                .ob-category{
                    color: $base-color-dark-blue;
                    line-height: 30px;
                    margin-top: 0px;
                        font-size: 17px;
                        font-weight: bold;
                        text-align: center;
                }
                .ob-article-heading{
                    font-weight: bold;
                    margin-top: -5px;
                        font-size: 17px;
                        text-align: center;
                        @include media-down(sm){
                            min-height: 75px;
                            max-height: 100px;                        
                        };
                }
                .ob-content{
                    line-height: 20px;
                    color: $base-color-greyish;
                    margin-bottom: 15px;
                        font-size: 16px;
                        text-align: center;
                        @include media-down(sm){
                            min-height: 115px;
                            max-height: 165px
                        }
                }
                .ob-article-link{
                    font-size: 20px;
                    text-decoration: underline;
                    text-underline-position: under;
                    color: $base-color-dark-blue;
                }
            }
            .ob-article-mobile-image{
                height: auto;
                width: 250px;
                .ob-mainAsset-wrapper-img{
                    width: 250px;
                }
            }
            img {
                width: 260px
            }
            .ob-promo-card-title {
                font-size: 20px;
                line-height: 20px;
                text-align: center;
                @include font-family('semi-bold');
                width: 30rem;
                min-height: 6rem;
                margin-top: 20px;
            }

            .ob-promo-card-desc {
                font-size: 16px;
                line-height: 16px;
                text-align: center;
                @include font-family('hurme-regular');
                width: 30rem;
                min-height: 7rem;
            }
            .ob-button{
                width: 30rem;
                height: 6rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
    }
}
}