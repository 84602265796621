@import "../../static/styles/mixins";

.ob-article-table-block {
    @include media-up(md) {
        max-width: 96.5rem;
        margin: 0 auto;
    }
    &__title {
        @include display-3-xl;
        text-align: center;
        margin: 5rem auto;
        @include media-up(md) {
            max-width: 60%;
        }
    }

    &__table {
        width: 90%;
        margin: 0 auto;

        @include media-up(sm) {
            border-collapse: collapse;
            table-layout: fixed;
            border: none;
        }
        @include media-up(md) {
            width: 100%;
        }
    }

    &__table-head-title {
        @include font-family('semi-bold');
        color: $base-color-dark-blue;
        font-size: 1.5rem;
        text-align: left;
        margin: 0;

        @include media-up(sm) {
            padding-bottom: 1rem;
            padding-left: 2rem;

            &:nth-child(1) {
                width: 30%;
            }
            &:nth-child(2) {
                width: 28%;
            }
            &:nth-child(3) {
                width: 42%;
                padding-left: 3rem;
            }
        }

    }

    &__table-body {
        padding: 0;
        list-style-type: none;
    }

    &__table-body-row {
        border-top: 1px solid $base-color-secondary-grey;
        justify-content: space-between;
        display: flex;
        padding: 2rem 0;
        &:first-child {
            border-top: none;
            @include media-up(sm) {
                border-top: 1px solid $base-color-secondary-grey;
            }
        }

        @include media-up(sm) {
            padding: 0;
            display: table-row;
        }
    }

    &__table-body-col {
        &:first-child {
            width: 30%;
        }
        &:last-child {
            width: 60%;
        }
    }

    &__table-body-cell {
        font-size: 1.4rem;
        letter-spacing: 0.5px;
        line-height: 2.2rem;
        color: $base-color-mid-dark-grey;
        @include media-up(sm) {
            padding: 2rem 0 2rem 2rem;
            vertical-align: top;
        }
        &--has-icon {
            @include media-up(sm) {
                display: flex;
                padding-right: 2rem;
            }
            @include media-up(md) {
                padding-right: 3rem;
            }
            .ob-mainAsset-wrapper {
                width: 6.2rem;
                img {
                    max-width: 100%;
                }
            }
            &__text {
                @include media-up(sm) {
                    width: 41%;
                    flex: 0 1 40%;
                    margin: 0 0 0 2.5rem;
                    font-size: 1.2rem;
                }

                @include media-up(md) {
                    font-size: 1.4rem;
                }
            }
        }
        &:nth-child(2) {
            @include media-up(sm) {
                padding-right: 4rem;
            }
        }
        &:nth-child(3) {
            @include media-up(sm) {
                padding-left: 3rem;
            }
        }
    }
}
