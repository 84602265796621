@import "../../../../../static/styles/mixins";

.ob-change {
    .ob-form__title {
        margin-bottom: 3.7rem;
        @include media-up(sm) {
            margin-bottom: 2rem;
        }
    }
    .ob-form__button__submit {
        margin: 2.6rem 0 0 0;
        @include media-up(sm) {
            margin: 5.2rem 0 0 0;
        }
    }
    .ob-form__requirements {
        margin-top: 2rem;
    }
}