@import "../../static/styles/mixins";

.ob-faq-details-block {
    max-width: $article-body-width;
    width: 80%;
    margin: 0 auto;
    padding: 2rem 0 0;

    @include media-down(md) {
        padding: 0;
    }

    h1 {
        @include display-2-xs;
        @include font-family('hurme-regular');
        font-size: 3rem;
    }

    .ob-faq-details__body {
        @include body-1-xl;
        margin-top: 30px;

        a {
            text-decoration: underline;

            &:focus {
                @include focusOutline();
            }
        }
    }
}