@import '../../static/styles/mixins';
/*
    This stylesheet is a bit confusing, but there was no way around it,
    the way HierarchyUtils is built, doesn't allow the addition of classnames and has a lot of recursion

    Nonetheless, I tried documenting it as much as possible.
*/
.ob-sitemap {
    background-color: $base-color-secondary-darker-off-white;
    padding: 3rem 0;

    a {
        font-size: 1.8rem;

        &:link, &:visited {
            color: $base-color-primary-grey;
        }
    }

    ul {
        list-style-type: none;
    }

    &__container {
        max-width: $breakpoint-large;
        margin: 0 auto;
    }

    &__title {
        @include display-1-xl;
        margin-top: 0;
        text-align: center;
    }

    &__list {
        padding: 0;
        @include media-up(sm) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__list-item {
        @include media-up(sm) {
            flex: 1 0 45%;
        }

        ul {
            padding-left: 2rem;

            li {
                margin: 2rem 0;
            }

            // styles for container in Hierarchy
            > li > div {

                // Styles for "h3"
                > div {
                    font-size: 2.4rem;
                }

                > ul {
                    border-bottom: 1px solid $base-color-primary-grey;
                }

                > ul > li > div {

                    //Styles for "h4"
                    >div {
                        @include font-family('semi-bold');
                        font-size: 2rem;
                    }
                }
            }
        }

        // removes border-bottom for all the last elements in a sub-sub hierarchy list
        > div > ul > li > div > ul li:last-child > div > ul,
        > div > ul > li:last-child > div > ul:last-child {
            border-bottom: none;
        }

        // Styles for "h2"
        > div > div {
            @include font-family('hurme-light');
            font-size: 3.2rem;
            letter-spacing: (0.5em / 32);
        }

        #Hierarchy---Sitemap---Oral-Health-Tips-and-Article {
            > li > a {
                font-size: 3.2rem;
            }
        }

        #Hierarchy---Sitemap---Contact-Us {
            padding-left: 0;
            border-bottom: none;

            > li {
                border-top: 1px solid $base-color-primary-grey;
                padding-top: 4rem;

                a {
                    font-size: 4rem;
                    @include font-family('hurme-light')
                }
            }
        }
    }
}
