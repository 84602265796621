@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-product-variants-recommander-block {
  position: relative;
}

.ob-product-variants-recommander-block-wrapper {
  position: relative;
  margin: 0 auto;
  max-width: $oralb-max-desktop-width;
  padding: rem(22) rem(10);

  @include media-up(md) {
    padding: 2.2rem 1rem 0;
  }

  &.ob-product-variants-recommander-block-disclaimer {
    font-size: 1.5rem;
  }
}

.ob-product-variants-recommander-block__heading,
.ob-product-variants-recommander-block__body-text,
.ob-product-variants-recommander-block__eyebrow {
  padding: 0 rem(10);
  text-align: center;
  color: $base-color-primary-grey;
}

.ob-product-variants-recommander-block__eyebrow {
  @include eyebrow-1-xs;
  margin: rem(8) 0;
}

.ob-product-variants-recommander-block__heading {
  @include display-3-xs;
  margin: rem(8) 0;
}

.ob-product-variants-recommander-block__body-text {
  @include body-1-xs;
  margin: rem(20) 0;
}

.ob-product-variants-recommander-block__product-grid-wrapper{
  @include media-up(md) {
    padding: 1rem 1rem 2.2rem;
  }
  .has-divider & {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.radial-background {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 73%);
  z-index: 0;
}
