@import "../../static/styles/mixins";

.ob-spotlightVideo {

    &.mobile-letterbox {

        @include media-down(sm) {
            .ob-spotlightVideo-container {
                align-items: center;
                background-color: $base-color__black;
                display: flex;
                height: 100%;

                .ob-icon {
                    fill: $base-color__white !important;
                }

                .ob-video-player-button {
                    color: $base-color__white !important;
                }
            }

            .video-js {

                &.vjs-fluid {
                    width: 100%;
                    position: relative;
                    height: auto;
                }

                .vjs-tech {
                    left: auto;
                    position: relative;
                    top: auto;
                }
            }
        }
    }


    &-container {
        height: 100%;
        position: relative;
        z-index: map-get($zindex, negative);

        .ob-video-player {
            height: 100%;
        }

        .video-js {
            &.vjs-fluid {
                height: 100%;
                padding-top: 0;
            }

            .vjs-tech {
                object-fit: cover;
            }
        }

        .cld-video-player.vjs-paused,
        .cld-video-player.vjs-paused.vjs-has-started {

            .vjs-big-play-button {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &__intro {
        margin-top: 2.9rem;
        padding: 0 4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: auto;
        left: 0;
        right: 0;

        .ob-spotlight-nl & {
            align-items: center;
        }

        .ob-bodyText {
            @include font-family('semi-bold');
            font-size: 2rem;
            text-align: center;

            .bg-black--sm-down & {
                @include media-down(md) {
                    background: rgba(0, 0, 0, 0.7);
                }
            }
        }

        .ob-spotlightContentBlock & {
            .ob-bodyText {
                margin-top: 0;
            }
        }

        &-image {
            display: flex;
            align-items: center;
            width: 80px;
            margin-right: 1.5rem;

            .ob-spotlight-nl & {
                width: 160px;
            }

            img {
                max-width: 100%;
            }
        }

        opacity: 0;
        transition: opacity .6s linear;

        .step-1 & {
            transition: opacity .6s linear;
            opacity: 1;
        }

        &.hide {
            opacity: 0;
        }

        @media screen and (max-width: 1100px){
            .ob-spotlight-nl & {
                margin-top: 1.5rem;
            }
            &-image {
                .ob-spotlight-nl & {
                    width: 100px;
                }
            }
        }

        @include media-down(sm) {
            flex-direction: column;
            align-items: center;
            padding: 0 2rem;
            margin-top: 1.5rem;

            .ob-spotlight-nl & {
                flex-direction: row;
                align-items: flex-start;
                margin-top: 1.2rem;
                .ob-bodyText {
                    text-align: left;
                    margin-top: -.2rem;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
            }

            &-image {
                width: 61px;
                margin-right: 0;
                .ob-spotlight-nl & {
                    width: 70px;
                    margin-right: 1rem;
                }
            }

            .ob-bodyText {
                font-size: 1.6rem;
                line-height: 2.4rem;
                margin-top: 1.1rem;
            }
        }

        @include media-down(xs) {
            .ob-bodyText {
                .ob-spotlight-nl & {
                    font-size: 1.3rem;
                }
            }
        }

    }

    .ob-video-player-button {
        &:focus {
            outline-style: solid;
            outline-width: 1px;
        }
    }
}

a.ob-SpotlightContentVideo__CCPA {
    @include font-family('hurme-regular');
    color: white;
    font-size: 1.4rem;
    text-decoration: underline;
    margin-right: 1rem;
    letter-spacing: .06rem;
    display: inline;
    position: fixed;
    top: auto;
    right: 5px;
    bottom: 20px;
    left: auto;

    @include media-up(md) {
        display: none;
    }

    &:hover {
        text-decoration: none;
    }
}
