@import "../../static/styles/mixins";

.ob-contact-us {
	display: flex;
	padding: 20px 0;

	.ob-contact-us-iframe {
		width: 942px;
		height: 560px;
		margin: auto;
		border: 0px solid;
	}
}

