@import "./mixins";

.zone.zone-header {
  position: fixed;
  top: 0;
  z-index: map-get($zindex, header);
  width: 100%;

  @include media-down(md) {
    html[lang="ja"] & {
      height: 86px;
    }
  }

  &.visible-true {
    transition: top 0.35s ease-in;
  }

  &.visible-false {
    transition: top 0.35s ease-out;
  }
}

.ob {
  &-utilityBar {
    display: none;

    @include media-up(md) {
      align-items: center;
      background-color: $base-color-secondary-darker-off-white;
      display: flex;
      min-height: 4rem;
      padding: 0.5rem calc((100% - #{$oralb-max-desktop-width}) / 2);
    }

    @include media-ie() {
      height: 4rem;
    }
    
    .ob-rich-text-link.pre-purchase-nav-link{
      margin-left: auto;
      font-size: 1.3rem;
      
      &::after{
        margin: 0px 5px;
        content: "|";
      }
    }
  }
}

.product-etb-full-layout,
.product-etb-default-layout,
.ob-product-legacy-full-layout{
  .zone.zone-header{
    position: relative !important;
  }
}