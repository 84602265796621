@import "../../static/styles/mixins";

.ob-disclaimer {
    pointer-events: all;
    @include body-4-xl;
    &[data-white-text='true'] {
        color: $base-color__white;
    }

    &.bg-white, &.bg-black {
        border-radius: 3px;
        padding: 0.2rem;
    }

    &.bg-white--sm-down, &.bg-black--sm-down {
        @include media-down(sm) {
            border-radius: 3px;
            padding: 0.2rem;
        }
    }

    &.bg-white {
        background: rgba($base-color__white, 0.7);
        &--sm-down {
            @include media-down(sm) {
                background: rgba($base-color__white, 0.7);
            }
        }
    }

    &.bg-black {
        background: rgba($base-color__black, 0.7);
        &--sm-down {
            @include media-down(sm) {
                background: rgba($base-color__black, 0.7);
            }
        }
    }
}

.disclaimer-bottom {
    .ob-disclaimer {
        @include media-up(md) {
            position: absolute;
            bottom: 0;
            top: auto;
            left: auto;
            right: auto;
        }
    }
}
