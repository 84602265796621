@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.post-purchase-register {
  scroll-margin-top: 12rem;
  .ob-register {
    &__all-fields-required {
      font-size: 1.6rem;
      letter-spacing: 0.57px;
      line-height: 2.2rem;
    }

    &__wrapper {
      text-align: center;
      max-width: 60%;
      @include media-down(md) {
        max-width: 80%;
      }
      @include media-down(sm) {
        max-width: 90%;
      }
    }

    &__cta-section {
      width: 100%;
      display: flex;
      justify-content: center;
      button:hover,
      button:focus {
        background: $base-color-tertiary-blue;
      }
      button:focus {
        text-decoration: underline;
        @include focusOutline();
        border: 1px solid ;
      }

    }

    

    &__row:first-of-type {
      margin-top: 2.4rem;
    }

    &__row {
      margin-top: 4rem;

      @include media-down(sm) {
        &-password {
          .ob-input__wrapper:last-of-type {
            margin-bottom: 0;
          }
        }

        &-other {
          margin-top: 3rem;
        }
      }
    }

    .ob-form {
      &__wrapper {
        grid-template-columns: 1fr;
        max-width: 50%;
        margin: 0 auto;
        @include media-down(md) {
          max-width: 100%;
        }
        .ob-input__label {
          text-align: left;
        }
      }
      &__error-message {
        color: red;
        font-size: 15px;
        padding-top: 20px;
      }
      &__form {
        .ob-input__error {
          font-size: 1.5rem;
          @include media-down(md) {
            font-size: 1.3rem;
          }
        }
      }
      &__layout {
        margin-top: 5rem;
        margin-bottom: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__title {
        width: 60%;
        font-weight: bold;
        @include media-down(md) {
          width: 80%;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        @include media-down(tiny) {
          width: 90%;
        }
      }
      &__promo-code,
      &__subtitle {
        font-size: 1.5rem;
        line-height: 2rem;
        color: $base-color-dark-blue;
        max-width: 35%;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: bold;
        @include media-down(md) {
          margin-bottom: 0;
          margin-top: 1rem;
          margin-bottom: 1rem;
          max-width: 80%;
          line-height: 22px;
        }
      }
      &__promo-code {
        margin-top: 1rem;
        height: 24px;
        max-width: 40%;
        color: $base-color-dark-blue;
        @include font-family('semi-bold');
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 24px;
        text-align: center;
        @include media-down(md) {
          max-width: 100%;
          width: 100%;
        }
      }
      &__replace-head-description,
      &__description {
        @include font-family('hurme-regular');
        font-size: 2.2rem;
        color: $base-color-dark-grey;
        line-height: 3rem;
        max-width: 50%;
        margin-top: 1rem;
        margin-bottom: 0;
        @include media-down(md) {
          max-width: 70%;
        }
        @include media-down(sm) {
          max-width: 50%;
          font-size: 1.8rem !important;
        }
        &:focus {
          outline: none;
          text-decoration: underline;
        }
      }
      &__replace-head-description {
        max-width: 40%;
        text-transform: uppercase;
        margin-top: 2rem;
        font-size: 16px;
        @include media-down(md) {
          display: none;
        }
      }
      &__description {
        @include media-down(md) {
          max-width: 70%;
        }
        @include media-down(sm) {
          max-width: 90%;
        }
        @include media-down(tiny) {
          max-width: 90%;
        }
      }
      &__form {
        margin-top: 3.6rem;
        @include media-up(sm) {
          margin-top: 2rem;
        }
      }
      &__subtitle a {
        text-decoration: underline;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
      &__requirements {
        margin-top: 0;
      }
    }
    .ob-eyebrow {
      font-size: 1.6rem;
      @include media-down(sm) {
        margin-bottom: 2.5rem;
      }
    }

    &__step-title.ob-eyebrow {
      @include font-family('hurme-light');
      font-size: 2.8rem;
      line-height: 2.4rem;
    }

    &__pass-requirements {
      @include font-family('hurme-regular');
      color: #4a4a4a;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 22px;
    }

    &__info-text,
    .ob-checkbox__label {
      font-size: 1.4rem;
      margin: 3rem 0;
      color: $base-color-primary-grey;

      p {
        line-height: 2.2rem;
        margin: 0;
      }
    }

    .ob-checkbox__label {
      margin: 2rem 0 0 !important;
    }

    &__info-text-bottom {
      font-size: 1.4rem;
      color: $base-color-primary-grey;

      ul {
        padding: 0;
        list-style: inside;
        margin-top: 2.8rem;
      }

      li {
        margin-bottom: 0.2rem;
      }
    }

    .ob-input__wrapper {
      text-align: left;
      @include media-down(sm) {
        margin: 0 0 1.6rem;
      }
    }

    .ob-checkbox__input:checked + .ob-checkbox__label:after {
      top: calc(50% - 3px);
    }

    .ob-checkbox__label {
      display: flex;
      align-items: center;
    }

    .ob-form__button__submit {
      margin: 5.6rem 0 2.6rem;
      width: 212px;
      text-decoration: none;
      @include media-up(sm) {
        margin: 4rem 0 2.6rem;
      }
      @include media-down(md) {
        height: 48px !important;
      }
    }

    &__modal-container {
      width: 82%;
      height: 250px;
      padding: 2rem 4rem 2rem 2rem;
      display: flex;
      align-items: center;
      background: $base-color__white;
      box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
      position: relative;
      border-radius: 4px;
      @include media-up(sm) {
        width: 52%;
        height: 200px;
        padding-right: 2rem;
      }
      p {
        font-size: 1.6rem;
        line-height: 2.2rem;
        @include font-family('hurme-regular');
      }

      .ob-modal-close {
        filter: none;
        transition: transform ease 0.35s;
        &:hover,
        &:focus {
          transform: scale(1.05);
        }
      }
    }

    .ob-register-product {
      &__wrapper {
        @include media-up(lg) {
          margin: 0;
          max-width: 100rem;
        }
        @include media-down(md) {
          width: 100%;
          max-width: 100rem;
        }
      }
      &__goals-text {
        margin: 3rem 0 1rem !important;
      }
    }
    .checkbox-email-disclaimer {
      top: 0;
    }
    .disclaimer-text-why {
      margin-bottom: 2rem !important;
      text-align: left;
    }
    .ob-checkbox__wrapper {
      text-align: left;
    }
    .ob-checkbox__label {
      align-items: flex-start;
    }
    .ob-checkbox__custom-box {
      top: 0;
    }
  }

  .ob-post-purchase-register-result-mobile {
    &__brush-types {
      display: none;
      @include media-down(md) {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 15px;
      }
    }
    &__brush-type {
      box-sizing: border-box;
      height: 130px;
      width: 79px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      display: flex;
      color: $base-color-dark-blue;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      cursor: pointer;
      margin-right: 32px;
      span {
        opacity: 0.2;
        width: 80%;
        margin: 0 auto;
        text-align: center;
        @include media-down(md) {
          width: 100%;
        }
        @media only screen and (min-width:760px) 
        and (max-width: 1020px) {
          width: 100px;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    &__brush-type:last-child {
      margin-right: 0;
    }
    &__active-brush-type {
      span {
        opacity: 1;
      }
    }
  }

  .ob-post-purchase-register-result {
    &__brush-types {
      box-sizing: border-box;
      height: 50px;
      width: 500px;
      border: 1px solid $base-color-dark-blue;
      border-radius: 10px;
      background-color: #ffffff;
      display: flex;
      flex-direction: row;
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: 15px;
      font-size: 1.5rem;
      @include media-down(md) {
        display: none;
      }
    }
    &__brush-type {
      box-sizing: border-box;
      height: 48.2px;
      width: 200px;
      background-color: #ffffff;
      color: $base-color-dark-blue;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      cursor: pointer;
      font-size: 1.5rem;
    }
    &__brush-type-left {
      border-radius: 10px 0 0 10px;
      width: 300px;
    }
    &__brush-type-right {
      border-radius: 0 10px 10px 0;
      width: 200px;
    }
    &__active-brush-type {
      background-color: $base-color-dark-blue;
      color: #ffffff;
    }

    &__disclaimer-note {
      height: 18px;
      width: 720px;
      color: #3d3d41;
      @include font-family('hurme-regular');
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.5px;
      line-height: 18px;
      text-align: center;
      @include media-down(md) {
        width: 80%;
      }
    }
    &__title {
      margin-top: 3.5rem;
    }

    &__app-title,
    &__title {
      font-size: 1.6rem;
    }

    &__text {
      font-size: 2.2rem;
    }

    &__carousel {
      position: relative;
      margin-top: 50px;

      .ob-mainAsset-wrapper-img {
        max-width: 100%;
        max-height: 100%;
      }

      &--control-button {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all ease 0.35s;
        display: block;
        &:disabled {
          pointer-events: none;
          opacity: 0.4;
        }

        @include media-down(md) {
          display: none;
        }

        &[data-control-button="next"] {
          right: -15rem;
        }

        &[data-control-button="prev"] {
          left: -15rem;
        }
      }
      .ob-dot-nav {
        display: none;
        @include media-down(md) {
          display: block;
        }
      }
    }

    &__product-list {
      display: flex;
      list-style-type: none;
      padding: 0;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      margin: 3rem auto;
      -ms-overflow-style: none;
      @include media-down(md) {
        width: 350px;
        max-width: 350px;
        min-width: 350px;
      }
      &::-webkit-scrollbar {
        display: none;
      }

      &.is-centered {
        justify-content: center;
      }
    }

    &__product-list-item {
      text-align: center;
      scroll-snap-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 444px;
      width: 221px;
      margin: 0 1.5rem;
      padding: 1rem;
      border-radius: 13.5px;
      box-shadow: inset 0 -2px 3px 0 rgba(187, 187, 187, 0.5),
        inset 0 2px 3px 0 rgba(187, 187, 187, 0.5),
        5px 7px 14px 0 rgba(0, 0, 0, 0.1);
      @include media-down(md) {
        margin: 0 1.5rem;
        width: 221px;
        min-width: 221px;
        max-width: 221px;
      }
    }

    &__product-list-item-link-disable {
      opacity: 0.5;
      pointer-events: none;
    }

    &__icon {
      max-width: 200px;
      max-height: 220px;
      margin-top: 20px;
      min-width: 200px;
      min-height: 220px;
      @include media-down(md) {
        height: auto;
        margin: 0 auto;
      }
    }

    .ob-mainAsset-wrapper {
      width: 200px;
      height: 220px;
      margin: 0 auto;
      @include media-up(sm) {
        width: 200px;
        height: 220px;
      }
    }

    &__product-image {
      max-width: 100%;
      max-height: 100%;
      border-style: none;
    }

    &__product-title {
      font-size: 1.6rem;
      color: $base-color-dark-grey;
      @include font-family('semi-bold');
    }

    &__product-link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 14px;
      letter-spacing: 0.5px;
      margin: 0;
      margin-top: 7px;
      text-decoration: none;
      color: $base-color-dark-blue;
      flex-wrap: wrap;
      @include font-family('hurme-regular');
      &:hover,
      &:focus {
        text-decoration: underline;
        cursor: pointer;
      }
      .ob-mainAsset-wrapper {
        width: 15px;
        height: 15px;
        margin: 0;
        margin-left: 2px;
      }
      &-item{
        display: flex;
        padding-top: 2px;
      }
    }
    &__product-link span {
      width: auto;
    }
    &__checkmark-icon {
      max-width: 15px;
      max-height: 15px;
      margin-left: 3px;
    }

    &__product-description {
      width: 92%;
      color: #3d3d41;
      @include font-family('hurme-regular');
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 16px;
      text-align: center;
    }
    &__product-button {
      &:first-child {
        margin-right: 2rem;
      }

      &.--is-primary {
        cursor: default;

        &:hover,
        &:focus {
          background-color: $base-color-dark-blue;
        }
      }
    }

    &__add-product-container {
      margin-top: 3rem;
      text-align: center;
    }

    &__product-limit-label {
      font-size: 1.4rem;
      margin-left: 2rem;
      color: $base-color-primary-grey;
      @include font-family('semi-bold');
    }

    &__app-container {
      margin: 5.5rem 0 0;
      padding: 5rem 0 0;
      border-top: 1px solid $base-color-secondary-grey;
    }

    &__app-list {
      list-style-type: none;
      padding: 0;
      display: flex;

      li {
        &:first-child {
          margin-right: 2rem;
        }

        .ob-mainAsset-wrapper-img {
          max-width: 100%;
        }
      }
    }

    &__app-img {
      transition: transform ease 0.4s;
      .ob-product-management__app-link:hover &,
      .ob-product-management__app-link:focus & {
        transform: scale(1.03);
      }
    }
  }
}

.post-purchase-register-legal-modal {
  .ReactModalPortal {
    .ReactModal__Content--after-open {
      @media only screen and (max-width: 1280px) {
        max-width: 100% !important;
      }
    }
  }
}
