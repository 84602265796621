@import "../../static/styles/mixins";

.ob-ProgressBar {
    bottom: auto;
    width: 100%;
    height: 3px;
    left: 0;
    position: fixed;
    z-index: 99999;
    right: 0;
    top: 0;
    background: $base-color-minimal-grey;

    .ob-spotlight-layout & {
        top: $ob-sticky-bar-height-mobile;
    }

    @include media-up(md) {
        height: 4px;

        .ob-spotlight-layout & {
            top: $ob-sticky-bar-height;
        }
    }
}

.ob-ProgressBar-inner {
    height: 100%;
    background: $base-color__white;
    transition: width .5s ease-in-out;
}