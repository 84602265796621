@import "../../static/styles/mixins";

.ob-pressQuoteItem {
    position: relative;
    &-wrapper {
        @include container-wrapper;
        height: 100%;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    &-textContent {
        opacity: 0;
        transition: opacity 2s ease-out,
        top 1s ease-out,
        left 1s ease-out,
        right 1s ease-out,
        bottom 1s ease-out,
        transform 1s ease-out;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        z-index: map-get($zindex, one);

        .scale-transition & {
            transform: scale(0.95);
        }

        .top-transition & {
            top: -4%;
        }

        .bottom-transition & {
            bottom: -4%;
        }

        .left-transition & {
            left: -4%;
        }

        .right-transition & {
            right: -4%;
        }

        .show & {
            opacity: 1;

            .top-transition & {
                top: 0;
            }

            .bottom-transition & {
                bottom: 0;
            }

            .left-transition & {
                left: 0;
            }

            .right-transition & {
                right: 0;
            }

            .scale-transition & {
                transform: scale(1);
            }
        }

        &.hasPadding {
            padding: 15px;
        }

        &.horizontal-left {
            justify-content: flex-start;
        }

        &.horizontal-center {
            justify-content: center;
            .ob-link,
            .ob-button {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &.horizontal-right {
            justify-content: flex-end;
        }

        &.vertical-top {
            align-items: flex-start;
        }

        &.vertical-center {
            align-items: center;
        }

        &.vertical-bottom {
            align-items: flex-end;
        }

        &-inner {
            width: 100%;
            padding: 3rem;
            border-radius: 1.2rem;

            @include media-up(sm) {
                width: 50%;
            }

            @include media-down(md) {
                padding: 0;
            }
        }
    }

    &-text-image {
        img {
            max-width: 100%;
        }
    }

    &-reviewStars {
        .ob-star-rating {
            height: 3rem;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .ob-icon {
                margin: 0 .3rem;
                padding: 0 .2rem;
            }
        }
    }

    .ob-heading {
        margin-left: auto;
        margin-right: auto;
    }

    .ob-bodyText {
        margin-top: 3rem;

        .blackText & {
            color: $base-color__black;
        }
    }

    .ob-link,
    .ob-button {
        margin-top: 3rem;

        @include media-up(md) {
            margin-top: 3.8rem;
        }
    }

    &:first-child {
        .ob-background-wrapper {
            min-height: 550px;
        }
    }

    &[data-respectimageaspectratio=false] {
        .ob-background-wrapper {
            @include getContentBlockHeightByScreenHeight;
        }
    }

    &-mainAsset-container {
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .image-above &, .image-below & {
            position: static;
            width: auto;
            height: auto;
        }

        .ob-mainAsset-wrapper {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            max-width: 100%;
            width: 100%;

            &.horizontal-left {
                justify-content: flex-start;
            }

            &.horizontal-center {
                justify-content: center;
            }

            &.horizontal-right {
                justify-content: flex-end;
            }

            &.vertical-top {
                align-items: flex-start;
            }

            &.vertical-center {
                align-items: center;
            }

            &.vertical-bottom {
                align-items: flex-end;
            }

            .img-parallax {
                width: 100%;
            }

            img {
                flex: 0 0 auto;
                width: auto;
                height: 100%;
            }
        }
    }

    &-gifAnimation {
        position: absolute;
        bottom: auto;
        left: auto;
        width: calc(max(85vh, 610px) * 65 / 610);
        top: 34%;
        right: calc(max(85vh, 610px) * 54 / 610);

        @include media-up(sm) {
            width: calc(max(85vh, 610px) * 155 / 653);
            top: 45%;
            right: calc(max(85vh, 610px) * 123 / 653);
        }

        //remove display none when we figure out a fix for Edge

        @include media-ie() {
            display: none;
            width: calc(85vh * 155 / 653);
            top: 45%;
            right: calc(85vh * 123 / 653);
        }

        @supports (-ms-ime-align:auto) {
            display: none;
        }

        @supports (-ms-accelerator:true) {
            display: none;
        }

        img {
            max-width: 100% !important;
            width: 100% !important;
            height: auto !important;
        }
    }
}

