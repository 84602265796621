@import '../../static/styles/mixins';
@import '../../static/styles/variables';

.ob-awards-block {
    min-height: 100px;

    &__wrapper {
        @include container-wrapper;
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        flex-wrap: wrap;

        @include media-down(md) {
            flex-direction: column;
            text-align: center;
        }

        .ob-awards-block__award-item {
            display: block;
            min-height: 70px;
            margin: 2rem;
            position: relative;

            &-logo, &-title {
                display: inline-block;
                vertical-align: middle;

                @include media-down(md) {
                    display: block;
                }
            }

            &-logo {
                height: 65px;

                .ob-mainAsset-wrapper {
                    display: block;
                    height: 65px;
                    transition: all ease 0.35s;

                    img {
                        max-width: 100%;
                        height: 65px;
                    }
                }

                a:hover, a:focus {
                    .ob-mainAsset-wrapper {
                        transform: scale(1.1);
                    }
                }
            }

            &-title {
                @include body-2-xl;
                padding-left: 1rem;
            }

            @include media-down(md) {
                margin: 0;
                min-height: 130px;

                &-content {
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    bottom: auto;
                    left: auto;
                    right: auto;
                    transform: translateY(-50%);
                }

                &-logo {
                    margin-bottom: 1rem;
                }
                @media only screen and (min-device-width:275px) and (max-device-width: 380px) {
                    margin-bottom: 4rem;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}
.ob-awards-block__award-item-link{
    display: flex;
    align-items: center;
    @include media-down(md) {
        flex-direction: column;
    }
    .ob-awards-block__award-item-title{
        color: $base-color-link-blue;
    }
}