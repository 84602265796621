@import "./variables";

@function rem($px, $base: 10) {
    @return 1rem / $base * $px;
}

@mixin media-up($breakpointKey) {
    @media screen and (min-width: map_get($mediaQueryBreakpoints, $breakpointKey)) {
        @content


    }
}

@mixin media-down($breakpointKey) {
    @media screen and (max-width: map_get($mediaQueryBreakpoints, $breakpointKey) - 1px) {
        @content
    }
}

@mixin media-up-orientation($breakpointKey, $orientation) {
    @media screen and (min-width: map_get($mediaQueryBreakpoints, $breakpointKey)) and (orientation: $orientation) {
        @content
    }
}

@mixin media-down-orientation($breakpointKey, $orientation) {
    @media screen and (max-width: map_get($mediaQueryBreakpoints, $breakpointKey) - 1px) and (orientation: $orientation) {
        @content
    }
}

@mixin media-ie() {
    /* IE10+ */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin media-ie-up($breakpointKey) {
    /* IE10+ */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) and (min-width: map_get($mediaQueryBreakpoints, $breakpointKey)) {
        @content;
    }
}

@mixin container-wrapper() {
    width: 100%;
    max-width: $oralb-max-desktop-width;
    margin: 0 auto;
    padding: 0 2rem;
    display: block;
    @include media-up(sm) {
        padding: 0 3rem;
    }
    @include media-up(md) {
        padding: 0 4rem;
    }
    &[data-is-full-width='true'] {
        padding: 0 0!important;
        max-width: 100%;
    }
}

@mixin visuallyHidden() {
    position: absolute;
    height: 0;
    width: 0;
    left: -999px;
    top: -999px;
    opacity: 0;
    display: block;
    &.focusable {
        &:active,
        &:focus {
            position: absolute !important;
            z-index: map-get($zindex, visuallyHidden);
        }
    }
}

@mixin getContentBlockHeight() {
    min-height: 610px;
    @include media-up(lg) {
        padding-top: 0!important;
        height: 610px;
    }
    @include media-up(xlg) {
        padding-top: 0!important;
        height: 700px;
    }
    @include media-up(xxlg) {
        padding-top: 0!important;
        height: 750px;
    }
    @include media-up(xxxlg) {
        padding-top: 0!important;
        height: 800px;
    }
}

@mixin getContentBlockHeightByScreenHeight() {
    min-height: 610px;
    height: 85vh;

    @include media-up(lg) {
        padding-top: 0!important;
        min-height: 680px;
    }
    @include media-up(xlg) {
        padding-top: 0!important;
        min-height: 690px;
    }
    @include media-up(xxlg) {
        padding-top: 0!important;
        min-height: 700px;
    }
    @include media-up(xxxlg) {
        padding-top: 0!important;
        min-height: 750px;
    }
}

@mixin simple-container-wrapper() {
    width: 100%;
    max-width: calc(#{$oralb-max-desktop-width} - 71px);
    margin: 0 auto;
}

@mixin font-family($font) {
   

    @if $font =='hurme-bold' {
        font-family: $hurmebold;
        font-weight: bold;
        font-style: normal;
    }

    @if $font =='semi-bold' {
        font-family: $hurmesemibold;
        font-weight: bold;
        font-style: normal;
    }

    @if $font =='hurme-light' {
        font-family: $hurmelight;
        font-weight: 300;
    }

    @if $font =='hurme-regular' {
        font-family: $hurmeregular;
        font-weight: normal;
        font-style: normal;
    }
}

// HEADINGS mixins

@mixin display-1-xtiny() {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    font-size: rem(25);
    letter-spacing: (0.5em / 32);
    line-height: rem(30);
}

@mixin display-1-xs() {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: rem(35);
}

@mixin display-1-xl() {
    @include display-1-xs;
    @include media-down(tiny) {
        @include display-1-xtiny;
    }
    @include media-up(sm) {
        @include font-family('hurme-light');
        font-size: rem(42); // lg/1.5
        line-height: rem(48); // lg/1.5
        letter-spacing: (-1.2em / 42);
    }
    @include media-up(lg) {
        font-size: 56px;
        line-height: rem(64);
        letter-spacing: 0.5px;
    }
}


@mixin display-2-xs() {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: rem(36);
    @include media-down(tiny) {
        font-size: 24px;
    }
}

@mixin display-2-xl() {
    @include display-2-xs;
    @include media-up(sm) {
        @include font-family('hurme-light');
        font-size: 56px; // lg/1.5
        line-height: rem(42); // lg/1.5
        letter-spacing: (-1.2em / 37);
    }
    @include media-up(lg) {
        font-size: rem(56);
        line-height: rem(64);
    }
}


@mixin display-3-xs() {
    @include font-family('hurme-regular');
    font-size: rem(28);
    letter-spacing: (0.5em / 28);
    line-height: rem(36);
}

@mixin display-3-xl() {
    @include display-3-xs;
    @include media-up(sm) {
        @include font-family('hurme-light');
        font-size: rem(32); // lg/1.5
        line-height: rem(37); // lg/1.5
    }
    @include media-up(lg) {
        font-size: rem(48);
        line-height: rem(56);
    }
}


// Body text mixins

@mixin body-1-xs() {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: rem(22);
}

@mixin body-1-xl() {
    @include body-1-xs;
    @include media-up(sm) {
        font-size: rem(20);
        line-height: rem(28);
    }
    @include media-up(lg) {
        font-size: 22px;
        line-height: rem(30);
    }
}


@mixin body-2-xs() {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    font-size: rem(18);
    letter-spacing: (0.5em / 18);
    line-height: rem(26);
}

@mixin body-2-xl() {
    @include body-2-xs;
    @include media-up(sm) {
        font-size: rem(20);
        line-height: rem(28);
    }
}


@mixin body-3-xs() {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    letter-spacing: (0.5em / 12);
    font-size: rem(12);
    line-height: rem(16);
}

@mixin body-3-xl() {
    @include body-3-xs;
    @include media-up(sm) {
        font-size: rem(16);
        line-height: rem(24);
    }
}


@mixin body-4-xs() {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    font-size: rem(14);
    letter-spacing: (0.5em / 14);
    line-height: rem(22);
}

@mixin body-4-xl() {
    @include body-4-xs;
}


// Text Link

@mixin text-link-1-xs() {
    @include font-family('semi-bold');
    color: $base-color-primary-grey;
    font-size: rem(16);
    letter-spacing: (0.5em / 16);
    line-height: rem(24);
}

@mixin text-link-1-xl() {
    @include text-link-1-xs;
    @include media-up(sm) {
        font-size: rem(14);
        line-height: rem(26);
    }
}

@mixin text-link-2-xs() {
    @include font-family('hurme-bold');
    color: $base-color-dark-blue;
    font-size: rem(16);
    letter-spacing: (0.5em / 16);
    line-height: rem(24);
}

@mixin text-link-2-xl() {
    @include text-link-1-xs;
    @include media-up(sm) {
        font-size: rem(14);
        line-height: rem(20);
    }
}

// Eyebrow

@mixin eyebrow-1-xs() {
    @include font-family('semi-bold');
    color: $base-color-primary-grey;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: rem(22);
}

@mixin eyebrow-1-xl() {
    margin: 0;
    @include eyebrow-1-xs;
    @include media-up(sm) {
        font-size: 16px;
        line-height: rem(26);
    }
}

@mixin x-btn($size, $thickness, $color: $base-color-dark-blue, $hoverScaleRatio: 1.25, $hoverColor: $color) {
    $diagonalRatio: 141.42136%;
    height: $size;
    position: relative;
    width: $size;

    &:before,
    &:after {
        background-color: $color;
        content: "";
        height: $thickness;
        left: 50%;
        margin: #{$thickness / -2} 0 0 #{$diagonalRatio / -2};
        position: absolute;
        top: 50%;
        transition: all 0.2s ease-in-out;
        width: $diagonalRatio;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    &:hover,
    &:focus {
        &:before,
        &:after {
            background-color: $hoverColor;
            margin: #{$thickness / -2} 0 0 #{($diagonalRatio * $hoverScaleRatio) / -2};
            width: #{$diagonalRatio * $hoverScaleRatio};
        }
    }
}

@mixin no-scrollbar() {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0 !important;
    }

    &::-moz-scrollbar {
        display: none;
    }
}

@mixin form-focus-state() {
    outline: none;
    border: 1px solid $base-color-dark-blue;
    box-shadow: 0px 0px 5px $base-color-dark-blue;
}

@keyframes opacity {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@mixin focusOutline($outlineColor: $base-color-dark-blue) {
    outline: 1px solid $outlineColor;
}

@mixin textOutline($outlineColor: $base-color__contest-input-dark-blue) {
    text-shadow: 0 0 3px $outlineColor, 0 0 5px $outlineColor, 0 0 3px $outlineColor, 0 0 5px $outlineColor;
}

