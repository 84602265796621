@import "../../static/styles/mixins";
.ob-spotlight-waitlist {
    overflow-x: hidden;

    .ob-background-wrapper {
        padding-top: 0 !important;

        @include media-up(sm) {
            body:lang(de) & {
                min-height: 900px;
            }
        }

        @include media-up(md) {
            min-height: 814px;
            background-position: center center !important;

            .ob-spotlight-reduce-footer-waitlist & {
                padding: 2rem 2rem 0;
            }

            body:lang(de) & {
                background-position: 34% center !important;
                min-height: 945px;
            }
        }

        @include media-up(lg) {
            body:lang(es) & {
                min-height: 900px;
            }

            body:lang(de) & {
                min-height: 965px;
            }
        }

    }
    &__wrapper {
        padding: 0 2rem 2rem;
        @include media-up(sm) {
            height: 100%;
            top: 0;
            right: 0;
            position: absolute;
            background: transparentize($base-color__white, 0.1);
            padding: 3rem 4rem 0;
            .ob-spotlight-reduce-footer-waitlist & {
                padding: 2rem 2rem 0;
            }

            body:lang(de) & {
                min-height: 900px;
            }
        }

        @include media-up(md) {
            width: 47.57%;
            min-height: 814px;
            .ob-spotlight-reduce-footer-waitlist & {
                width: 55%;
            }

            body:lang(de) & {
                min-height: 945px;
            }
        }

        @include media-up(lg) {
            .ob-spotlight-reduce-footer-waitlist & {
                width: 52%;
                padding: 3rem 4rem 0;
            }

            body:lang(es) & {
                min-height: 900px;
            }

            body:lang(de) & {
                width: 52.5%;
                min-height: 965px;
                padding: 3rem 2rem 0;
            }
        }

        @include media-up(xlg) {
            .ob-spotlight-reduce-footer-waitlist & {
                width: 47.57%;
            }
        }

        @include media-up(xxlg) {
            padding: 3rem 10rem 0;
        }
    }

    &__title {
        @include display-1-xs;
        @include media-up(sm) {
            @include display-2-xl;
            .ob-spotlight-reduce-footer-waitlist & {
                font-size: 3.6rem;
                line-height: 4.4rem;
            }
        }
        @include media-up(xlg) {
            .ob-spotlight-reduce-footer-waitlist & {
                font-size: 5.6rem;
                line-height: 6rem;
            }
        }
    }

    &__body {
        @include body-2-xl;
        margin-top: 1rem;

        @include media-down(lg) {
            .ob-spotlight-reduce-footer-waitlist & {
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }
    }

    &__form-list {
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .ob-spotlight-reduce-footer-waitlist & {
            margin-top: 1rem;
        }

        @include media-up(sm) {
            .ob-spotlight-reduce-footer-waitlist & {
                margin-top: 0rem;
            }
        }
    }

    &__form-list-item {
        margin-top: 2rem;
        width: 100%;

        @include media-up(sm) {
            width: 48.5%;
            margin-top: 2.1rem;
            .ob-spotlight-reduce-footer-waitlist & {
                margin-top: 1rem;
            }
            &:last-child {
                width: 100%;
            }
        }

        @include media-up(lg) {
            .ob-spotlight-reduce-footer-waitlist & {
                margin-top: 1.5rem;
            }
        }
    }

    &__form-label {
        display: block;
        font-size: 2.2rem;
        margin-bottom: 1rem;
        @include media-up(sm) {
            @include body-3-xl;
            margin-bottom: 0;
        }
    }

    &__form-select {
        &-container {
            position: relative;

            select::-ms-expand {
                display: none;
            }

            .ob-icon {
                pointer-events: none;
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);

                &--chevronDown {
                    transform: translateY(-50%) rotate(90deg);
                }
            }
        }
    }

    &__form-input {
        -webkit-appearance: none;
        background-color: $base-color__white;
        border-radius: 0;
        display: block;
        padding: 1.2rem;
        border: 1px solid $base-color-isolate-grey;
        width: 100%;
        @include eyebrow-1-xl;
        @include media-down(sm) {
            font-size: 1.6rem;
        }

        .ob-spotlight-uk & {
            line-height: 2rem;
        }

        &.--select {
            padding-right: 4rem;
        }

        &.--error {
            border: 1px solid $base-color__error;
            color: $base-color__error;

            &:-internal-autofill-selected {
                -webkit-text-fill-color: $base-color__error;
            }

            & + .ob-icon {
                fill: $base-color__error;
            }
        }
    }

    &__form-error,
    &__form-mention {
        font-size: 1.6rem;
        margin: 1.5rem 0 0;
        body:lang(de) & {
            font-size: 1.4rem;
            margin: .5rem 0 0;
        }
    }

    &__form-error {
        color: $base-color__error;
    }

    .ob-button {
        background-color: $base-color-dark-blue;
        border: 1px solid $base-color-dark-blue;
        color: $base-color__white;
        display: block;
        margin-left: auto;
        font-size: 1.6rem;
        width: 100%;
        position: relative;

        @include media-up(sm) {
            width: 25.4rem;
        }

        &:hover,
        &:focus {
            background-color: $base-color__white;
            color: $base-color-dark-blue;
        }

        &[disabled] {
            background: $base-color-proxima-grey;
            color: $base-color-dark-blue;
            cursor: not-allowed;
        }

        &__spinner {
            position: absolute;
            top: 1.7rem;
            right: auto;
            bottom: auto;
            left: 3rem;
            animation: rotate360 1s linear infinite;
            transform: translateZ(0);

            border: 2px solid $base-color-dark-blue;
            border-left: 2px solid transparent;
            background: transparent;
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        @keyframes rotate360 {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        &.not-us {
            margin-right: auto;
            margin-top: 1rem;
        }
    }

    &__legal-text {
        @include eyebrow-1-xl;
        @include font-family('hurme-regular');
        font-size: 1.6rem;
        line-height: 2.1rem;
        margin-top: 2.4rem;


        @include media-up(sm) {
            font-size: 1.3rem;
            line-height: 1.5;
            body:lang(de) & {
                font-size: 1.2rem;
            }
        }

        @include media-up(lg) {
            body:lang(de) & {
                font-size: 1.3rem;
            }
        }

        @include media-up(xxlg) {
            font-size: 1.4rem;
            line-height: 2.1rem;
        }

        .ob-spotlight-reduce-footer-waitlist & {
            margin-top: 1.4rem;
            padding-left: 2rem;
        }
    }

    &__mobile-img {
        margin: 5.2rem 0 3.2rem;
        max-width: 37.6rem;

        img {
            height: auto;
            max-width: 100%;
        }
    }

    &__modal-container,
    &__modal-container-legal {
        width: 90%;
        position: absolute;
        background: $base-color__white;
        top: $ob-sticky-bar-height-mobile;
        left: 50%;
        margin: 15px 0;
        transform: translate(-50%, 0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        max-width: 37.5rem;

        @include media-up(sm) {
            display: flex;
            top: 50%;
            width: 79%;
            margin: 0;
            max-width: 113.7rem;
            max-height: 56rem;
            transform: translate(-50%, -50%);
        }
        img {
            max-width: 100%;
            display: block;
        }
    }

    &__modal-container-legal {
        width: 82%;
        height: 250px;
        padding: 2rem;
        display: flex;
        align-items: center;
        padding-right: 4rem;
        @include media-up(sm) {
            width: 52%;
            height: 200px;
            padding-right: 2rem;
        }
        p {
            font-size: 1.6rem;
            line-height: 2.2rem;
            @include font-family('hurme-regular');
        }
    }

    &__modal-content {
        @include media-up(sm) {
            width: 50%;
        }
    }

    &__modal-text-container {
        padding: 1rem 2rem;
        .ob-heading {
            @include display-2-xl;
        }
        @include media-down(sm) {
            .ob-heading {
                padding-right: 3rem;
            }
        }

        @include media-up(sm) {
            padding: 5rem 2rem 1rem 2rem;
        }
    }

    &__modal-text-content {
        @include font-family('hurme-light');
        font-size: 1.8rem;
        line-height: 2.6rem;

        @include media-up(md) {
            font-size: 2.2rem;
            line-height: 3rem;
        }
    }



    &__modal-cta {
        align-items: flex-start;
        color: inherit;
        display: flex;
        transition: all 0.4s ease;

        &:link,
        &:visited {
            color: inherit;
        }

        &:hover,
        &:focus {
            color: $base-color-dark-blue;
            text-decoration: none;
        }

        &-content {
            @include font-family('hurme-regular');
            font-size: 1.6rem;
            letter-spacing: 0.05rem;
            line-height: 2.4rem;
            margin-top: 3.6rem;
        }

        .ob-icon {
            flex-shrink: 0;
            margin: 0.3rem 0.8rem 0 0;
        }
    }

    &__close-modal {
        position: absolute;
        top: 2rem;
        right: 2rem;
        transition: all ease 0.35s;

        &:hover, &:focus {
            transform: scale(1.1);
        }
    }
}

.legal-checkbox {
    cursor: pointer;
    position: absolute;
    opacity: 0;
}

.legal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.legal-input-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: -1.9rem;
}

.legal-checkbox-fake {
    margin-right: .5rem;
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    background: white;
    border: 1px solid $base-color-isolate-grey;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    flex: 0 0 auto;
    top: 2px;
    @include media-up(sm) {
        top: 1px;
    }
}

.legal-checkbox:checked + .legal-checkbox-fake:after {
    content: '';
    position: absolute;
    display: inline-block;
    transform: rotate(45deg) translateY(-50%);
    width: .2rem;
    height: .7rem;
    left: 0;
    right: auto;
    top: 50%;
    bottom: auto;
    margin-top: -2px;
    border-bottom: 2px solid $base-color-dark-blue;
    border-right: 2px solid $base-color-dark-blue;
}

.legal-checkbox:focus + .legal-checkbox-fake {
    border: 1px solid $base-color-dark-blue;
}

body:lang(de) {
    .thank-you-de {
        .ob-SpotlightStickyBar {
            position: static;
            display: none;
            @include media-up(sm) {
                display: block;
            }
        }

        .ob-SpotlightStickyBar__button-zone {
            display: none;
        }

        .ob-spotlightContentBlock {
            bottom: auto;
            left: auto;
            opacity: 1;
            overflow-x: auto;
            position: static;
            right: auto;
            top: auto;
            background: $base-color__white;

            @include media-up(sm) {
                background: none;
            }
        }

        .ob-spotlight-waitlist__wrapper {
            background: none;
            transform: translateX(0%);
            min-height: auto;

            @include media-down(xs) {
                padding: 0 3rem 3rem;
            }

            @include media-up(sm) {
                padding-top: 21rem;
                position: absolute;
            }
        }

        div.ob-background-wrapper {
            min-height: 687px;
            height: 100%;
            background-position-y: 0 !important;


            @media screen and (min-width: 450px) {
                background-position-y: -104px !important;
            }

            @media screen and (min-width: 615px) {
                background-position-y: -279px !important;
            }

            @include media-up(sm) {
                background-position-y: center !important;
            }

            @include media-up(lg) {
                min-height: 810px;
                max-height: 810px;
                overflow: hidden;
            }
        }

        .zone.footer-spotlight {
            display: block;
        }

        .ob-heading,
        .ob-spotlight-waitlist__body {

            color: $base-color__white;
            max-width: 400px;

            @include media-up(lg) {
                max-width: 475px;
            }
        }

        .ob-heading {
            padding-top: 3.2rem;

            @include media-up(lg) {
                padding-top: 0rem;
            }
        }

        .ob-spotlight-waitlist__body {
            font-size: 1.8rem;
            padding-top: 3.6rem;
            margin-top: 0;

            @include media-up(sm) {
                font-size: 2rem;
            }
        }

        .ob-spotlight-waitlist__link-de {
            @include font-family('semi-bold');
            font-size: 1.6rem;
            margin-top: 3.2rem;
        }

        .ob-icon-rounded-container {
            top: 0;
        }

        #Hierarchy-Footer-Utility {
            justify-content: flex-start;
            @include media-up(sm) {
                li {
                    margin: 0 2.8rem 0;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .ob-thank-you-modal {
        .ob-spotlight-waitlist__modal-container {
            @include media-down(md) {
                width: 90%;
            }
        }
    }
}

.ob-spotlight-danke .zone.footer-spotlight,
.thank-you-de .zone.footer-spotlight {
    display: block;
}

.ob-spotlight-layout{
    .ob-background-wrapper{
        .btn-preorder {
            height: 6.4rem;
            text-align: center;
            @include media-down(md){
                height: 6.4rem;
                text-align: center;
            }
            .span-preorder{
                top: 1rem;
            }
        }   
    }
    .btn-preorder {
        @include media-down(md){
            height: 6.4rem;
            text-align: center;
        }
    }    
}

.ob-campaignoverlay-modal {
    background-color: #277ec8;
    .ob-spotlight-waitlist__close-modal {
        z-index: 1;
    }    
    
    .legal-input-container{
        margin-left: 0;
    }

    .ob-spotlight-waitlist__mobile-img{
        @include media-down(md){
            display: none;
        }

    }
    .ob-spotlight-waitlist__wrapper{
        padding: 3rem 2.5rem 5rem;
        margin-top: 10rem;
        text-align: center;
        overflow-y: auto;
        padding-top: 5rem;
        margin-top: 0;
        min-height: auto !important;
        width: 50%;
        right: 0;
        position: absolute;
        transform: translateX(0%);
        min-height: 580px;
        background: transparentize($base-color__white, 1) !important;
        background-color:  #0a2c5f;
        @include media-down(md){
            padding: 0 1rem 2rem;
            background: none;
            background-image: linear-gradient(to top left, #277ec8, #0a2c5f) !important;
            width: 100%;
            transition: none;
            transform: translateX(0%);
            height: 100vh;
            overflow-y: auto;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            position: inherit;
        }
        a:focus, button:focus{
            border: 2px solid $base-color__white;
            text-decoration: none;
        }

        .ob-spotlight-waitlist__body{
            margin-top: 0;
            font-size: 1.8rem;
            line-height: normal;
            color: #ffff;
            width: 90%;
            margin: 0 auto;
            margin-top: 2rem;
            @include media-down(md) {
                width: 100%;
                margin: 0 auto;
                margin-top: 2rem;
            }
        }

        .ob-background-wrapper {
            min-height: 635px !important;
        }

        .ob-spotlight-waitlist__title {
            @include font-family('hurme-regular');
            font-size: 2.8rem;
            line-height: normal;
            color: #fff;
            font-weight: bold;
            width: 80%;
            margin: 0 auto;
            @include media-down(md) {
                width: 80%;
                margin: 0 auto;
                margin-top: 3rem;
            }
        }
        .ob-spotlight-waitlist__form-list-item {
            margin-top: 1.1rem;
            @include media-up(sm) {
                width: 100%;
            }
            .ob-spotlight-waitlist__form-list-item-label{
                span{
                    display: none;
                }
            }
            &.cta-section{
                justify-content: space-between;
                display: flex;

            }
            &.margin-cta-section{
                @include media-up(sm) {
                    margin-top: 1.5rem;
                }
                &:focus{
                    border: 2px solid $base-color-link-blue;
                }
            }
            .ob-spotlight-waitlist__form-input{
                padding: 1rem;
                font-size: 1.5rem;
                line-height: normal;
            }

            .cta-button {display: flex;}
            .ob-button{ width: 48%; padding: 0 1rem; height: 5rem; font-size: 1.5rem; justify-content: center;}
            .ob-button:link {
                border: 1px solid #fff;
            }
        }
        .ob-spotlight-waitlist {
            &__form-label {
                color: #ffff;
                text-align: left;
            }
            &__form-error {
                font-weight: 600;
            }
            &__legal-text {
                color: #ffff;
                font-size: 1.2rem !important;
                margin-top: 2rem;
                a:link, a:visited, .link-button {
                    color:#ffff;
                    text-decoration: underline;
                }
                @include media-down(md) {
                    font-size: 1.2rem !important;
                }
            }
            &__form-legal-text-wrapper{
                color: #ffff !important;
                font-size: 1.8rem !important;
                margin-top: 2rem;
                margin-left: 4%;
                width: 90%;
                text-align: left;
                font-family: Hurme Light,sans-serif;
                font-weight: 300;
            .ob-checkbox__wrapper{
                    color: white !important;
                    .ob-checkbox__label{
                        p{
                        a:link, a:visited, .link-button{
                            color: white !important;
                            text-decoration: underline;
                         }
                    }
                }
            }
        }
        }

        .ob-button {
            &.cta-button {
                margin-right: auto;
                font-size: 1.8rem;
                height: 6rem;
                padding: 1rem;
                @include media-down(md) {
                    font-size: 1.7rem;
                }
            }
            &.cta-button-margin {
                margin-top: 1rem;
                &:focus{
                    border: 2px solid $base-color-link-blue;
                }
            }
        }
        
    }

    .ob-spotlight-survey {
        &__wrapper {
            background-color:  #277ec8;
            background-image: linear-gradient(to top left, #277ec8, #0a2c5f);
            text-align: center;
            overflow-y: auto;
            margin-top: 0;
            padding-top: 4rem;
            min-height: 535px;
            width: 100%;
            color: #fff;
            @include media-down(md) {
                padding-top: 3.5rem;
            }

        }
        &__body {
            font-size: 2rem;
            line-height: normal;
            color: #ffff;
            width: 45%;
            margin: 0 auto;
            margin-top: 1.5rem;
            @include media-down(md) {
                width: 65%;
            }
        }

        &__question-body {
            font-size: 2rem;
            line-height: normal;
            color: #ffff;
            width: 45%;
            margin: 0 auto;
            margin-top: 1.5rem;
            @include media-down(md) {
                margin-top: 2rem;
                width: 100%;
            }
        }

        &__title {
            @include font-family('hurme-regular');
            font-size: 2.8rem;
            line-height: normal;
            color: #fff;
            @include media-down(md) {
                width: 50%;
                margin: 0 auto;
            }
        }
        &__form-label {
            color: #ffff;
            text-align: left;

        }
        &__form-error {
            font-weight: 600;
        }
        &__legal-text {
            color: #ffff;
            margin-bottom: 2rem;
            font-size: 1.5rem;
            line-height: normal;
            font-weight: bold;
            @include media-down(md) {
                font-weight: normal;
                font-size: 1.4rem;
            }
        }
        &__form-list {
            justify-content: space-around;
            display: flex;
            margin-top: 3rem;
            padding-left: 5rem;
            padding-right: 5rem;
            @include media-down(md) {
                flex-direction: column;
                margin-top: 1.5rem;
                padding-left: 7rem;
                padding-right: 7rem;
                margin-bottom: 2rem;
            }
        }
        &__form-list-item {
            display: flex;
            flex-direction: column;
            margin: 1rem;
            @include media-down(md) {
                flex-direction: row;              
            }
            .ob-checkbox__label {
                color: #fff !important;
            }
        }
        &__form-list-item label {
            font-size: 14px;
            min-height: 40px;
        }
        &__form-list-item img {
            min-width: 100px;
            max-width: 100px;
            width: 100px;
            margin-bottom: 2rem;
        }
        .ob-button {
            &.cta-button {
                margin-right: auto;
                font-size: 1.8rem;
                height: 6rem;
                padding: 1rem;
                @include media-down(md) {
                    font-size: 1.5rem;
                }
            }
            &.cta-button-margin {
                margin-top: 1rem;
                &:focus{
                    border: 2px solid $base-color-link-blue;
                }
            }
        }
        &__cta-section {
            margin-bottom: 2rem; 
            margin-top: 2rem;
            @include media-down(md) {
                margin-top: 1rem;
            }
        }
    }
}
.ob-spotlighti010-layout{
    .home-body-zone{
        margin-top: 0px !important;
    }
}

.ob-campaignoverlay-modal {
    .loaderImg {
        max-width: 7%;
        position: absolute;
        float: left;
        margin-left: -56%;
        margin-top: 37px;
    }
}

.ob-spotlight-survey__wrapper {
    .loaderImg {
        max-width: 5% !important;
        position: absolute;
        float: left;
        margin-left: -5% !important;
        margin-top: 10px !important;
    }
}

.ob-footer{
    .io10-spotlight-waitlistBlock{
        .ob-focus-error {
            line-height:44px;
            color:#f44f4f !important;
        }
        .ob-spotlight-waitlist__optin-text {
            .ob-checkbox__label{
                color: #3D3D41 !important;
            }
        }
    }
}
#Interstitial-popup{
    .ob-spotlight-waitlist__form{
        .ob-background-wrapper{
            height: 86vh !important;
                @media screen and (min-width: 1200px) and (max-width: 1500px) {
                    height: auto !important;
                    min-height: 580px !important;
                }
                @media screen and (min-width: 1600px) and (max-width: 2001px) {
                    height: auto !important;
                    min-height: 780px !important;
                }
                @media screen and (min-width: 2001px){
                    height: auto !important;
                    min-height: 1000px !important;
                }
                @media screen and (min-width: 3001px){
                    height: auto !important;
                    min-height: 1440px !important;
                }
            .ob-spotlight-waitlist__wrapper{
                    .ob-focus-error.ob-brush-select__system-error{
                        color:#f44f4f !important;
                    }  
               }
           }
       }
       .ob-spotlight-survey__form{
        .ob-checkbox__label{
            @include media-up(sm) {
                justify-content: center;
            }
        }
       }
   }


    