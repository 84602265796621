@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-article-hero-content {
    box-sizing: border-box;
    padding: 38px 24px 24px;
    text-align: center;

    @include media-up(xs) {
        text-align: left;
    }

    @include media-up(sm) {
        padding: 130px 24px 24px;
    }
}

.ob-article-hero-inner {
    max-width: 1136px;
    height: 100%;
    margin: 0 auto;
}

.ob-article-hero-eyebrow {
    margin-bottom: rem(20);
    font-size: rem(16);
    text-transform: uppercase;
}

.ob-article-hero-heading {
    @include display-1-xl;
    @include display-3-xs;
    max-width: rem(552);
    margin-bottom: rem(20);
    text-transform: uppercase;
}

.ob-article-hero-text {
    @include body-1-xl;
    max-width: rem(500);
}

.ob-article-hero-time {
    @include body-1-xl;
    margin-top: rem(30);
}
