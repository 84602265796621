@import "../../static/styles/mixins";
@import "../../static/styles/variables";

@mixin highlight-borders {
    border: 1px solid $base-color-isolate-grey;
    border-left: none;
    border-right: none;
}

@keyframes fadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes leftIn {
    0%   { left: -50px; }
    100% { left: 0; }
}

@keyframes topIn {
    0%   { top: 40px; }
    100% { top: 0; }
}

.ob-product-highlights {
    min-height: 70vh;
    &__ctn {
        max-width: $oralb-max-desktop-width;
        margin: 0 auto;
        text-align: center;
        padding: 1rem 1.5rem;
        @include media-up(md) {
            padding: 2.8rem 0 3rem;
            text-align: left;
            display: flex;
            justify-content: center;
        }
    }

    [data-bv-show=rating_summary] {
        display: flex;
        justify-content: center;
        @include media-up(md) {
            margin-top: .5rem;
            margin-bottom: 1.5rem;
            justify-content: flex-start;
        }
    }

    .ob-mobile-product-highlights-header{
        @include media-down(md) {
            display: block;
        }
        @include media-up(md) {
            display: none;
        }
    }

    .ob-background-wrapper {
        padding-top: 0 !important;
        min-height: 690px;
    }

    &__img-ctn {
        padding: 0;
        @include media-up(md) {
            flex: 1 0 auto;
        }
        img {
            position: relative;
            max-height: 40rem;
            margin: 0 auto;
            display: block;
            @include media-up(md) {
                max-height: 61.1rem;
            }
        }
    }

    &__img {
        opacity: 0;
        height: 0;
        &.is-active {
            position: static;
            display: block;
            height: 100%;
            opacity: 1;
        }
        .ob-mainAsset-wrapper {
            height: 100%;
        }
    }

    &__360-ctn {
        height: 100%;
        > div {
            height: 100%;
        }
    }

    &__360-icon {
        display: block;
        margin: 2rem 0;
        text-align: center;
    }

    .ob-icon--icon360 {
        width: 5.3rem;
        height: 3.9rem;
    }

    &__header {
        min-height: 10.2rem;
        margin: 2.4rem 0 3.7rem;
        @include media-up(md) {
            margin: 0;
        }
    }

    &__surtitle {
        font-size: 1.4rem;
        line-height: 2rem;
        color: $base-color-primary-grey;
        text-transform: uppercase;
        @include media-up(md) {
            font-size: 1rem;
            line-height: 1.7rem;
        }
    }

    &__title {
        @include font-family('hurme-regular');
        font-size: 2.8rem;
        margin-bottom: 0.6rem;
        color: $base-color-primary-grey;
        h2{
        font-size: 48px;
        font-weight: 400;
        }
        .ob-product-highlight__small-title & {
            font-size: 2.3rem;
        }
        @include media-up(md) {
            @include display-3-xl;
        }
    }

    &__description {
        @include body-2-xl;
        @include media-down(md) {
            margin-top: 5rem;
            padding: 0 2rem;
        }

        + .ob-button {
            margin-top: 2rem;
        }
    }

    &__text-content {
        position: relative;
        width: 90vw;
        margin: 2.4rem auto;
        text-align: center;
        @include media-up(md) {
            width: 44.3rem;
            text-align: left;
            margin: 0 auto;
            margin-right: 110px;
            .ob-button {
                font-size: 1.8rem;
            }
        }
    }

    &__disclaimer{
        &--mobile {
            width: 90vw;
            margin: 2.4rem auto;
            text-align: center;
        }

        &--desktop {
            margin-left: 1rem;
        }
    }

    &__label {
        margin: 0 0 1rem;
        font-size: 1.6rem;
        @include media-up(md) {
            @include body-3-xl;
            @include font-family('semi-bold');
        }
    }

    &__highlights-ctn {
        margin: 2rem 0;
        @include media-up(md) {
            padding: 2rem 0;
            @include highlight-borders;
        }
    }

    &__quote {
        @include font-family('hurme-regular');
        color: $base-color-primary-grey;
        font-size: 2rem;
        font-style: italic;
        @include media-down(md) {
            font-size: 2.2rem;
            padding: 2rem 0;
            @include highlight-borders;
        }

        img {
            max-width: 70px;
        }
    }

    &__quote-text {
        display: block;
        margin: 1rem 0;
    }

    &__highlights-content {
        font-size: 1.4rem;
    }

    &__highlights-list {
        @include font-family('hurme-regular');
        font-size: 1.4rem;
        line-height: 2rem;
        color: #3D3C42;
        list-style-position: outside;
        padding-left: 2.5rem;
        text-align: left;
    }

    &__highlights-list-item {
        padding-left: 2.4rem;
        margin-bottom: 2rem;
        &:last-of-type {
            margin-bottom: 0;
        }
        &.hide {
            display: none;
        }

        p {
            margin-bottom: 0;

            &:first-child {
                margin-top: 0;
            }
        }

        b, strong {
            color: $base-color-dark-blue;
        }
    }

    &__see-more.ob-link.primaryGrey {
        font-size: 1.4rem;
        line-height: 2.2rem;
        &:hover{
            color: $base-color-bright-blue;
        }
    }

    &__colors {
        display: flex;
        list-style-type: none;
        padding: 0;
        flex-wrap: wrap;
        justify-content: center;
        @include media-up(md) {
            justify-content: flex-start;
            margin-bottom: 3.7rem;
        }
    }

    &__selected-colors-item {
        &:first-child {
            .ob-product-highlights__color {
                margin-left: 0;
            }
        }
        &:last-child {
            .ob-product-highlights__color {
                margin-right: 0;
            }
        }
    }

    &__color {
        margin: 0 1rem;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        outline: none;
        border: 1px solid $base-color-saturn-grey;
        transition: box-shadow 0.2s ease-in-out;

        &.is-active {
            border: 2px solid $base-color-dark-blue;
        }

        &:hover,
        &:focus {
            box-shadow: 0 0 5px rgba($base-color-dark-blue, 0.5);
        }
    }

    &__dropdown-label {
        display: block;
    }

    &__dropdown-list {
        padding: 0;
        list-style-type: none;
        margin: 0;
    }

    &__dropdown-list-item-ctn {
        margin: 0 0 2rem;
        display: inline-block;
        position: relative;
    }

    &__dropdown-select {
        min-width: 200px;
        height: 40px;
        font-size: 2rem;
        background: white;
        border: 1px solid $base-color-saturn-grey;
        border-radius: 0;
        appearance: none;
        &::-ms-expand {
            display: none;
        }
        padding: 0 4rem 0 1rem;
    }

    &__dropdown-icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 1rem;
        bottom: auto;
        left: auto;
    }
}


.ob-product-highlights__carousel {
    @include media-up(md) {
        width: 50%;
    }

    .ob-product-highlights__carousel-main-list,
    .ob-product-highlights__carousel-thumbnail-list {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        margin: 0 auto;
        padding-inline-start: 0px;
        list-style-type: none;
        align-items: center;
    }

    .ob-product-highlights__carousel-main-list {
        min-height: 41rem;
        position: relative;
    }

    .ob-product-highlights__carousel-main-list-item {
        width: 100%;
        scroll-snap-align: end;
        flex: 1 0 100%;
        opacity: 0;
        transition: opacity ease 0.35s;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: auto;
        bottom: auto;
        padding: 0 1.5rem;

        @include media-down(md) {
            display: flex;
            justify-content: center;
        }

        &.--is-active {
            opacity: 1;
            position: static;
        }

        .ob-mainAsset-wrapper {
            display: flex;
            justify-content: center;

            @include media-down(md) {
                width: 100%;
                max-width: 500px;
            }

            img {
                max-width: 100%;
                max-height: 410px;

                @media screen and (min-height: 1024px) {
                    max-height: 550px;
                }
            }
        }
    }

    .ob-product-highlights__carousel-thumbnail-ctn {
        position: relative;
    }

    .ob-product-highlights__carousel-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all ease 0.35s;
        bottom: auto;
        &:hover, &:focus {
            transform: translateY(-50%) scale(1.1);
        }

        &.--is-disabled {
            opacity: 0.25;
            pointer-events: none;
            &:hover, &:focus {
                transform: translateY(-50%) scale(1);
            }
        }

        &--prev {
            left: 2rem;
            right: auto;
        }

        &--next {
            left: auto;
            right: 2rem;
        }
    }
    .ob-icon {
        fill: $base-color-dark-blue;
    }
    .ob-product-highlights__carousel-thumbnail-list {
        max-width: 67%;
        margin-top: 5rem;
    }

    .ob-product-highlights__carousel-thumbnail-list-item {
        margin: 0 1rem;
        cursor: pointer;
        flex: 1 0 auto;
        width: 62px;
        height: 62px;
        @include media-up(md) {
            width: 100px;
            height: 100px;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

        &--not-full {
            flex: 0 1 auto;
        }

        &--not-square {
            width: auto;
            height: auto;

            img {
                max-height: 100px;
            }
        }
    }

    .ob-product-highlights__carousel-thumbnail-button {
        max-width: 100%;
        &:hover, &:focus {
            outline: none;
            img {
                border-color: $base-color-secondary-blue;
            }
        }
        &.--is-active img {
            border-color: $base-color-dark-blue;
        }

        img {
            max-width: 100%;
            transition: all ease 0.35s;
            border: 2px solid transparent;
        }
    }
}

.zone.etb-full-body-zone{
    .ob-product-highlights.io_magnetic_9{
        .ob-product-highlights__surtitle,
        .ob-heading.ob-product-highlights__title,
        .ob-product-highlights__description,
        .ob-product-highlights__label,
        .ob-accessibility-highlighted,.ob-accessibility,
        .ob-product-highlights__highlights-list-item,
        .ob-disclaimer{
            color: $base-color__white;
        }
        .ob-product-highlights__highlights-list-item{
            b{
                color:white;
                &:hover{
                    color:$base-color-dark-blue-hover;
                }
            }
        }
        .ob-product-highlights__see-more.ob-link.primaryGrey{
            color: $base-color__white;
            @include media-down(sm){
                color:$base-color-primary-grey;
            }
            &:hover{
                color: $base-color-dark-blue-hover;
            }
        }
        .ob-button{
            background-color: $base-color__white;
            color: $base-color-dark-blue;
            @include media-down(sm){
                background-color: $base-color-dark-blue;
                color: $base-color__white;
            }
            &:hover{
                background-color: $base-color-dark-blue-hover;
                span{
                    color:$base-color__white;
                }
            }
        }
        .ob-product-highlights__header {
            .bv_main_container {
                .bv_text {
                    color: $base-color__white !important;
                }
            }
        }
        .ob-accessibility-highlighted,
        .ob-accessibility,.ob-product-highlights__label,
        .ob-product-highlights__highlights-list-item,
        .ob-disclaimer{
            @include media-down(sm){
                color:$base-color-primary-grey;
                b{
                    color:$base-color-dark-blue;
                }
            }
        }
    }
}
.ob-product-highlights__highlights-list{
    .ob-product-highlights__highlights-list-item{
        a {
            &:focus{
                border:2px solid $base-color__white;
            }
        }
    }
}