@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.ob-footer {
  background: $base-color-secondary-off-white;
  padding: 0 0 1rem;
  position: relative;
  z-index: map-get($zindex, footer);
  ul {
    padding: 0;
    list-style-type: none;
    max-width: $oralb-max-desktop-width;
    overflow: hidden;
  }

  &__list {
    padding: 1.8rem 2.4rem;
    border-bottom: 1px solid transparentize($base-color-primary-grey, 0.8);
    flex: 0 0 15%;
    &:first-child {
      border-top: 1px solid transparentize($base-color-primary-grey, 0.8);
      @include media-up(md) {
        border-top: none;
      }
    }
    &:last-child {
      width: 100%;
      border-bottom: none;  
      flex-basis: 100%;  
    }
    &:nth-of-type(5) {
      width: 100%;
      border-bottom: none;
      flex-basis: 40%;
    }
    @include media-up(md) {
      padding: 0;
      border-bottom: none;
    }

    li {
      font-size: 1.6rem;
      line-height: 2.3;
      a {
        display: flex;
      }

      &.ob-footer__social-link {
        img {
          margin-right: 5px;
          max-width: 20px;
          vertical-align: middle;
        }
      }

      &.ob-footer__youtube {
        img {
          width: 20px;
        }
      }

      .ob-footer__adChoice {
        img {
          margin-top: 7px;
          margin-right: 5px;
        }
      }

      &.ob-footer__bbb-seal {
        a {
          &:focus {
            img {
              border: 1px solid $base-color-dark-blue;
            }
          }
        }
      }
    }
  }

  li a,
  &__item a {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;

    &:hover,
    &:focus {
      color: $base-color-dark-blue;
    }
  }

  .ob-footer-image-link {
    display: block;
    transition: all ease 0.35s;

    .ob-mainAsset-wrapper {
      img {
        max-width: 100%;
        display: block;
      }
    }

    @include media-down(md) {
      transform-origin: left;
    }

    &:hover, &:focus {
      transform: scale(1.1);
    }
  }

  &__title-ctn {
    @include media-down(md) {
      width: 100%;
    }
    @include media-up(md) {
      cursor: default;
      pointer-events: none;

      &:hover, &:focus {
        outline: none;
        text-decoration: none;
      }
    }

    + ul {
      margin: 1rem 0 0;
    }
  }

  &__title {
    @include font-family('semi-bold');

    @include media-down(md) {
      color: $base-color-primary-grey;
      display: flex;
      justify-content: space-between;
      font-size: 1.6rem;
      letter-spacing: (0.5em / 14);
      line-height: rem(26);
      width: 100%;
    }

    @include media-down(sm) {
      line-height: rem(22);
    }
  }

  &__title-icon {
    @include media-up(md) {
      display: none;
    }
    transition: all ease-in-out 0.35s;

    &--reversed {
      transform: rotate(-180deg);
    }

  }

  &__item {
    font-size: 1.4rem;
    margin: 2rem;
    line-height: 1.6;

    @include media-up(md) {
      margin: 3rem 7.3rem 0 0;
    }
  }

  #Hierarchy-Footer {
    @include body-3-xl();
    margin: 0 auto 0;
    @include media-up(md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 4.8rem 2rem 1rem;
    }
  }

  #Hierarchy-Footer-Utility {
    @include media-up(md) {
      display: flex;
      justify-content: space-between;
      margin: 3rem 0 0;

      li {
        margin: 0 1rem 0;
      }
    }
  }
}

.ob-spotlight-layout {
  .zone.footer-404,
  .zone.footer-spotlight {
    position: relative;
  }

  .zone.footer-spotlight {
    display: none;
  }

  .ob-footer {
    width: 100%;

    &__onlyShowOn {
      &--mobile {
        @include media-up(md) {
          display: none;
        }
      }

      &--desktop {
        @include media-down(md) {
          display: none;
        }
      }
    }
  }

  .ob-footer__list {
    #Hierarchy-Footer-Top .spotlight-footer-logo {
      @include media-down(md) {
        margin: 0 auto;
      }
      img {
        width: auto;
        max-width: 90px;
        margin-right: 0;
        @include media-up(md) {
          max-width: 110px;
        }
      }
    }
  }

  #Hierarchy-Footer {
    @include media-up(md) {
      padding: 1rem;
    }

    .ob-footer__item.ob-spotlight__footer-contact-us {
      border-bottom: 1px solid transparentize($base-color-primary-grey, 0.8);
      margin: 0;
      padding: 1.8rem 2.4rem;
      a {
        @include eyebrow-1-xl;
      }
    }
  }


  #Hierarchy-Footer-Top {
    @include media-up(md) {
      display: flex;
      margin: 2rem 0;
      align-items: center;

      li {
        margin: 0 7.3rem 0 0;
        &:last-child {
          margin: 0;
        }
      }

    }


    .ob-spotlight__footer-contact-us a {
      @include eyebrow-1-xl;
    }

    .ob-spotlight__footer-logo-wrapper {
      line-height: 0;
      padding: 0.7rem;

      @include media-down(md) {
        padding-left: 0;
      }
    }
  }

  #Hierarchy-Footer-Utility {
    li {
      padding: 0.7rem;

      @include media-down(md) {
        padding-left: 0;
      }
    }
  }

  &.ob-spotlight-reduce-footer-waitlist {
    #Hierarchy-Footer-Top {
      @include media-up(md) {
        margin: .5rem 0;
      }
    }

    #Hierarchy-Footer-Utility {
      @include media-up(md) {
        margin: .0rem 0 0;
      }
    }
  }
}
#Hierarchy-Footer-Utility-Spotlight{
  @include media-up(md) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto 32px;
    align-items: baseline;
  }
  @include media-down(md) {
    flex-direction: column;
  }
}
.footer-list-sign-up{
  display: flex;
  border-radius: 25px;
  background-color: #fff; 
  padding: 1% 0%;
  input{
    border: 2px solid $base-color-mid-dark-grey !important;
  }
}
.border-white{
  border: none !important;
}
.padding-label{
  padding: 11px;
  span{
    display: none;
  }
}
.border-white{
&.--select, &:focus,&::after{
  padding-right: 4rem;
  border: none !important;
}
}
.ob-footer__list{
  .ob-spotlight-waitlist__legal-text-not-US{
    a:focus,button:focus{
      text-decoration: none !important;
      border: 2px solid $base-color-outline-blue !important;
    }
  }
  ul{
    li{
      a:focus{
        text-decoration: none;
        border: 2px solid $base-color-outline-blue;
      }
    }
  }
}
.ob-footer__onlyShowOn--desktop,.ob-footer__onlyShowOn--mobile{
  a:focus{
    text-decoration: none;
    border: 2px solid $base-color__black;
  }
}
.adchoices-footer{
  a{
    .ob-mainAsset-wrapper {
      order: 2;
      margin-left: 2px;
      @include media-down(sm){
        margin-left: 8px;
      }
    }
  }
}

.io10InputContainer {
  .loaderImg {
    height: 36px;
    position: absolute;
    top: -20px;
    left: -5px;
  }
}