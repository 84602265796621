@import "../../static/styles/mixins";

.ob-profile {
  $ob-profile-max-width-mobile: 87vw;
  $ob-profile-max-width-desktop: 86.4rem;

  &__wrapper {
    margin-top: 3rem;
    @include media-up(sm) {
       max-width: 97vw;
     }

    @include media-up(md) {
      max-width: $ob-profile-max-width-mobile;
    }

    @include media-up(lg) {
      max-width: 87rem;
    }

    @include media-up(xxlg) {
      max-width: $ob-profile-max-width-desktop;
    }
  }

  &__title {
    max-width: $ob-profile-max-width-mobile;
    @include display-3-xs;
    margin: 3rem auto 1rem;
    text-transform: uppercase;


    @include media-up(lg) {
      @include display-2-xl;
      margin: 4rem auto;
      max-width: 87rem;
    }

    @include media-up(xxlg) {
      max-width: $ob-profile-max-width-desktop;
    }
  }

  &__tab-list-container {
    padding: 1.4rem 0;
    margin: 0 0 2rem;
    background: $base-color-secondary-darker-off-white;

    @include media-up(sm) {
      padding: 2.4rem 0;
      margin: 0;
    }

    &.--mobile {
      @include media-up(sm) {
        display: none;
      }
    }

    &.--desktop {
      display: none;
      @include media-up(sm) {
        display: block;
      }
    }
  }

  &__tab-title {
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    display: flex;
    width: $ob-profile-max-width-mobile;
    margin: 1rem auto;
    color: $base-color-dark-blue;
    justify-content: space-between;
    @include font-family('semi-bold')
  }

  &__tab-list {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    max-width: $ob-profile-max-width-mobile;
    overflow: auto;

    @include media-up(sm) {
      display: flex;
      justify-content: space-between;
    }

    @include media-up(lg) {
      max-width: 87rem;
      justify-content: flex-start;
    }

    @include media-up(xxlg) {
      max-width: $ob-profile-max-width-desktop;
      justify-content: flex-start;
    }
  }

  &__tab-item {
    &:not(:last-child) {
      margin: 1rem 0 2rem 0;
      @include media-up(sm) {
        margin: 0;
      }
    }
    @include media-up(lg) {
      &:not(:last-child) {
        margin-right: 4rem;
      }
    }
  }

  &__tab-btn {
    transition: color ease 0.2s;
    font-size: 1.6rem;
    &:link, &:visited {
      color: $base-color-primary-grey;
      @include font-family('semi-bold');
    }

    @include media-up(sm) {
      font-size: 1.4rem;
      &:link, &:visited {
        color: $base-color-dark-grey;
      }
    }

    .is-active & {

      &:link,
      &:visited {
        color: $base-color-dark-blue;
      }
    }
  }

  &__tab-container {
    display: none;
    &.is-active {
      display: block;
    }
  }

  &__modal-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
  }

  &__modal-title {
    font-size: 2.8rem;
  }

  &__modal-subtitle {
    font-size: 2.2rem;
    @include font-family('semi-bold');
    color: $base-color-saturn-grey;
  }

  &__modal-confirm-btn.ob-button, &__modal-cancel-btn.ob-button {
    display: block;
    margin: 3.5rem auto;
    font-size: 1.6rem;
  }
}

.ob-profile__modal-overlay {
  .ReactModal__Content--after-open {
    width: 88%;
    height: 60%;
    max-height: 350px;
    padding: 2rem;
    @include media-up(sm) {
      width: 62rem;
      height: 45rem;
      max-height: none;
      padding: 2rem 13rem;
    }
  }

  .ReactModal__Body--open {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    overflow: visible;
  }
}
