@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.slide-in-right {
    left: 0;
}

.replacing-brush-heads-gentle-care,
.ultimate-clean-slider {
    position: absolute;
    top: 0px;
    display: flex;
    height: 100%;
    z-index: 5;
    animation: 0.3s ease-in 0s 1 forwardsIn;
    .ob-mainAsset-wrapper {
        display: flex;
        align-items: flex-start;
        img{
            height: 100% !important;
        }
    }

    @keyframes forwardsIn {
        0% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(0);
        }
    }
    .cta-container{        
        position: absolute;
        bottom: 120px;
        right: 260px;
        @include media-down(sm){
            position: absolute;
            bottom: 10vw;
            margin: 0 auto;   
            right: unset;
            width: 100vw;
        }        
        @media screen and (device-width: 360px) {
            bottom: 2vw;
        }
        @media screen and (min-width: 1248px) {
            bottom: 8px;
            right: 7%;
        } 
        @media screen and (min-width: 1440px) {
            bottom: 8px;
            right: 9%;
        }
        @media screen and (min-width: 1480px) and (max-width: 1498px) {
            bottom: 53px;
            right: 20%;
        }
        @media screen and (min-width: 1528px) and (max-width: 1538px) {
            bottom: 20px;
            right: 20%;
        }
        @media screen and (min-width: 1600px) {
            bottom: 100px;
            right: 24%;        
        }
        @media screen and (min-width: 1700px) and (max-width: 1702px) {
            bottom: 75px;
            right: 21%;       
        }
        @media screen and (min-width: 1745px) {
            bottom: 85px;
            right: 23%;   
        }
        @media screen and (min-width: 1900px) {
            bottom: 60px;
            right: 22%;       
        }

        @media screen and (min-width: 1024px) and (max-width:1030px) {
            bottom: -20px;
            right: 40px;
            scale: 0.8;
        }
        .start-guide-cta-link{
            display: block;
            @include media-down(sm){
                display: flex;
                justify-content: center;            
            }
        }
    }
    @supports (-ms-ime-align:auto) {        
        @media screen and (min-width: 1702px) {
            bottom: 75px;
            right: 22%;       
        }
        @media screen and (min-width: 1745px) {
            bottom: 75px;
            right: 22%;       
        }
        
        @media screen and (min-width: 1872px) {
            bottom: 100px;
            right: 24%;         
        }
    }
    @-moz-document url-prefix() {    
        @media screen and (min-width: 1600px) {  
            .cta-container{  
                bottom: 7%;          
                right: 0;
            }
        }
        @media screen and (min-width: 1760px) {  
            .cta-container{  
                bottom: 7%;
                right: -85px;
            }
        }
        @media screen and (min-width: 1920px) {  
            .cta-container{
                right: -15%;
            }
        }           
    }
    .close-popup {
        width: 20px;
        height: 20px;
        padding: 15px;
        border: solid 2px #fff;
        display: block;
        position: absolute;
        border-radius: 50%;
        right: 0;
        top: 35px;
        cursor: pointer;
        @media screen and (min-width: 1600px) {
            top: 5%;
        }
        @include media-down(sm){
            right: auto;
            top: 32%;
            transform: translate(0%, 0%);
            margin-left: 89vw;
            top: 27%;
        }
        @media screen and (device-width: 360px) {
            margin-left: 88vw;
            top: 30%;
        }
    }
    @media screen and (min-width: 1600px) {
        @-moz-document url-prefix() {      
            .close-popup{            
                right: 340px;
            }
        }           
    }

    .close-popup::before,
    .close-popup::after {
        position: absolute;
        content: '';
        height: 1px;
        width: inherit;
        background: $base-color__white;
        left: 4px;
        padding: 1px;
        top: 50%;
        transition: all .3s ease-in;
    }

    .close-popup::before {
        transform: rotate(45deg);
    }

    .close-popup::after {
        transform: rotate(-45deg);
    }

    @include media-down(sm) {
        width: 100%;

        .slide-in-background {
            width: 100vw;
            position: absolute;
            bottom: -10px;

            img {
                width: 100vw;
            }
        }

        .slide-in-text-content {
            position: absolute;
            bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
                font-size: 22px;
                line-height: 21px;
                @include font-family('hurme-bold');
                margin-block-start: 10px;
                margin-block-end: 10px;
            }

            .description {
                text-align: center;
                font-size: 14px;
                line-height: 22px;
                margin-block-start: 10px;
                margin-block-end: 10px;
            }

            .start-guide-cta-link {
                p {
                    max-width: 159px;
                    max-height: 43px;
                    font-size: 14px;
                    line-height: 17px;
                    padding: 15px 45px;
                }
            }
        }

        animation: 0.3s ease-in 0s 1 forwardsIn;

        @keyframes forwardsIn {
            0% {
                transform: translateY(100%);
            }

            100% {
                transform: translateY(0%);
            }
        }
    }
}

.Gum-Bleed-Container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: center;
    align-items: center;

    .ob-mainAsset-wrapper {
        padding: 15% 0 15% 30%;

        @media screen and (min-width: 1280px) and (max-width: 1285px) {
            padding: 15% 0 15% 15%;
        }

        img {
            height: 100%;
            width: 100%;
        }
    }

    .text-content {
        padding: 10%;

        @media screen and (max-width: 1440px) {
            padding: 5%;
        }

        .title {
            color: $io2-base-color-dark-blue;
            font-family: 'Hurme bold';
            font-size: 45px;
            line-height: 55px;
            padding-bottom: 15px;

            @media screen and (max-width: 1440px) {
                font-size: 30px;
                line-height: 40px;
            }
        }

        .description {
            font-family: 'Hurme Regular';
            font-size: 20px;
            color: $io2-base-color-dark-blue;
            line-height: 30px;
            padding-bottom: 20px;

            .bold-text {
                font-family: 'Hurme Bold';
            }
        }

        .subtitle {
            font-family: 'Hurme Regular';
            font-size: 12px;
            line-height: 22px;
            color: $io2-base-color-dark-blue;
        }
    }
}

.Gum-Bleed-Container-mobile {
    @include media-down(sm) {
        padding: 38px 60px 20px;
        display: grid;
        align-items: center;
        justify-content: center;

        .ob-mainAsset-wrapper img {
            height: 252px;
            width: 252px;
        }

        .title {
            height: 52px;
            width: 252px;
            color: $io2-base-color-dark-blue;
            font-family: 'Hurme Bold';
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 26px;
            margin-bottom: 16px;
        }

        .description {
            height: 88px;
            width: 251px;
            color: $io2-base-color-dark-blue;
            font-family: 'Hurme Regular';
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
            margin-bottom: 16px;
        }

        .subtitle {
            height: 22px;
            width: 251px;
            color: $io2-base-color-dark-blue;
            font-family: 'Hurme Regular';
            font-size: 10px;
            letter-spacing: 0;
            line-height: 22px;
        }
    }
}

.gum-bleed-close-button {
    .ob-icon {
        fill: $io2-base-color-dark-blue !important;
    }
}

.z-index-updater {
    position: relative;
    z-index: 99;
}

.start-guide-hero-banner-new {

    .mobile-welcome-block{
        padding-top: 7vw;
        padding-bottom: 10vw;
        .io-background-img{
            img{
                width: 100%;
                @include media-down(sm) {
                    max-width: 100%;
                    padding: 0 4%;
                }
            }
        }
    }

    .welcome-container,
    .welcome-container-0,
    .welcome-container-1,
    .welcome-container-2,
    .welcome-container-3 {
        padding-top: 7vw;
        padding-bottom: 6vw;

        .io-background-img {
            display: flex;
            justify-content: center;
            height: 25vw;
        }

        .welcome-brush-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            margin-top: -24vw;
            @media screen and (min-width: 1920px) {
                margin-left: 3vw;
            }
            img {
                height: 27vw;
            }
            .brush-image-0 {
                margin-left: 175px;
            }
            .brush-image-1 {
                margin-top: -30px;
                margin-right: -15px;
            }
            .brush-image-3 {
                margin-top: -30px;
                margin-left: -15px;
            }
            .brush-image-4 {
                img {
                    height: 10vw;
                }
            }
        }
    }
    .welcome-container-0{
        background-color: $base-color__white;
    }
    .welcome-container-1{
        background-color: $base-color__white;
    }
    .welcome-container-2{
        background-color: $base-color__white;
    }
    .welcome-container-3{
        background-color: $base-color__white;
    }
    .welcome-text{
        margin: 3vw auto 0vw;
        color: $io2-blue;
        .welcome-title{
            text-align: center;
            font-size: 36px;
            line-height: 40px;
            font-weight: bold !important;
            @include font-family('hurme-light');
            @include media-down(sm){
                font-size: 28px;
                line-height: 36px;         
            }
            .bold-text{
                @include font-family('hurme-bold');
            }
        }
        .welcome-description{
            width: 35%;
            margin: 0 auto;
            text-align: center;
            font-size: 21px;
            line-height: 29px;
            margin-top: 15px;
            @include font-family('hurme-regular');
            @include media-down(sm){
                width: calc(100% - 30px);
                font-size: 17px;
                line-height: 23px;
            }
            @media screen and (min-width: 1024px) and (max-width:1030px) {
                width: 66%;
            }
            @media screen and (min-width: 1440px) and (max-width: 1500px) {
                width: 45%;
            }
        }
    }
    .shadow-below-image{
        background-image: url('/static/images/blue-shadow.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: initial;
        position: relative;
        height: 7px;
        margin-top: 40px;
        opacity: .77;
        @media screen and (min-width: 1920px) {
            margin-left: 3vw;
        }
    }

}

.sign-up{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    @include media-down(sm){
        margin-top: 10%;
    }
    .sign-up-heading{
        background: $io2-lightgrey;
        color: $io2-blue;
        font-size: 1.458vw;
        @include media-down(sm){
            font-size: 5.458vw;
        }
    }
    .sign-up-text{
        text-align: center;
        font-size: 0.972vw;
        line-height: 1.25vw;
        margin-top: 1.5%;
        color: $io2-blue;
        @include media-down(sm){
            font-size: 3.972vw;
            line-height: 6.25vw;
            margin-bottom: 6%;
            margin-top: 8%;        
        }
    }
}

.replacing-brush-heads-gentle-care {
    .cta-container {
        @media screen and (min-width: 1248px) {
            bottom: 42px;
        }
        @media screen and (min-width: 1800px) {
            bottom: 90px;
        }
    }
}

.ultimate-clean-slider {
    .cta-container {
        @media screen and (min-width: 1248px) {
            bottom: -15px;
        }
    }
}