@import "../../../static/styles/variables";

.image-float-right {
    width: 100%;

    .ob-mainAsset-wrapper-img {
        float: right;
    }
    .ob-mainAsset-wrapper-img:focus{
      border-style: solid;
      border-color: red;
    }
}
.ob-mainAsset-wrapper {
  .is-visible{
    height: 100%;
  }
  
}
.ob-background-wrapper-visible{
  max-width: 100%;
}