@import "../../static/styles/mixins";

@keyframes fadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes marginLeftIn {
    0%   { margin-left: -50px; }
    100% { margin-left: 0; }
}

@keyframes topIn {
    0%   { top: 100px; }
    100% { top: 0; }
}

.ob-in-the-box {
    background: $base-color__white;
    position: relative;
    display: block;
    width: 100%;
    min-height: 550px;

    @include media-down(md) {
        padding-top: 4rem;
        padding-bottom: 2rem;
    }
    @include media-up(md) {
        min-height: 709px;
    }

    &-wrapper {
        @include container-wrapper;
        height: 100%;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        @include media-down(md) {
            position: relative;
            width: 100%;
        }
    }

    &-textContent {
        animation: fadeIn 1.2s ease-in-out, topIn 1s ease-in-out;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        color: $base-color-primary-grey;

        &-inner {
            width: 100%;
            @include media-up(md) {
                width: 43%;
            }

            .ob-heading {
                @include font-family("hurme-light");
                text-transform: uppercase;
                font-size: 4.8rem;
                letter-spacing: 0.5px;
                line-height: 56px;
                .show & {
                    animation: fadeIn 1.5s ease-in-out;
                }

                @include media-down(md) {
                    text-align: center;
                }
            }

            .ob-in-the-box__content-body {
                border-bottom: 1px solid $base-color-secondary-grey;
                @include media-down(md) {
                    text-align: center;
                }
            }

            .ob-in-the-box__content-items {
                font-size: 1.6rem;
                font-weight: 400;

                p {
                    margin: 0.7rem 0;
                }

                strong {
                    @include font-family('semi-bold')
                }
            }
        }


    }

    &:first-child {
        .ob-background-wrapper {
            min-height: 610px;
        }
    }

    &-text-image {
        height: 100%;
        position: absolute;
        width: 52%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .show & {
            animation: fadeIn 1.3s ease-in-out, marginLeftIn 1s ease-in-out;
        }

        @include media-down(md) {
            position: relative;
            display: block;
            width: 100%;
            max-width: 100%;
        }

        @include media-down(md) {
            position: relative;
            display: block;
            width: 100%;
            max-width: 100%;
        }

        .ob-mainAsset-wrapper {
            align-items: flex-end;
            display: flex;
            height: 100%;
            max-width: 100%;
            width: 100%;
            .is-visible{
                img{
                    width: 100%;
                }
            }
        }

        img {
            max-height: 100%;
            max-width: inherit;

            @include media-down(md) {
                padding-left: 2rem;
            }
        }
    }

    .ob-in-the-box__content-items {
        ul {
            @include font-family('hurme-light');
            font-size: 1.8rem;
            margin: 0;
            padding: 0;
            list-style-type: none;
            list-style-position: inside;
        }
    }
}
