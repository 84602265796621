@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-article-share {
    @include font-family('hurme-regular');
    margin-top: rem(40) !important;
    margin-bottom: rem(14) !important;
    font-size: rem(16);
    color: $base-color-primary-grey;
    text-transform: uppercase;
}

.ob-article-share__content {
    padding: 0 2rem;
}

.ob-article-share__label {
    display: inline;
}

.ob-article-share__list {
    display: inline;
    margin: 0;
    padding: 0;
    list-style: none;
}

.ob-article-share__item {
    display: inline;
    margin: rem(6) 0 0 rem(7);
}

.ob-article-share__link {
    display: inline-block;
    position: relative;
    top: -2px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    vertical-align: middle;
    transition: all ease 0.35s;

    &:hover, &:focus, &:active {
        background: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    &:focus {
        @include focusOutline();
    }
}

.ob-article-share__icon {
    display: block;
}

.ob-article-share + .article-body {
    margin-top: 0;

    & > :first-child {
        margin-top: rem(14);
    }
}

.ob-article-share__modal-container {
    h2 {
        font-size: 2.8rem;
    }

    p {
        font-size: 1.8rem;
    }
}

.ob-article-share__close-btn {
    display: block;
    margin-left: auto;
    transition: transform ease 0.35s;
    &:hover, &:focus {
        transform: scale(1.2);
    }
}
