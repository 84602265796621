@import "../../static/styles/mixins";

.ob-faq-block__qa {
    border-top: 1px solid $base-color-secondary-grey;
}

.ob-faq-block__question {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    cursor: pointer;
    width: 100%;

    &:hover,
    &:active {
        text-decoration: underline;
    }
}


.ob-faq-block__icon-wrapper {
    padding: rem(16) rem(16) rem(16) 0;
    line-height: rem(20);
    flex: none;
}

.ob-faq-block__icon {
    display: inline-flex;
    height: rem(20);
    width: rem(20);
    border-radius: 100%;
    background-color: $base-color-dark-blue;
    justify-content: center;
    align-items: center;

    &.ob-faq-block__icon-arrow {
        background-color: $base-color__white;

        path,
        rect {
            fill: $base-color-dark-blue;
        }
    }

    svg {
        width: rem(10);
        height: rem(10);
    }

    path,
    rect {
        fill: #ffffff;
    }
}

.ob-faq-block__question-text {
    flex: 1;
    padding: rem(16) rem(16) rem(16) 0;
    line-height: rem(20);
    font-size: rem(20);
    color: $base-color-primary-grey;
    margin: 0;
    font-weight: normal;
    text-align: left;
}


.ob-faq-block__answer {
    height: 0;
    opacity: 0;
    pointer-events: none;
    font-size: rem(16);
    color: $base-color-primary-grey;

    .ob-faq-block__answer-content {
        display: none;
    }

    &:focus {
        outline-style: none;
        box-shadow: none;
        border-color: transparent;
    }

    .ob-faq-block__qa.enter &,
    .ob-faq-block__qa.exit & {
        transition: height 400ms ease-in-out, opacity 400ms ease-in-out, transform 400ms ease-in-out;

        .ob-faq-block__answer-content {
            display: block;
        }
    }

    .ob-faq-block__qa.enter-active &,
    .ob-faq-block__qa.enter-done & {
        opacity: 1;
        pointer-events: all;

        .ob-faq-block__answer-content {
            display: block;
        }
    }

    .ob-faq-block__qa.enter-done & {
        height: auto !important;
    }

    .ob-faq-block__qa.exit-active &,
    .ob-faq-block__qa.exit-done & {
        height: 0 !important;
    }

    .ob-faq-block__qa.exit-active & {
        .ob-faq-block__answer-content {
            display: block;
        }
    }

    .ob-faq-block__qa.exit-done & {
        .ob-faq-block__answer-content {
            display: none;
        }
    }

    li {
        font-size: rem(16);
        cursor: pointer;
    }

    &:before {
        height: rem(10);
    }

    &:after {
        height: rem(16);
    }

    &:after,
    &:before {
        content: '';
        display: block;
    }
}

.ob-faq-block__answer-helpfulness {
    margin-top: rem(24);
}

.ob-faq-block__answer-helpfulness__response {
    display: inline-block;
    margin: 0 rem(10);
}