@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.ob-collection-tabs__eyebrow {
    @include eyebrow-1-xl;
    text-align: center;
    margin: rem(36) 0;
}

.ob-collection-tabs-block__tabs-wrapper{
    margin: 0 auto;
    max-width: 1050px;
    padding-bottom: rem(20);
    #Collection-Tabs-Block---Kids-Products-Collection & {
        padding-bottom: 0;
    }
    @include media-up(md) {
        border-bottom: 1px solid $base-color-secondary-grey;
        margin: rem(42) auto 0 auto;
        padding-bottom: rem(29);
    }
}

//Global Equity
.zone.toothpaste{
    .ob-collection-tabs{
        .ob-collection-tabs-block__tabs-wrapper{
            @media(max-width: 767.98px) {
                padding-bottom: 0;
            }
        }
    }
}
