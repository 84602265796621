@import "./variables";
@import "./mixins";

div.optanon-alert-box-wrapper {
    background: rgba($base-color-dark-blue, 0.95) !important;
    color: white !important;
    padding: 18px 20px 2px 20px !important;

    @include media-up(sm) {
        padding: 10px 15px 10px 15px !important;
    }

    * {
        @include font-family('semi-bold');
    }

    .optanon-alert-box-bottom-top {
        margin-top: -10px !important;
    }

    .optanon-alert-box-bg {
        margin: 0 auto !important;
        padding: 0 !important;

        @include media-up(sm) {
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
            max-width: $oralb-max-desktop-width !important;
            max-width: 1130px !important;
        }
    }

    .optanon-alert-box-bg p {
        @include font-family('semi-bold');
        margin: 0 0 rem(16) !important;
        padding: 0 !important;
        font-size: rem(16) !important;
        line-height: (24em / 16) !important;
        color: white !important;

        &:last-child {
            margin: 0 !important;
        }
    }

    .optanon-alert-box-bg p.optanon-alert-box-title {
        @include font-family('hurme-bold');
        margin: 0 0 rem(6) !important;
        padding: 0 !important;
        line-height: (24em / 16) !important;
        text-transform: uppercase !important;

        @include media-up(sm) {
            @include font-family('semi-bold');
            float: left !important;
            margin: 0 rem(4) 0 0 !important;
            white-space: nowrap !important;

            &:after {
                content: ' : ' !important;
            }
        }
    }

    .optanon-alert-box-logo {
        display: none !important;
        flex: none !important;
    }

    .optanon-alert-box-body {
        flex: 1 1 auto !important;
        margin: 0 !important;
        padding: 0 0 0 3px !important;

        @include media-up(sm) {
            width: 892px !important;
            padding: 14px 0 !important;
        }
    }

    .banner-policy-link {
        text-decoration: underline !important;

        &:focus, &:active {
            text-decoration: none !important;
        }
    }

    .optanon-alert-box-button-container {
        flex: none !important;
        position: static !important;
        margin: 12px 0 0 !important;
        padding: 0 !important;
        text-align: left !important;

        @include media-up(sm) {
            margin: 0 0 0 64px !important;
        }
    }

    .optanon-alert-box-button {
        float: none !important;
        margin: 10px 0 !important;
    }

    .optanon-alert-box-button-middle {
        padding: 0 !important;
        border: none !important;
        background: none !important;
    }

    .optanon-alert-box-button-middle button {
        @include font-family('semi-bold');
        display: block !important;
        box-sizing: border-box !important;
        position: static !important;
        top: auto !important;
        left: auto !important;
        height: rem(64) !important;
        margin: 0 !important;
        padding: rem(3) rem(30) rem(0) !important;
        border: 2px solid white !important;
        border-radius: 32px !important;
        background: white !important;
        color: $base-color-dark-blue  !important;
        font-size: rem(16) !important;
        white-space: nowrap !important;
        text-align: center !important;
        text-transform: uppercase !important;

        &:focus, &:active {
            background: none !important;
            color: white !important;
        }
    }

    .optanon-button-more .optanon-alert-box-close:before,
    .optanon-button-allow .optanon-allow-all:before {
        content: "" !important;
        display: none !important;
    }

    .optanon-button-allow {
        @include media-down(sm) {
            display: flex;
            justify-content: center!important;
        }
    }
}

/* Custom Styles for danish banner */

html[lang="da"] {
    body #onetrust-consent-sdk {
        #onetrust-banner-sdk {
            bottom: 0 !important;
            width: 100% !important;

            @media only screen and (max-width: 896px) and (min-width: 426px) {
                max-width: 100%;
            }

            @media (min-width: 1024px) {
                max-height: none !important;
                height: auto !important;
            }
        }

        #onetrust-accept-btn-handler, #onetrust-banner-sdk #onetrust-reject-all-handler {
            background-color: transparent !important;
            border-color: $base-color__white !important;
        }
    }
}
