@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.ob-paging-button,
.ob-paging__page-number {
    color: $base-color-dark-blue;
    font-size: rem(16);
    line-height: rem(18);
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        outline: 1px solid $base-color-dark-blue;
    }

    &.ob-paging-button--disabled {
        text-decoration: none;
        color: $base-color-secondary-grey;

        &:hover {
            text-decoration: none;
        }
    }
}

.ob-paging__page-numbers {
    display: inline-block;
    padding: 0 rem(18);

    .ob-paging__page-number {
        color: $base-color-primary-grey;
        font-size: rem(20);
        padding: 0 rem(8);
        text-decoration: none;

        &.active {
            color: $base-color-dark-blue;
            font-weight: bold;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    span {
        font-size: rem(20);
    }
}


.ob-paging-button-content {
    @include media-down(tiny) {
        display: none;
    }
}

.ob-paging-icon {
    text-transform: none;
    display: inline-block;
    vertical-align: middle;
    margin: 0 9px;
    &:focus {
        outline: 2px solid $base-color-dark-blue;
    }
}


.ob-paging-middle {
    color: $base-color-primary-grey;
    font-size: rem(15);
    display: inline-block;
    padding: 0 15px;
}

.ob-paging-wrapper{
    text-align: center !important;
}