@import "../../static/styles/mixins";
@import "../../static/styles/variables";

$minimum-width-mobile: 40vw;
$chart-mobile-horizontal-padding: 0;

.pre-purchase-comparison {
  margin-top: 0;
  .pre-purchase-product-comparison {
    &__wrapper {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      @include media-down(md) {
        width: 100%;
      }
    }

    &__title {
      color: #3d3d41;
      @include font-family('semi-bold');
      font-size: 56px;
      letter-spacing: 0.5px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 60px;
      margin-top: 2.3rem;
      margin-bottom: 2rem;
      @include media-down(md) {
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        margin-top: 3.5rem;
      }
    }

    &__table {
      display: table;
      @include media-up(sm) {
        width: 100% !important;
      }
    }

    &__row {
      display: table-row;
    }

    &__column {
      display: table-cell;
      box-sizing: content-box;
    }

    &__carousel {
      position: relative;
      &--control-button {
        min-width: 50px;
        min-height: 50px;
        width: 50px;
        height: 50px;
        max-width: 50px;
        max-height: 50px;
        border-radius: 50%;
        position: absolute;
        top: 25%;
        transform: translateY(-50%);
        transition: all ease 0.35s;
        display: block;
        &:disabled {
          pointer-events: none;
          opacity: 0.4;
        }
        @include media-down(md) {
          display: block;
        }
        &[data-control-button="next"] {
          right: 7rem;
        }
        &[data-control-button="prev"] {
          left: 7rem;
        }
        img {
          min-height: 50px;
        }
      }
    }

    &__carousel-left-image,
    &__carousel-right-image {
      min-width: 30px;
      max-width: 30px;
      height: 205px;
      display: block;
      min-height: 205px;
    }
    &__carousel-left-image {
      img {
        margin-left: -2.8rem;
        max-height: 205px;
        margin-top: 28px;
        @include media-down(sm) {
          margin-left: -2rem;
        }
        @include media-down(xs) {
          margin-left: -1.5rem;
        }
        @include media-down(tiny) {
          margin-left: -1rem;
        }
      }
    }
    &__carousel-right-image {
      img {
        margin-left: -0.5rem;
        max-height: 205px;
        margin-top: 28px;
      }
    }
    &__carousel-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__carousel-main {
      text-align: center;
      scroll-snap-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem;
      margin: 0 auto;
      @include media-down(md) {
        width: 250px;
        min-width: 250px;
        max-width: 250px;
      }
    }

    &__carousel-control {
      width: 80%;
    }

    &__pre-load-images {
      display: none;
    }
    &__best-match-label {
      .info-icon-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
      }
      .tooltip-container {
        border: 0.5px solid #3d3d41;
        background-color: #ffffff;
        max-width: 355px;
        @include media-down(md) {
          max-width: 330px;
        }
        @include media-down(sm) {
          max-width: 300px;
        }
        p {
          font-weight: bold;
          color: #3d3d41;
          @include font-family('hurme-regular');
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 24px;
          padding-top: 13px;
          padding-left: 20px;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
        }
        hr {
          max-width: 90%;
          opacity: 0.1;
          height: 1px;
          color: #3d3d41;
          border: 1px solid #3d3d41;
        }
        ul {
          list-style: none;
          text-align: left;
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        li {
          display: flex;
          flex-direction: row;
          text-align: left;
          color: #3d3d41;
          @include font-family('semi-bold');
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.44px;
          line-height: 15px;
          padding-bottom: 10px;
          img {
            padding-right: 8px;
            margin-top: -4px;
          }
        }
      }
      .tooltip-arrow {
        width: 35px;
        height: 25px;
        background-color: #fff;
      }
      .tooltip-container[data-popper-placement*="bottom"]
        .tooltip-arrow::before,
      .tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow::after,
      .tooltip-container[data-popper-placement*="top"] .tooltip-arrow::before,
      .tooltip-container[data-popper-placement*="top"] .tooltip-arrow::before {
        border: none;
      }
      .tooltip-container[data-popper-placement*="bottom"] {
        margin-top: 1.5rem;
      }
      .tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow {
        img {
          position: absolute;
          bottom: 2rem;
          margin-left: -20px;
          @include media-down(md) {
            bottom: 2.1rem;
            margin-left: -22px;
          }
        }
      }
      .tooltip-container[data-popper-placement*="top"] {
        margin-bottom: 1.5rem;
      }
      .tooltip-container[data-popper-placement*="top"] .tooltip-arrow {
        img {
          transform: rotate(180deg);
          margin-top: 1.5rem;
          position: absolute;
          left: -13px;
        }
      }
      .info-icon-wrapper:first-child {
        color: $base-color-dark-blue;
        @include font-family('semi-bold');
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 24px;
        @include media-down(md) {
          font-size: 14px;
          line-height: 15px;
        }
      }
      .info-icon:focus {
        border: 1px solid $base-color-dark-blue;
      }
      .info-icon:focus,
      .info-icon:active,
      .info-icon:hover,
      .info-icon {
        margin-top: 2px;
        margin-left: 2px;
        @include media-down(md) {
          margin-top: -2px;
        }
      }
    }
    &__product-name {
      color: #3d3d41;
      @include font-family('semi-bold');
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 26px;
      margin-top: 1rem;
      margin-bottom: 0;
      @include media-down(md) {
        font-size: 14px;
        line-height: 15px;
      }
    }
    &__product-description {
      color: #3d3d41;
      @include font-family('hurme-regular');
      font-size: 22px;
      letter-spacing: 0.5px;
      line-height: 26px;
      min-height: 78px;
      overflow: hidden;
      max-width: 330px;
      margin: 0 auto;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      @include media-down(md) {
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        min-height: 32px;
        margin-bottom: 0;
        max-width: 330px;
      }
      @include media-down(sm) {
        max-width: 310px;
      }
      @include media-down(xs) {
        max-width: 300px;
      }
    }
    &__price-starts-at {
      margin-top: 0.5rem;
      @include media-down(md) {
        margin-top: 17px;
      }
      span {
        color: #3d3d41;
        @include font-family('semi-bold');
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 24px;
        @include media-down(md) {
          font-size: 18px;
          line-height: 15px;
        }
      }
    }
    &__color-label-text {
      margin-top: 1rem;
      span {
        color: #3d3d41;
        @include font-family("book-bold");
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        @include media-down(md) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    &__product-image {
      img {
        max-height: 555px !important;
        min-height: 535px !important;
        @include media-down(md) {
          max-height: 205px !important;
          min-height: 205px !important;
          max-width: 50px;
        }
      }
    }
    &__product-colors-list {
      display: flex;
      flex-direction: row;
      list-style: none;
      justify-content: center;
      padding-left: 0;
      margin-top: 0;
      list-style: none;
      @include media-down(md) {
        max-width: 50%;
      }
      @include media-down(sm) {
        max-width: 60%;
      }
      @include media-down(xs) {
        max-width: 65%;
      }
    }
    &__product-colors-item {
      padding: 3px;
      .ob-series__color {
        &:focus,
        &:hover {
            box-shadow: 0 0 5px rgba($base-color-dark-blue, 0.5);
            border: 1px solid $base-color-dark-blue;
        }
      }
    }
    .bv_numReviews_component_container {
      display: none !important;
    }
    &__learn-more-cta {
      text-align: center;
      width: 220px;
      justify-content: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }

  .pre-purchase-compare-chart {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 6rem;
    @include media-down(md) {
      width: 100%;
      margin-top: 0rem;
    }
    &__title {
      color: #3d3d41;
      @include font-family('semi-bold');
      font-size: 56px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 60px;
      margin-top: 2.3rem;
      margin-bottom: 0;
      @include media-down(md) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    &__wrapper {
      position: relative;
      @include media-down(md) {
        max-width: 100% !important;
        overflow-x: hidden !important;
      }
    }

    &__fade-left,
    &__fade-right {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 0;
      display: none;
      pointer-events: none;
      @include media-up(sm) {
        display: none;
      }
    }

    &__fade-left {
      left: 0;
      opacity: 0;
      background-image: linear-gradient(
        to left,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }

    &__fade-right {
      right: 0;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }

    &__scroll-wrapper {
      @include no-scrollbar;
      @include media-up(sm) {
        padding: rem(18) 0;
        position: relative;
        width: 100%;
      }
      @include media-down(md) {
        max-width: 100% !important;
        overflow-x: hidden !important;
        padding: 18px 0;
      }
    }

    &__table {
      display: table;
      table-layout: fixed;
      width: 100% !important;
      @include media-down(md) {
        width: 98% !important;
        max-width: 100% !important;
        overflow-x: hidden !important;
        margin: 0 auto;
      }
    }

    &__row {
      display: table-row;
      max-width: 100% !important;
      overflow-x: hidden !important;
    }

    &__cell {
      display: table-cell;
      box-sizing: content-box;
    }

    &__image {
      padding-bottom: 18px;
      display: flex;
      img {
        margin: 0 auto;
      }
    }

    &__header {
      font-size: rem(16);
      color: $base-color-dark-blue;
      @include font-family('semi-bold');
      .ob-comparison-chart-image {
        transform: scale(0.95);
        transition: 0.5s ease-in-out;
      }
      &:focus,
      &:hover {
        .ob-comparison-chart-image {
          transform: scale(1) translateX(2%);
        }
      }
    }

    &__select-brush {
      border-bottom: 2px solid;
      display: block;
      border-right: none;
      border-top: none;
      border-left: none;
      font-size: 1.5rem;
      padding: 1rem 0;
      color: $base-color-dark-blue;
      margin: 2rem auto;
      width: 50%;
      max-width: 300px;
      @include media-down(md) {
        width: 150px;
        max-width: 150px;
        text-align: left;
      }
      @include media-down(sm) {
        width: 140px;
        max-width: 140px;
      }
    }

    &__select-brush option {
      @include media-down(sm) {
        width: 140px;
        max-width: 140px;
      }
    }

    &__select-brush-option {
      width: 100%;
    }

    &__down-arrow {
      transform: rotate(45deg);
    }

    &__dropbtn {
      border-bottom: 2px solid;
      display: block;
      border-right: none;
      border-top: none;
      border-left: none;
      font-size: 1.5rem;
      padding: 1rem 0;
      color: $base-color-dark-blue;
      margin: 2rem auto;
      width: 50%;
      max-width: 300px;
      background: transparent;
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      @include media-down(md) {
        width: 150px;
        max-width: 150px;
        text-align: left;
      }
      @include media-down(sm) {
        width: 140px;
        max-width: 140px;
      }
      span {
        @include media-down(md) {
          max-width: 150px;
          padding-left: 3px;
        }
        @include media-down(sm) {
          max-width: 100px;
        }
      }
      i {
        border: solid $base-color-dark-blue;
        border-width: 0 1.8px 1.8px 0;
        display: inline-block;
        padding: 4px;
        margin-right: 0.5rem;
      }
    }

    &__modal-container {
      width: 100%;
    }

    &__product-image {
      height: 350px;
      min-height: 350px;
      max-width: 130px;
    }

    &__feature-cell {
      border-top: 1px solid $base-color-secondary-grey;
      color: $base-color-primary-grey;
      font-size: rem(16);
      position: relative;
      * {
        display: flex;
        text-align: center;
        flex-direction: column;
        margin: 1rem auto;
        justify-content: center;
      }
    }

    &__row-header-title {
      color: #3d3d41;
      @include font-family('hurme-regular');
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 21px;
      max-width: 110px;
      text-align: left;
      min-height: 45px;
      margin-top: 18px;
      @include media-down(md) {
        min-height: 80px;

        text-align: left;
        align-items: flex-start;
      }
    }

    &__feature-cell-content-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 45px;
      @include media-down(md) {
        justify-content: flex-start;
        text-align: left;
        align-items: start;
      }
      p {
        margin-bottom: 0;
        img {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .info-icon-wrapper {
        display: flex;
        flex-direction: row;
        @include media-down(md) {
          flex-direction: column;
        }
        img {
          width: 26px;
          height: 23px;
          padding-left: 3px;
        }
        span {
          margin: 0;
        }
        strong {
          margin: 0;
        }
        .info-title {
          height: 44px;
          max-width: 250px;
          color: #3d3d41;
          @include font-family('hurme-regular');
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 0.5px;
          line-height: 22px;
          text-align: center;
          @include media-down(md) {
            margin-top: 1rem;
            margin-bottom: 0;
            min-height: 44px;
            height: auto;
            width: 150px;
            max-width: 150px;
            font-size: 18px;
            line-height: 18px;
          }
          @include media-down(md) {
            width: 140px;
            max-width: 140px;
          }
        }
        .info-icon {
          margin-top: 2px;
          @include media-down(md) {
            margin-top: 0;
          }
        }
        .info-icon:focus {
          border: 1px solid $base-color-dark-blue;
        }
      }
      .tooltip-container {
        max-width: 350px;
        height: auto;
        border: 0.5px solid #3d3d41;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        @include media-down(md) {
          max-width: 340px;
        }
        @include media-down(sm) {
          max-width: 330px;
        }
      }
      .tooltip-arrow {
        width: 35px;
        height: 25px;
        background-color: #fff;
      }
      .tooltip-container[data-popper-placement*="bottom"]
        .tooltip-arrow::before,
      .tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow::after,
      .tooltip-container[data-popper-placement*="top"] .tooltip-arrow::before,
      .tooltip-container[data-popper-placement*="top"] .tooltip-arrow::before {
        border: none;
      }
      .tooltip-container[data-popper-placement*="bottom"] {
        margin-top: 1.5rem;
      }
      .tooltip-container-less-than-three-products[data-popper-placement*="bottom"] {
        margin-left: 0rem;
      }
      .tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow {
        img {
          position: absolute;
          bottom: 1.1rem;
          margin-left: -20px;
        }
      }
      .tooltip-container-less-than-three-products[data-popper-placement*="bottom"]
        .tooltip-arrow-lessthan-three-product {
        img {
          margin-left: -22px;
        }
      }
      .tooltip-container[data-popper-placement*="top"] {
        margin-bottom: 1.5rem;
      }
      .tooltip-container-less-than-three-products[data-popper-placement*="top"] {
        margin-left: 0rem;
      }
      .tooltip-container[data-popper-placement*="top"] .tooltip-arrow {
        img {
          transform: rotate(180deg);
          margin-top: 4rem;
          position: absolute;
          left: -13px;
        }
      }
      .tooltip-container-less-than-three-products[data-popper-placement*="top"]
        .tooltip-arrow-lessthan-three-product {
        img {
          margin-top: 4.1rem;
        }
      }
      .tooltip-container-less-than-three-products[data-popper-placement*="top"] {
        margin-left: 0rem;
      }
      .tooltip-inner-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        padding: 0;
        margin: 0;
      }
      .tooltip-title {
        color: #3d3d41;
        @include font-family('semi-bold');
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 5px;
        padding-bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left !important;
        padding-left: 0;
        margin-left: 0;
      }
      .tooltip-content {
        text-align: left;
        padding: 0;
        margin: 0;
        color: #3d3d41;
        @include font-family('hurme-regular');
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.44px;
        line-height: 15px;
        padding-top: 0;
        margin-top: 10px;
        padding-bottom: 0;
        margin-bottom: 0;
        padding-left: 0;
        margin-left: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
      }
      hr {
        max-width: 90%;
        opacity: 0.1;
        height: 1px;
        color: #3d3d41;
        border: 1px solid #3d3d41;
        min-width: 303px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
      }
    }

    &__header-content {
      max-width: 265px;
      margin: 0 auto;
      padding: 0;
      margin-top: 10px;
      position: relative;
      @include media-down(md) {
        position: relative;
        width: 180px;
        max-width: 180px;
      }
    }

    &__single-product-compare {
      .info-title {
        @include media-down(md) {
          width: 150px !important;
        }
      }
    }

    &__header-content-single-column {
      @include media-up(md) {
        margin: 0 auto;
        position: relative !important;
      }
    }
    &__cell-content-wrapper {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      justify-content: flex-start;
    }
    &__compare-point-mode-detail {
      ul {
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        @include media-down(md) {
          margin-top: -1rem;
          margin-left: 0;
        }
        li {
          align-items: flex-start;
          display: flex;
          justify-content: center;
          p {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #3d3d41;
            @include font-family('hurme-regular');
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            text-align: center;
            max-width: 125px;
            img {
              max-width: 55px;
              max-height: 55px;
              min-width: 55px;
              min-height: 55px;
            }
            strong {
              color: #3d3d41;
              @include font-family('semi-bold');
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 0.5px;
              line-height: 22px;
              text-align: center;
              @include media-down(md) {
                font-size: 16px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
    &__accessories {
      ul {
        list-style: none;
        text-align: left;
        margin-top: 8rem;
        margin-bottom: 2rem;
        @include media-down(md) {
          margin-top: 0;
          padding-left: 0;
        }
        li {
          display: flex;
          align-items: flex-start;
          color: #3d3d41;
          @include font-family('hurme-regular');
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 22px;
          padding-left: 11px;
          padding-bottom: 20px;
          width: 231px;
          padding-left: 0;
          text-align: left;
          @include media-down(md) {
            font-size: 16px;
            line-height: 21px;
            width: 120px;
          }
        }
      }
      ul li::before {
        content: "\2022";
        color: $base-color-dark-blue;
        font-weight: bold;
        display: inline-block;
        width: 16px;
        margin-right: 1rem;
      }
    }
    &__accessories-content-wrapper {
      display: flex;
      justify-content: center;
    }
    &__expand-row {
      min-width: 100%;
    }
    &__full-width-cell {
      min-width: 80vw;
      position: absolute;
      margin-top: -7rem;
      @include media-down(md) {
        min-width: 100vw;
        margin-top: -5rem;
      }
    }
    &__learn-more-cta-row {
      width: 100%;
    }
    &__learn-more-cta {
      text-align: center;
      width: 200px;
      justify-content: center;
      margin-top: 6rem;
      margin-bottom: 5rem;
      @include media-down(md) {
        padding: 0 1rem;
        width: 142px;
        max-width: 142px;
        margin-top: 5rem;
      }
    }
    &__see-more {
      color: #3d3d41;
      @include font-family('hurme-regular');
      font-size: 14px !important;
      font-weight: 600 !important;
      letter-spacing: 0.5px !important;
      line-height: 22px !important;
      margin-bottom: 2.5rem;
      margin-top: 2.5rem;
      @include media-down(md) {
        letter-spacing: 0;
        line-height: 21px !important;
        margin-top: 0;
        font-size: 14px !important;
      }
    }
    &__see-more-accessories {
      margin-top: 7rem;
      margin-bottom: 3rem;
      @include media-down(md) {
        margin-top: 5rem;
      }
    }
    &__hide-accessories {
      margin-top: 2.5rem;
      margin-bottom: 3rem;
    }
    &__rating-and-review {
      margin-top: 2rem;
      img {
        max-width: 230px;
        @include media-down(md) {
          max-width: 126px;
        }
      }
      hr {
        max-width: 250px;
        @include media-down(md) {
          max-width: 130px;
        }
      }
    }
    &__disable-review-icon {
      img {
        opacity: 0.12;
      }
    }
    &__rating-text {
      color: #3d3d41;
      margin: 0 auto;
      max-width: 300px;
      @include font-family('hurme-regular');
      font-size: 28.8px;
      letter-spacing: 0;
      line-height: 39.09px;
      min-height: 200px;
      height: 200px;
      max-height: 200px;
      margin-bottom: 0;
      margin-top: 2.3rem;
      overflow-y: auto;
      @include media-down(md) {
        min-height: 140px;
        height: 140px;
        max-height: 140px;
        font-size: 16px;
        line-height: 21.72px;
        max-width: 180px;
        width: 160px;
        padding-left: 10px;
      }
      @include media-down(sm) {
        max-width: 160px;
        min-height: 140px;
        height: 140px;
        max-height: 140px;
      }
      @include media-down(xs) {
        max-width: 160px;
        width: 160px;
        min-height: 140px;
        height: 140px;
        max-height: 140px;
      }
    }
    &__rating-value {
      color: #3d3d41;
      @include font-family('hurme-regular');
      font-size: 21.6px;
      letter-spacing: 0.9px;
      line-height: 21.6px;
      margin-top: 0;
      min-height: 21px;
      @include media-down(md) {
        font-size: 12px;
        letter-spacing: 0.5px;
        line-height: 12px;
        min-height: 12px;
      }
    }
    &__customer-review-text {
      color: #3d3d41;
      @include font-family('hurme-regular');
      font-size: 22px;
      letter-spacing: 0.5px;
      line-height: 30px;
      margin-bottom: 0;
      @include media-down(md) {
        font-size: 12.36px;
        letter-spacing: 0.28px;
        line-height: 16.86px;
      }
    }
    &__rating-text::-webkit-scrollbar {
      width: 0.1em;
      @include media-down(sm) {
          width: 0.05em;
      }
    }
    
    &__rating-text::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #FFF;
      border-radius: 10px;
    }
    
    &__rating-text::-webkit-scrollbar-thumb {
      background-color: #C1C1C1;
      outline: 1px solid #C1C1C1;
      border-radius: 10px;
    }
    &__customer-review-value {
      color: #3d3d41;
      @include font-family('hurme-regular');
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      span {
        color: $base-color-dark-blue;
      }
    }
    &__customer-star-rating {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;
    }
    #ratings-summary {
      display: flex !important;
      flex-direction: row !important;
      min-width: 200px !important;
      max-width: 200px !important;
      flex-wrap: wrap;
      justify-content: center;
      @include media-down(md) {
        min-width: 180px !important;
        max-width: 180px !important;
        pointer-events: none;
      }
      @include media-down(sm) {
        min-width: 170px !important;
        max-width: 170px !important;
      }
      @include media-down(xs) {
        min-width: 150px !important;
        max-width: 150px !important;
      }
    }

    #ratings-summary:focus {
      .bv_numReviews_text {
        border: 1px solid $base-color-dark-blue !important;
        @include media-down(md) {
          border: none;
        }
      }
    }

    .bv_stars_component_container {
      min-width: 160px !important;
      justify-content: center;
    }
    .bv_avgRating_component_container {
      margin-top: 0.5rem !important;
      color: #3d3d41 !important;
      @include font-family('semi-bold');
      font-size: 14px !important;
      letter-spacing: 0 !important;
      line-height: 24px !important;
      font-weight: 600 !important;
      @include media-down(md) {
        font-size: 10px !important;
        letter-spacing: 0 !important;
        line-height: 13.49px !important;
      }
    }
    [data-bv-show="rating_summary"]
      .bv_main_container
      .bv_avgRating_component_container {
      color: #3d3d41 !important;
      font-size: 14px !important;
      letter-spacing: 0 !important;
      line-height: 13.49px !important;
      @include font-family('semi-bold');
      @include media-down(md) {
        font-size: 10px !important;
      }
    }
    .bv_histogram_component_container {
      @include media-down(md) {
        min-width: 150px !important;
      }
    }
    .bv_button_component_container {
      button{
        display: none !important;
      }
    }
    .bv_avgRating_component_container::after {
      content: " out of 5";
      color: #3d3d41 !important;
      @include font-family('semi-bold');
      font-size: 14px !important;
      font-weight: 600 !important;
      letter-spacing: 0 !important;
      line-height: 24px !important;
      padding-left: 5px;
      @include media-down(md) {
        font-size: 10px !important;
        letter-spacing: 0 !important;
        line-height: 13.49px !important;
        padding-left: 3px;
      }
    }
    .bv_numReviews_component_container {
      margin-top: 0.3rem !important;
      margin-right: 1.5rem !important;
      @include media-down(md) {
        margin-right: 0.5rem !important;
      }
      div::before {
        top: 12px !important;
        @include media-down(md) {
          top: 11px !important;
          right: -7px !important;
        }
      }
    }
    [data-bv-show="rating_summary"] .bv_numReviews_text {
      @include media-down(md) {
        font-size: 1rem !important;
        pointer-events: none;
      }
    }
    .bv_numReviews_text {
      @include media-down(md) {
        font-size: 10px !important;
        font-weight: 600;
        letter-spacing: 0 !important;
        line-height: 13.49px !important;
      }
    }
    .bv_numReviews_text::before {
      top: 12px !important;
      @include media-down(md) {
        display: none;
      }
    }
  }
}

.pre-purchase-review-detail {
  .bv_histogram_component_container {
    @include media-down(md) {
      min-width: 100px !important;
    }
  }
  .bv_button_component_container {
    .bv_button_buttonFull {
      display: none !important;
    }
  }
}

.pre-purchase-comparison-no-data {
  min-height: 80vh;
}

.pre-purchase-comparison-mobile-view {
  @include media-down(md) {
    margin-top: -100px;
  }
  @include media-down(sm) {
    margin-top: -20px;
  }
  @include media-down(xs) {
    margin-top: 0px !important;
  }
}

.ob-brush-select-list {
  &__after-open {
    padding: 0;
    width: 80vw;
    height: 45vh;
    overflow-x: hidden;
  }
  &__modal-container {
    margin: 0;
    width: 100%;
    height: 100%;
  }
  &__brush-list-wrapper {
    font-size: 18px;
    @include font-family('semi-bold');
    height: 100%;
    color: $base-color-dark-blue;
    font-weight: 600;
    letter-spacing: 0.71px;
    line-height: 20px;
    .react-list-select {
      display: inline-block;
      position: relative;
      outline: none;
      width: 100%;
      overflow-y: scroll;
      overflow-x: auto;
      height: 100%;
      padding: 1rem 0.5rem;
      -webkit-overflow-scrolling: touch;
    }

    .react-list-select::-webkit-scrollbar {
      width: 5px; /* width of the entire scrollbar */
    }
    .react-list-select::-webkit-scrollbar-track {
      background: #fff; /* color of the tracking area */
    }
    .react-list-select::-webkit-scrollbar-thumb {
      background-color: #d8d8d8; /* color of the scroll thumb */
      border-radius: 9px; /* roundness of the scroll thumb */
      border: 1px solid #d8d8d8; /* creates padding around scroll thumb */
    }

    .react-list-select--item {
      padding: 0.3em 0.6em;
      cursor: pointer;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      outline: none;
    }

    .react-list-select--item.is-selected {
      -webkit-user-select: inherit;
      -moz-user-select: inherit;
      -ms-user-select: inherit;
      -o-user-select: inherit;
      user-select: inherit;
    }

    .react-list-select--item.is-disabled {
      cursor: default;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      scroll-behavior: smooth;
      overflow: hidden;
    }

    .react-list-select {
      background: #fafafa;
      border: 1px solid #cacaca;
      margin-right: 0.5em;
      scroll-margin-bottom: 40px;
    }
    .react-list-select--item.is-selected,
    .react-list-select--item.is-selected:hover {
      background: #a0ccf0 !important;
      outline: none;
    }

    .react-list-select--item.is-focused {
      background: #e5f3ff;
      outline: none;
    }
    .react-list-select--item.is-focused::before,
    .react-list-select--item.is-focused::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 2px;
      background: #e5f3ff;
    }
    .react-list-select--item.is-focused::before {
      left: 0;
    }
    .react-list-select--item.is-focused::after {
      right: 0;
    }

    .react-list-select--item.is-disabled {
      color: #afafaf;
    }

    .context-menu .react-list-select {
      padding: 0.3em 0;
    }
    .context-menu .react-list-select--item {
      @include font-family('hurme-light');
    }

    /* Component in list - last example */

    .brush-list-wrapper {
      padding: 0.5em;
    }

    .brush-list-wrapper .product-name {
      color: #3d3c42;
      @include font-family('hurme-regular');
      line-height: 24px;
      margin-top: 1px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;

      @include media-down(md) {
        line-height: 18px;
        margin-top: 2px;
        font-size: 18px;
      }
    }
  }
}

.rows-with-column-two{
  @include media-up(md){
    display: grid;
    grid-template-columns: 1rem 2fr 2fr;
  }
}
.rows-with-column-one{
  @include media-up(md){
    display: grid;
    grid-template-columns: 1rem 2fr;
  }
}
.feature-rows, .pre-purchase-compare-chart__compare-point-details-row, .pre-purchase-compare-chart__costomer-rating-and-review, .pre-purchase-compare-chart__learn-more-cta-row{
  @include media-up(md){
    display: grid;
    grid-template-columns: 1rem 1fr 1fr 1fr;
  }
}
.feature-rows-mobile, .pre-purchase-compare-chart__learn-more-cta-row,.pre-purchase-compare-chart__costomer-rating-and-review{
  @include media-down(md){
    display: grid;
    grid-template-columns: 2fr 2fr;
  }
}
.pre-purchase-compare-chart__compare-point-details-row{
  @include media-down(md){
    display: grid;
    grid-template-columns: 0rem 2fr 2fr;
  }
}
.pre-purchase-product-comparison__product-name{
  &:focus{
    border: 2px solid black;
  }
}
.pre-purchase-compare-chart__cell{
  .bv_main_container_row_flex.bv_ratings_summary{
    @include media-down(md){
      display: flex!important;
      flex-direction:column;
    }
  }
}