@import "../../static/styles/mixins";

.ob-youtube-video-block {
    position: relative;
    iframe {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
