@import "../../../../static/styles/mixins";

.ob-edit-profile {

    &__fields-required {
        @include body-4-xl;
        font-size: 1.6rem;
        margin: 0;
    }

    .ob-form__wrapper:first-of-type {
        margin-top: 0.8rem;
    }

    .ob-form__wrapper.ob-edit-profile__half-width {
        @include media-ie() {
            width: 50%;
        }
    }

    .ob-register__row.ob-edit-profile__location {
        @include media-ie() {
            justify-content: flex-start;
        }
    }

    &__select-country {
        margin-top: 0.6rem;
        .ob-form-select__select {
            margin-top: 1rem;
        }
        @include media-ie() {
            margin-left: 2rem;
        }
    }

    &__save-btn {
        &.ob-button {
            margin: 5rem auto 2rem;
            display: block;
            font-size: 1.6rem;
            @include media-up(sm) {
                display: inline;
                margin: 0;
            }
        }
    }

    &__change-pwd-link {
        @include font-family('semi-bold');
        font-size: 1.6rem;
        display: block;
        text-align: center;

        @include media-up(sm) {
            display: inline-block;
            margin-left: 5rem;
            text-align: left;
        }
    }

    .ReactModalPortal &__modal-container {
        .ob-form__button__submit {
            margin: 0 auto;
        }
    }

    .ob-birthdate {
        @include media-up(sm) {
            margin-bottom: 3rem;
        }
    }

    .ob-checkbox__label {
        margin: 2rem 0 0 !important;
        display: flex;
        align-items: center;
        line-height: 2.2rem !important;
    }

    &__success {
        font-size: 1.8rem;
        @include font-family('semi-bold');
    }

    .ob-form__form-checkbox{
        margin-bottom: 5rem;
        justify-content: inherit;
        @include media-down(md) {
            margin-top: 3rem;
        }
    }
}
