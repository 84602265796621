@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.ob-search-block {
    margin: 0 auto;
    max-width: 804px;
    min-height: 500px;
    padding: 2.4rem;
    @include media-down(sm) {
        padding: 2.4rem;
    }
}

.ob-search-block__search-title {
    display: block;
    font-size: 1.6rem;
    font-weight: bold;
    color: #4A4A4A;
    margin: 1rem 0;
}

.ob-search-block__popular-topics {
    margin-top: 3rem;

    .filterSelected {
        background-color: $base-color-dark-blue !important;
        color: $base-color__white  !important;
    }

    .filterNotSelected {
        background-color: $base-color__white  !important;
    }
}

.ob-search-block__popular-topics-title,
.ob-search-block__results-title,
.ob-search-block__no-results-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: #4A4A4A;
    margin: 1rem 0 0 0;
    text-transform: uppercase;
}

.ob-search-block__results-title {
    margin: 3rem 0 0 0;
}

.ob-search-block__popular-topics-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 (-(rem(10)));
}

.ob-search-block__popular-topics__toggle-wrapper {
    margin: 1rem;
}

.ob-search-block__results {
    margin: 24px 0;
}

.ob-search-block__search-result {
    display: flex;
    margin: 14px 0;
    @include media-down(sm) {
        margin: 0;
        padding: 14px 0;
        border-bottom: 1px solid #ececec;
        &:last-child {
            border-bottom: none;
        }
    }
}

.ob-search-block__search-result-content {
    flex: 1;
    padding-right: 24px;
    @include media-down(sm) {
        padding-right: 0;
    }
}

.ob-search-block__search-result-header {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding-top: 14px;
}

.ob-search-block .ob-search-block__search-result-chevron {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $base-color-dark-blue;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 9px;
    text-align: center;
    line-height: 14px;
    font-size: 6px;
    @include media-up(sm) {
        padding-left: 2px;
    }
    color: $base-color__white;
}

.ob-search-block__search-result-title {
    vertical-align: middle;
}

.ob-search-block__search-result-body {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-top: 9px;
}

.ob-search-block__search-result-title em,
.ob-search-block__search-result-body em,
.ob-search-block__results-title em {
    font-weight: bolder;
    font-style: normal;
    color: $base-color-dark-blue;
}

.ob-search-block__search-result-image-wrapper {
    width: 240px;
    height: 130px;
    position: relative;
    overflow: hidden;
    &:focus {
        outline: 2px solid $base-color-dark-blue;
    }
    
    @include media-down(sm) {
        display: none;
    }
}

.ob-search-block__search-result-image {
    width: auto;
    height: 100px;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ob-search-block__paging {
    text-align: center;
    margin: 12px 0;
}

.ob-search-block__suggested-title {
    @include font-family('hurme-light');
    font-size: 3.6rem;
    color: $base-color-primary-grey;
    text-transform: uppercase;
    margin: 3rem 0;
}

.ob-search-block__suggested-categories {
    padding-left: 0;
    list-style-type: none;
    a {
        display: block;
        margin: 3rem 0;
        font-size: 1.6rem;
        color: $base-color-dark-blue;
        text-transform: uppercase;
        font-weight: bold;
    }
}

@keyframes blur-animation {
    0% {
        filter: blur(0.5rem);
    }
    50% {
        filter: blur(1rem);
    }
    100% {
        filter: blur(0.5rem);
    }
}

@keyframes blur-animation-start {
    0% {
        filter: blur(0rem);
    }
    100% {
        filter: blur(0.5rem);
    }
}

.ob-search-block-content--animate {
    animation: blur-animation-start 1s ease-in-out 0s 1 normal forwards,
        blur-animation 2s ease-in-out 1s infinite normal;
}

.ob-search-block-first-step {
    margin: 5.6rem 0;
    font-size: 2.4rem;
}
.MuiOutlinedInput-input{
    color: $base-color-dark-blue !important;
    font-size: 2.2rem !important;
    padding: 0 5.1rem 0 1.5rem !important;
    @include font-family('hurme-regular');
}

ais-highlight-0000000000 {
    color: $base-color-dark-blue !important;
    font-weight: bold;
}

.autocomplete-options-true{
    background-color: #fff;
    position: absolute;
    padding: 3.3rem 4rem;
    border: 1px solid #9b9b9b;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    z-index: 1;
}
.ob-paging-button--disabled {
    text-decoration: none;
    color: $base-color-secondary-grey;
}
.ob-search-bar-autocomplete-title {
    text-align: left;
}
.ob-main-layout-align{
    .ob-search-block{
        @include media-down(sm) {
            min-height: 710px;
        }
        @include media-up(md) {
            min-height: 600px;
        }
    }
}