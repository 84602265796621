@import "../../static/styles/mixins";

.ob-mini-nav {
    @include body-3-xl;
    background: $base-color-secondary-darker-off-white;
    overflow: hidden;
    @include media-up(md) {
        padding: 1.7rem 0;
        background: transparent;

        &.desktop-large-padding-bottom {
            padding-bottom: rem(45);
        }
    }

    &.grey-background {
        background: $base-color-secondary-darker-off-white;
    }

    &.empty {
        background: none;
        padding: 0;
    }

    &__list {
        flex-wrap: wrap;
        margin: 0 auto;
        padding: 0;
        display: flex;
        list-style-type: none;
        justify-content: center;
        flex-direction: column;

        .white-bg--md-down & {
            @include media-down(md) {
                background: $base-color__white;
            }
        }

        @include media-up(md) {
            padding: 0 10%;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-end;

            .centered-list & {
                justify-content: center;
            }
        }

        @include media-up(xlg) {
            .ob-layout-article-legacy & {
                padding: 0 19%;
            }
        }

        @include media-ie() {
            justify-content: space-between;
        }
    }

    &__list-item {
        text-align: center;
        padding: 1rem;
        border-bottom: 1px solid transparentize($base-color-primary-grey, 0.8);

        &:first-child {
            border-top: 1px solid transparentize($base-color-primary-grey, 0.8);

            @include media-up(md) {
                margin-left: 0;
                margin-top: 0;
                border-top: none;
            }
        }

        &:last-child {
            border-bottom: none;
            .white-bg--md-down & {
                border-bottom: 1px solid transparentize($base-color-primary-grey, 0.8);
                @include media-up(md) {
                    border-bottom: none;
                }
            }
            @include media-up(md) {
                margin-right: 0;
            }
        }

        @include media-up(md) {
            text-align: left;
            border-bottom: none;

            .centered-list & {
                padding: 1rem 1.6rem;
            }
        }

        a {
            font-size: 1.4rem;
            line-height: 1.8rem;
            @include font-family('semi-bold');
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            letter-spacing: 0;

            .ob-layout-article-legacy & {
                text-transform: uppercase;

                &:hover{
                    color:$base-color-dark-blue-hover;
                }
            }
        }
    }

    .ob-mainAsset-wrapper {
        margin-bottom: 1rem;
    }

    &__title {
        @include text-link-2-xs;
        display: block;
        margin: 0 0;
        width: 100%;
        outline: none;
        position: relative;
        height: 6.4rem;
        text-transform: uppercase;
    }

    &__icon {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 3.3%;
        bottom: 0;
        left: auto;
        transform: rotate(0deg);
        transition: all linear 0.2s;

        &.is-open {
            transform: rotate(-180deg);
        }
    }
}

.post-purchase-hero-section {
    &__options {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 100%;
        width: 90% !important;
        margin: 0 auto;
        justify-content: space-between;
        margin-top: 4%;
        @include media-down(md) {
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            height: auto;
            overflow-y: auto;
            margin-top: 1rem;
            padding-top: 4rem;
            overflow-x: hidden;
            max-width: 100%;
            width: 80% !important;
        }
        @include media-down(sm) {
            margin-top: 1rem !important;
            width: 90% !important;
        }
        @media only screen and (device-width: 1024px) 
        and (device-height: 1366px) {
            width: 98% !important;
        }
        a, a:focus, a:hover {
            text-decoration: none;
        }
    } 
    &__option {
        display: flex;
        flex-direction: column;
        height: 200px;
        width: 204px;
        margin-left: 7px;
        margin-right: 7px;
        border-radius: 13.5px;
        background-color: #FFFFFF;
        box-shadow: inset 0 -2px 3px 0 rgba(187,187,187,0.5), inset 0 2px 3px 0 rgba(187,187,187,0.5), 5px 7px 14px 0 rgba(0,0,0,0.1);
        align-items: center;
        padding-top: 12px;
        @include media-down(md) {
            margin-left: 0;
            margin-right: 0;
            width: 48%;
            margin-bottom: 10px;
            height: 160px;
        }
    }
    &__option:focus {
        border: 1px solid $base-color-dark-blue;
    }
    &__write-review {
        @include media-down(md) {
            margin-left: 13rem;
        }
        @include media-down(sm) {
            margin-left: 8rem;
        }
    }
    &__icon {
        height: 71.67px;
        width: 71.6px;
        img {
            max-height: 72px;
        }
    }
    &__sub-icon {
        box-sizing: border-box;
        align-items: center;
        margin-top: 0;
        img {
            width: 14px;
            height: 13px;
        }
        @include media-down(md) {
            display: none;
        }
    }
    &__description {
        height: 61.4px;
        width: 167.52px;
        color: #3D3D41;
        @include font-family('semi-bold');
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.68px;
        line-height: 16.2px;
        text-align: center;
        margin-top: 15px;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        @include media-down(md) {
            height: 24px;
            width: 124px;
            color: #3D3D41;
            @include font-family('hurme-regular');
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0.5px;
            line-height: 12px;
            text-align: center;
            margin-top: 5px;
        }
    }
}
.sustainablity-class.ob-article-body a{
    text-decoration: underline !important;
}

// Global Equity
@media(max-width: 767.98px) {
    .zone.kids-products-by-product-type, .zone.kids-products-by-character{
        .ob-mini-nav.white-bg--md-down{
            .ob-mini-nav__title{
                .ob-mini-nav__icon{
                    .ob-icon{
                        fill: $base-color-dark-blue;
                    }
                }
            }
            .ob-mini-nav__list{
                background: #E5E8EC;

                li.ob-mini-nav__list-item{
                    border-top: 1px solid #fff;
                    border-bottom: 0;
                    width: 100%;

                    a{
                        color: $base-color__black;
                        @include font-family('hurme-regular');
                    }

                    .ob-icon.ob-icon--chevronDown {
                        display: none;
                    }
                }
            }
        }
    }

    .ob-mini-nav.grey-background{
        .ob-mini-nav__title{
            background: $base-color__white;
        }
    
        .ob-mini-nav__list{
            .ob-mini-nav__list-item{
                border-top: 1px solid #ffffff;
                border-bottom: 0;
                width: 100%;
            }
        }
    }
}

.ob-mini-nav{
    .ob-mini-nav__list-item{
    a.event_internal_link::after{
        content: '\e912';
        display: block;
        font-size: 8px;
        font-family: 'icomoon', monospace !important;
        color: $base-color-dark-blue;
        padding-top: 9px;
        background-color: transparent;
            &:hover,
            &:focus {
                color: $base-color-dark-blue;
                font-size: 8px;
                font-family: 'icomoon', monospace !important;
                text-decoration: none;
            }
        }
    }
}