@import "../../../../static/styles/mixins";

.ob-checkbox {
    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked + label .ob-checkbox__custom-box:after {
            content: '';
            position: relative;
            display: inline-block;
            transform: rotate(45deg);
            width: 4px;
            height: 9px;
            top: -2px;

            border-bottom: 2px solid $base-color__white;
            border-right: 2px solid $base-color__white;
        }

        &:checked+label[data-is-squared="true"] .ob-checkbox__custom-box{
            background-color: $base-color-dark-blue;
            width: 9px;
            height: 21px;

            border-bottom-width: 2px;
            border-right-width: 2px;
        }

        &:checked + label[data-is-small="true"] .ob-checkbox__custom-box:after {
            width: 4px;
            height: 9px;
        }

        &:checked + label[data-is-white="true"] .ob-checkbox__custom-box:after {
            color: $base-color__white;
            border-color: $base-color__white;
        }

        &:checked+label[data-is-squared="false"] .ob-checkbox__custom-box {
            background-color: $base-color-dark-blue;
        }

        &:focus + label .ob-checkbox__custom-box {
            @include form-focus-state;
        }
    }

    &__label {
        @include font-family('hurme-regular');
        font-size: 1.5rem;
        letter-spacing: 0.5px;
        cursor: pointer;
        padding: 0;
        color: #fff;
        position: relative;
        display: flex !important;
        margin-top: 2rem;

        &[data-is-bold="true"] {
            @include eyebrow-1-xl;
            font-size: 1.6rem;
            line-height: 2.4rem;
        }

        &[data-is-white="true"] {
            color: white;
        }

        @media screen and (min-width: 860px) {
            display: block;
        }

    }
    &__error {
        color: #c60100;
        font-size: 1.6rem;
    }

    &__custom-box {
        margin-right: 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 auto;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        max-width: 20px;
        background: white;
        border: 1px solid $base-color-isolate-grey;
        border-radius: 4px;

        @include media-up(md) {
            flex: 1 0 auto;
        }

        .ob-register & {
            position: relative;
            top: -10rem;

            .zone-create-account--france & {
                top: -19.7rem;
            }

            .zone-create-account--netherlands & {
                top: -25rem;
            }

            .zone-create-account--germany & {
                top: -18.8rem;
            }

            @media screen and (min-width: 400px) {
                top: -8.9rem;

                .zone-create-account--france & {
                    top: -17.7rem;
                }

                .zone-create-account--netherlands & {
                    top: -23rem;
                }

                .zone-create-account--germany & {
                    top: -15.7rem;
                }
            }

            @include media-up(xs) {
                top: -7.7rem;

                .zone-create-account--france & {
                    top: -16.7rem;
                }

                .zone-create-account--netherlands & {
                    top: -22rem;
                }

                .zone-create-account--germany & {
                    top: -15.7rem;
                }
            }

            @media screen and (min-width: 500px) {
                top: -4.3rem;

                .zone-create-account--france & {
                    top: -9rem;
                }

                .zone-create-account--netherlands & {
                    top: -17.3rem;
                }

                .zone-create-account--germany & {
                    top: -7.7rem;
                }
            }

            @include media-up(md) {
                top: -2.2rem;

                .zone-create-account--france & {
                    top: -6.8rem;
                }

                .zone-create-account--netherlands & {
                    top: 8.9rem;
                }

                .zone-create-account--germany & {
                    top: -6.6rem;
                }
                .zone-create-account--uk & {
                    top: -6.6rem;
                }
            }

            @include media-up(lg) {
                .zone-create-account--netherlands & {
                    top: -8.7rem;
                }
            }
        }

        [data-is-white="true"] & {
            background: transparent;
        }

        [data-is-small="true"] & {
            width: 14px;
            height: 14px;
            max-width: 14px;
            border-radius: 0;
        }

        [data-is-squared="true"] & {
            width: 30px;
            height: 30px;
            max-width: 30px;
            border-radius: 0;
            border-color: $base-color-primary-grey;
        }
        
        [data-is-round="true"] & {
            border-radius: 50%;
        }
    }
    &__wrapper{
        .ob-checkbox__label{
            p{
                margin-top:0rem;
            }
        }
    }
    
}

.register-product-createprofile {
    .ob-checkbox__label{
        @include media-up(md) {
            margin: 0 !important;
        }
        @include media-down(md) {
            margin: 0 !important;
        }
    }
    .ob-checkbox__custom-box {
        @include media-up(md) {
            top: -0.2rem;
        }
        @include media-down(md) {
            top: -0.2rem;
        }
    }
}
.checkbox-email-disclaimer .ob-checkbox__label{
    align-items: flex-start !important;
}
.checkbox-email-disclaimer .ob-checkbox__custom-box{
    top: 0 !important;
}
.legal-text-checkbox .ob-checkbox__label{
    a:focus{
        border: 2px solid white;
    }
}