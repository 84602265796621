@import "../../static/styles/mixins";

.ob-promo {
    position: relative;

    &.white-text {
        color: white;
    }

    .ob-background-wrapper {
        // We have to override the padding top,
        // because the image is way too big
        // in those specific resolutions
        @media (min-width: 400px) and (max-width: 499px) {
            padding-top: 250% !important;
        }

        @media (min-width: 500px) and (max-width: 599px) {
            padding-top: 230% !important;
        }

        @media (min-width: 600px) and (max-width: 699px) {
            padding-top: 210% !important;
        }

        @media (min-width: 700px) and (max-width: 767px) {
            padding-top: 200% !important;
        }
    }

    &.top-content .ob-background-wrapper {
        @media (min-width: 400px) and (max-width: 499px) {
            padding-top: 175% !important;
        }

        @media (min-width: 500px) and (max-width: 599px) {
            padding-top: 180% !important;
        }

        @media (min-width: 600px) and (max-width: 699px) {
            padding-top: 140% !important;
        }

        @media (min-width: 700px) and (max-width: 767px) {
            padding-top: 110% !important;
        }
    }

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @include media-up(sm) {
            display: flex;
            padding-left: 30.5%;

            &[data-horizontal-alignment="left"] {
                padding-right: 30.5%;
                padding-left: 0;
            }

            &[data-horizontal-alignment="right"] {
                padding-left: 30.5%;
                padding-right: 0;
            }

            &[data-horizontal-alignment="center"] {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &__content {
        @include body-1-xl;
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        text-align: center;
        -ms-flex-align: center;

        .top-content & {
            justify-content: flex-start;

            .ob-bodyText {
                @include media-down(sm) {
                    font-size: 1.6rem;
                }
            }
        }

        .ob-promo.white-text & {
            color: white;
        }

        @include media-up(sm) {
            justify-content: center;
            max-width: 555px;
            height: auto;
            margin: auto;
        }
    }

    &__top {
        width: 100%;
        flex: none;
        margin: 0;
        padding: rem(34) 1rem 0;

        @include media-up(sm) {
            margin-bottom: rem(20);
            padding: rem(34) 4rem 0;
        }

        @include media-up(md) {
            margin-bottom: rem(20);
            padding: 0;
        }
    }

    &__title {
        @include display-3-xl;
        @include font-family('hurme-regular');
        max-width: rem(276);
        margin: 0 auto rem(22);
        .top-content & {
            max-width: 100%;
        }
        @include media-up(md) {
            max-width: none;
            margin-bottom: rem(10);
            letter-spacing: 0;
        }
    }

    &__subtitle {
        @include font-family('hurme-regular');
        max-width: rem(245);
        margin: 0 auto;
        line-height: (26em / 18);

        @include media-up(md) {
            max-width: none;
            font-size: rem(22);
            letter-spacing: 0;
        }
    }

    &__retailer-wrapper {
        flex: none;
        padding: 0 0 rem(56);
    }

    .ob-promo__retailer-logo {
        margin: 0 0 rem(15);

        @include media-up(md) {
            margin-bottom: rem(12);
        }
    }

    &__retailer-text {
        max-width: rem(339);
        margin: 0 auto;
        line-height: (26em / 18);

        a {
            color: white;
            font-size: 1.6rem;
            @include font-family('semi-bold');

            &:focus,
            &:hover {
                text-decoration: underline;
            }
        }

        .top-content & {
            @include media-down(sm) {
                margin-top: 2rem;
            }
        }

        @include media-up(md) {
            max-width: rem(398);
            line-height: (30em / 22);
            letter-spacing: 0;
        }
    }

    &__retailer-button {
        @include font-family('semi-bold');
        margin: rem(20) auto 0;
        padding-left: rem(70) !important;
        padding-right: rem(70) !important;
        font-size: rem(16);
        letter-spacing: 0;

        @include media-up(md) {
            margin-top: rem(42);
        }
    }
}
