@import "../../../../static/styles/mixins";

.ob-product-management {

    &__title {
        margin-top: 3.5rem;
    }

    &__app-title, &__title {
        font-size: 1.6rem;
    }

    &__text {
        @include font-family('hurme-regular');
        font-size: 18px;
        line-height: 26px;
        @include media-down(md) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    &__carousel {
        position: relative;

        .ob-mainAsset-wrapper {
            width: calc(100vw - 4rem);
            height: 320px;
            margin: 0 auto;
            @include media-up(sm) {
                width: 265px;
            }
        }

        .ob-mainAsset-wrapper-img {
            max-width: 100%;
            max-height: 100%;
        }

        &--control-button {
            width: 100px;
            height: 100px;
            border: 1px solid $base-color-primary-grey;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: all ease 0.35s;
            display: none;
            &:disabled {
                pointer-events: none;
                border-color: $base-color-secondary-grey;
                svg.ob-icon--thinChevronDown  {
                    fill: $base-color-secondary-grey;
                }
            }

            @include media-up(lg) {
                display: block;
            }

            svg.ob-icon--thinChevronDown  {
                fill: $base-color-dark-grey;
            }

            &:hover,
            &:focus {
                background-color: $base-color-dark-blue;
                border-color: $base-color-dark-blue;

                svg.ob-icon--thinChevronDown  {
                    fill: $base-color__white;
                }
            }

            &[data-control-button="next"] {
                right: -15rem;
            }

            &[data-control-button="prev"] {
                left: -15rem;
            }
        }
    }

    &__product-list {
        display: flex;
        list-style-type: none;
        padding: 0;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        margin: 3rem auto;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }

        &.is-centered {
            justify-content: center;
        }
    }

    &__product-list-item {
        text-align: center;
        scroll-snap-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include media-up(sm) {
            margin: 0 1.5rem;
            width: 265px;
        }
    }

    &__product-image-wrapper{
        width: 265px;
        height: auto;
        @include media-down(md) {
          width: calc(100vw - 4rem);
          height: auto;
          margin: 0 auto;
        }
    }

    &__product-image {
        max-width: 100%;
        max-height: 100%;
        border-style: none;
    }

    &__product-title {
        font-size: 1.6rem;
        color: $base-color-dark-grey;
        @include font-family('semi-bold');
    }

    &__product-date {
        font-size: 1.6rem;
        letter-spacing: 0.5px;
        margin: 0 0 2rem;

        &.ob-button {
            @include font-family('hurme-regular');
            text-decoration: underline;

            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }

    &__product-button {
        &:first-child {
            margin-right: 2rem;
        }

        &.--is-primary {
            cursor: default;

            &:hover,
            &:focus {
                background-color: $base-color-dark-blue;
            }
        }
    }

    &__add-product-container {
        margin-top: 3rem;
        text-align: center;
    }

    &__product-limit-label {
        font-size: 1.4rem;
        margin-left: 2rem;
        color: $base-color-primary-grey;
        @include font-family('semi-bold');
    }

    &__app-container {
        margin: 5.5rem 0 0;
        padding: 5rem 0 0;
        border-top: 1px solid $base-color-secondary-grey;
    }

    &__app-list {
        list-style-type: none;
        padding: 0;
        display: flex;

        li {
            &:first-child {
                margin-right: 2rem;
            }

            .ob-mainAsset-wrapper-img {
                max-width: 100%;
            }
        }
    }

    &__app-img {
        transition: transform ease .4s;

        .ob-product-management__app-link:hover &,
        .ob-product-management__app-link:focus & {
            transform: scale(1.03);
        }
    }

    &__spinner {
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 1000;
        animation: rotateanimate360 1s linear infinite;
        transform: translateZ(0);
      
        border: 5px solid #3D3C42;
        border-left: 5px solid transparent;
        background: transparent;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        margin: -38px 0px 0px -38px;
      }

      @keyframes rotateanimate360 {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
}
