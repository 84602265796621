@import "../../../static/styles/mixins";
@import "../../../static/styles/variables";

.ob-background-wrapper {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  min-height: 79vh !important;
}

.ob-background-wrapper-desktop {
  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }

  @media screen and (min-width: $breakpoint-small) {
    display: block;
  }

  @media screen and (min-width: $breakpoint-large) {
    display: block;
  }
}


.ob-background-wrapper-mobile {
  @media screen and (max-width: $breakpoint-small) {
    display: block;
  }

  @media screen and (min-width: $breakpoint-small) {
    display: none;
  }

  @media screen and (min-width: $breakpoint-large) {
    display: none;
  }
}

.zone {
  &.home-body-zone {
    .ob-homepage-hero-block {
      .ob-background-wrapper {
        @include media-down(sm) {
          min-height: 750px !important;
        }
        @include media-up(md) {
          background-position: right;
          height: 100%;
          min-height: 100vh !important;
        }
        .ob-contentBlock-textContent-inner {
          @include media-up(md) {
            margin-right: 0 !important;
          }
          @include media-up(xlg) {
            margin-right: -8% !important;
            margin-bottom: 15% !important;
          }
          @media only screen and (min-width: 1800px){
            margin-right: -20% !important;
          }
          @media only screen and (min-width: 2000px){
            margin-right: -13% !important;
            margin-bottom: 25% !important;
          }
          @media only screen and (min-width: 2200px){
            margin-right: -33% !important;
            margin-bottom: 25% !important;
          }
        }
      }
    }
    .second-module {
      .ob-background-wrapper {
        @include media-up(md) {
          min-height: 680px !important;
          height: auto;
        }
        @include media-up(xlg) {
          min-height: 802px !important;
        }
        @media screen and (min-width: 1800px) {
          min-height: 1050px !important;
        }
        @media screen and (min-width: 2200px) {
          min-height: 1190px !important;
        }
        .ob-contentBlock-textContent-inner {
          @include media-up(md) {
            margin-right: -9% !important;
          }
          @include media-up(xlg) {
            margin-right: -5% !important;
            margin-bottom: 3% !important;
            width: 30% !important;
          }
          @media screen and (min-width: 1600px) {
            margin-right: -13% !important;
            margin-bottom: 3% !important;
            width: 30% !important;
          }
          @media screen and (min-width: 1800px) {
            margin-right: -20% !important;
          }
          @media screen and (min-width: 2200px) {
            margin-right: -30% !important;
          }
        }
      }
      .ob-contentBlock {
        &-wrapper {
          .ob-button {
            @include media-down(sm) {
              margin-top: 7rem;
            }
            @media screen and (max-width: 390px) {
              margin-top: 5rem;
            }
            @media screen and (max-width: 380px) {
              margin-top: 0;
            }
          }
        }        
      }
    }
    .three-module {
      .ob-background-wrapper {        
        @include media-down(sm) {
          min-height: 750px !important;
        }
        @include media-up(lg) {
          min-height: 800px !important;
        }
        @include media-up(xlg) {
          height: 100% !important;
          min-height: 990px !important;
        }
        @media screen and (min-width: 1800px) {
          min-height: 1330px !important;
        }
        .ob-contentBlock-textContent-inner {
          @include media-up(md) {
            margin-left: -2% !important;
            max-width: 40%;
          }
        }
      }
    }
    .fourth-block {
      .ob-background-wrapper {
        @include media-up(xlg) {
          height: 100% !important;
          min-height: 730px !important;
        }
        @media screen and (min-width: 1800px) {
          min-height: 990px !important;
        }  
        .ob-contentBlock-textContent-inner {
          @include media-up(md) {
            margin-right: -2% !important;
          }
          @include media-down(sm) {
            margin-bottom: 15% !important;
          }
        }
      }
    }
    .fifth-block {
      .ob-background-wrapper {
        @include media-up(xlg) {
          height: 100% !important;
          min-height: 730px !important;
        }
        @media screen and (min-width: 1800px) {
          min-height: 990px !important;
        }    
      }
    }
  }
}