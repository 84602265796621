@import "../../../../static/styles/mixins";

.ob-preferences {
    &__text {
        font-size: 2.2rem;
        margin: 0;
    }

    &__wrapper {
        padding: 3rem 0 0;
        @include media-up(sm) {
            padding: 3rem 0;
        }
        &--with-border {
            border-bottom: 1px solid $base-color-secondary-grey;
        }
    }

    &__toggle-container {
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
    }

    &__toggle-title {
        margin-right: 2.6rem;
    }

    &__toggle-input {
        &--mobile {
            margin: 1.5rem 0 3.6rem;
            @include media-up(sm) {
                display: none;
            }
        }

        &--desktop {
            display: none;
            @include media-up(sm) {
                display: block;
            }
        }
    }

    &__remind-date {
        margin: 1.5rem 0;
        font-size: 1.6rem;
        @include media-up(sm) {
            margin: 3rem 0;
        }
    }

    &__reminder-list {
        margin: 0 0 2.6rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        list-style-type: none;
        @media screen and (min-width: 600px) {
            justify-content: flex-start;
        }
        @include media-up(sm) {
            margin: 3.6rem 0 1.5rem;
            overflow-x: visible;
            flex-wrap: nowrap;
        }
    }

    &__reminder-list-item {
        margin: 2rem .5rem;
        @include media-up(sm) {
            flex: 0 0 auto;
            padding: 0;
            margin: 0;
        }

        &:not(:last-child) {
            @include media-up(sm) {
                margin-right: 2rem;
            }
        }
    }

    &__reminder-radio {
        position: absolute;
        opacity: 0;

        &:checked {
            +.ob-preferences__reminder-label {
                border-color: $base-color-dark-blue;
                background-color: $base-color-dark-blue;
                color: $base-color__white;
            }
        }
    }

    &__reminder-label {
        @include font-family('semi-bold');
        cursor: pointer;
        font-size: 1.6rem;
        padding: 1rem 2rem;
        border-radius: 20px;
        border: 1px solid $base-color-secondary-blue;
        color: $base-color-secondary-blue;
        transition: ease all 0.35s;
    }

    &__goals-title {
        margin-bottom: 3rem;
    }

    .ob-edit-profile__save-btn.ob-button {
        margin-top: 0;
        font-size: 1.6rem;
    }
}
