@import "../../../../../static/styles/mixins";
@import "../../../../../static/styles/variables";

.ob-register {
    &__all-fields-required {
        font-size: 1.6rem;
        letter-spacing: 0.57px;
        line-height: 2.2rem;
    }

    &__wrapper {
        @include media-up(lg) {
            max-width: 80.6rem;
        }
    }

    &__row:first-of-type {
        margin-top: 2.4rem;
    }

    &__row {
        margin-top: 4rem;

        @include media-down(sm) {
            &-password {
                .ob-input__wrapper:last-of-type {
                    margin-bottom: 0;
                }
            }

            &-other {
                margin-top: 3rem;
            }
        }
    }

    .ob-form {
        &__layout {
            margin-top: 5rem;
        }
        &__subtitle{
            font-size: 2rem;
            line-height: 3rem;

            strong {
                @include font-family('semi-bold');
                line-height: 3rem;
            }
            a:link{
                &:hover{
                    color:$base-color-dark-blue-hover;
                }
            }
        }
        &__form {
            margin-top: 3.6rem;
            @include media-up(sm) {
                margin-top: 5.5rem;
            }
        }
        &__subtitle a {
            text-decoration: underline;
            &:hover{
                text-decoration: none;
                color:$base-color-dark-blue-hover;
            }
            &:focus {
                border: 1px solid #002D72;
            }
        }
        &__requirements {
            margin-top: 0;
        }
    }
    .ob-eyebrow {
        font-size: 1.6rem;
        @include media-down(sm) {
            margin-bottom: 2.5rem;
        }
    }

    &__step-title.ob-eyebrow {
        @include font-family('hurme-light');
        font-size: 2.8rem;
        line-height: 2.4rem;
    }

    &__pass-requirements {
        @include font-family('hurme-regular');
        color: #4A4A4A;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 22px;
    }

    &__info-text, .ob-checkbox__label {
        font-size: 1.4rem;
        margin: 3rem 0;
        color: $base-color-primary-grey;

        p {
            line-height: 2.2rem;
            margin: 0;
        }
    }

    .ob-checkbox__label {
        margin: 2rem 0 0 !important;
        display: flex;
        align-items: center;
    }

    &__info-text-bottom {
        font-size: 1.4rem;
        color: $base-color-primary-grey;

        ul {
            padding: 0;
            list-style: inside;
            margin-top: 2.8rem;
        }

        li {
            margin-bottom: .2rem;
        }
    }

    &__birthdate-wrapper {
        .zone-create-account--fi-fi &,
        .zone-create-account--sv-se &,
        .zone-create-account--nb-no & {
            @include media-up(sm) {
                grid-template-columns: 65% 31%;
            }
        }

        .zone-create-account--it-it & {
            @include media-up(sm) {
                grid-template-columns: 51% 45%;
            }
        }
    }

    .ob-input__wrapper {
        @include media-down(sm) {
            margin: 0 0 1.6rem;
        }
    }
   
    .ob-checkbox__input:checked + .ob-checkbox__label:after {
        top: calc(50% - 3px);
    }

    .ob-form__button__submit {
        margin: 5.6rem 0 2.6rem;

        @include media-up(sm) {
            margin: 4rem 0 2.6rem;
        }
    }

    &__modal-container {
        width: 82%;
        height: 250px;
        padding: 2rem 4rem 2rem 2rem;
        display: flex;
        align-items: center;
        background: $base-color__white;
        box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
        position: relative;
        border-radius: 4px;
        @include media-up(sm) {
            width: 52%;
            height: 200px;
            padding-right: 2rem;
        }
        p {
            font-size: 1.6rem;
            line-height: 2.2rem;
            @include font-family('hurme-regular');
        }

        .ob-modal-close {
            filter: none;
            transition: transform ease 0.35s;
            &:hover, &:focus {
                transform: scale(1.05);
            }
        }
    }
}

.ob-register-product {
    &__wrapper {
        @include media-up(lg) {
            margin: 0;
            max-width: 100rem;
        }
        @include media-down(md) {
            width: 100%;
            max-width: 100rem;
        }
    }
    &__goals-text {
        margin: 3rem 0 1rem !important;
    }
}
.checkbox-email-disclaimer{
    top:0;
}
.disclaimer-text-why{
    margin-bottom: 2rem !important;
}
.checkbox-email-disclaimer {
    top:0;
    a{
        text-decoration: underline;
        &:focus{
            border: 2px solid $base-color-outline-blue;
        }
    }
}
.disclaimer-text-why{
    margin-bottom: 2rem !important;
    a{
        text-decoration: underline;
        &:focus{
            border: 2px solid $base-color-outline-blue;
        }
    }
}