@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.ob-spotlight-experience {
  overflow-x: hidden;

  .ob-background-wrapper {
    padding-top: 0 !important;
    @media screen and (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge) {
      min-height: 750px !important;
    }
    
  }

  &__wrapper {
    padding: 0 2rem 2rem;
    @media screen and (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge) {
      min-height: 750px !important;
    }
    @include media-down(sm) {
      padding: 2.5rem 2rem 2rem;
    }
    &.no-background {
      margin-left: auto;
      position: relative;
    }

    .ob-series__color.ob-series__color--large-mobile {
      &:focus {
        @include focusOutline();
      }
    }

    @include media-down(sm) {
      .ob-series__color.ob-series__color--large-mobile {
        height: rem(32);
        width: rem(32);
      }
    }

    @include media-up(sm) {
      background: transparentize($base-color__white, 0.1);
    }

    @include media-up(md) {
      height: 100%;
      top: 0;
      right: 0;
      position: absolute;
      padding: 2rem 4rem 0;
    }

    @include media-up(md) {
      width: 55%;
      min-height: 814px;

      body:lang(de) & {
        min-height: 945px;
      }
    }

    @include media-up(lg) {
      width: 58%;
      padding: 3rem 5.6rem 0;

      body:lang(es) & {
        min-height: 900px;
      }

      body:lang(de) & {
        min-height: 100vh;
        padding: 3rem 2rem 0;
      }
    }

    @include media-up(xxlg) {
      padding: 3rem 10rem 0;
    }
  }

  &__content {
    padding-top: rem(23);

    @include media-up(lg) {
      max-width: 64rem;
      width: 100%;
    }
  }

  &__title {
    @include display-2-xl;

    @include media-up(md) {
        font-size: 5.6rem;
        line-height: 6rem;
    }
  }

  &__body {
    @include body-1-xl;
    margin-top: 1rem;

    @include media-down(md) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  &__product {

    &-list {
      list-style: none;
      margin: 0;
      padding-left: 0;

      &__item {
        padding: rem(10) 0;
        position: relative;

        &::before {
          background-color: $base-color-secondary-blue;
          bottom: 50%;
          content: "";
          left: auto;
          right: calc(100% + 2.3rem);
          position: absolute;
          top: 50%;
          transition: all ease-in-out 0.3s;
          width: 5px;
        }

        &.current-product {
          &:before {
            bottom: rem(5);
            top: rem(5);
          }
        }


        &:not(:first-child) {
          border-top: 2px solid $base-color-isolate-grey;
        }
      }

      @include media-up(sm) {
        padding-left: rem(45);

        &__item {
          padding: rem(20) 0;
        }
      }

      @include media-up(md) {

        &__item {
          &::before {
            right: calc(100% + 1.5rem);
          }
        }
      }

      @include media-down(sm) {
        border-bottom: 2px solid $base-color-isolate-grey;
      }

      @include media-up(md) {
        margin-left: auto;
        max-width: rem(594);
        padding-left: rem(20);

        &:not(:first-child) {
          border-top: 2px solid $base-color-isolate-grey;
        }
      }
    }

    &-info {
      @include media-up(sm) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
      }
    }

    &-name {
      margin: 0;

      &__large {
        display: inline-block;
        font-size: rem(28);
        vertical-align: baseline;
      }

      &__btn {
        font-size: rem(18);
        letter-spacing: 0.05em;
        line-height: rem(26);
      }

      @include media-up(sm) {
        flex: 0 0 auto;
        padding-right: rem(16);
        width: rem(132);

        &__large {
          @include font-family('hurme-light');
          font-size: rem(48);
        }
      }

      @include media-up(md) {
        width: 100%;
      }

      @include media-up(lg) {
        width: rem(132);
      }
    }

    &-options-text {
      font-size: rem(14);
      letter-spacing: 0.05em;
      line-height: 1.5;

      ul {
        list-style: none;
        padding-left: 0;
      }

      @include media-up(sm) {
        font-size: rem(16);
        padding-right: rem(20);
      }
    }

    &-colors {
      margin: rem(8) 0 0;

      .ob-series-comparison__product-colors-list {
        width: 100%;
      }

      @include media-up(sm) {
        flex: 0 0 auto;
        margin: 0 0 0 auto;
        width: 17.1rem;
      }
    }

    &-pic-image {

      &__desktop {
        align-items: center;
        height: 90%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 5%;
        display: flex;
        width: 45%;

        @include media-up(lg) {
          width: 42%;
        }

        img {
          flex: 0 1 auto;
          height: 100%;
          width: auto;
        }
        @media screen and (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge) {
          top: 25%;
        }
      }

      &__mobile {
        flex: 0 0 auto;
        margin-left: rem(-20);
        margin-right: rem(20);
        width: rem(160);

        img {
          height: auto;
          width: 100%;
        }

        @include media-up(sm) {
          margin-right: rem(-20);
        }
      }
    }

    @include media-up(sm) {
      align-items: center;
      display: flex;
    }
  }
  .ob-footer{
    @media screen and (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge) {
      position: absolute !important;
    }
  }
  &__row {
    @include media-down(lg) {
      display: flex;
    }
  }

  &__column {
    @include media-down(md) {
      margin-top: rem(30);
    }

    @include media-down(lg) {
      flex: 1 1 auto;
      max-width: 64rem;
    }
  }

  &__form-list {
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .ob-spotlight-reduce-footer-waitlist & {
      margin-top: 1rem;
    }

    @include media-up(sm) {
      .ob-spotlight-reduce-footer-waitlist & {
        margin-top: 0rem;
      }
    }
  }

  &__form {
    margin: rem(22) 0;
    max-width: rem(300);
    width: 100%;
  }

  &__form-list-item {
    margin-top: 2rem;
    width: 100%;

    @include media-up(sm) {
      width: 48.5%;
      margin-top: 2.1rem;
      .ob-spotlight-reduce-footer-waitlist & {
        margin-top: 1rem;
      }
      &:last-child {
        width: 100%;
      }
    }

    @include media-up(lg) {
      .ob-spotlight-reduce-footer-waitlist & {
        margin-top: 1.5rem;
      }
    }
  }

  &__form-label {
    display: block;
    font-size: 2.2rem;
    margin-bottom: 1rem;
    @include media-up(sm) {
      @include body-3-xl;
      margin-bottom: 0;
    }
  }

  &__form-select {
    &-container {
      position: relative;

      select::-ms-expand {
        display: none;
      }

      .ob-icon {
        pointer-events: none;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);

        &--chevronDown {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
  }

  &__form-input {
    -webkit-appearance: none;
    background-color: $base-color__white;
    border-radius: 0;
    display: block;
    padding: 1.2rem;
    border: 1px solid $base-color-isolate-grey;
    width: 100%;
    @include eyebrow-1-xl;
    @include media-down(sm) {
      font-size: 1.6rem;
    }

    .ob-spotlight-uk & {
      line-height: 2rem;
    }

    &.--select {
      padding-right: 4rem;
    }

    &.--error {
      border: 1px solid $base-color__error;
      color: $base-color__error;

      &:-internal-autofill-selected {
        -webkit-text-fill-color: $base-color__error;
      }

      & + .ob-icon {
        fill: $base-color__error;
      }
    }
  }

  &__form-error,
  &__form-mention {
    font-size: 1.6rem;
    margin: 1.5rem 0 0;
    body:lang(de) & {
      font-size: 1.4rem;
      margin: .5rem 0 0;
    }
  }

  &__form-error {
    color: $base-color__error;
  }

  .ob-button {
    align-items: center;
    background-color: $base-color-dark-blue;
    border: 1px solid $base-color-dark-blue;
    color: $base-color__white;
    display: flex;
    font-size: 1.6rem;
    height: rem(64);
    justify-content: center;
    margin-left: auto;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    text-align: center;
    width: 100%;

    @include media-up(sm) {
      width: 25.4rem;
    }

    &:hover,
    &:focus {
      background-color: $base-color__white;
      color: $base-color-dark-blue-hover;
    }

    &[disabled] {
      background: $base-color-proxima-grey;
      color: $base-color-dark-blue;
      cursor: not-allowed;
    }

    &__spinner {
      position: absolute;
      top: 1.7rem;
      right: auto;
      bottom: auto;
      left: 3rem;
      animation: rotate360 1s linear infinite;
      transform: translateZ(0);

      border: 2px solid $base-color-dark-blue;
      border-left: 2px solid transparent;
      background: transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    @keyframes rotate360 {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    &.not-us {
      margin-right: auto;
      margin-top: 1rem;
    }
  }

  &__legal-text {
    @include eyebrow-1-xl;
    @include font-family('hurme-regular');
    font-size: 1.6rem;
    line-height: 2.1rem;
    margin-top: 2.4rem;


    @include media-up(sm) {
      font-size: 1.3rem;
      line-height: 1.5;
      body:lang(de) & {
        font-size: 1.2rem;
      }
    }

    @include media-up(lg) {
      body:lang(de) & {
        font-size: 1.3rem;
      }
    }

    @include media-up(xxlg) {
      font-size: 1.4rem;
      line-height: 2.1rem;
    }

    .ob-spotlight-reduce-footer-waitlist & {
      margin-top: 1.4rem;
      padding-left: 2rem;
    }
  }

  &__mobile-img {
    margin: 5.2rem 0 3.2rem;
    max-width: 37.6rem;

    img {
      height: auto;
      max-width: 100%;
    }
  }

  &__modal-container {
    align-items: center;
    background: transparentize($base-color__white, 0.05);
    bottom: 0;
    display: flex;
    height: auto;
    left: auto;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all ease-in-out 0.5s;
    transform: translateX(100%);
    width: 100%;

    .ReactModal__Overlay--after-open & {
      transform: none;
    }

    p {
      font-size: 1.6rem;
      line-height: 2.2rem;
      @include font-family('hurme-regular');
    }

    @include media-up(sm) {
      width: 55%;
    }

    @include media-up(md) {
      width: 550px;
    }

    @include media-up(lg) {
      width: 670px;
    }

    .ob-modal-close {
      right: rem(35);
      top: rem(40);

      &:focus {
        @include focusOutline();
      }
    }
  }

  &__modal-bubble {
    @include font-family('semi-bold');
    align-items: center;
    border-radius: 50%;
    background-color: $base-color-dark-blue;
    color: $base-color__white;
    display: inline-flex;
    font-size: rem(12);
    height: rem(14);
    justify-content: center;
    line-height: 0;
    margin-left: rem(3);
    width: rem(14);

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:focus {
      @include focusOutline();
    }
  }

  &__modal-overlay {
    z-index: map-get($zindex, modal);
  }

  &__modal-content {
    margin: auto;
    padding: 2rem 5.8rem;
    width: 100%;

    > div {
      max-width: rem(487);

      h1,
      h2,
      h3 {
        @include display-2-xl;
        margin: 0;

        @include media-up(md) {
          font-size: 5.6rem;
          line-height: 6rem;
        }
      }

      > p {
        @include body-1-xl;
        margin-top: 0rem;

        @include media-down(md) {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }

      > ul {
        list-style: none;
        margin: 0;
        padding-left: rem(30);

        > li {
          margin-top: rem(25);

          ul {
            list-style-type: disc;
            padding-left: rem(45);

            li {

              p {
                font-size: rem(16);
              }
            }
          }

          p {
            @include body-2-xs;
            line-height: rem(24);
            margin: 0;
          }
        }
      }
    }
  }

  &__modal-text-container {
    padding: 1rem 2rem;
    .ob-heading {
      @include display-2-xl;
    }
    @include media-down(sm) {
      .ob-heading {
        padding-right: 3rem;
      }
    }

    @include media-up(sm) {
      padding: 5rem 2rem 1rem 2rem;
    }
  }

  &__modal-text-content {
    @include font-family('hurme-light');
    font-size: 1.8rem;
    line-height: 2.6rem;

    @include media-up(md) {
      font-size: 2.2rem;
      line-height: 3rem;
    }
  }



  &__modal-cta {
    align-items: flex-start;
    color: inherit;
    display: flex;
    transition: all 0.4s ease;

    &:link,
    &:visited {
      color: inherit;
    }

    &:hover,
    &:focus {
      color: $base-color-dark-blue-hover;
      text-decoration: none;
    }

    &-content {
      @include font-family('hurme-regular');
      font-size: 1.6rem;
      letter-spacing: 0.05rem;
      line-height: 2.4rem;
      margin-top: 3.6rem;
    }

    .ob-icon {
      flex-shrink: 0;
      margin: 0.3rem 0.8rem 0 0;
    }
  }

  &__close-modal {
    position: absolute;
    top: 2rem;
    right: 2rem;
    transition: all ease 0.35s;

    &:hover, &:focus {
      transform: scale(1.1);
    }
  }
}

.legal-checkbox {
  cursor: pointer;
  position: absolute;
  opacity: 0;
}

.legal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.legal-input-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: -1.9rem;
}

.legal-checkbox-fake {
  margin-right: .5rem;
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  background: white;
  border: 1px solid $base-color-isolate-grey;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  flex: 0 0 auto;
  top: 2px;
  @include media-up(sm) {
    top: 1px;
  }
}

.legal-checkbox:checked + .legal-checkbox-fake:after {
  content: '';
  position: absolute;
  display: inline-block;
  transform: rotate(45deg) translateY(-50%);
  width: .2rem;
  height: .7rem;
  left: 0;
  right: auto;
  top: 50%;
  bottom: auto;
  margin-top: -2px;
  border-bottom: 2px solid $base-color-dark-blue;
  border-right: 2px solid $base-color-dark-blue;
}

.legal-checkbox:focus+.legal-checkbox-fake {
  border: 1px solid $base-color-dark-blue;
}

body:lang(de) {
  .thank-you-de {
    .ob-SpotlightStickyBar {
      position: static;
      display: none;
      @include media-up(sm) {
        display: block;
      }
    }

    .ob-SpotlightStickyBar__button-zone {
      display: none;
    }

    .ob-spotlightContentBlock {
      bottom: auto;
      left: auto;
      opacity: 1;
      overflow-x: auto;
      position: static;
      right: auto;
      top: auto;
      background: $base-color__white;

      @include media-up(sm) {
        background: none;
      }
    }

    div.ob-background-wrapper {
      min-height: 687px;
      height: 100%;
      background-position-y: 0 !important;


      @media screen and (min-width: 450px) {
        background-position-y: -104px !important;
      }

      @media screen and (min-width: 615px) {
        background-position-y: -279px !important;
      }

      @include media-up(sm) {
        background-position-y: center !important;
      }

      @include media-up(lg) {
        min-height: 810px;
        max-height: 810px;
        overflow: hidden;
      }
    }

    .zone.footer-spotlight {
      display: block;
    }

    .ob-heading,
    .ob-spotlight-waitlist__body {

      color: $base-color__white;
      max-width: 400px;

      @include media-up(lg) {
        max-width: 475px;
      }
    }

    .ob-heading {
      padding-top: 3.2rem;

      @include media-up(lg) {
        padding-top: 0rem;
      }
    }

    .ob-spotlight-waitlist__body {
      font-size: 1.8rem;
      padding-top: 3.6rem;
      margin-top: 0;

      @include media-up(sm) {
        font-size: 2rem;
      }
    }

    .ob-spotlight-waitlist__link-de {
      @include font-family('semi-bold');
      font-size: 1.6rem;
      margin-top: 3.2rem;
    }

    .ob-icon-rounded-container {
      top: 0;
    }

    #Hierarchy-Footer-Utility {
      justify-content: flex-start;
      @include media-up(sm) {
        li {
          margin: 0 2.8rem 0;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .ob-thank-you-modal {
    .ob-spotlight-waitlist__modal-container {
      @include media-down(md) {
        width: 90%;
      }
    }
  }
}

.ob-spotlight-danke .zone.footer-spotlight,
.thank-you-de .zone.footer-spotlight {
  display: block;
}

