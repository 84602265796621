@import '../../static/styles/mixins';
@import "../../static/styles/variables";

.ob-article-body {
    min-height: 120px;
    h1, h2, h3 {
        padding: 0 2rem;
        color: $base-color-primary-grey;
        @include media-down(sm) {
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            hyphens: auto;
        }
    }
    a {
        word-wrap: break-word;
    }

    h2 {
        @include display-3-xl;
        margin-bottom: 0;
    }

    h3 {
        @include display-1-xs;
    }

    .ob-side-by-side {
        h3 {
            padding: 0;
        }
    }

    p {
        color: $base-color-primary-grey;
    }

    .ob-article-pros-cons {
        h3 {
            @include body-1-xl;
        }

        p {
            @include font-family('hurme-light');
        }
    }

    ol li, ul li {
        font-size: 2.2rem;
    }
    ol li p, ul li p  {
        padding: 0 2rem 0 0;
    }

    a {
        text-decoration: underline;

        &:hover, &:focus {
            color: $base-color-dark-blue-hover;
            outline: dotted 1px;
        }
    }

    img {
        max-width: 100%;
    }

    hr {
        margin-top: 8rem;
        border-color: transparent transparent $base-color-secondary-grey transparent;
    }
}
.bottom-link-container{
    .learn-footer{
        a{
            text-decoration: underline !important;
        }
    }
}
.text-btn-container{
    .btn-text-container{
        a{
            text-decoration: underline;
        }
    }
}
.education-btn{
    &:focus{
        border: 2px solid $base-color-outline-blue;
    }
  }
  .access-btn{
    &:focus{
        border: 2px solid $base-color-outline-blue;
    }
  }
  .innovation-btn{
    &:focus{
        border: 2px solid $base-color-outline-blue;
    }
  }