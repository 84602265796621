@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-switch-tab{
    &-block{
        margin-top: 5%;
    }
    &-title{
        text-transform: uppercase;
        margin: 2rem 0rem;
        text-align: center;
        font-size: 16px;
    }
    &__content-details{
        box-sizing: border-box;
        height: 60px;
        width: 500px;
        border: 1px solid $base-color-dark-blue;
        border-radius: 10px;
        background-color: $base-color__white;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        list-style: none;
        padding: 0;
        margin: 15px auto 0;
        &-left{
            width: 150px;
            border-radius: 10px 0 0 10px;
            @media only screen and (max-width: 330px){
                width: 110px;
            }
        }
        &-right{
            width: 348px;
            @include media-down(sm){
                width: 100%;
            }
            border-radius: 0 10px 10px 0;
        }
        @include media-down(sm){
            width: 90%;
        }
        li{
            div{
                padding: 0%;
            }
        } 
    }
    &__content-details-type {
        box-sizing: border-box;
        height: 58px;
        background-color: $base-color__white;
        color: $base-color-dark-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        cursor: pointer;
        font-size: 20px;
    }
    &__active-switch {
        background-color: $base-color-dark-blue;
        color: $base-color__white;
        border-radius: 10px;
    }
}

.ob-switch-tab-multiple{
    &__content-details{
        width: 50%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0;
        li{
            list-style-type: none;
            a{
                width: 164px;
                height: 148px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: $base-color-secondary-off-white;
                z-index: 2;
                @include media-down(sm){
                    width: 118px;
                    height: 160px;
                }

                .switch-tab-label{
                    font-size: 16px;
                    line-height: 32px;
                    letter-spacing: 0.13px;
                    color: $base-color__black;
                    @include media-down(sm){
                        width: 50%;
                        font-size: 14px;
                        line-height: 18px;
                        letter-spacing: 0.11px;
                        text-align: center;
                        margin-top: 4px;
                    }
                }
                img{
                    width: 100px;
                    height: 100px;
                }
            }
            .ob-switch-tab__active-switch{
                border: 3px solid $base-color-dark-blue;
            }
            .ob-switch-tab__active-switch::after{
                position: absolute;
                height: 0px;
                width: 0px;
                border: none;
                transform: translateY(3px);
                border-top: 10px solid $base-color-dark-blue;
                border-left: 15px solid rgba(0,0,0,0);
                border-right: 15px solid rgba(0,0,0,0);
                margin-top: 151px;
                content: "";
                @include media-down(sm){
                    margin-top: 162px;
                }
            }
        }
    }
}
.no-articles{
    text-align: center;
    font-size: 30px;
    line-height: 300px;
    font-weight: bolder;
    @include media-down(sm){
        font-size: 25px;
        line-height: 30px;
    }
}