@import "../../../../static/styles/mixins";

.ob-form__button {
  &__submit {
    margin: 2.6rem 0;
    position: relative;
    width: 100%;
    height: 64px;
    background: $base-color-dark-blue;
    color: $base-color__white;
    font-size: 1.6rem;
    border-radius: 30px;
    cursor: pointer;
    padding: 0;
    border: none;
    transition: all 0.35s ease;
    @include font-family('semi-bold');
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-up(sm) {
      width: 262px;
      height: 48px;
    }

    &:hover {
      background: $base-color-dark-blue-hover;
      text-decoration: none;
    }

    &:disabled {
      cursor: not-allowed;
      text-decoration: none;
      background: $base-color-dark-blue;
      color: $base-color__white;
    }

  }

  &__spinner {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 2rem;
    animation: rotate360 1s linear infinite;
    transform: translateZ(0);

    border: 2px solid $base-color__white;
    border-left: 2px solid transparent;
    background: transparent;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  @keyframes rotate360 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
