@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.article-landing-page {
    .ob-article-collection {
        .ob-contentBlock-textContent-inner {
            span {
                color: $base-color-dark-blue;
                font-size: 80px;
                line-height: 80px;

                @include media-down(sm) {
                    font-size: 40px;
                    line-height: 37px;
                }
            }

            @include media-down(sm) {
                margin-top: 275px;
            }
        }

        .ob-contentBlock-wrapper {
            max-width: 88%;

            @include media-down(sm) {
                max-width: 123.6rem;
            }

            .ob-contentBlock-textContent-inner {
                width: 58%;
                text-align: center;

                @include media-down(sm) {
                    width: unset;
                    text-align: unset;
                }
            }
        }
    }

    .ob-article-text {
        text-align: center;
        margin-top: 3%;

        .article-text {
            h1 {
                font-size: 40px;
                line-height: 30px;
                color: $base-color-dark-blue;

                @include media-down(sm) {
                    font-size: 26px;
                    line-height: 24px;
                }
            }

            .sub-text {
                font-size: 20px;
                line-height: 30px;
                color: $base-color-dark-blue;
                font-weight: bold;
                margin-top: 0px;

                @include media-down(sm) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }

    .ob-switch-tab-block {
        margin-top: 3%;
    }

    .ob-switch-tab__content-details {
        height: unset;
        width: unset;
        border: unset;
        justify-content: center;
        padding: 0px 8%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;

        @include media-down(sm) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-top: 20px;
        }

        li {
            padding: 0% 1%;

            @media screen and (min-width: 1232px) and (max-width: 1280px) {
                padding: 0% 0.25%;
            }

            @include media-down(sm) {
                padding: 10px;
                width: 150px;
            }

            div {
                width: 200px;
                height: 85px;
                border: 1px solid $base-color-dark-blue;
                border-radius: 10px;
                text-decoration: none;
                font-weight: bolder;
                text-align: center;
                @media screen and (min-width: 1232px) and (max-width: 1240px) {
                    width: 194px;
                }
                @include media-down(sm) {
                    width: 150px;
                    height: 56px;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 1px solid $base-color-white;
                }
            }
        }
    }
    .app-download-hero-block{
      @include media-up(sm){
        .ob-backdrop__ratio{
            padding-top: 48.6449% !important;
        }
      }
      @media screen and (max-width: 365px) {
        .ob-backdrop__ratio{
            padding-top: 210.498% !important;
        }
      }
    }
    .ob-app-download-stores .ob-app-download-store img[data-format=mobile] {
        width: 100%;
    }
    .ob-app-download-content-wrapper {
        @include media-down(sm) {
            padding: 0;
        }

        .ob-app-download-content {
            justify-content: center;
            align-items: flex-start;
            text-align: left;

            @include media-down(sm) {
                height: 100%;
                justify-content: end;
                align-items: center;
                text-align: center;
                margin-top: -4%;
            }

            .ob-long-heading {
                font-size: 44px;
                line-height: 54px;
                color: $base-color-dark-blue;

                @include media-down(sm) {
                    font-size: 36px;
                    line-height: 38px;
                }
            }

            .ob-app-download-body {
                color: $base-color-dark-blue;
                margin-top: 20px;

                @include media-down(sm) {
                    width: 80%;
                }
            }
        }
    }
}