@import "../../static/styles/mixins";

.ob-three-columns-quotes {
    margin: 6rem 0;
    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0 auto;
        max-width: 95%;
        @include media-up(md) {
            padding: 0;
            display: flex;
            justify-content: center;
            &--two-columns {
                justify-content: space-between;
            }
        }
        @include media-up(lg) {
            padding: 0;
            display: flex;
            justify-content: center;
            max-width: $oralb-max-desktop-width;
            &--two-columns {
                justify-content: space-between;
            }
        }
    }

    &__list-item {
        text-align: center;
        margin: 0 0 5.6rem;
        @include media-up(md) {
            margin: 0 1rem 0;
            width: 33.33%;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }

            .ob-three-columns-quotes__list--two-columns & {
                margin: 0;
                width: auto;
                max-width: 41%;
            }
        }
    }

    &__date-posted,
    &__quote-title,
    &__quote-text,
    &__author-name {
        color: $base-color-primary-grey;
    }

    &__date-posted {
        font-size: 1.5rem;
        margin: 0 0 1rem;
    }

    &__quote-title {
        @include font-family('semi-bold');
        font-size: 1.6rem;
        margin-top: 0;
    }

    &__quote-text {
        font-size: 2.2rem;
        line-height: 3rem;
        margin-bottom: 2rem;
        &--is-featured {
            font-size: 3.5rem;
            line-height: 4.83rem;
            margin-top: 2rem;
            margin-bottom: 0;
            @include font-family('hurme-regular');

            @include media-up(md) {
                margin-top: 3.6rem;
                margin-bottom: 0;
            }
        }
        @include media-up(md) {
            margin-bottom: 3.6rem;
        }

        p {
            margin: 0;
        }
    }

    &__star-rating {
        height: auto;
        .ob-icon.ob-icon--star {
            padding: 0;
            margin: 0 2px;
        }
    }

    &__author-name {
        font-size: 1.8rem;
        margin: 1rem 0 0;
    }

    &__see-more.ob-button {
        display: flex;
        margin: 3rem auto;
    }

}
