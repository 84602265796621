@import "../../static/styles/mixins";

.ob-content-details-block {
    position: relative;
    padding-top: 0;
    min-height: 550px;
    margin: 5% 0;

    @include media-down(md) {
        min-height: 445px;
    }

    @include media-down(sm) {
        min-height: 420px;
    }

    @include media-up(lg) {
        min-height: 650px;
    }
    @include media-up(xlg) {
        min-height: 700px;
    }

    &-wrapper {
        @include container-wrapper;
        height: 100%;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: center;
        position: relative;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;
        height: 100%;

        @include media-down(md) {
            flex-direction: column-reverse;
            align-items: center;
        }

        .ob-content-details-block__text-content,
        .ob-content-details-block__image-content {
            display: flex;
            flex: 1 0 50%;
            width: 50%;
            height: 100%;

            @include media-down(md) {
                flex: 1 0 100%;
                width: 100%;
                height: 100%;
            }
        }

        .ob-content-details-block__image-content {
            flex-direction: row;
            text-align: right;

            &__main-image, &__details {
                height: 100%;
            }

            &__main-image {
                display: flex;
                flex: 1 0 70%;
                align-items: center;
                justify-content: center;
                height: initial;

                @include media-down(md) {
                    max-width: 330px;
                }

                &-desktop, &-mobile {
                    width: 100%;
                    height: 100%;

                    .ob-mainAsset-wrapper {
                        width: 100%;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                        display: flex;

                        img {
                            max-width: 100%;
                        }
                    }
                }

                &-desktop {
                    @include media-down(sm) {
                        display: none;
                    }
                }

                &-mobile {
                    @include media-up(sm) {
                        display: none;
                    }

                    img {
                        width: 70%;
                    }
                }
            }

            &__details {
                flex: 1 0 30%;
                color: $base-color-primary-grey;
                text-align: center;

                .ob-content-details-block__details-title {
                    font-size: 2.1rem;
                    margin-bottom: 2rem;
                }

                .ob-content-details-block__detail-content {
                    margin-bottom: 2.5rem;

                    .ob-content-details-block__detail-content-text {
                        font-size: 2rem;
                        margin: 1rem 0;
                    }

                    .ob-content-details-block__detail-content-image {
                        @include media-down(sm) {
                            img {
                                width: 90%;
                            }
                        }
                    }
                }
            }
        }

        .ob-content-details-block__text-content {
            align-items: center;
            text-align: left;
            padding-left: 8rem;

            @include media-down(md) {
                padding-left: 0;
                padding-bottom: 8rem;
                justify-content: center;
            }

            .ob-content-details-block__text {
                @include media-up(md) {
                    padding-top: 20%;
                }

                @include media-down(md) {
                    text-align: center;
                }

                .ob-bodyText {
                    margin-top: .5rem;

                    @include media-down(md) {
                        margin-top: 1.5rem;
                    }
                }

                .ob-link,
                .ob-button {
                    margin-top: 3rem;
                    text-align: center;

                    @include media-up(md) {
                        margin-top: 3.8rem;
                    }
                }
            }
        }
    }
}
.ob-four-steps-heading{
    display: flex;
    flex-direction: column;
    margin-top: 3rem!important;
}
.ob-four-steps-heading-span{
    text-transform: none;
    margin-top: 1rem!important;
}

// Global Equity
.zone.whole-body-health-body{
    .ob-article-category-hero-cta-link{
        color: $base-color-dark-blue;

        &:hover, &:focus{
            color: $base-color-dark-blue-hover;
        }
    }

    .ob-content-details-block.protect-health{
        .ob-article-category-hero-cta-link{
            text-align: center;

            span.ob-icon-rounded-container.ob-article-category-hero-cta-icon{
                position: relative;
                left: 65px;
                top: 3px;
            }
        }
    }

    .ob-floss-dental + .ob-rich-text-link{
        a{
            &:hover, &:focus{
                color: $base-color-dark-blue-hover;
            }
        }
    }
}

.dmt-hb-block{
    margin-bottom: 0px!important;
    .ob-content-details-block__image-content__main-image{
        display: none;
    }

    @include media-up(md){
        flex-direction: column-reverse;
        
        .ob-content-details-block{
            &__text-content{
                width: 100%;
                padding: 0px!important;   
            }
            &__text{
                width: 50%;
                text-align: center;
                padding: 0px !important;
                margin:0 auto;    
            }
            &__image-content{
                &__details{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
            }
            &__image-content{
                width: 100%!important;
                &__details{
                    width: 100%;
                }
            }
            &__detail-content-image{
                .ob-mainAsset-wrapper{
                    .ob-mainAsset-wrapper-img{
                        height: 260px;
                    }
                }
            }
            &__detail-content-text{
                width: 50%;
            }
        }
    }
    @include media-down(sm){
        .ob-content-details-block{
            &__detail-content{
                display: flex;

                div + div {
                    width: 40%;
                }
            }
            &__detail-content-image{
                width: 70%;
            }
            &__image-content{
                &__details{
                    div:nth-child(2) {
                        flex-direction: row-reverse!important;
                    }
                }
            }
            &__detail-content-text{
                margin:5rem 0px !important;
            }
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px){
        .ob-content-details-block{
            &__detail-content{
                display: flex;

                div + div {
                    width: 40%;
                }
            }
            &__detail-content-image{
                width: 70%;
                .ob-mainAsset-wrapper{
                    .ob-mainAsset-wrapper-img{
                        height: 260px;
                    }
                }
            }
            &__image-content{
                &__details{
                    div:nth-child(2) {
                        flex-direction: row-reverse!important;
                    }
                }
            }
            &__detail-content-text{
                margin: 50% 0px !important;
            }
        }
    }
    @include media-up(md){
        min-height: 500px;
    }
}

.dmt-block-2{
    margin: 0px !important;
    min-height: 355px !important;
    .ob-content-details-block__text-content,.ob-content-details-block__image-content__main-image{
        display: none!important;
    }

    @include media-up(md){
        .ob-content-details-block{
            &__image-content{
                &__details{
                    display: flex;
                    justify-content: center;
                }
            }
            &__detail-content-image{
                .ob-mainAsset-wrapper{
                    .ob-mainAsset-wrapper-img{
                        height: 260px;
                    }
                }
            }
            &__detail-content-text{
                width: 50%;
            }
        }   
    }
    @include media-down(sm){
        .ob-content-details-block{
            &__detail-content{
                display: flex;
                div:nth-child(2){
                    width: 40%;
                }
            }
            &__detail-content-image{
                width: 70%;
            }
            &__image-content{
                &__details{
                    div:nth-child(1) {
                        flex-direction: row-reverse!important;
                    }
                }
            }
            &__detail-content-text{
                margin:3rem 0px !important;
            }
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px){
        .ob-content-details-block{
            &__detail-content{
                display: flex;
                div:nth-child(2){
                    width: 40%;
                }
            }
            &__detail-content-image{
                width: 70%;
                .ob-mainAsset-wrapper{
                    .ob-mainAsset-wrapper-img{
                        height: 260px;
                    }
                }
            }
            &__image-content{
                &__details{
                    div:nth-child(1) {
                        flex-direction: row-reverse!important;
                    }
                }
            }
            &__detail-content-text{
                margin:50% 0px !important;
            }
        }
    }
}
.dmt-hp-block-3{
    .ob-app-download-divider{
        display: none!important;
    }
    .ob-app-download-content{
        margin: 0 auto!important;
        text-align: center;
    }
    .ob-app-download-content-wrapper{
        padding: 1rem!important;
    }
    @include media-down(sm){
        .ob-app-download-content-wrapper{
            padding: 4rem!important;
        }
    }
}

@include media-down(sm){
    .ob-app-download__magic-timer {
        .ob-app-download-content{
            text-align: center;
            margin-top: 85%!important;
            .ob-app-download-stores{
                a{
                    margin: 10px auto;
                }
            }
        }
    }
}

@include media-up(md){
    .dmt-hb-block{
        .ob-content-details-block__image-content__details{
            .ob-content-details-block__detail-content{
                width: 33.33%;
                &:nth-child(1){
                    .ob-content-details-block__detail-content-text{
                        margin: 0 20%;
                    }
                }
                &:nth-child(2){
                    .ob-content-details-block__detail-content-text{
                        margin: 0 30%;
                    }
                }
                &:nth-child(3){
                    .ob-content-details-block__detail-content-text{
                        margin: 0 30%;
                    }
                }
            }
        }
    }
    
    .dmt-block-2{
        .ob-content-details-block__image-content__details{
            .ob-content-details-block__detail-content{
                &:nth-child(1){
                    width: 75%;
                    .ob-content-details-block__detail-content-text{
                        margin: 0 30%;
                    }
                }
                &:nth-child(2){
                    .ob-content-details-block__detail-content-text{
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

.ob-side-by-side__wrapper{    
    .ob-heading.ob-display-2-xl{
        line-height: 4rem !important;
    }
}

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
    .ob-contentBlock.content#Block---In-The-Box---Learn---Disney-Magic-Timer{
        .ob-contentBlock-textContent{
            justify-content: flex-start !important;
        }  
    }     
}