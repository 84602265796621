@import "./mixins";

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    margin: 0;
    overflow-x: hidden;
    overflow-y: hidden;

    @include font-family('hurme-regular');
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    * {
        box-sizing: border-box;
    }

    &.noScroll {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        overflow: visible;
    }
}

button {
    padding: 0;
    background: none;
    border-radius: 0;
    border: none;
    cursor: pointer;
    &:focus {
        outline: none;
        text-decoration: underline;
    }

    &:hover {
        text-decoration: underline;
    }
}

a:link, a:visited, .link-button {
    color: $base-color-dark-blue;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:focus {
    text-decoration: underline;
    outline: none;
}

.visually-hidden:not(:focus):not(:active) {
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding:0;
    border:0;
    height: 1px;
    width: 1px;
   display: block;
   left: -999px;
   top: -999px;
}
.visually-hidden a:focus{
    left: 0;
    top: 0;
    padding: 3px;
    background: #ffc;
    border:1px solid #990000;
}
.ob-skip-navigation {
    font-size: 2rem;
    background: $base-color__white;
    padding: 1rem;
    display: block;
}
.ob-accessibility{
    font-size: 1.5rem;
    text-align: center;
    font-weight: lighter;
}
.ob-accessibility-highlighted{
    font-size: 1.5rem;
    text-align: center;
    font-weight:bold;
}
.ob-color-name  {
    font-size: 1.5rem;
    text-align: center;
    padding-top: 1rem
}
.ob-color-name-highlighted {
    font-size: 1.5rem;
    text-align: center;
    padding-top: 1rem;
    font-weight: bold;
}
.color-container{
    width: 6rem;
    margin: 1.5rem 0 0 0.5rem;
}
.color-container-feature{
    width: 5rem;
    margin: 1.5rem 0 0 0.5rem;
}
.color-container-pdp{
    width: 5rem;
    margin: 1.5rem 0 0 0.5rem;
}
/* TODO : ADD HOVER EFFECT */
/*a:hover, a:focus, .link-button:hover, .link-button:focus {
    color: $base-color-primary-blue-hover;
}*/

.visuallyhidden {
    @include visuallyHidden();
}

.nowrap {
    white-space: nowrap;

    &--only-on-mobile {
        @include media-up(md) {
            white-space: normal;
        }
    }

    &--only-on-desktop {
        @include media-down(md) {
            white-space: normal;
        }
    }
}

.eyebrow-1-xl {
    @include eyebrow-1-xl();
}

.ob-bold {
    @include font-family('hurme-bold');
}

.stick-footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .zone[role="main"] {
        flex-grow: 1;
    }
}

.layout-announcement-bar-hidden:not(.ob-spotlight-layout) {
    transition: padding ease 0.35s;   
}

.home-body-zone {
    overflow: hidden;
}

.purple-45-gradient {
    background: linear-gradient(45deg, #294891, #4D0D7C)
}

.pink-45-gradient {
    background: linear-gradient(45deg, #FFF0ED, #E9E4E5)
}

.secondary-grey-background {
    background: $base-color-secondary-grey;
}

.lowercase {
    text-transform: lowercase;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}
.remove-empty-paragraph {
 p {
      display: none;
    }
  }

br.tiny {
    display: block;
}

@include media-up(tiny) {
    br.tiny {
        display: none;
        /* Add other properties here */
    }
}

br.mobile {
    display: block;
}

@include media-up(sm) {
    br.mobile {
        display: none;
        /* Add other properties here */
    }
}

.ob-eu-cookie-consent, .ob-us-personal-data {

    position: fixed;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    z-index: map-get($zindex, cookieConsent);

    button {
        @include font-family('semi-bold');
        font-size: 1.1rem;
        color: $base-color-dark-blue;
        position: relative;
        border-radius: 5px;
        border: 1px solid $base-color-dark-blue;
        background: white;
        padding: 8px;
        line-height: 1;

        @include media-up(sm) {
            font-size: 1.2rem;
        }
    }
}

.ob-us-personal-data {
    @include media-down(xs) {
        max-width: 100px;
    }
    @include media-up(sm) {
         padding: 10px 0;
     }
}

@media screen and (max-width: 751px) {
    .ob-body #optanon #optanon-popup-wrapper {
        margin-top: 0!important;
        overflow-y: scroll;
        height: 85vh;
    }
}

.d-none {
    display: none;
}

.ta-left {
    text-align: left !important;
}

.ta-center {
    text-align: center !important;
}

.ta-right {
    text-align: right !important;
    &--md-up {
        @include media-up(md) {
            text-align: right !important;
        }
    }
}

.hide-sm-down {
    display: none;
    @include media-up(sm) {
        display: block;
    }
}

.hide-sm-up {
    display: block;
    @include media-up(sm) {
        display: none;
    }
}

.hide-md-down {
    display: none;
    @include media-up(md) {
        display: block;
    }
}

.hide-md-up {
    display: block;
    @include media-up(md) {
        display: none;
    }
}

.hide-lg-down {
    display: none;
    @include media-up(lg) {
        display: block;
    }
}

.hide-lg-up {
    display: block;
    @include media-up(lg) {
        display: none;
    }
}

.ob-body {

    .three-columns,
    .four-columns {
        margin-bottom: 5.5rem;
        margin-top: 5.5rem;
        h2 {
            font-size: 3rem;
        }
        ul {
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            list-style: none;
            li {
                margin: 0;
                padding: 0;
                width: 32%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                p {
                    font-size: 2rem;
                    text-align: center;
                }
            }


        }
        @include media-down(sm) {
            margin-bottom: 3.5rem;
            margin-top: 3.5rem;
            ul {
                flex-direction: column;
                li {
                    width: 100%;
                    p {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }

    .four-columns {
        ul {
            li {
                width: calc(100% * (1 / 4) - 10px - 1px);
                p {
                    font-size: 1.6rem;
                }
            }
        }
        @include media-down(lg) {
            margin-bottom: 3.5rem;
            margin-top: 3.5rem;
            ul {
                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 48%;
                    p {
                        font-size: 2rem;
                    }
                }
            }
        }
        @include media-down(sm) {
            ul {
                flex-direction: column;
                li {
                    width: 100%;
                    p {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }

    .center-images {
        .ob-mainAsset-wrapper img {
            display: block;
            margin: 0 auto;
        }
    }

}
