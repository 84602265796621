@import "../../../../static/styles/mixins";

.ob-form-select {
    &[data-is-half-width="true"] {
        @include media-up(sm) {
            width: 50%;
        }
    }

    &__select {
        display: block;
        margin: 0.8rem 0 0.6rem;
        width: 100%;
        height: 50px;
        min-width: 95px;
        background: white;
        border: 1px solid $base-color-saturn-grey;
        border-radius: 0;
        font-size: 1.6rem;
        -webkit-appearance: none;
        appearance: none;
        padding: 0 1rem;

        @include media-ie() {
            padding-right: 0;
        }
    }

    &__select-ctn {
        position: relative;

        &:after {
            content: '';
            display: block;
            float: right;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1.6rem;
            bottom: 0;
            left: auto;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 6px 0 6px;
            border-color: $base-color-dark-blue transparent transparent transparent;

            @include media-ie() {
                display: none;
            }
        }
    }
}
