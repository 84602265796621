@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-dot-nav__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    html[lang="pt"] & {
        padding: 0 0 1rem;
    }
}

.ob-dot-nav__item {
    flex: none;

    &:first-child {
        margin-left: auto;
    }

    &:last-child {
        margin-right: auto;
    }
}

.ob-dot-nav__button {
    display: block;
    padding: rem(4) rem(6);
}

button.ob-dot-nav__button {
    border: none;
    background: none;
    appearance: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.ob-dot-nav__dot {
    display: block;
    box-sizing: border-box;
    width: rem(14);
    height: rem(14);
    border: 1px solid $base-color-isolate-grey;
    border-radius: 100%;
    text-indent: -999em;
    overflow: hidden;

    .ob-dot-nav__button:focus & {
        border-color: $base-color-dark-blue;
    }

    .ob-dot-nav__item--active & {
        border: none;
        background-color: $base-color-isolate-grey;
    }

    .ob-dot-nav__item--active.ob-dot-nav__item--blue & {
        background-color: $base-color-dark-blue;
    }

    .ob-dot-nav__item--active.ob-dot-nav__item--white & {
        background-color: $base-color__white;
    }

    .ob-dot-nav__item--white & {
        border-color: $base-color__white;
    }

    .ob-dot-nav__button:hover &, .ob-dot-nav__button:focus & {
        border: none;
        background-color: $base-color-secondary-blue;

        .ob-dot-nav__item.ob-dot-nav__item--white & {
            background-color: $base-color__light-yellow;
        }

        .ob-dot-nav__item--active.ob-dot-nav__item--white & {
            background-color: $base-color__light-yellow;
        }
    }
}
