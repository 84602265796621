@import "../../static/styles/mixins";

.ob-SpotlightStickyBar {
    width: 100%;
    position: fixed;
    background: $base-color-dark-blue;
    z-index: map-get($zindex, header);
    top: 0;
    transition: top ease 0.3s;

    &.hideStickyBar {
        top: -$ob-sticky-bar-height;
        .ob-contest-thankyou-layout & {
            top: 0;
        }
    }

    &__wrapper {
        @include container-wrapper;
        height: $ob-sticky-bar-height;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        @include media-down(md) {
            height: $ob-sticky-bar-height-mobile;
        }
        @include media-down(sm) {
            height: $ob-sticky-bar-height-mobile_new;
        }

    }
    &-link.ob-link {
        display: none;
        transform: translateZ(0);
        transition: all ease 0.35s;
        @include media-up(sm) {
            display: block;
        }
        .ob-mainAsset-wrapper {
            width: 114px;
            img {
                max-width: 100%;
                display: block;

                .spotlight-zone-header--ja-jp & {
                    max-width: 80%;
                }
            }
        }

        &:hover, &:focus {
            transform: scale(1.1) translateZ(0);
        }
    }
    &__eyebrow-container {
        left: 0;
        padding-right: 2rem;

        .german-spotlight-zone-header & {
            padding-right: 1rem;
        }

        @include media-up(sm) {
            left: 50%;
            padding-right: 0;
            position: absolute;
            transform: translateX(-50%);

        }

        @include media-ie() {
            transform: translateX(-50%) translateY(-50%);
        }

        .ob-eyebrow {
            top: .2rem;
            position: relative;
            font-size: 1.3rem;
            line-height: 1.8rem;

            .spotlight-zone-header--ja-jp & {
                font-size: 1.2rem;

                @include media-up(xtiny) {
                    font-size: 1.1rem;
                    line-height: 1.6rem;
                }

                @include media-up(sm) {
                    font-size: 1.5rem;
                    line-height: 2.2rem;
                }

                @include media-up(lg) {
                    font-size: 1.55rem;
                    line-height: 2.2rem;
                }
            }

            .german-spotlight-zone-header & {
                font-size: 1rem;
                line-height: 1.4rem;

                @include media-up(xtiny) {
                    font-size: 1.1rem;
                    line-height: 1.6rem;
                }

                @include media-up(sm) {
                    font-size: 1.5rem;
                    line-height: 2.2rem;
                }

                @include media-up(lg) {
                    font-size: 1.55rem;
                    line-height: 2.2rem;
                }
            }

            @include media-up(xtiny) {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }

            @include media-up(sm) {
                font-size: 1.8rem;
            }

            .france-spotlight-zone-header & {
                @include media-down(sm) {
                    font-size: 1.1rem;
                    line-height: 1.3rem;
                }
            }
        }
    }
    .ob-button {
        flex-shrink: 0;
        justify-self: flex-end;
        margin-left: auto;
        font-size: 1.6rem;
        height: 3.875rem;

        &[data-size=medium] {
            height: 3.875rem;
        }

        @include media-up(sm) {
            font-size: 1.8rem;
            padding: 0 3rem;
        }

        @include media-down(xs) {
            .german-spotlight-zone-header & {
                font-size: 1.4rem;
                padding: 0 1.5rem;
            }
        }
    }

    .icon-io {
        font-size: .9em;
    }
}

.ob-SpotlightStickyBar__button-zone {
    flex-shrink: 0;
    justify-self: flex-end;
    margin-left: auto;
}

a.ob-SpotlightStickyBar__CCPA {
    @include font-family('hurme-regular');
    color: white;
    font-size: 1.4rem;
    text-decoration: underline;
    margin-right: 1rem;
    letter-spacing: .06rem;
    display: none;

    @include media-up(md) {
        display: inline;
    }

    &:hover {
        text-decoration: none;
    }
}

.ob-link.ob-SpotlightStickyBar__adchoice {
    color: $base-color__white;
    font-size: 1.1rem;
    position: relative;
    margin-right: 1rem;

    > span {
        margin-right: .2rem;
    }

    .ob-mainAsset-wrapper {
        width: 15px;
        top: -2px;
        position: relative;
    }

    img {
        max-width: 100%;
    }

    @include media-down(md) {
        color: $base-color__black;
        position: fixed;
        top: auto;
        left: auto;
        right: .25rem;
        bottom: .25rem;
        background: white;
        height: 25px;
        padding: 7px;
        margin-right: 0;
    }

    @include media-down(sm) {
        font-size: 1rem;
    }
}
