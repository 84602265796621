@import "../../static/styles/mixins";

.ob-rich-text-link {
    a:focus {
        img {
            @include focusOutline();
        }
    }
}
.ob-utilityBar {
    .ob-rich-text-link {
        font-size: 1.3rem;
    }
}
