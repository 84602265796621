@import "../../static/styles/mixins";

.ob-see-more-button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.ob-see-more-button__icon {
    flex: none;
}

.ob-see-more-button__text {
    @include text-link-1-xs;
    text-transform: uppercase;
    padding-left: 10px;
    text-align: left;
    @include font-family('semi-bold');

    @include media-up(sm) {
        @include body-3-xl;
        @include font-family('semi-bold');
    }

    .ob-see-more-button--white & {
        color: #ffffff
    }
}
