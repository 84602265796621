@import "../../static/styles/mixins";

.ob-product-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.35s ease;
  pointer-events: none;

  &.is-active {
    pointer-events: all;
    opacity: 1;
  }

  &__wrapper {
    height: 100%;
    width: 100%;
    padding: 4rem 2rem;
    transition: all 0.35s ease;
    background: $base-color__white;
    transform: translateX(100%);
    overflow: scroll;
    @include media-up(sm) {
      width: 55%;
      float: right;
    }
    @include media-up(md) {
      width: 550px;
    }
    @include media-up(lg) {
      width: 670px;
    }

    .is-active & {
      transform: translateX(0);
    }

    &--buy-now-lite {
      @include media-up(sm) {
        width: 360px;
      }
    }
  }

  &__close-btn {
    margin-bottom: 5px;
    float: right;
    transition: all 0.35s ease;
    position: relative;
    top: -30px;
    left: 10px;
    &:hover, &:focus {
      transform: scale(1.1);
    }

    &:focus {
      @include focusOutline();
    }
  }

  .buy-now-lite-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buy-now-lite-link {
    display: block;
    margin: 1rem 0;
    img {
      transition: transform 0.35s ease-in-out;
    }

    &:hover, &:focus {
      img {
        transform: scale(1.1);
      }
    }
  }
}
