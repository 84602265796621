@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.main-menu {
    background: $base-color__white;
    &.is-active {
        .ob-main-menu__logo--mobile {
            padding-bottom: 2rem;
        }
    }
}
.ob-main-menu {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    max-width: calc(#{$oralb-max-desktop-width} - 71px);
    .ob-full-width-nav & {
        max-width: 100%;
    }
    padding: 2.2rem 0;
    top: 0;
    margin: 0 auto;
    list-style-type: none;
    @include body-4-xs();
    @include media-down(sm) {
        &__desktop{
            &__div{
                display: none;
            }
        }
    }
    @include media-up(md) {
        &__desktop{
            &__div{
                display: block;
            }
        }
        align-items: center;
        padding: 1.6rem;
    }
    @include media-up(lg){
        &__desktop{
            &__div{
                display: block;
            }
        }
    }
    @include media-down(md) {
        &__desktop{
            &__div{
                display: none;
            }
        }
        > :last-child {
            width: 100%;
        }
    }

    ul {
        list-style-type: none;
        @include media-up(md) {
            padding-left: 0;
        }
    }

    &__ctn {
        display: flex;

        @include media-up(md) {
            .ob-full-width-nav & {
                margin: 0 auto;
            }
        }

        @include media-up(lg) {
            .ob-header--ja-jp & {
                width: 72%;
            }
        }

        @include media-up(xlg) {
            .ob-header--ja-jp & {
                width: 60%;
            }
        }
    }

    &__return-btn {
        padding-left: 2rem;
        @include media-up(md) {
            display: none;
        }
        
        svg{
            fill:$base-color-dark-blue !important;
        }
    }

    [data-is-language-selector="true"], [data-is-login-navigation-block="true"] {
        @include media-up(md) {
            display: none;
        }
    }

    &--desktop {
        display: none;
        @include media-up(md) {
            display: flex;
        }
    }

    &--mobile {
        @include media-up(md) {
            display: none;
        }

        .ob-navigation-login,
        .ob-navigation-register {
            display: block;
        }
    }

    &__logo {
        &--desktop {
            display: none;
            @include media-up(md) {
                display: block;
                margin-right: 3rem;
            }
        }

        &--mobile {
            padding-left: 2rem;
            @include media-up(md) {
                display: none;
            }
        }

        .ob-mainAsset-wrapper {
            width: 76px;
            display: flex;
            img {
                max-width: 100%;
                width: 100%;
                height: 100%;
            }
            @include media-up(md) {
                width: 115px;
                height: 40px;

                .ob-header--ja-jp & {
                    width: 105px;
                    height: auto;
                }
            }
        }

        a {
            display: block;

            &:focus {
                outline: 1px solid $base-color-dark-blue;
            }
        }

        span {
            position: absolute;
            clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
            clip: rect(1px, 1px, 1px, 1px);
            padding:0;
            border:0;
            height: 1px;
            width: 1px;
            overflow: hidden;
        }
        &--ctn {
            width: 100%;
            display: flex;
        }

    }

    &__back-btn + &__logo--link {
        margin: 0 auto;
    }

    &__open-ctn {
        padding-right: 2rem;
        @include media-up(md) {
            display: none;
        }

        button {
            &:focus {
                @include focusOutline();
            }

            span > svg{
                fill:$base-color-dark-blue !important;
            }
        }

    }
    .ob-menu-collapsible {
        overflow: auto !important;
    }

    &__list {
        padding: 0;
        margin: 0;
        width: 100%;
        background: $base-color__white;
        z-index: map-get($zindex, menuList);
        transition: all 0.35s ease;

        @include media-down(md) {
            opacity: 0;
        }

        @include media-up(md) {
            display: flex;
            position: static;
            background: none;
            opacity: 1;
            visibility: visible;
            pointer-events: initial;
            width: auto;
            height: 100%;
            max-width: 74rem;
            align-items: center;

            .ob-full-width-nav & {
                max-width: 100%;
            }
        }

        @include media-up(lg) {
            max-width: 90rem;

            .ob-full-width-nav & {
                max-width: none;
            }

            .ob-header--ja-jp & {
                width: 100%;
            }
        }

        &.is-subnav-active {
            overflow: hidden;
        }

        &--item {
            margin: 10px 0;
            list-style-type: none;
            background: $base-color-secondary-off-white;
            padding: 2rem 0;
            font-size: 1.3rem;
            @include font-family('semi-bold');
            &[data-is-logo="true"] {
                display: none;
            }

            @include media-up(md) {
                padding: 2rem;
                button {
                    text-align: left;
                }

                .ob-main-menu__subNav--btn-title, a span {
                    line-height: 1.4;
                    font-weight: 800;
                    color: $base-color-dark-blue;
                }
            }

            &:not(.no-img):not(.ob-main-menu__search--ctn) {
                @include media-down(md) {
                    height: auto;

                    > a {
                        > span, .ob-mainAsset-wrapper {
                            text-align: center;
                            width: 50%;
                        }
                        >span {
                            text-align: left;
                        }
                    }

                    .ob-main-menu--mobile {
                        height: 100%;

                        > div, .ob-menu-collapsible, button {
                            height: 100%;
                        }
                    }
                }
            }

            &.ob-main-menu__search--ctn {
                padding-left: 90px;
                @include media-up(md) {
                    .ob-full-width-nav & {
                        padding-left: 0;
                        margin-right: 0;
                    }
                }

                > a {
                    display: block;

                    &:focus {
                        outline: 1px solid $base-color-dark-blue;
                    }
                }

                > a > span {
                    line-height: 1.15;

                    @include media-up(md) {
                        @include visuallyHidden();
                    }
                }

                > a div {
                    @include media-up(md) {
                        display: block;
                        padding-top: 10px;
                    }

                    @include media-down(md) {
                        display: none;
                    }
                }
            }

            > a {
                position: relative;
                height: 40px;
                display: flex;
                align-items: center;
                @include media-down(md) {
                    justify-content: space-between;
                    letter-spacing: 0.5px;
                    height: 100%;

                    .ob-mainAsset-wrapper {
                        img {
                            max-height: 240px;
                        }
                    }
                }
                > div {
                    @include media-up(md) {
                        display: none;
                    }
                }
            }

            &.no-img {
                font-size: 1.4rem;
            }

            &:not(.no-img) {
                > a {
                    @include media-down(md) {
                        justify-content: space-around;
                    }
                }
            }

            &.no-img, &.ob-main-menu__search--ctn {
                @include media-down(md) {
                    background: none;
                    border-top: 1px solid $base-color-secondary-grey;
                    padding: 1rem 2rem 0;
                    a, .ob-main-menu__subNav--btn-title {
                        color: $base-color-primary-grey;
                        font-weight: 400;
                    }

                    .ob-main-menu__link--name {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .ob-main-menu__subNav--btn, .event_button_click {
                        width: 100%;
                        > span {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            top: 1px;
                            position: relative;
                            .ob-main-menu__subNav--btn-icon {
                                top: -2px;
                                position: relative;
                            }
                        }
                    }

                    &:last-child {
                        padding: 1rem 2rem;
                        border-bottom: 1px solid $base-color-secondary-grey;
                    }
                }
            }


            @include media-up(md) {
                margin: 0 5px;
                padding: 0;
                background: none;
                max-width: 20%;

                .ob-full-width-nav & {
                    max-width: 100%;
                    margin: 0 8px;
                }

                .link-button {
                    @include media-up(md) {
                        height: 40px;
                    }
                    > span {
                        display: flex;
                        align-items: center;
                    }
                }

                >a,
                .link-button {
                    text-decoration: none;
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: -1.55rem;
                        left: 50%;
                        height: 4px;
                        width: 0;
                        opacity: .6;
                        background: transparent;
                        transition: width .4s ease, background-color .4s ease, left .4s ease;
                    }

                    &:hover,
                    &:focus,
                    &.is-active {

                        &:after {
                            width: 100%;
                            left: 0;
                            background: $base-color-dark-blue;
                            opacity: 1;
                        }

                        &:focus {
                            outline: none;

                            >span {
                                outline: none;
                            }
                        }
                    }

                    &.is-active {
                        &:after {
                            opacity: 1;
                        }
                    }

                }

            }

            @include media-up(lg) {
                margin: 0 25px;

                .ob-full-width-nav & {
                    max-width: 25%;
                    margin: 0 11px;
                    font-size: 1.2rem;
                }
            }
        }

    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &--name {
            width: 50%;
            text-align: left;
        }

        .ob-icon {
            margin-left: 1rem;
        }
    }

    &__subNav {

        &--btn {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            position: relative;
            letter-spacing: 0.5px;
            width: 100%;
            @include media-up(md) {
                width: auto;
                flex-wrap: nowrap;
            }
        }

        &--btn-title {
            margin-right: 0.5rem;
        }

        &--btn-ctn {
            display: flex;
            align-items: center;
            text-align: left;
            width: 100%;
            justify-content: space-around;

            @include media-down(md) {
                height: 100%;
                min-height: 100%;

                .ob-main-menu__subNav--btn-img, .ob-main-menu__subNav--btn-content {
                    width: 50%;
                    text-align: center;
                }

                .ob-main-menu__subNav--btn-content {
                    display: flex;
                    justify-content: space-between;
                    height: 100%;

                    .ob-main-menu__subNav--btn-icon {
                        @include media-down(md) {
                            top: -2px;
                            position: relative;
                        }
                        svg{
                            fill:$base-color-dark-blue !important;
                        }
                    }
                }

                .ob-main-menu__subNav--btn-title {
                    text-align: left;
                    padding-left: 0.5rem;
                }

                .ob-main-menu__subNav--btn-img {
                    height: 100%;
                    text-align: center;

                    .ob-mainAsset-wrapper {
                        max-height: 260px;
                        img {
                            max-width: 100%;
                            max-height: 255px;
                        }
                    }
                }
            }
        }

        &--ctn {
            position: absolute;
            background: $base-color__white;
            width: 100vw;
            max-width: 100vw;
            top: 6.8rem;
            left: 0;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            padding: 0;
            transform: translateX(-100%);
            transition: all 0.35s ease;

            .ob-main-menu__subNav-list {
                position: relative;

                @include media-up(md) {
                    max-width: 116.5rem;
                }

                &.is-active {
                    width: 80%;
                    @include media-up(md) {
                        width: 97%;
                    }
                    display: flex;
                    justify-content: center;
                }

                &#Hierarchy---More-Products {
                    .ob-sub-navigation {
                        a > span {
                            @include media-down(md) {
                                white-space: nowrap;
                            }
                        }
                        @include media-up(md) {
                            width: 30%;
                            max-width: 410px;
                            &:first-child {
                                width: 70%;
                                max-width: 1000px;
                                .ob-sub-navigation__title {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

                &#Hierarchy---More-Products, &#Hierarchy---Learn {
                    @include media-down(md) {
                        top: 5.7rem;
                    }

                    .ob-sub-navigation {
                        @include media-up(md) {
                            margin-top: 0.4rem;
                        }
                        &__title {
                            @include media-up(md) {
                                padding-left: 2.5rem;
                            }
                            @include media-up(lg) {
                                margin: 0 0 1rem;
                            }
                        }
                    }
                }

                &#Hierarchy---Manual-Toothbrush{
                    a > span {
                        @include media-up(md) {
                            line-height: 1.5;
                            font-size: 2rem;
                        }
                    }
                    .ob-main-menu__subNav--close-btn{
                        right:-70px;
                    }
                    .ob-sub-navigation a > span{
                        margin: 4rem -1.5rem 0px -2.5rem
                    }
                    @media screen and (min-width: 992px) {
                        width:100%;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        padding-bottom: 25px;
                        .ob-main-menu__subNav--close-btn{
                            right:40px;
                        }
                    }
                }

                &#Hierarchy---Electric-toothbrushes {
                    @include media-up(md) {
                        max-width: calc(123.6rem - 71px);
                        flex-wrap: nowrap;
                    }
                    .ob-sub-navigation {
                        @include media-ie() {
                            flex: 0 1 33.33%;
                            padding: 0 1rem;
                        }
                        a > span {
                            @include media-up(md) {
                                margin: 4rem -3.5rem 0px -2.5rem;
                                text-align: center;
                                line-height: 1.5;
                                font-size: 2rem;
                            }
                        }
                    }
                }
                &#Hierarchy---Toothpaste{
                    .ob-sub-navigation {
                        a > span {
                            @include media-up(md) {
                                margin: 3rem 0.5rem 0;
                                line-height: 1.5;
                                font-size: 2rem;
                            }
                        }
                        .ob-mainAsset-wrapper{
                            @include media-up(md) {
                                width: 58%;
                                display: flex;
                                justify-content: center;
                                img {
                                    max-height: 40rem;
                                    max-width: 40rem;
                                }
                            }

                        }      
                    }
                }
                &#Hierarchy---Learn {
                    @include media-up(md) {
                    .ob-sub-navigation a > span{
                        margin: auto .5rem 0 0.5rem;
                    }
                    .no-img a {
                            padding: 1.5rem 2rem 1rem;
                        }
                }
                }
                .replacement-brush-heads{
                    @include media-up(lg){
                        img{
                            margin: -42px 0px 42px;
                        }
                    }
                }
            }

            @include media-up(md) {
                background: $base-color-secondary-off-white;
                position: absolute;
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                justify-content: space-around;
                top: 7.2rem;
                left: 50%;
                transform: translateX(-50%);
                transition: opacity 0.35s ease, visibility 0.35s ease;
                padding: 4rem;
                @include media-up(xlg) {
                    justify-content: center;
                }
            }

            a {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;

                @include media-up(md) {
                    height: 100%;
                }
            }

            &.is-active {
                opacity: 1;
                visibility: visible;
                pointer-events: initial;
                height: 100vh;
                max-height: 100vh;
                overflow: auto;
                z-index: map-get($zindex, activeMenuSubNav);
                transform: translateX(0);
                a {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: initial;
                }
                @include media-up(md) {
                    max-height: none;
                    left: 50%;
                    height: calc(100vh - 115px);
                    transform: translateX(-50%);
                }
            }

            .ob-main-menu__list {
                position: static;
                visibility: visible;
                pointer-events: inherit;
                opacity: 1;

                &--item {
                    margin-top: 4rem;
                }
            }

        }

        &--close-btn {
            display: none;
            position: absolute;
            bottom: auto;
            left: auto;
            right: 0;
            top: 0;
            z-index: map-get($zindex, menuSubNavCloseBtn);

            @include media-up(md) {
                display: block;
            }

            button {
                transition: transform ease 0.35s;
                &:hover, &:focus {
                    transform: scale(1.3)
                }

                &:focus {
                    outline: 1px solid $base-color-dark-blue;
                }
            }
        }
    }

    .ob-navigation-login,
    .ob-navigation-register {
        display: none;
    }

    .hidden--md-up {
        @include media-up(md) {
            display: none;
        }
    }
}

.ob-sub-navigation {
    .ob-mainAsset-wrapper {
        @include media-down(md) {
            img {
                display: block;
                margin: 0 auto;
                max-height: 24.3rem;
                @media screen and (max-width: 750px){
                    max-height: 13.5rem !important;
                }
            }
        }
        @include media-up(md) {
            width: 58%;
            display: flex;
            justify-content: center;
            img {
                max-height: 50rem;
                max-width: 50rem;
            }
        }
        @include media-ie() {
            width: 58%;
            display: flex;
            justify-content: center;
            img {
                max-height: 17rem;
                max-width: 17rem;
            }
        }
    }
    @include media-up(md) {
        margin-top: 0;
        border-right: 1px solid transparentize($base-color-primary-grey, 0.9);
        padding: 0 2.5rem;
        &:nth-last-of-type(2) {
            border-right: none;
        }
        &:first-of-type {
            padding-left: 0;
        }
    }

    a {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        span {
            @include media-down(md) {
                color: $base-color-dark-blue;
                padding-right: 1rem;

                .zone-header--lower-padding-md-down & {
                    padding-right: 0.2rem;
                }
            }
        }
        @include media-up(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            font-size: 1.4rem;
            flex-direction: column;
            padding: 0 30px;
            color: $base-color-primary-grey;

            > :first-child {
                width: 100%;
            }
        }

        svg {
            margin: -0.3rem 1rem 0 0.5rem;
            @include media-down(md) {
                width: 1.2rem;
                height: 1.2rem;
                fill: $base-color-dark-blue;

                .zone-header--lower-margin-md-down & {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }

        > span {

            justify-self: flex-end;
            @include media-down(md) {
                justify-self: flex-start;
            }
            @include media-up(md) {
                margin: auto .5rem 0 2.5rem;
            }
        }
    }

    &__title {
        @include display-3-xl();
        @include media-up(md) {
            text-align: left;
        }
        @include media-up(lg) {           
            font-size: 2rem;
            margin: 1rem 0; 
            line-height: 1.5;

            .ob-main-menu--smaller-sub-nav-title & {
                font-size: 3rem;
            }
        }
        @include media-down(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid $base-color-secondary-grey;
            padding: 1rem 3rem;
            @include font-family('hurme-light');
            &:hover, &:focus {
                text-decoration: none;
            }
        }

        &--desktop {
            display: none;
            @include media-up(md) {
                display: block;
                color: $base-color-dark-blue;
                font-weight: 600;
            }
        }

        &--mobile {
            &:focus {
                text-decoration: none;
            }
            @include media-up(md) {
                display: none;
            }
        }
    }

    &__list {
        padding: 0;
        overflow: hidden;

        li {
            padding: 1rem 0;
            border-bottom: 1px solid $base-color-secondary-grey;
            @include media-up(md) {
                padding: 0.2rem 0;
                border-bottom: none;
            }

            a {
                display: flex;
                justify-content: space-between;
                @include media-down(md) {
                    padding: 0 1rem 0 6rem;
                }
            }

            &:not(.no-img) {
                a {
                    @include media-down(md) {
                        padding: 0;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        justify-items: center;
                    }
                }
            }
            .ob-icon {
                width: 0.6rem;
                @include media-up(md) {
                    width: 1.8rem;
                    position: relative;
                    bottom: 2px;
                }
            }

            &.no-img {
                @include media-down(md) {
                    a {

                        > span {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .ob-main-menu__list:not(#Hierarchy---More-Products---By-Type) {
            @include media-up(md) {
                display: block;
            }
        }

        #Hierarchy---More-Products---By-Type {
            @include media-up(md) {
                display: grid;
                grid-template-columns: repeat(1, 1fr);

                li {
                    padding-top: 2rem;
                    height: 100%;
                    border-right: 1px solid transparentize($base-color-primary-grey, 0.9);
                    border-bottom: 1px solid transparentize($base-color-primary-grey, 0.9);
                    &:nth-child(2), &:nth-child(4) {
                        border-right: none;
                    }
                    &:nth-child(n+3) {
                        border-bottom: none;

                        span {
                            margin-top: 3.5rem;
                        }
                    }
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    a span {
                        width: 100%;
                        text-align: center;
                        margin: 1rem 0.5rem 0.5rem;
                    }
                }
                .ob-mainAsset-wrapper {
                    @include media-up(md) {
                        img {
                            max-height: 22rem;
                        }
                    }
                }
            }
            @include media-ie() {
                display: flex;
                flex-wrap: wrap;
                max-width: 100%;
                width: 100%;
                li {
                    height: 300px;
                    width: 50%;
                    >a {
                        width: 100%;
                        max-width: 100%;
                        img {
                            max-width: 215px;
                            width: auto;
                        }
                    }
                }
            }
        }
        .ob-sub-navigation--item.no-img {
            a {
                @include media-down(md) {
                    padding: 0 3rem;
                    font-size: 1.4rem;

                    &:focus {
                        span {
                            color: $base-color-dark-blue;
                            text-decoration: none;
                        }
                    }
                }
                span {
                    @include media-down(md) {
                        width: 100%;
                        color: $base-color-dark-blue;
                    }
                }

                svg {
                    @include media-down(md) {
                        float: right;
                        fill: $base-color-dark-blue;
                    }
                }
            }
        }
    }


    .no-img {
        width: 100%;
        @include media-up(md) {
            a {
                padding: 1.5rem 0 1rem;
                color: $base-color-primary-grey;
                transition: color ease 0.35s, background-color ease 0.35s;

                &:hover,&:focus {
                    background: $base-color-dark-blue;
                    color: $base-color__white;
                    text-decoration: none;
                }
            }
        }
    }

    @include media-up(md) {
        .see-all a>span {
            position: relative;
            white-space: nowrap;

            &::before {
                content: "\e915";
                font-family: 'icomoon', sans-serif;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                width: 1.6rem;
                height: 1.6rem;
                border-radius: 50%;
                font-size: .4rem;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-color: $base-color-dark-blue;
                color: $base-color__white;
                position: relative;
                top: -3px;
                margin-right: .8rem;
                padding-left: 1px;
                box-sizing: border-box;
                transition: background-color .4s ease, color .4s ease;
            }
        }

        .see-all a:hover {
            text-decoration: none;

            >span::before {
                background-color: $base-color__white;
                color: $base-color-dark-blue;
            }

            >span>span {
                text-decoration: underline !important;
            }
        }
    }
}

.zone-header--it-it {
    #Hierarchy---Toothpaste > li:nth-child(1) > a > span {
        @include media-down(md) {
            padding-right: 0;
        }
    }

    #Hierarchy---Electric-toothbrushes > li:nth-child(3) > a > span {
        @include media-down(md) {
            padding-right: 1.1rem;
        }
    }
}

.zone-header--sv-se {
    #Hierarchy---Electric-toothbrushes > li:nth-child(3) > a > span {
        @include media-down(md) {
            padding-right: 1.2rem;
        }
    }
}
.ob-manual-toothbrush-menu{
    .ob-sub-navigation{
        .ob-mainAsset-wrapper{
            height: 80%;
            @include media-up(md) {
                img {
                    height: 39rem !important;
                    max-height: 50rem !important;
                    max-width: 28rem !important;
                }
            }
        }
    }
}
.ob-toothpaste-menu{
    .ob-sub-navigation{
        .ob-mainAsset-wrapper{
            height: 38rem;
            @media screen and (max-width: 720px){
                height: 16rem;
            }
        }
    }
    .ob-saude-toothpaste{
        a > span{
            margin: auto 5.5rem 0 2.5rem !important;
            @media screen and (max-width: 720px){
                margin: auto 0 !important;
            }
            @media only screen and (device-width: 768px) 
            and (device-height: 1024px) {
                margin: auto 0 !important;
            }
        }

    }
}
.ob-electric-toothbrush-menu{
    .ob-sub-navigation {
        height: 32% !important;
        .ob-mainAsset-wrapper {
            @include media-up(md) {
                img {
                    max-height: 50rem !important;
                }
            }
        }
    }
    .ob-main-menu__list{
        @include media-down(sm) {
            height: calc(530px);
        }
        @media screen and (max-width: 320px){
            height: calc(465px) !important;
        }
    }
}
.ob-toothpaste-menu{
    .ob-sub-navigation {
        a{
            > span {
                @include media-up(md) {
                    margin: 1.5rem auto 0 !important;
                }
            }
        }
    }
}
.ob-main-menu__list--item,.ob-main-menu__logo{
    .link-button:focus-visible,.event_menu_click:focus-visible{
        border: 2px solid $base-color-link-blue ;
    }
}
.link-button,.ob-main-menu__search--ctn,.ob-mini-nav,.ob-sub-navigation,.ob-main-menu__logo--desktop,.ob-main-menu__logo--mobile{
    a:focus-visible{
        border: 2px solid $base-color-link-blue ;
    }
}
.ob-mini-nav{
    a:focus-visible{
        text-decoration: none;
    }
}
.ob-sub-navigation__title--mobile:focus{
    border: 2px solid $base-color-link-blue ;
}

// Global Equity
#Hierarchy---More-Products{
    li:nth-child(2).ob-sub-navigation.no-img{
        .ob-main-menu--mobile{
            .ob-sub-navigation__list{
                li.ob-sub-navigation--item.no-img{
                    a span{
                        @include font-family('hurme-regular');
                        color: $base-color__black;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

.ob-main-menu-learn-section{
    .ob-main-menu--mobile{
        .ob-sub-navigation__list{
            li.ob-sub-navigation--item.no-img{
                a span{
                    @include font-family('hurme-regular');
                    color: $base-color__black;
                    font-weight: 400;
                }
            }
        }
    }
}

@media(max-width: 767.98px) {
    .main-menu.header-main-menu{
        .ob-main-menu--mobile{
            .ob-icon{
                fill: $base-color-dark-blue !important;
            }
        }
    }

    #Hierarchy---Electric-toothbrushes {
        li:nth-child(3).ob-sub-navigation{
            img{
                width: 80%;
                @include media-down(sm){
                    width:auto;
                }
            }
        }
    }

    #Hierarchy---Toothpaste{
        li:nth-child(4).ob-sub-navigation{
            img{
                width: 80%;
                @include media-down(sm){
                    width:auto;
                }
            }
        }
    }

    .ob-main-menu__list.ob-main-menu__subNav--ctn{
        .ob-sub-navigation{
            a > span{
                font-weight: 400;
            }
        }
    }

    #Hierarchy---Toothpaste{
        li.ob-sub-navigation{
            a svg{
                margin: 0;
            }
        }
    }
}

#Hierarchy---Learn {
    @include media-up(md) {
        max-width: 130rem;
        width: 100%;
        .ob-sub-navigation {
            a{
                span{
                    flex-direction: unset;
                }
            }
        }
    }
}

#Hierarchy---Electric-toothbrushes {
    @include media-up(md) {
        width: 100%;
        li.ob-sub-navigation{
            padding: 0 0.5rem;
        }
        li:nth-child(4).ob-sub-navigation{
            white-space: nowrap;
            padding: 0 3rem;
        }
    }
}
.ob-main-menu__list--item a{
    &:hover{
        span {
            @include media-up(md) {
            color: $base-color__white;
            }        
        }
    }
    &:focus{
        span{
            @include media-up(md) {
                color: $base-color__white;
                }
        }
    }
    &:active{
        span{
            @include media-up(md) {
                color: $base-color__white;
                }
        }
    }
}
.ob-main-menu{
    @include media-down(sm) {
        padding: 2.3rem 0;
    }
    &__subNav--btn-ctn{
        @include media-down(sm) {
            padding: 0 10px
        }
    }
    &__subNav--close-btn{
        @include media-up(md) {
            right: -12px;
            }
    }
    &__list--item{
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            a{
                &:hover {
                    span  {
                        color: $base-color-dark-blue !important ;
                    }
                }
                &:active {
                    span  {
                        color: $base-color-dark-blue !important ;
                    }
                }
                &:focus {
                    span  {
                        color: $base-color-dark-blue !important ;
                    }
                }
            }
        }
    }
}
#Hierarchy---More-Products---By-Type {
    li {
        border-right: unset !important;
    a  {
        flex-direction: column-reverse;
        &:hover{
            span{
                color: $base-color-dark-blue;
            }
        }
        &:active{
            span{
                color: $base-color-dark-blue;
            }
        }
        &:focus{
            span{
                color: $base-color-dark-blue;
            }
        }
    }
  } 
}
#Hierarchy---Electric-toothbrushes{
    .ob-main-menu{
        &__subNav--ctn{
            &.is-active{
                a{
                    @include media-up(md) {
                    text-align: center;
                    }
                }
            }
        }
    }
}
#Hierarchy---Main-Menu{
    li{
        &:nth-child(2), &:nth-child(3),&:nth-child(4){
            .ob-main-menu{
                &__subNav--ctn{
                    &.is-active{
                        a{
                            @include media-up(md) {
                            text-align: center;    
                            flex-direction: column-reverse;
                            flex-wrap: unset;
                            }
                            span{
                                svg{
                                    @include media-up(md) {
                                    display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &:nth-child(5){
            .ob-main-menu{
                &__subNav--ctn{
                    &.is-active{
                        a{
                            @include media-up(md) {
                            flex-direction: column-reverse;
                            flex-wrap: unset;
                            }
                            .ob-mainAsset-wrapper {
                                @include media-up(md) {
                                order: 1;
                                }
                            }
                         span{
                            svg{
                                @include media-up(md) {
                                margin: 0;
                                }
                            }
                         }
                        }
                    }
                }
            }
            .ob-sub-navigation{
                @include media-up(md) {
                    padding: 0 30px;
                }
                &:nth-child(3){
                    @include media-up(md) {
                        padding: 0 10px;
                    }
                }
                &__list{
		   #Hierarchy---Learn---Articles{
                        li{
                            &:nth-child(1) {
                                a{
                                    @include media-up(md) {
                                        align-items: flex-start;
                                    }
                                    span{
                                        @include media-up(md) {
                                         max-width: 180px;
                                         margin-left: 1px;
                                        }
                                    }
                                }                              
                            }
                            &:nth-child(3) {
                                a{
                                    @include media-up(md) {
                                        align-items: flex-start;
                                    }
                                    span{
                                        @include media-up(md) {
                                         max-width: 168px;
                                         margin-left: 3px;
                                        }
                                    }
                                }                              
                            }
                        }
                    }
                    #Hierarchy---More-Products---By-Type{
                        li{
                            &:nth-child(n+2),&:nth-child(n+3) {
                                span{
                                    @include media-up(md) {
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }       
    }
}
.ob-sub-navigation{
    a > span{
        @include media-up(md) {
            margin: auto 0.5rem 0 1.5rem;
          }
    }
}
#Hierarchy---More-Products-And-Information{
    .ob-sub-navigation{
        &__title{
            span{
                @include media-down(sm) {
                    text-align: left;
                }
            }
        }
    }
}
.ob-main-menu--mobile {
    .ob-main-menu__list {
        .ob-main-menu__subNav--ctn {
            &.is-active {
                .ob-sub-navigation:nth-child(4) {
                    a>.ob-mainAsset-wrapper+span {
                        @include media-down(sm) {
                            max-width: 150px;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
.ob-main-menu__subNav--ctn{
    .ob-main-menu__subNav-list{
        &#Hierarchy---Toothpaste{
            @include media-up(md) {
                padding-bottom: 25px;
            }
        }
    }
}
.ob-sub-navigation__title{
    @include media-down(sm) {
       text-align: left;
    }
}
.ob-login-navigation--mobile, .pre-purchase-nav-link--mobile  {
    @include media-up(md){
        display: none;
    }
}