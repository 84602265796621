@import "../../static/styles/mixins";

.ob-pypestream {

  &-chat-container {
    bottom: 0;
    height: 100% !important;
    pointer-events: none;
    position: fixed;
    right: 0;
    z-index: map-get($zindex, modal);

    iframe {
      pointer-events: auto;
    }
  }

  &-modal {
    position: relative;

    &-content {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      padding: 1.5rem;

      &-button-container {
        margin-top: auto;
        width: 100%;
      }

      @include media-up(sm) {
        padding: 4.5rem 4rem 3rem;
      }
    }

    &-copy {
      color: $base-color__white;
      padding-right: 6.5rem;
      width: 100%;

      p {
        @include font-family('hurme-bold');
        font-size: 1.7rem;
        line-height: 2.1rem;
        margin: 0;
      }

      @include media-up(sm) {
        padding-right: 12rem;
        p {
          font-size: 3.6rem;
          line-height: 4.3rem;
        }
      }

    }

    &-button {
      @include font-family('hurme-bold');
      background-color: #0075c9;
      box-shadow: 2px 2px 6px rgba(43,43,43,0.13);
      border-radius: 4px;
      color: $base-color__white;
      font-size: 1.2rem;
      line-height: 2.4rem;
      padding: 3px 11px;
      transition: all 0.3s ease-in-out;

      &:not(:first-child) {
        margin-left: 0.7rem;
      }

      &:hover,
      &:focus {
        background-color: #00365c;
        text-decoration: none;
        border-style: solid;
        border-color: red;
      }

      &-container {
        display: flex;
        margin-top: 0.8rem;
        width: 100%;
      }

      @include media-up(sm) {
        font-size: 1.5rem;
        line-height: 3.1rem;

        &-container {
          margin-top: 1.8rem;
        }
      }
    }

    .ob-background-wrapper {
      min-height: 0;
      padding-top: 0! important;
    }

    @include media-down(sm) {
      margin: 0 auto;
      max-width: 27rem;
      width: 100%;
    }
  }

  &-toggle-button {
    background: none;
    box-shadow: none;
    bottom: 50px;
    height: 70px;
    left: auto;
    margin: 0;
    position: fixed;
    right: 8px;
    top: auto;
    transform: translateZ(0);
    transition: all 0.3s ease-in-out;
    width: 70px;
    z-index: map-get($zindex, persistentChatToggle);

    img {
      height: auto;
      max-width: 100%;
    }
    img:focus{
      border-style: solid;
      border-color: red;
    }
    &:hover,
    &:focus {
      transform: translateZ(0) scale(1.1);
    }
  }
}