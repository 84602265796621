@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-app-download-backdrop-wrapper {
    display: flex;
    flex-direction: column;
}

.ob-app-download-main-wrapper {
    flex: none;
    margin: auto 0;
    height: 100%;
}

.ob-app-download.ob-app-download__coupons--fr-fr {
    .ob-disclaimer {
        margin-left: 0;
    }
}

.ob-app-download-content {
    margin-top: -60px;
    
    [data-horizontal-alignment="right"] & {
        float: right;
    }
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    .ob-app-download__rbh-io-exclusive & {
        @include media-down(sm) {
            display: block;
        }
    }

    [data-has-disclaimer="true"] & {
        height: 100%;
        @include media-up(sm) {
            height: 85%;
            margin-bottom: 3rem;
        }
        @include media-up(md) {
            height: 90%;
        }
    }

    .app-download-hero-block & {
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
    }

    .ob-app-download__magic-timer & {
        @include media-up(sm) {
            width: 62%;
        }

        @include media-up(md) {
            width: 50%;
        }

        @include media-down(sm) {
            display: block;
            text-align: left;
        }
    }

    .ob-app-download__home & {
        @include media-down(sm) {
            height: auto;
            width: 55%;
            text-align: left;
            justify-content: inherit;
            align-self: center;
        }
    }


    @include media-up(sm) {
        width: 50%;
        text-align: left;

        .ob-side-by-side__column & {
            width: 80%;
            min-width: 340px;
            margin: auto;
        }

        .ob-app-download__coupons & {
            width: 100%;
            min-width: 0;
        }
    }


    @include media-up(md) {
        .ob-side-by-side__column & {
            min-width: 450px;
        }
    }

    [data-vertical-alignment="bottom"] & {
        justify-content: flex-end;
    }

    [data-vertical-alignment="top"] & {
        justify-content: flex-start;
    }

    &-wrapper {
        position: relative;
        @include container-wrapper;
        height: 100%;

        @include media-up(sm) {
            padding: 3rem;

            .app-download-hero-block & {
                padding: 9rem 3rem;
            }
        }

        @include media-up(md) {
            padding: 4rem;

            .app-download-hero-block & {
                padding: 12rem 4rem 8rem;
            }
        }

        @include media-down(xs) {
            padding: 0 1rem;

            .ob-app-download__magic-timer & {
                width: 90%;
            }
        }

        @include media-down(sm) {
            padding: 0 2rem;

            .app-download-hero-block & {
                padding: 0 5.2rem;
            }

            .ob-app-download__magic-timer & {
                width: 90%;
            }
        }

        [data-has-disclaimer="true"] & {
            @include media-up(lg) {
                min-height: 700px;
            }
        }

        .ob-app-download__home & {
            @include media-down(sm) {
                display: flex;
            }
        }

        .no-spacing & {
            padding: 3rem 2rem;

            @include media-up(md) {
                padding: 3rem 4rem;
            }
        }

        .no-spacing .ob-app-download-backdrop--de-de & {
            @include media-up(lg) {
                padding: 3rem 1rem;
            }
        }

        .no-spacing .ob-app-download__kids--ja-jp & {
            @include media-up(lg) {
                padding: 5rem 3rem;
            }
        }

        .ob-app-download__coupons & {
            @media screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-large) {
                padding: 2rem;
            }
        }
    }
}

.ob-app-download-eyebrow {
    @include eyebrow-1-xl;
    margin: rem(16) 0;

    @media screen and (min-width: 768px) and (max-width: 991px) {
        width:50%    
    }

    &.white-text {
        color: white;
    }

    .app-download-hero-block & {
        color: $base-color-dark-blue;
        margin-bottom: 0;

        @include media-down(sm) {
            margin-top: 3.1rem;
        }

        @include media-up(md) {
            font-size: rem(16);
            margin-top: 0;
        }
    }

    .ob-app-download__magic-timer & {
        @include media-down(md) {
            margin-top: 5rem;
        }
    }

    .ob-app-download__home & {
        @include media-down(sm) {
            margin-top: 2rem;
            margin-bottom: 0;
        }
    }

    .no-spacing & {
        margin: 0.5rem 0;
    }

    .ob-app-download--bg-white-sm-up & {
        @include media-up(sm) {
            margin: 0;
            padding: 0.5rem 0;
            background: rgba($base-color__white, 0.7);
        }
        @include media-up(xlg) {
            margin: 0.5rem 0;
            padding: 0;
            background: none;
        }
    }

    .ob-app-download--bg-white-sm-down & {
        @include media-down(sm) {
            margin: 0;
            padding: 0.5rem 0;
            background: rgba($base-color__white, 0.7);
        }
    }
}

.ob-app-download-heading {

    @media screen and (min-width: 768px) and (max-width: 991px) {
        width:50%    
    }
    
    .ob-app-download & {
        @include display-2-xl;
        margin: rem(16) 0;
        width: 100%;
    }

    .ob-app-download__coupons & {
        @include media-up(lg) {
            font-size: 4.6rem;
            line-height: 5.4rem;
        }
        @media screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-large) {
            font-size: 2.5rem;
            line-height: normal;
        }
    }

    .ob-app-download-backdrop--de-de.ob-app-download__coupons & {
        @include media-up(lg) {
            font-size: 4.2rem;
            line-height: 5rem;
        }
    }

    .white-title & {
        color: $base-color__white;
    }

    &[data-white-text="true"] {
        color: white;
    }

    .ob-app-download-spotlight & {
        @include media-up(sm) {
            @include font-family('hurme-regular');
            font-size: rem(32);
            line-height: rem(40);
            letter-spacing: 0.5px;
            strong {
                @include font-family('semi-bold');
                letter-spacing: normal;
            }
        }

        @include media-down(md) {
            flex: 1 1 75%;
        }

        @include media-down(xs) {
            flex: 1 1 50%;
        }
    }

    .app-download-hero-block & {
        @include media-down(sm) {
            flex: 0;
            margin-top: rem(18);
        }

        @include media-up(md) {
            font-size: rem(56);
            line-height: 1.125;
            margin-bottom: rem(12);
        }

        @include media-up(lg) {
            font-size: rem(64);
        }
    }

    @include media-down(sm) {
        flex: 1 1 65%;
    }

    .ob-app-download__home & {
        @include media-down(sm) {
            flex: 0;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .ob-app-download__home--nl-nl & {
        @include media-up(lg) {
            font-size: 4.6rem;
            line-height: 5.4rem;
        }
    }

    .no-spacing & {
        margin: 0;
        padding: 0;

        @include media-down(sm) {
            flex: 0;
        }
    }

    .ob-app-download--bg-white-sm-up & {
        @include media-up(sm) {
            background: rgba($base-color__white, 0.7);
        }

        @include media-up(xlg) {
            background: none;
        }
    }

    .ob-app-download--bg-white-sm-down & {
        @include media-down(sm) {
            background: rgba($base-color__white, 0.7);
        }
    }
}

.ob-app-download-spotlight {
    .ob-app-download-content {
        margin-top: 3rem;

        @include media-down(sm) {
            width: 90%;
            float: none;
            margin: 3rem auto 0;
        }

        @include media-up(xlg) {
            width: 38%;
            text-align: left;
            position: relative;
            right: 110px;
        }
    }

    .ob-app-download__mainAssetContainer {
        @include media-down(md) {
            flex: 1 1 55%;
        }
    }

    .ob-app-download-stores {
        @include media-down(md) {
            flex: 1 1 10%;
        }
    }
}

.ob-app-download-divider {
    display: block;
    background-color: $base-color-secondary-grey;
    width: 100%;
    height: 1px;
    margin: 3rem 0;

    @include media-down(md) {
        display: none;
    }
}

.ob-app-download-body {
    font-size: 2.2rem;
    letter-spacing: 0.5px;
    color: $base-color-primary-grey;
    margin-bottom: 4rem;

    .white-text & {
        color: white;
    }

    .ob-app-download__coupons & {
        @media screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-large) {
            font-size: 1.5rem;
        }
    }

    .ob-app-download__magic-timer & {
        @include media-up(sm) {
            width: 72%;
            line-height: 3rem;

            p {
                margin: 0;
            }
        }
    }

    .ob-app-download__home & {
        @include media-down(sm) {
            margin-top: 0;
            width: 80%;

            p {
                margin-top: 0.5rem;
            }
        }
    }

    .app-download-hero-block & {
        margin-bottom: rem(23);

        p {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include media-down(sm) {
        flex: 0 1 18%;
        margin-bottom: 1rem;

        .app-download-hero-block & {
            flex: 0;
            width: 60%;

            p {
                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include media-down(xs) {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }

    .no-spacing & {
        margin-bottom: 0;

        @include media-down(sm) {
            flex: 0 1 80%;
        }

        p {
            margin-top: 0.5rem;
        }
    }

    .ob-app-download--bg-white-sm-up & {
        @include media-up(sm) {
            background: rgba($base-color__white, 0.7);
            p {
                margin: 0;
                padding: 0.5rem 0;
            }
            margin-bottom: 1rem;
        }
        @include media-up(xlg) {
            background: none;
        }
    }

    .ob-app-download--bg-white-sm-down & {
        @include media-down(sm) {
            p {
                background: rgba($base-color__white, 0.7);
                margin: 0;
                padding: 0.5rem 0;
            }
        }
    }
}
.ob-app-download-content-wrapper{
.ob-app-download-content{
    .ob-app-download-stores{
        .is-visible{
            margin-right: 3%;
        }
    }
}
}
.ob-app-download-stores {
    @include media-down(sm) {
        flex: 0 1 17%;
        align-items: center;
    }
   
    .is-visible{
        @include media-down(sm) {
            height: 52%;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        position:static;
    }

    @media screen and (min-width: 991px) {
        position:static;
    }

    .ob-app-download__magic-timer & {
        @include media-down(sm) {
            display: flex;
            flex-direction: column;
        }

        @include media-up(sm) {
            margin-bottom: 3rem;
        }
    }

    .no-spacing & {
        @include media-down(sm) {
            display: flex;
            flex-direction: column;
            align-items: center;

        }
    }

    .ob-app-download-store {
        display: inline-block;

        .app-download-hero-block & {

            @include media-down(sm) {
                display: block;

                &:not(:first-child) {
                    margin-top: 2.2rem;
                }

            }
        }

        @include media-up(sm) {
            &:not(:last-child) {
                margin-right: 18px;
            }
            margin-bottom: 18px;

            @media screen and (min-width: 768px) and (max-width: 991px) {
                width: 46%;            
            }
        }

        .ob-app-download__magic-timer &,
        .no-spacing & {
            @include media-down(sm) {
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }

        .ob-app-download__home & {
            @include media-down(sm) {
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }

        img {
            transition: transform ease .4s;
        }
        &:hover, > img:focus {
            outline: none;
        }

        &:focus {
            @include focusOutline();
            outline-width: 2px;
        }

        &:focus,&:hover > img {
            transform: scale(1.03);
        }
        img[data-format="mobile"] {
            display: block;
            height: 50px;
            @include media-up(sm) {
                display: none;
            }
        }
        img[data-format="desktop"] {
            display: none;
            height: 65px;
            @include media-up(sm) {
                display: block;
            }
        }
    }
}

.ob-app-download__mainAssetContainer {
    @include media-up(md) {
        &.ob-app-download__mainAssetContainer-mobile {
            display: none;
        }
    }

    @include media-down(md) {
        &.ob-app-download__mainAssetContainer-desktop {
            display: none;
        }
    }

    .ob-mainAsset-wrapper {
         img {
             max-width: 100%;
         }
     }

    &.ob-app-download__mainAssetContainer-desktop {
        position: absolute;
        bottom: 0;
        left: 0;
        right: auto;
        top: auto;
        width: 450px;
        height: 635px;
        margin: 0 3rem 0 11rem;

        .ob-mainAsset-wrapper {
            width: 100%;
            height: 100%;

            img {
                height: 100%;
            }
        }

        @include media-down(lg) {
            padding-top: 7rem;
        }
    }

    &.ob-app-download__mainAssetContainer-mobile {
        width: 100%;

        .ob-mainAsset-wrapper img {
            max-height: 100%;
        }
    }
}

.ob-app-download__home {
    z-index: map_get($zindex, homeAppDownload);
}

.no-spacing .ob-app-download__kids-collection--es-es .ob-app-download-content-wrapper {
    @include media-up(md) {
        padding: 3rem 3rem 0;
    }
}

.ob-app-download__post-purchase{
    margin-top: -9%;
    @include media-down(md) {
        margin-top: 0%;
    }
    .ob-app-download{
        &-content-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: 100px;
            padding: 0;
        }
        &-content{
            height: 15%;
            text-align: center;
            @include media-down(md) {
                height: 17%;
                width: 99%;
            }
        }
        &-store{
            img[data-format="mobile"] {
                @include media-down(md) {
                    height: 65px;
                }
            }
        }
        &-stores{
            margin-right: 10%;
            @include media-down(md) {
                margin-right: 0%;
            }
            @media only screen and (device-width:1024px) 
            and (device-height: 1366px) {
                margin-right: 0%;
            }
        }
        &-store:not(:last-child){
            margin-right: 10%;
            @include media-down(md) {
                margin-right: 0%;
            }
        }
       &_post-purchase-disclaimer{
            width: 55%;
            text-align: center;
            @include media-down(sm) {
                margin-top: 35%;
                margin-bottom: -23%;
                width: 80%;
            }
            @media only screen and (min-width:760px) 
                and (max-width: 1023px) {
                margin-bottom: -23%;
                width: 80%;
            }
            @media only screen and (device-width:1024px) 
            and (device-height: 1366px) {
                margin-bottom: -3%;
                margin-top: 3%;
                font-size: 2rem;
                line-height: 3.5rem;
                width: 75%;
            }
       }
    }
}
.ob-app-download-kids-by-age-fun-zone{
    height: 700px;
    background: #d3efe0;

    @media screen and (min-width: 991px) {
        height:750px;
        background: #d3efe0;
    }
    @media screen and (max-width: 768px) {
        height:800px;
        background: #d3efe0;
    }

    .ob-app-download-store{

        @media screen and (max-width: 768px) {
            position: relative;
            left: 90px;
            top: 45px;            
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            position: static;
            left: 110px;
            top: 20px;            
        }
    }
    .ob-app-download-heading{
        @media screen and (min-width: 768px) and (max-width: 991px) {
            width:100%;
        }
    }
    .ob-app-download-content{
        margin-top: 20px;
        @media screen and (max-width: 768px) {
            margin-top:-20px;
        }
        @media screen and (min-width: 768px) and (max-width: 991px) {
            margin-top: 30px;
        }
    }
}

.ob-app-download-kids-by-age{
    height: 700px;
    background: #6faed1;

    @media screen and (min-width: 991px) {
        height:750px;
        background: #6faed1;
    }

    @media screen and (max-width: 768px) {
        height:800px;
        background: #6faed1;
    }

    .ob-app-download-store{

        @media screen and (max-width: 768px) {
            position: relative;
            right: 90px;
            top: 20px;            
        } 
        @media screen and (min-width: 768px) and (max-width: 991px) {
            position: static;
            left: 110px;
            top: 10px;            
        }
    }
    .ob-app-download-heading{
        @media screen and (min-width: 768px) and (max-width: 991px) {
            width:100%;
        }
    }
    .ob-app-download-content{
        margin-top: 20px;

        @media screen and (max-width: 768px) {
            margin-top:10px;
        }
    }
}

//Global Equity
.ob-side-by-side__left-side {
    .ob-app-download {
        .ob-app-download-content {
            .ob-eyebrow.ob-app-download-eyebrow,
            .ob-heading.ob-app-download-heading,
            .ob-app-download-body {
                color: $base-color__white;
            }
        }
    }
}

.zone.article-landing-page{
    .app-download-hero-block{
        @include media-down(sm) {
            margin-top: 40%;
            .ob-backdrop__image{
                overflow: unset;
            }
        }
    }
}