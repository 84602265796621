@import "../../static/styles/mixins";

.ob-bodyText {
    @include body-1-xl;
    margin: 0;
    &[data-white-text='true'] {
        color: $base-color__white;
    }

    .blackText & {
        color: $base-color__black;
    }

    .textOutline & {
        @include textOutline();
    }

    strong {
        @include font-family('hurme-regular');
    }
}