@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-error-block-content-wrapper-1 {
    display: flex;
    flex-direction: column;
}

.ob-error-block-content-wrapper-2 {
    flex: none;
    @include media-up(sm) {
        margin: auto 0;
    }
}

.ob-error-block-content-wrapper-3 {
    @include container-wrapper;
    position: relative;
}

.ob-error-block-content {
    width: 100%;
    margin: 0 0 0 auto;
    padding: 33.33% 0 rem(30) 0;

    .ob-search-block__search-title {
        @include eyebrow-1-xl;
        text-transform: uppercase;
        margin: 0 0 rem(16);
    }

    @include media-up(sm) {
        width: 60%;
        padding: rem(30) 0;
    }
}

.ob-error-block-eyebrow {
    text-transform: uppercase;
    @include eyebrow-1-xl;
}

.ob-error-block-heading {
    margin: rem(5) 0;
    text-transform: uppercase;
    @include display-1-xl;
}

.ob-error-block-body {
    margin: rem(16) 0 rem(24) 0;
    @include body-1-xl;
}

.ob-error-block-search {
    display: block;
    margin: rem(24) 0;
}

.ob-error-block-search-title {
    text-transform: uppercase;
    @include eyebrow-1-xl;
}

.ob-error-block-search-bar {
    margin: rem(16) 0;
    position: relative;
    z-index: map-get($zindex, errorSearch);
}

.ob-error-block-shortcuts-wrapper {
    margin: rem(10) 0 0 0;
    @include media-up(sm) {
        margin: rem(90) 0 0 0;
    }
}

.ob-error-block-shortcuts-title {
    text-transform: uppercase;
    @include eyebrow-1-xl;
    @include media-down(sm) {
        padding: 0.5rem;
        background-color: rgba($base-color__white, 0.5);
    }
}

.ob-error-block-shortcuts {
    margin: rem(10) (-(rem(16))) (-(rem(16))) 0;
}

.ob-error-block-shortcut {
    margin-right: rem(16);
    margin-bottom: rem(16);
    display: inline-block;
}

.ob-error-block-shortcuts{
    padding-left: 0;
    list-style-type: none;
    display: flex;
    @include media-down(sm){
        flex-wrap: wrap;
    }
}