@import '../../../../static/styles/mixins';

.ob-form-goals {
  &__checkbox-list {
    padding: 0;
    list-style-type: none;
    margin: 1rem 0 4rem;

    @include media-up(sm) {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-auto-flow: column;
      grid-gap: 2rem;
    }

    @include media-ie() {
      display: -ms-grid;
      -ms-grid-rows: 1fr 1fr 1fr;
      -ms-grid-columns: 1fr 1fr 1fr
    }

    &--item {
      margin: 2rem 0;
      @include media-up(sm) {
        margin: 0;
      }

      @include media-ie() {
        margin-bottom: 2rem;
      }

      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
        @include media-down(md) {
          margin-right: 15px; 
         }
      }

      &:nth-child(2) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
        @include media-down(md) {
          margin-right: 15px; 
         }
      }

      &:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
        @include media-down(md) {
          margin-right: 15px; 
         }
      }

      &:nth-child(4) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }

      &:nth-child(5) {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
      }

      &:nth-child(6) {
        -ms-grid-column: 2;
        -ms-grid-row: 3;
      }
    }
  }
}

.register-product-createprofile {
  .ob-form-goals {
    &__checkbox-list{
      grid-gap: 0;
    }
    &__checkbox-list::before {
      margin-top: 2rem;
    }
    &__checkbox-list::after {
      margin-bottom: 3rem;
    }
  }
}
