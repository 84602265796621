@import "../../../../../static/styles/mixins";

.ob-reset {
    &__subtitle {
        @include media-up(sm) {
            margin-bottom: 7rem;
        }
    }

    &__requirements {
        margin-top: 1rem;
    }
}
