@import "../../static/styles/mixins";

.ob-videoBlock {
    display: flex;
    flex-direction: column;
	max-height: none;
	background: transparent;
	position: relative;

	&.layout-image-above,
	&.layout-image-below {
		> .ob-videoContent-wrapper {
			min-height: 0;
			position: relative;
			text-align: center;
		}
	}

	.ob-background-wrapper {
		@include getContentBlockHeightByScreenHeight;
	}

	&#Block---Linear-Magnetic-Drive {
		.ob-videoContent-text {
			padding: 1rem 0 0;
			max-width: 100%;
		}

		@include media-up(sm) {
			padding: 1rem;
		}

		@include media-down(md) {
			.ob-heading {
				font-size: 3.2rem;
			}

			.ob-bodyText {
				font-size: 2rem;
			}

			.ob-videoContent-wrapper {
				padding: 0;
			}
		}
	}

	&.ob-content-block__dentist-inspired--ja-jp .ob-videoContent-disclaimer {
		bottom: 15rem;
		@include media-up(lg) {
			bottom: 1rem;
			left: 45rem;
			width: 40%!important;
		}
	}
}

.ob-videoContent {
	&__text-image {
        img {
            max-width: 100%;
        }
    }
	&-wrapper {
		@include container-wrapper;
		height: 100%;
		display: flex;
		text-align: left;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		.layout-image-above &,
		.layout-image-below & {
			padding: 0 3.8rem;
		}

		.layout-image-left &,
		.layout-image-right & {
			position: relative;
		}

		.layout-image-left & {
			flex-direction: row-reverse;
		}

		.image-above &, .image-below & {
			width: auto;
			height: auto;
		}

		.scale-transition & {
			transform: scale(0.95);
		}

		.top-transition & {
			top: -5%;
		}

		.bottom-transition & {
			bottom: -5%;
		}

		.left-transition & {
			left: -5%;
		}

		.right-transition & {
			right: -5%;
		}

		.show & {
			opacity: 1;

			.top-transition & {
				top: 0;
			}

			.bottom-transition & {
				bottom: 0;
			}

			.left-transition & {
				left: 0;
			}

			.right-transition & {
				right: 0;
			}

			.scale-transition & {
				transform: scale(1);
			}
		}

		&.horizontal-left {
			justify-content: flex-start;
		}

		&.horizontal-center {
			justify-content: center;
			.ob-link,
			.ob-button {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&.horizontal-right {
			justify-content: flex-end;
		}

		&.vertical-top {
			align-items: flex-start;
		}

		&.vertical-center {
			align-items: center;
		}

		&.vertical-bottom {
			align-items: flex-end;
		}
	}

	&-mainAsset-container {
		height: 100%;
		position: absolute;
		width: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;

		@include media-down(sm) {
			padding-bottom: 30px;
		}

		&.horizontal-left {
			justify-content: flex-start;
		}

		&.horizontal-center {
			justify-content: center;
		}

		&.horizontal-right {
			justify-content: flex-end;
		}

		&.vertical-top {
			align-items: flex-start;
		}

		&.vertical-center {
			align-items: center;
		}

		&.vertical-bottom {
			align-items: flex-end;
		}

		.image-above &, .image-below &, .ob-side-by-side__column & {
			position: static;
			width: auto;
			height: auto;
		}

		.ob-mainAsset-wrapper {
			align-items: center;
			height: 100%;
			justify-content: center;
			max-width: 100%;
			width: 100%;

			.ob-content-block__dentist-inspired--ja-jp & {
				width: 200px;
				height: 91px;
			}
		}

		img {
			max-width: 100%;
		}
	}

	&-textContainer {
		position: relative;
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		opacity: 0;
		transition: opacity 1s ease-out;
		pointer-events: all;

		&.show {
			opacity: 1;
		}

		.layout-background-image-top-center & {
			align-self: flex-start;
		}

		.layout-background-image-bottom-center & {
			align-self: flex-end;
		}

		.layout-background-image-center-center & {
			align-self: center;
		}

		.layout-background-image-top-center &,
		.layout-background-image-bottom-center & {
			display: flex;
			flex-direction: column;
		}

		.layout-image-above &,
		.layout-image-below & {
			margin-right: auto;
		}

		.ob-why-switch-pop-up & {
			margin-right: 0;
		}

		.ob-content-video__spotlight-genius--ja-jp.layout-image-below & {
			margin-right: 0;
		}
	}


	&-text {
		.align-center & {
			text-align: center;
		}

		.align-center.layout-image-above &,
		.align-center.layout-image-below & {
			margin: 0 auto;
		}

		.layout-image-above &,
		.layout-image-below & {
			max-width: 80%;
			padding: 2rem 0;
			margin: 0 auto;

			.ob-heading {
				@include display-1-xs;
				@include media-down(md) {
					font-size: rem(22);
				}
			}

			.ob-bodyText {
				@include media-down(md) {
					font-size: rem(16);
				}
			}
		}

		.layout-image-left &,
		.layout-image-right & {
			&Container {
				@include media-up(md) {
					width: 50%;
				}
			}

			.ob-heading {
				margin-left: 0;
				margin-right: 0;
			}
		}

		.ob-content-video__spotlight-genius--ja-jp.layout-image-below & {
			max-width: none;
		}
	}

	&-disclaimer {
		position: absolute;
		top: auto;
		right: auto;
		bottom: 1rem;
		left: 4rem;

		@include media-down(sm) {
			left: 50%;
			transform: translateX(-50%);
			width: 100%;
		}
	}

	&-button {
		pointer-events: all;
		border-radius: 50%;
		transition: background-color ease 0.35s;
		&:hover, &:focus {
			background-color: $base-color__white;
		}

		&:focus {
			@include focusOutline();
		}

		&--whitePlayIcon {
			&:hover, &:focus {
				svg {
					fill: $base-color__black!important;
				}
			}
		}

		&-container {
			margin: 0;
			width: 100%;

			.align-center &,
			.layout-background-image-top-center &,
			.layout-background-image-bottom-center &,
			.layout-background-image-center-left-button-right & {
				text-align: center;
			}

			.layout-background-image-bottom-center &,
			.layout-background-image-center-left-button-top & {
				margin: 0 0 3.6rem;
				order: -1;
			}

			.layout-background-image-center-left-button-right & {
				margin: 0;
				width: 50%;
			}
		}
	}

	&-image {
		position: relative;

		.layout-image-above & {
			order: -1;
			@include media-ie() {
				height: 100%;
			}
		}

		.ob-autoplay-video {

			&-controls {
				pointer-events: all;

				button {

					&:focus {
						outline: 1px solid;
					}
				}
			}

			.vjs-big-play-button {
				display: none !important;
			}
		}

		.ob-videoContent-button {
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			pointer-events: all;

			.ob-play-button-small & {
				background-color: rgba(0, 0, 0, 0.5);
				&:hover, &:focus {
					background-color: $base-color__white;
					svg {
						fill: $base-color__black!important;
					}
				}
			}
		}

		img {
			max-width: 100%;
			width: 100%;
		}
	}
}

.post-purchase-video-section {
	margin-top: 0;
	min-height: 820px;
	scroll-margin-top: 12rem;
	@include media-down(md) {
		min-height: 1620px !important;
		margin-top: 5rem;
	}
	background-color: #FFFFFF;
	&__options {
		display: flex;
		flex-direction: row;
		align-items: center;
		max-width: 100%;
		margin: 0 auto;
		margin-top: 4rem;
		justify-content: space-between;
		@include media-down(md) {
			flex-direction: column;
		}
	} 
	&__option {
		display: flex;
		flex-direction: column;
		height: 350px;
		width: 341px;
		margin-left: 25px;
		margin-right: 25px;
		background-color: #FFF;
		align-items: center;
		@include media-down(md) {
			height: 450px;
			width: 330px;
			margin-bottom: 3rem;
		}
		@media only screen and (device-width: 1024px) 
		and (device-height: 1366px) {
			width: 250px !important;
		}
	}
	&__icon {
		height:341px;
		width: 341px;
		@include media-down(md) {
			height:250px;
			width: 330px;
		}
		@media only screen and (device-width: 1024px) 
		and (device-height: 1366px) {
			width: 260px !important;
		}
	}
	&__icon img{
		min-width: 341px;
		max-height:341px;
		@include media-down(md) {
			min-width: 330px;
			max-height:250px;
		}
	}
	&__play-icon {
		height: 110px;
		width: 110px;
		img {
			min-height: 110px;
			min-width: 110px;
			max-height: 110px;
		}
	}
	&__play-icon:focus {
		border: 1px solid $base-color-dark-blue;
	}
	&__title {
		@include font-family('hurme-regular');
		font-size: 16px;
		letter-spacing: 0.5px;
		line-height: 20px;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 2px;
		width: 80%;
		color: #3D3D41;
		font-weight: bold;
		@include media-down(md) {
			width: 100%;
		}
	}
	&__description {
		width: 330px;
		@include font-family('hurme-regular');
		font-size: 19px;
		letter-spacing: 0.5px;
		line-height: 26px;
		text-align: center;
		margin-top: 0.5rem;
		color: #3D3D41;
		@include media-down(md) {
			width: 300px;
		}
		@media only screen and (device-width: 1024px) 
		and (device-height: 1366px) {
			width: 270px !important;
		}
	}
	.ob-background-wrapper {
		min-height:341px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		@include media-down(md) {
			min-height:270px;
			padding-top: 0 !important;
		}
		@media only screen and (device-width: 1024px) 
		and (device-height: 1366px) {
			min-height: 250px !important;
    		padding-top: 0% !important;
		}
	}
    .ob-videoContent-wrapper {
        justify-content: flex-start;
        flex-direction: column;
    }
    .ob-videoContent-textContainer {
        justify-content: center;
    }
    .ob-heading {
        width: 57%;
		margin-top: 2rem;
		font-weight: bold;
		@include media-down(md) {
			width: 60%;
			@include font-family('hurme-regular');
			font-weight: bold;
		}
    }
    .ob-videoContent-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
    }
	.post-purchase-video-close {
		left: 1.5rem !important;
		top: 1.5rem !important;
		.ob-icon {
			@include media-down(md) {
				width: 1.2rem !important;
				height: 1.2rem !important;
				font-size: 1.2rem !important;
			}
		}
	}
}

// Global Equity
.zone.home-body-zone{
	.ob-contentBlock.ob-videoBlock{
		.ob-videoContent-button-container{
			svg.ob-icon.ob-icon--play {
				fill: $base-color-dark-blue !important;

				&:hover,
				&:focus {
					fill: $base-color-dark-blue-hover !important;
				}
			}
		}
	}
}

.product-etb-full-layout{
    .zone.etb-full-body-zone{
        .ob-brand-guarantee  + .ob-contentBlock.ob-videoBlock{
            .ob-videoContent-button-container{
                .ob-icon.ob-icon--play {
                    fill: $base-color__white !important;

					&:hover,
					&:focus {
						fill: $base-color-dark-blue-hover !important;
					}
                }
            }
        }

		.ob-product-sub-nav__container{
			.ob-product-sub-nav__title{
				button{
					svg.ob-icon--chevronDown{
						fill: $base-color-dark-blue !important;						
					}

					.ob-icon.ob-icon--chevronTop {
						fill: $base-color-dark-blue !important;
					}
				}
			}
		}
    }
}

.zone.collection-toothbrush-body-zone{
	.ob-series{
		.ob-videoContent-image{
			.ob-videoContent-button{
				&:hover,
				&:focus {
					background-color: rgba(0, 0, 0, 0);
				}
			}
		}
	}
}

.zone.etb-full-body-zone{
	.ob-contentBlock.ob-videoBlock{
		.ob-videoContent-button-container{
			.ob-videoContent-button{
				&:hover,
				&:focus {
					background-color: rgba(0, 0, 0, 0) !important;
				}
			}
		}
	}
}

#Dentist-inspired{
	.ob-videoContent-button {
		&:hover,
		&:focus {
			background: transparent !important;
		}
	}
}

.zone.collection-toothbrush-body-zone{
	.ob-side-by-side.side-test{
		.layout-image-above{
			.ob-videoContent-textContainer{
				margin-right: 0;
			}
		}
	}

	@media(max-width: 767.98px) {
		.ob-comparison-chart{
			.ob-comparison-chart-table{
				.find-retailer-cta{
					.find-a-retailer-secondary-btn-style{
						background-color: $base-color-dark-blue;
						color: $base-color__white;
						@include font-family('hurme-regular');
					}
				}
			}
		}
	}
}

@media(max-width: 767.98px) {
	.product-etb-full-layout{
		.ob-features-tab{
			.ob-features-tab--mobile{
				.ob-features-tab__text{
					color: $base-color__white;
				}
			}
		}
	}

	.zone.toothpaste{
		.ob-collection-tabs{
			.ob-button-tabs-navigation-list{
				background: #e5e8ec;
			}
		}
	}
}

.zone.whole-body-health-body{
	.ob-videoBlock{
		.ob-videoContent-button-container{
			.ob-videoContent-button{
				&:hover,
				&:focus {
					background-color: rgba(0, 0, 0, 0);
				}

				.ob-icon{
					&:hover,
					&:focus {
						fill: $base-color-dark-blue-hover !important;
					}
				}
			}
		}
	}
}

.zone.body-zone-article-landing-why-oral-b{
	.ob-videoBlock{
		.ob-videoContent-button{
			&:hover,
			&:focus {
				background-color: rgba(0, 0, 0, 0);
			}

			.ob-icon{
				&:hover,
				&:focus {
					fill: $base-color-dark-blue-hover !important;
				}
			}
		}
	}

	#Block---Learn---iO-vs-Sonic---Hero{
		.ob-background-wrapper.ob-background-wrapper-desktop  {
			min-height: 615px;
		}
	}
}

.zone.etb-full-body-zone {
    .ob-videoBlock#Content-Video---iO10---Revolutionary-iO-Technology {
        .ob-videoContent-textContainer {
            .ob-heading,
            .ob-bodyText {
                color: $base-color__white;
            }
        }
        .ob-videoContent-disclaimer {
            .ob-disclaimer {
                color: $base-color__white;
            }
        }
        @include media-down(sm) {
            .ob-videoContent-textContainer {
                .ob-heading,
                .ob-bodyText {
                    color: $base-color-primary-grey;
                }
            }
            .ob-videoContent-disclaimer {
                .ob-disclaimer {
                    color: $base-color-primary-grey;
                }
            }
        }
    }
}