@import "../../../../../static/styles/mixins";

.ob-error {
  &__text, &__heading {
    color: $base-color__error;
    font-size: 2.2rem;
    margin: 0;
  }

  &__heading {
    margin: 0 0 1rem;
  }

  &__text {
    margin: 0 0 2rem;
  }
}