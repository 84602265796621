@import "../../../../../static/styles/mixins";

.ob-register-product {
  &__step-title {
    margin: 2rem 0;
  }

  &__product-list-title {
    @include font-family('hurme-regular');
    color: #3D3C42;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 36px;
    @include media-down(md) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__step-title-link {
    color: #002D72 !important;  
    &:hover{
      color: #87A4D2;
    }
  }

  &__heading {
    @include font-family('hurme-regular');
    font-size: 24px;
    line-height: 30px;
    width: 90%;
    font-weight: 300;
    @include media-down(md) {
      font-size: 18px;
      line-height: 26px;
      width: 90%;
    }
  }

  &__wrapper {
    @include media-up(lg) {
      max-width: 90rem;
    }
    margin: auto;
  }

  &__goals-text {
    margin: 5rem 0 3rem;
  }
  &__product-group-names {
    @include font-family('hurme-regular');
    width: 75%;
    color: #3D3C42;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    @include media-down(md) {
      width: 100%;
    }
  }
  &__product-group-names li{
    font-weight: bold;
    @include media-down(md) {
      font-weight: normal;
    }
  }
  &__entry-list-wrapper{
    display: flex;
    flex-direction: row;
    @include media-down(md) {
      flex-direction: column-reverse;
    }
  }
  &__entry-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  &__entry-section{
    display: flex;
    flex-direction: column;
    @include media-down(md) {
      width: 100%;
    }
  }
  &__dop-select-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    @include media-down(md) {
      margin-top: 20px;
      flex-direction: column-reverse;
    }
    .ob-input {
      &__wrapper {
        @include media-down(md) {
          margin-bottom: 1rem;
        }
      }
    }
  }
  &__product-images{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    img {
      margin-right: 5px;
    }
  }
  &__product-image{
    width: 208px;
    height: 230px;
    @include media-down(md) {
      width: 150px;
      height: 150px;
    }
  }
  &__image-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 60px;
    @include media-down(md) {
      padding-left: 0px;
      margin-bottom: 10px;
    }
  }
  &__product-image-disclaimer-title {
    @include font-family('hurme-regular');
    width: 100%;
    color: #4A4A4A;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
  }
  &__dop-disclaimer {
    @include font-family('hurme-regular');
    width: 100%;
    color: #4A4A4A;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 27px;
    margin-bottom: 20px;
    display: block;
    @include media-down(md){
      display: none;
    }
    b {
      font-weight: bold;
    }
  }
  &__dop-disclaimer-mobile {
    display: none;
    @include media-down(md){
      display: block;
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
  &__sample-receipt-display{
    @include media-down(md){
      justify-content: center;
      text-align: center;
    }
  }
  
  &__dop-container {
    @include media-up(md) {
      display: flex;
      width: 85%;
    }
    .ob-mainAsset-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  &__dop-input-container {
    width: 100%;
    margin-right: 3rem;
  }
  .ob-form {
    &__layout {
      margin-top: 5rem;
    }
  }
}
