
.ob-video-player {
  position: relative;

  /*
  needed to keep video width within grid system
  */
  & .video-player-dimensions {
    width: 100%;
  }

  /*
  needed to kill all things
  that we do not want to be displayed
   */
  & .vjs-loading-spinner,
  .vjs-error-display,
  .vjs-modal-dialog,
  .vjs-hidden,
  .vjs-poster,
  .vjs-text-track-settings,
  .vjs-resize-manager,
  .vjs-title-bar,
  .vjs-upcoming-video,
  .vjs-recommendations-overlay,
  .vjs-cloudinary-button {
    display: none;
    visibility: hidden;
  }
  video{
    width: 100%;
  }
}
