@import "../../static/styles/mixins";

.ob-pressQuoteBlock {
    background: white;
    position: relative;
    z-index: map-get($zindex, contentBlock);

    .ob-pressQuotes-container {
        .ob-pressQuoteItem__nav {
            position: absolute;
            left: 50%;
            bottom: 5%;
            transform: translateX(-50%);
            background: rgba($base-color__black, 0.4);
            transition: background 0.2s ease-in-out;
            padding: 1rem 2rem;
            border-radius: 72px;

            &:hover {
                background: rgba($base-color__black, 0.6);
            }

            @include media-down(md) {
                bottom: 10%;
            }
        }

        .ob-pressQuotes__arrow-container {
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;

            @include media-down(md) {
                display: none;
            }

            .ob-pressQuotes__arrow {
                pointer-events: all;
                width: 3rem;
                height: 12rem;
                background: rgba($base-color__black, 0.4);
                transition: background 0.2s ease-in-out;
                border-radius: 72px;

                &.ob-pressQuotes__arrow-left {
                    border-radius: 0 72px 72px 0;
                }

                &.ob-pressQuotes__arrow-right {
                    border-radius: 72px 0 0 72px;
                }

                svg {
                    fill: $base-color__white;
                }

                &:hover, &:focus {
                    background: rgba($base-color__black, 0.6);

                    svg {
                        fill: $base-color__light-yellow;
                    }
                }

                &:focus {
                    @include focusOutline();
                }
            }

            .ob-pressQuotes__arrow-left {
                position: relative;
                left: 0;
            }

            .ob-pressQuotes__arrow-right {
                position: absolute;
                right: 0;
            }
        }
    }
}

ul.ob-pressQuotes-list {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;
    padding-inline-start: 0px;
    padding-left: 0px;
    list-style-type: none;

    li.ob-pressQuotes-list__item {
        min-width: 100%;

        .ob-background-wrapper {
            padding-top: 0px !important;
        }
    }
}