@import "../../static/styles/mixins";

@keyframes mover {
    0% {
        transform: translateY(0rem) translateX(-50%);
    }

    76% {
        transform: translateY(0rem) translateX(-50%);
    }

    88% {
        transform: translateY(.5rem) translateX(-50%);
    }

    100% {
        transform: translateY(0rem) translateX(-50%);
    }
}
@keyframes mover-above-mobile {
    88% {
        transform: translateY(.5rem) translateX(-50%);
    }
}
.one-page-scroll {

    &-wrapper {
        overflow: hidden;
    }
}

.ob-one-page-scroll {

    &-arrow {
        animation-name: mover;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
        top: auto;
        bottom: .5rem;
        left: 50%;
        transform: translateX(-50%);
        position: fixed;
        transition: all 0.8s ease-in-out;
        z-index: map-get($zindex, header);
        cursor: pointer;

        @include media-ie() {
            animation: none;
            transition: none;
        }

        @include media-up(md) {
            bottom: 1rem;
            animation-name: mover-above-mobile;
        }

        svg {
            transition: transform 0.2s ease-out;
        }

        &:hover,
        &:focus {
            svg {
                @include media-up(md) {
                    transform: scale(1.2);
                }
            }
        }
    }
}

.ob-spotlight-layout {
    background-color: $base-color__black;
    width: 100%; // Sets each panel to the width and height
    height: 100%; // of the body (which is set to the window)
    min-height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    background-attachment: fixed; //Sets background fixed in window
    background-size: cover;

    > div {
        width: 100%; // Sets each panel to the width and height
        height: 100%; // of the body (which is set to the window)
    }

    .ob-background-wrapper {
        max-height: 100%;
        height: 100vh;
    }

    .scroller-container {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: fixed;
        top: $ob-sticky-bar-height;
        width: 100%;

        @include media-down(md) {
            top: $ob-sticky-bar-height-mobile;
        }

        .ob-background-wrapper {
            height: 100%;
            &:not(.has-footer) {
                min-height: 0;
                padding-top: 0 !important;               
            }
        }

        .scroller-content.pre-step {
            pointer-events: none;
        }

        .scroller-content[data-is-active='false'] {
            button,
            [href],
            input,
            select,
            textarea, 
            [tabindex] {
                display: none!important;
            }
        }
    }
}
.ob-spotlight-waitlist{
    .ob-background-wrapper {
        height: 100%;
        &:not(.has-footer) {
            @include media-up(md) { 
                min-height: 800px !important;
            }
        }
    }
}