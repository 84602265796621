//namespace
$ob: 'ob';

// font family
$hurmesemibold: 'Hurme Semi Bold', sans-serif;
$hurmelight: 'Hurme Light', sans-serif;
$hurmeregular: 'Hurme Regular', sans-serif;
$hurmebold: 'Hurme Bold', sans-serif;

// responsive break-point
$breakpoint-xtiny: 365px;
$breakpoint-tiny: 376px;
$breakpoint-xsmall: 426px;
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1200px;
$breakpoint-xlarge: 1440px;
$breakpoint-xxlarge: 1980px;
$breakpoint-xxxlarge: 2200px;

// main colors
$base-color-link-blue:#0051AD;
$base-color-primary-blue: #0057B8;
$base-color-secondary-blue: #4A90E2;
$base-color-outline-blue: #2170CC;
$base-color-tertiary-blue: #1967BE;
$base-color-bright-blue: #2376d8;
$base-color-primary-grey: #3D3D41;
$base-color-dark-grey: #4a4a4a;
$base-color-mid-dark-grey: #6F6F74;
$base-color-isolate-grey: #979797;
$base-color-minimal-grey: #C0C0C0;
$base-color-secondary-grey: #D8D8D8;
$base-color-light-grey: #EAE6E6;
$base-color-light-grayish-blue: #E5E8EC;
$base-color-proxima-grey: #E6E6E6;
$base-color-saturn-grey: #9B9B9B;
$base-color-secondary-off-white: #FAFAFA;
$base-color-secondary-darker-off-white: #F4F4F4;
$base-color-tertiary-off-white: #F7F7F7;
$base-color__white: #ffffff;
$base-color-dark-blue: #002D72;
$base-color__black:  #000000;
$base-color__yellow:  #FFB702;
$base-color__light-yellow: #FFC011;
$base-color__contest-button-dark-blue: #010412;
$base-color__contest-input-dark-blue: #0f112c;
$base-color-dark-blue-hover: #87A4D2;
$base-color-white: #d3d3d3;
$base-color-greyish: #6F6F6F;
$base-color-navy-blue: #003082;
$base-color-navy-blue: #151E35;
$io2-light-blue-color: #a9c8ea;
$io2-dark-blue-color: #02102e;
$io2-lighter-blue-color: #86ABD533;
$io2-secondary-blue-color: #cce5ff;
$io2-navy-blue-color:#1D3F79;
$io2-tertiary-blue-color: #0E174C;
$io2-tertiary-light-blue: #BAD7F5;
$io2-base-color-dark-blue: #001534;
$io2-base-color-slight-dark: #021648;
$io2-base-color-heavy-dark: #021648;
$io2-mid-blue-color: #33578e;
$io2-maintain-clean-blue: #040d1f;
$base-color-start-guide-text-blue: #001534;
$article-carousel-bg-color: #FBFBFB;
$poh-bg-color: #151E35;
$io2-blue: #002072;
$base-color-greyish: #F3E7E3;
$io2-grey: #DAE8DF;
$io-grey: #AEB1B8;
$io2-lightgrey: #f6f6f6;
$io2-dark-bluish: #384254;
$base-close-color: #FFFFFF33;
$bases-grey: #b6b6b6;
$base-color-lighter-blue: #2C579A;

// TBD
$base-color__error:  #c60100;

$mediaQueryBreakpoints: (
    xtiny  : $breakpoint-xtiny,
    tiny  : $breakpoint-tiny,
    xs  : $breakpoint-xsmall,
    sm  : $breakpoint-small,
    md  : $breakpoint-medium,
    lg  : $breakpoint-large,
    xlg : $breakpoint-xlarge,
    xxlg : $breakpoint-xxlarge,
    xxxlg : $breakpoint-xxxlarge,
);

// If we need to add a z-index value to an element, we will add it in this map.
// It's easier to keep track of all the values like this.
$zindex: (
    negative : -1,
    default : 0,
    one : 1,
    menuList : 10,
    spotlightClipBaseStep3 : 10,
    productSubNav : 10,
    activeMenuSubNav : 20,
    menuSubNavCloseBtn : 30,
    waitlistModal: 90,
    header: 100,
    persistentChatToggle: 19,
    modal: 110,
    visuallyHidden: 1000,
    footer: 1,
    errorSearch: 2,
    contentBlock: 2,
    brushConfigurator: 2,
    cookieConsent: 2,
    adChoices: 2,
    homeAppDownload: 2
);


// container

//Wrapper from design 1136px/113.6rem
$oralb-max-desktop-width: 123.6rem;

$ob-menu-mobile-max-height: calc(100vh - 149px);

// spotlight
$ob-sticky-bar-height: 60px;
$ob-sticky-bar-height-mobile: 57px;
$ob-sticky-bar-height-mobile_new: 77px;

// Articles
$article-body-width: 954px;
$legacy-article-body-width: 984px;
