@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.ob-contentBlock {
    background: white;
    position: relative;
    z-index: map-get($zindex, contentBlock);
    pointer-events: none;

    &.no-background {
        padding-top: 0;
        min-height: 550px;

        &.ob-video-block-style {
            min-height: 0 !important;
        }

        @include media-down(md) {
            min-height: 445px;

            .ob-side-by-side__column & {
                height: auto;
            }
        }

        @include media-down(sm) {
            min-height: 420px;

            .ob-side-by-side__column & {
                height: auto;

                &.ob-video-block-style {
                    height: auto;

                    .ob-contentBlock-mainAsset-container {
                        padding-bottom: 0;
                    }
                }
            }

            .ob-side-by-side-water-flosser & {
                height: auto;
            }

            .ob-magic-timer-side-by-side & {
                height: auto;

                .ob-button {
                    margin-top: 0;
                    width: 100%;
                    span {
                        margin: 0 auto;
                    }
                }
            }
        }

        @include media-up(lg) {
            padding-top: 0!important;

            &.ob-video-block-style {
                height: auto;
            }

            .ob-side-by-side-water-flosser & {
                height: auto;
            }
        }
        @include media-up(xlg) {
            padding-top: 0!important;
            height: auto;

            .ob-side-by-side-no-height & {
                height: auto !important;
            }
        }
    }

    &.ob-content-block__dentist-inspired--ja-jp .ob-contentBlock-disclaimer {
        bottom: 15rem;
        @include media-down(sm) {
            width: 85% !important;
        }

        @include media-up(sm) {
            bottom: 10rem;
            left: 31rem;
        }

        @include media-up(md) {
            left: 37rem;
        }

        @include media-up(lg) {
            bottom: 1rem;
            left: 45rem;
            width: 42%!important;
        }

        @include media-ie() {
            left: 45rem !important;
        }
    }

    &.ob-content-block__home-kids-left--ja-jp {
        @include media-up(lg) {
            height: 100%;
        }
    }

    &.blackText {
        color: $base-color__black;
    }

    &.noMaxHeight {
        max-height: none;
    }

    &.background-primary-grey {
        background: $base-color-primary-grey;
    }

    &.background-secondary-grey {
        background: $base-color-secondary-grey;
    }

    &.background-isolate-grey {
        background: $base-color-isolate-grey;
    }

    &.background-secondary-off-white {
        background: $base-color-secondary-off-white;
    }

    &.background-primary-blue {
        background: $base-color-primary-blue;
    }

    &.background-secondary-blue {
        background: $base-color-secondary-blue;
    }

    &-wrapper {
        @include container-wrapper;
        height: 100%;
        display: flex;
        text-align: left;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        .image-above &, .image-below &, .ob-side-by-side__column & {
            display: block;
            position: relative;
        }

        .ob-content-block__home-kids-right--ja-jp & {
            display: flex;
            position: absolute;
        }

        &.ob-contentBlock-wrapper--has-divider {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            @include media-down(sm) {
                padding-bottom: 30px;
            }
        }
    }

    &-textContent {
        opacity: 0;
        transition: opacity 2s ease-out,
            top 1.2s ease-out,
            left 1.2s ease-out,
            right 1.2s ease-out,
            bottom 1.2s ease-out,
            transform 1.2s ease-out;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .image-above &, .image-below & {
            width: auto;
            height: auto;
        }

        .scale-transition & {
            transform: scale(0.95);
        }

        .top-transition & {
            top: -5%;
        }

        .left-transition & {
            left: -5%;
        }

        .right-transition & {
            right: -5%;
        }

        .show & {
            opacity: 1;

            .top-transition & {
                top: 0;
            }

            .bottom-transition & {
                bottom: 0;
            }

            .left-transition & {
                left: 0;
            }

            .right-transition & {
                right: 0;
            }

            .scale-transition & {
                transform: scale(1);
            }
        }

        &.hasPadding {
            padding: 15px;
        }

        &.horizontal-left {
            justify-content: flex-start;

            .ob-contentBlock-disclaimer {
                @include media-ie() {
                    left: 0;
                }
            }
        }

        &.horizontal-center {
            justify-content: center;
            .ob-link,
            .ob-button {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &.horizontal-right {
            justify-content: flex-end;

            .ob-contentBlock-disclaimer {
                @include media-ie() {
                    right: 0;
                }
            }
        }

        &.vertical-top {
            align-items: flex-start;
        }

        &.vertical-center {
            align-items: center;
        }

        &.vertical-bottom {
            align-items: flex-end;
        }

        &-inner {
            pointer-events: all;
            width: 100%;

            &[style*="background-color"] {
                padding: 0.4rem 2rem;

                .ob-contentBlock-textContent-obLink-container {
                    margin-bottom: rem(15);
                }
            }

            @include media-up(sm) {
                width: 50%;
            }
        }

        .ob-collection-replacement-brush-head & {
            .ob-link {
                @include media-down(sm) {
                    text-align: left;
                    font-size: 1.6rem;
                }
            }
        }
    }

    &-text-image {
        img {
            max-width: 100%;
        }
    }

    &.small-text-image {
        .ob-contentBlock-text-image {
            img {
                height: 35px;

                @include media-down(sm) {
                    height: 22px;
                }
            }
        }
    }

    &-mainAsset-container {
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        @include media-down(sm) {
            padding-bottom: 30px;
        }

        .image-above &, .image-below &, .ob-side-by-side__column & {
            position: static;
            width: auto;
            height: auto;
        }

        .ob-content-block__home-kids-left--ja-jp & {
            @include media-down(sm) {
                padding-bottom: 0;
            }

            @include media-up(lg) {
                height: 100%;

                img {
                    height: 100%;
                }
            }
        }

        .ob-mainAsset-wrapper {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: center;
            max-width: 100%;
            width: 100%;

            &.horizontal-left {
                justify-content: flex-start;
            }

            &.horizontal-center {
                justify-content: center;
            }

            &.horizontal-right {
                justify-content: flex-end;
            }

            &.vertical-top {
                align-items: flex-start;
            }

            &.vertical-center {
                align-items: center;
            }

            &.vertical-bottom {
                align-items: flex-end;
            }

            .img-parallax {
                width: 100%;
            }
        }

        img {
            max-width: 100%;
            width: 100%;

            .ob-content-block__dentist-inspired--ja-jp & {
                width: 200px;
                height: 91px;
            }
        }
    }

    .ob-eyebrow {
        margin-bottom: 1.6rem;
    }

    .ob-heading {
        margin-left: auto;
        margin-right: auto;

        sup {
            top: -0.7em;
            font-size: 50%;
        }
    }

    &.ob-homepage-hero-block {
        .ob-heading {
            font-size: 2.6rem;
            letter-spacing: 2.69px;
            line-height: 4rem;
            @include font-family('semi-bold');
            @include media-up(sm) {
                font-size: 4rem;
                letter-spacing: 0.4rem;
                line-height: 4.6rem;
            }
        }
    }

    &.children-oral-health {
        .ob-heading {
            @include media-up(sm) {
                margin: 0;
                text-align: left;
            }
        }
    }

    &.text-spacing {
        .ob-heading {
            @include media-down(sm) {
                font-size: 2.8rem;
                margin-bottom: 1.6rem;
            }
            @include media-down(xtiny) {
                font-size: 2rem;
                line-height: 1;
                margin-bottom: 1rem;
            }
        }
        .ob-eyebrow {
            @include media-down(sm) {
                margin-top: 3rem;
            }
            @include media-down(xtiny) {
                margin-top: 1rem;
            }
        }
        .ob-bodyText {
            @include media-down(xtiny) {
                font-size: 1.4rem;
            }
        }
    }

    &.ob-why-switch-three-brushes {
        .ob-bodyText {
            @include media-down(sm) {
                margin-top: 150%;
            }
        }
    }

    &.ob-app-intelligent-way {
        background: linear-gradient(120deg, #0A0A32, #072961);

        .ob-contentBlock-textContent {
            position: static;
        }

        @include media-down(md) {
            .ob-contentBlock-wrapper {
                position: relative;
            }
        }

        @include media-up(md) {
            background: linear-gradient(120deg, #0A0A32, #072961);
        }
    }

    &.ob-app-download-oral-health {
        hr {
            border-top: none;
            margin: 3rem auto;
            max-width:rem(400);
            width: 80%;

            @include media-up(sm) {
                margin: 3rem 0;
            }
        }

        .ob-heading {
            @include media-up(lg) {
                &.ob-display-2-xl {
                    font-size: 4.8rem;
                    line-height: 1.166666666666667;
                }
            }
        }

        .ob-bodyText {
            line-height: 1.4444444444;
            margin-top: 2.4rem;

            @include media-up(lg) {
                letter-spacing: 0;
                line-height: 1.363636363636364;
                margin-top: 1rem;
            }
        }

        span.oral-health-smaller {
            display: inline-block;
            font-size: 0.77778em;
            line-height: 1.571429;
            margin: 0 auto;
            width: 90%;

            @include media-up(lg) {
                width: 66%;
                line-height: 1.5;
            }
        }
    }

    &.ob-app-download {

        &-module-copy {
            &,
            &.ob-contentBlock,
            &.layout-image-above .ob-videoContent-text,
            &.layout-image-below .ob-videoContent-text {
                .ob-heading {

                    @include media-down(md) {
                        font-size: 2.8rem;
                        line-height: 1.285714285714286;
                        margin-bottom: 2.4rem;
                    }
                }

                .ob-bodyText {
                    @include media-down(md) {
                        font-size: 1.8rem;
                        letter-spacing: 0;
                        line-height: 1.444444444444444;
                    }
                }
            }
        }

        &-border-bottom-mobile {
            $borderWidth: 27.2rem;

            @include media-down(sm) {

                &:after {
                    background-color: $base-color-secondary-grey;
                    bottom: 0;
                    content: "";
                    height: 1px;
                    left: 50%;
                    margin-left: #{$borderWidth / -2};
                    opacity: 0.4;
                    position: absolute;
                    width: $borderWidth;
                }
            }
        }

        &-border-top-desktop {
            $borderWidth: 77%;

            @include media-up(sm) {
                &:after {
                    background-color: $base-color-secondary-grey;
                    content: "";
                    height: 1px;
                    left: 50%;
                    margin-left: #{$borderWidth / -2};
                    opacity: 0.4;
                    position: absolute;
                    top: 0;
                    width: $borderWidth;
                }
            }

        }
    }

    .ob-bodyText {
        margin-top: .5rem;
        margin-bottom: 1.5rem;
    }

    .ob-link,
    .ob-button {
        margin-top: 3rem;
        text-align: center;

        @include media-up(md) {
            margin-top: 3.8rem;
        }
    }

    &.ob-content-block__dentist-inspired--ja-jp {
        .ob-button, .ob-link {
            @include media-down(tiny) {
                margin-top: 0;
            }

            @include media-down(sm) {
                margin-top: 0;
            }
        }
    }

    .ob-contentBlock-disclaimer {
        width: 100%;
        position: absolute;
        top: auto;
        right: auto;
        bottom: 1rem;
        left: 1rem;


        @include media-ie() {
            right: 0;
        }

        @include media-up(sm) {
            width: 50%;
            left: auto;
        }

      html[lang="pt"] & {
        @include media-down(sm) {
          left: 0;
          padding: 0 2rem;
        }
      }
      #disclaimer--sbs{
        position: relative;
        top: 10rem;
        text-align: left;
      }
      #depending-on-the-handle-model{
        position: relative;
        top: 4rem;
        text-align: left;
      }
    }

    &.ob-contentBlock--lower-disclaimer {
        .ob-contentBlock-disclaimer {
            bottom: -3rem;
        }
    }

    &.why-oral-b-landing--ja-jp {
        .ob-contentBlock-disclaimer {
            @include media-down(sm) {
                left: 0;
            }
        }
    }

    &.ob-app-intelligent-way--de-de .ob-contentBlock-disclaimer {
        left: 0;
        @include media-up(sm) {
            left: auto;
        }
    }

    &[data-respectimageaspectratio=false] {
        .ob-background-wrapper {
            @include getContentBlockHeightByScreenHeight;
            height: 96vh;
        }

    }
    &.ob-content-block__home-kids-right--ja-jp {
        @include media-up(xlg) {
            height: 100%;
            .ob-background-wrapper {
                height: 100%;
            }
        }
    }
}

.ob-contentBlock-video-wrapper {
    position: relative;
}

.ob-contentBlock-video-controls-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: all;
    width: 130px;
}

.ob-contentBlock-video-controls {
    padding: rem(16);
    font-size: rem(24);
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
    color: black;
    position: relative;
    z-index: map-get($zindex, one);
    border-radius: 2rem;
    background: rgba($base-color__black, 0.6);

    @include media-up(md) {
        background: rgba($base-color__black, 0.4);
        transition: background 0.2s ease-in-out;

        &:hover {
            background: rgba($base-color__black, 0.6);
        }
    }

    .ob-video-player-button {
        color: $base-color__white;

        &:focus {
            outline-style: solid;
            outline-width: 1px;
        }

        svg {
            margin-bottom: 0.7rem;
        }
    }
}

.ob-contentBlock-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    * {
        display: none !important;
        background: none !important;
    }

    .ob-video-player {
        position: static !important;
    }
    .ob-video-player,
    .cld-video-player {

        height: 100% !important;
        width: 100% !important;
        display: block !important;
        padding: 0!important
    }

    #video-player {
        position: relative !important;
    }

    video {
        height: auto !important;
        width: auto !important;
        display: block !important;
        position: absolute !important;
        min-height: 100% !important;
        min-width: 100% !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;

    }
}

.ob-contentBlock-video-content {
    width: 100%;
    min-height: 550px;

    @include media-up(md) {
        min-height: 800px;
    }

    .ob-content-block__brush-head-video & {
        @media screen and (min-width: 1600px) {
            min-height: 1200px;
        }
        @include media-up(xxxlg){
            min-height: 1400px;
        }
    }
}

//Some homepage content block custom style
#gum-health {
    .ob-mainAsset-wrapper {
        img {
            @include media-down(md) {
                width: 210px;
                margin-left: 40%;
            }

            @include media-down(sm) {
                max-width: 95%;
                margin-left: 0;
            }
        }
    }
}

//Product Safety
.ob-safety-product {
	.ob-product-safety-hero {
		.ob-heading {
			font-size:6.5rem;
            @include media-down(sm) {
            font-size: 2.8rem;
            }
		}
    }	
	
	.ob-content-details-block {
		margin: 4% 0;
		
		.ob-content-details-block-wrapper {
			max-width: 143.6rem;
		
			.ob-content-details-block__content {
				-webkit-box-orient: vertical;
				-webkit-box-direction: reverse;
				-webkit-flex-direction: column-reverse;
				
				.ob-content-details-block__image-content, 
				.ob-content-details-block__text-content {
					display: -webkit-box;
					display: -webkit-flex;
					display: flex;
					-webkit-box-flex: 1;
					-webkit-flex: 1 0 50%;
					flex: 1 0 50%;
					width: 50%;
					height: 100%;
				}
				.ob-content-details-block__image-content {
					-webkit-box-orient: horizontal;
					-webkit-box-direction: normal;
					-webkit-flex-direction: row;
					flex-direction: row;
					text-align: right;
					width: 100%;
					
					.ob-content-details-block__image-content__details, 
					.ob-content-details-block__image-content__main-image {
						height: 100%;
					}
					
					.ob-content-details-block__image-content__details {
						-webkit-box-flex: 1;
						-webkit-flex: 1 0 100%;
						flex: 1 0 100%;
						color: #3d3d41;
						text-align: center;
						
						display: -webkit-inline-box;
						display: -webkit-inline-flex;
						display: inline-flex;
						margin-top: 1.5rem;
						    @include media-down(sm) {
                                display: inline-block;
                            }
						.ob-content-details-block__detail-content {
							margin-bottom: 2.5rem;
							padding: 3rem;
							width: 25%;
                            @include media-down(sm) {
                                width: 100%;
                                margin-bottom: 0;
                            }
							.ob-content-details-block__detail-title {
								margin-bottom: 4rem;
								
								h3 {
									font-size: 3rem;
									text-transform: uppercase;
									margin-bottom: 1rem;
									font-weight: 600;
								}
								h2 {
									font-size: 3.2rem;
                                    line-height: 34px;
									margin-top: 0;
									font-weight: 500;
                                    height: 70px;
								}
							}
							.ob-mainAsset-wrapper-img {
								width: 75%;
							}
							
							.ob-content-details-block__detail-content-text {
								line-height: 2.5rem;
								padding-top: 2.5rem;
								width: 90%;
								margin: auto;
                                @include media-down(sm) {
                                   width: 100%;
                                 }

                                b {
                                    font-weight: bold;
                                }
							}
						}
					}
				}
				.ob-content-details-block__text-content {
					-webkit-box-align: center;
					-webkit-align-items: center;
					align-items: center;
					text-align: left;
					padding-left: 0;
					width: 100%!important;
                    @include media-down(sm) {
                        padding-bottom: 0px;
                     }
					
					.ob-content-details-block__text {
						text-align: center;
						width: 53%;
						margin: 0 auto;
						padding-top: 0!important;
                        @include media-down(sm) {
                           width: 100%;
                        }
						
						.ob-heading {
							text-transform: uppercase;
							margin-bottom: 2rem;
						}
						.ob-bodyText {
							font-size: 2.2rem;
							line-height: 3rem;
							margin-top: .5rem;                                
						}
					}
				}
			}
		}
	}
	
	.ob-product-safety-video-text {
		.ob-heading {
			@include font-family('hurme-regular');
			font-weight: 300;
			letter-spacing: -.03243em;
			text-transform: uppercase;
			text-align: center;
			padding-bottom: 3rem;
		}
	}
    
    .ob-product-safety-video {
        max-width: 100rem;
        width: 90%;
        margin: auto auto 10rem;
    }
	
	.ob-product-safety-ingredients {
		.ob-contentBlock-textContent-inner .ob-heading {
			text-transform: uppercase;
		}
	}
    @media screen and (min-width: 414px){
		.ob-product-safety-hero {
			.ob-heading {
				font-size:3.2rem;
			}
		}
        .ob-content-details-block {
            .ob-content-details-block-wrapper {
                .ob-content-details-block__content {
                    .ob-content-details-block__image-content {
                        .ob-content-details-block__image-content__details{
                            display:block;
                            .ob-content-details-block__detail-content{
                                width: 100%;
                                padding: 0 3rem;
                                margin-bottom: 0;
                                .ob-content-details-block__detail-title{
                                    margin-bottom: 2rem;
                                    h2{
                                        height:auto;
                                        margin-bottom: 0;
                                    }
                                }
                                .ob-mainAsset-wrapper-img{
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
            .ob-content-details-block__text-content{
                padding-bottom: 2rem;
            }
        }        
    }
    .ob-product-safety-video-text{
        .ob-heading{
			font-size: 3rem;
            line-height: 4rem;
        }
    }
    @media screen and (min-width: 768px){
		.ob-product-safety-hero {
			.ob-heading {
				font-size:4rem;
			}
		}
        .ob-content-details-block {
            .ob-content-details-block-wrapper {
                .ob-content-details-block__content {
                    .ob-content-details-block__image-content {
                        .ob-content-details-block__image-content__details{
                            display:block;
                            .ob-content-details-block__detail-content{
                                width: 100%;
                                padding: 0 3rem;
                                margin-bottom: 0;
                                .ob-content-details-block__detail-title{
                                    margin-bottom: 2rem;
                                    h2{
                                        height:auto;
                                        margin-bottom: 0;
                                    }
                                }
                                .ob-mainAsset-wrapper-img{
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
            .ob-content-details-block__text-content{
                padding-bottom: 2rem;
            }
        }
    }	
	@media screen and (min-width: 1200px){
		.ob-product-safety-hero {
			.ob-heading {
				font-size:6.5rem;
			}
		}
        .ob-content-details-block {
            .ob-content-details-block-wrapper {
                .ob-content-details-block__content {
                    .ob-content-details-block__image-content {
                        .ob-content-details-block__image-content__details{
                            display:flex;
                             .ob-content-details-block__detail-content{
                                width: 25%;
                                .ob-content-details-block__detail-title{
                                    h2{
                                        height:70px;
                                    }
                                }
                                .ob-mainAsset-wrapper-img{
                                    width: 75%;
                                }
                            }
                            .ob-content-details-block__detail-content:first-child {
                                .ob-mainAsset-wrapper-img {
                                    width: 84%;
                                    margin-bottom: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
	}    
    .ob-product-safety-video-text{
        .ob-heading{
			font-size: 4.1rem;
            line-height: 5rem;
        }
    }
}

#Content-Block---Home---Revolutionary-video{
    .ob-contentBlock-video{
        @include media-down(sm) {
            right: -720px; 
            bottom: -170px;
        }
    }
}


.ob-contentBlock-video-wrapper-visible{
    @media screen and (min-width: $breakpoint-medium){
      visibility: hidden;
    }
}

.post-purchase-hero-section {
    scroll-margin-top: 10rem;
    @include media-down(md) {
        margin-top: 0;
    }
    @include media-down(sm) {
        margin-top: 0;
    }
    @media only screen and (device-width:1024px) 
    and (device-height: 1366px) {
        margin-top: 11%;
    }
    .ob-contentBlock-wrapper {
        width: 100%;
    }
    .ob-contentBlock-textContent {
        align-items: flex-start;
        margin-top: 6rem;
        @include media-down(md) {
            justify-content: center;
            margin-top: 86rem !important;
        }
        @include media-down(sm) {
            margin-top: 59rem !important;
        }
        &-inner {
            width: 51% !important;
        }
    }
    .ob-background-wrapper {
        min-height: 450px !important;
        height: 60vh !important;
        @include media-up(md) {
            padding-top: 0 !important;
        }
        @include media-down(md) {
            min-height: 212px !important;
            height: 212px !important;
            background-size: cover;
            padding-top: 55% !important;
        }
        @include media-down(sm) {
            background-size: auto !important;
            padding-top: 65% !important;
        }
        @include media-down(tiny) {
            background-size: auto !important;
            padding-top: 70% !important;
        }
        @media only screen and (device-width: 1024px) 
        and (device-height: 1366px) {
            padding-top: 0% !important;
            height: 15vh !important;
        }
    }
    .ob-heading {
        font-weight: bold;
        @include media-down(md) {
            margin-top: 0rem;
            text-align: center;
        }
    }
    .ob-bodyText {
        @include media-down(md) {
            margin: 0 auto;
            margin-top: 0.5rem;
            margin-bottom: 1.5rem;
            text-align: center;
            width: 80%;
            font-size: 16px;
        }
    }
}

.post-purchase-warranty-section {
    scroll-margin-top: 10rem;
    .ob-background-wrapper {
        min-height: 1000px !important;
        height: auto;
        width: 100%;
        margin: 0 auto;
        padding-top: 0% !important;
        @include media-down(md) {
            min-height: 1270px !important;
            height: auto !important;  
            width: 100% !important;
        }
        @include media-down(sm) {
            min-height: 1170px !important;
        }
        @media only screen and (device-width: 1024px) 
        and (device-height: 1366px) {
            padding-top: 0% !important;
            height: 0vh !important;
            min-height: 800px !important;
        }
    }
    .ob-contentBlock-textContent {
        align-items: flex-start;
        margin-top: 28rem;
        @include media-down(md) {
            justify-content: center;
            margin-top: 5rem;
        }
        @media only screen and (device-width: 1024px) 
        and (device-height: 1366px) {
            margin-top: 8rem !important;
        }
    }
    .ob-contentBlock-textContent-inner {
        text-align: center;
    }
    .ob-heading {
        color: #FFF;
        width: 100%;
        font-weight: bold;
        @include media-down(md) {
            width: 80%;
        }
    }
    .ob-bodyText {
        color: #FFF;
        width: 90%;
        line-height: 26px;
        margin: 0 auto;
        margin-top: 17px;
        @include media-down(md) {
            font-size: 15px;
            width: 100% !important;
        }
    }
    
}

.post-purchase-app-section {
    margin-top: 6rem;
    margin-bottom: 2.5rem;
    scroll-margin-top: 10rem;
    .ob-contentBlock-textContent {
        justify-content: center;
    }
    .ob-background-wrapper {
        min-height: 1400px !important;
        width: 100%;
        margin: 0 auto;
        @include media-down(md) {
            min-height: 1250px !important;
        }
        @include media-down(sm) {
            min-height: 1550px !important;
        }
        @media only screen and (min-device-width: 768px) 
        and (max-device-width: 1020px) {
            min-height: 1370px !important;
        }
    }
    .ob-contentBlock-textContent {
        align-items: flex-start;
        padding-top: 5%;
        @include media-down(md) {
            margin-top: 10%;
        }
    }
    .ob-contentBlock-textContent-inner {
        width: 100%;
        text-align: center;
    }
    .ob-heading {
        width: 70%;
        color: #FFF;
        font-weight: bold;
        @include media-down(md) {
            width: 90%;
        }
        @include media-down(sm) {
            width: 50%;
        }
        @include media-down(xs) {
            width: 90%;
        }
    }
    .ob-bodyText {
        color: #FFF;
        width: 50%;
        margin: 0 auto;
        margin-top: 2rem !important;
        margin-bottom: 1.5rem;
        @include media-down(md) {
            width: 80%;
            font-size: 16px;
        }
        @include media-down(sm) {
            width: 51%;
        }
        @include media-down(xs) {
            width: 100%;
        }
    }
    &__options {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 80%;
        margin: 0 auto;
        justify-content: space-between;
        @include media-down(md) {
			flex-direction: column;
		}
        @media only screen and (min-device-width: 768px) 
        and (max-device-width: 1024px) {
            flex-direction: row !important;
            max-width: 100% !important;
        }
    } 
    &__option {
        display: flex;
        flex-direction: column;
        height: 171px;
        width: 251px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: transparent;
        align-items: center;
        @include media-down(md) {
			margin-top: 3rem;
            height: 200px;
		}
        @media only screen and (min-device-width: 768px) 
        and (max-device-width: 1020px) {
            height: 233px !important;
            width: 200px !important;
        }
    }
    &__icon {
        height: 78px;
        width: 78px;
        img {
            max-height: 78px;
        }
    }
    &__title {
        color: #FFFFFF;
        @include font-family('hurme-regular');
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 2px;
        font-weight: bold;
    }
    &__description {
        width: 251px;
        color: #FFFFFF;
        @include font-family('hurme-regular');
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 22px;
        text-align: center;
        margin-top: 0;
        @media only screen and (min-device-width: 768px) 
        and (max-device-width: 1020px) {
            width: 220px !important;
        }
    }
    &__app-icons {
        width: 38%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: -12rem;
        @include media-down(md) {
            width: 40%;
            margin-top: 5rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        @include media-down(sm) {
            margin-top: 3rem !important;
        }
        @include media-down(xs) {
            margin-top: 2rem !important;
        }
        img {
            min-height: 60px;
            min-width: 190px;
            @include media-down(md) {
                min-height: 65px !important;
                min-width: 200px !important;
                margin-bottom: 1rem;
            }
        }
    }
    .ob-contentBlock-disclaimer {
        bottom: 2rem;
        @include media-down(md) {
            bottom: -22rem;
        }
        @include media-down(sm) {
            bottom: -21rem;
        }
    }
    .ob-note-text {
        width: 38%;
        margin: 0 auto;
        text-align: center;
        font-size: 15px;
        color: #3D3D41;
        margin-top: 1.5rem;
        line-height: 18px;
        @include media-down(md) {
            width: 55%;
        }
        @include media-down(xs) {
            width: 90%;
        }
    }
}
.ob-skip-navigation:focus{
    border: 2px solid $base-color-outline-blue;
  }

.ob-contentBlock.content.pre-purchase-pcp{
    .ob-background-wrapper{
        padding-top: 0px!important;
        min-height: 655px!important;
        @media screen and (min-width: 768px) and (max-width: 1024px){
            height:100%;
            padding-top: 0px !important;
            background-size: contain;
            background-position: top;
        }
    }
    .ob-heading.ob-display-2-xl{
        font-size: 3.6rem;
        line-height: 50px;
        width: 75%;
        color: #FFFFFF;
        .highlight{
            font-weight: bold;
            color: #FFFFFF;
            text-shadow: 1px 0px 3px;
        }
        @include media-down(sm){
            font-size: 2rem;
            width: 90%;
            line-height: 30px;
        }
        @media screen and (min-width: 768px) and (max-width: 1024px){
            width: 80%;
            font-size: 2.5rem;
            line-height: 25px;
        }
    }
    .ob-bodyText{
        width: 75%;
        margin: 0 auto;
        color: #FFFFFF;
        p{
            font-size: 1.8rem;
            @include media-down(sm){
                font-size: 1.5rem;
                line-height: 20px;
            }
            @media screen and (min-width: 768px) and (max-width: 1024px){
                font-size: 1.5rem;
                line-height: 16px;
            }
        }
        @include media-down(sm){
            width: 100%!important;
        }
        
    }
    .ob-contentBlock-textContent-obLink-container{
        a{
            width: 50%;
            background-color: #FFFFFF;
            span{
                left:35%;
                color: $base-color-dark-blue;
                @include media-down(sm){
                    left: 25%;
                }
                @media screen and (min-width: 768px) and (max-width: 1024px){
                    left:30%;
                }
            }
            @include media-down(sm){
                margin-top: 0px;
            }
        }

    }
}
.layout-announcement-bar-hidden #main {
    margin-top: 16.2rem;
    @include media-down(sm) {
        margin-top: 6.9rem;
    }
}

.layout-announcement-bar-hidden .collection-toothbrush-body-zone.ob-main-layout-align#main {
    margin-top: 162px;
    @include media-down(sm) {
        margin-top: 69px;
    }
}

.home-body-zone.ob-main-layout-align#main {
    margin-top: 112px;
    @include media-down(sm) {
        margin-top: 69px;
    }
}

.home-body-zone.ob-main-layout-align-padding#main {
    margin-top: 0px;
    @include media-down(sm) {
        margin-top: 0px;
    }
}

#main {
    margin-top: 162px;
    @include media-down(sm) {
        margin-top: 69px;
    }
    @media only screen and (min-width:768px) and (max-width:820px)  {
        margin-top: 0px;
    }

}

.home-body-zone#main{
    margin-top: 112px;
    @include media-down(sm) {
        margin-top: 69px;
    }
}

.post-purchase#main{
    margin-top: 85px;
    @include media-down(sm) {
        margin-top: 60px
    }
}
.pre-purchase#main{
    margin-top: 0px;
    @include media-down(sm) {
        margin-top: 0px
    }
}
.pre-purchase-result#main{
    margin-top: 85px;
    @include media-down(sm) {
        margin-top: 23px
    }
}

.collection-toothbrush-body-zone#main {
    @media only screen and (min-width: 1024px) and (max-width: 1030px){
        margin-top: 120px;
    }
    @include media-down(sm) {
        margin-top: 69px
    }
}

.ob-zone-body-article-legacy.ob-main-layout-align-padding#main{
    @include media-down(sm) {
        margin-top: 67px
    }
}
.collection-toothbrush-body-zone.ob-main-layout-align-padding#main {
    margin-top: 162px;
    @include media-down(sm) {
        margin-top: 69px;
    }
}

.ob-search#main{
    @include media-down(sm) {
        margin-top: 162px;
    }
}

.ob-search.ob-main-layout-align-padding#main{
    @include media-down(sm) {
        margin-top: 47px;
    }
}
.etb-full-body-zone#main {
    margin-top: 0px;
    #ob-product-subnav-scroll {
        @include media-down(sm) {
            position: fixed !important;
            z-index: 999 !important;
        }
    } 
    .ob-product-highlights {
        &__header {
            @include media-down(sm) {
                margin-top: 223px;
            }
        }
    }   
}
.product-legacy-body-zone#main {
    margin-top: 0px;
    #ob-product-subnav-scroll {
        @include media-down(sm) {
            position: fixed !important;
            z-index: 999 !important;
        }
    }    
}

.rbh-io-zone#main {
    margin-top: 0px;
    #ob-product-subnav-scroll {
        @include media-down(sm) {
            position: fixed !important;
            z-index: 999 !important;
        }
    }
}

.ob-home-layout {
    .breadcrumb-main {
        display: none;
    }
}

.instantlycalm{
    font-size: 2.2rem!important;
    letter-spacing: 0.031rem!important;
    .ob-background-wrapper{
        min-height: 34.375rem!important;
        .parallax-outer{
            .parallax-inner{
                .ob-eyebrow{
                    font-size: 1.6rem!important;
                    letter-spacing: 0.031rem!important;
                }
                .ob-heading{
                    font-size: 5.5rem!important;
                    letter-spacing: 0.031rem!important;
                }
                .ob-contentBlock-textContent-obLink-container{
                    .ob-button{
                    font-size: 1.8rem!important;
                    height: 4.6rem!important;
                    border-radius: 4.5rem!important;
                    }
                }
            }
        }
    }
}

//Global Equity
.ob-main-menu__subNav--btn{
    .ob-main-menu__subNav--btn-icon{
        .ob-icon.ob-icon--chevronDown{
            fill: $base-color-dark-blue  !important;
        }
    }
}

.ob-main-menu__subNav-list{
    .ob-sub-navigation{
        a svg.ob-icon.ob-icon--ovalArrowRight{
            fill: $base-color-dark-blue !important;
            color: $base-color__white;
            width:2.6rem;
            margin: -0.3rem 1rem 0 -3.5rem;
        }
    }

    .ob-main-menu__subNav--close-btn{
        svg.ob-icon.ob-icon--close {
            fill: $base-color-dark-blue !important;
        }
    }
}

.zone.toothpaste{
    .ob-contentBlock-textContent-inner {
        .parallax-outer{
            .ob-eyebrow{
                color: $base-color-dark-blue;
            }
        }
    }

    #whitening-solutions{
        .ob-contentBlock-textContent-inner {
            .parallax-outer{
                .ob-eyebrow{
                    color: $base-color__white;
                }
            }
        }
    }

    #specialised{
        .ob-contentBlock-textContent-inner {
            .parallax-outer{
                .ob-eyebrow{
                    color: $base-color__white;
                }
            }
        }
    }
}

.home-layout{
    .home-body-zone{
        .ob-app-download__home{
            .ob-app-download-backdrop-wrapper{
                .ob-app-download-stores{
                    .ob-app-download-store + .ob-button.event_button_click{
                        background: $base-color-dark-blue;
                        color: $base-color__white;

                        &:hover, 
                        &:focus{
                            background: $base-color-dark-blue-hover;
                        }
                    }
                }
            }
        }
    }
}

.ob-comparison-chart-table {
    .find-retailer-cta {
        .ob-button {
            background: #002d72;
            color: #fff;
            @include font-family('hurme-regular');
            
            &:hover{
                background:$base-color-dark-blue-hover;
                text-decoration: underline;
            }
        }
    }
}

.zone.body-zone-article-landing-why-oral-b.ob-main-layout-align{
    .ob-contentBlock.content.oral-b-electric-toothbrushes{
        .ob-eyebrow{
            a{
                color:$base-color__white;
            }
        }
    }
}

.zone.etb-full-body-zone{
    .ob-gallery.gallery{
        .ob-background-wrapper  {
            min-height: 750px;
            @media screen and (min-width: 992px){
                min-height: 850px;
            }
            @media only screen and (min-width: 1800px){
                min-height: 1050px;
            }
        }
    }
}


.zone.home-body-zone{
    .ob-homepage-hero-block{
        .ob-background-wrapper{
            @media screen and (min-width: 992px){
                min-height: 840px;
            } 
            @media screen and (min-width: 992px) and (max-width: 1440px){
                min-height: 750px !important;
            }
            @media screen and (min-width: 1500px) and (max-width: 1540px){
                min-height: 800px !important;
            }     
            @media only screen and (min-width: 1800px){
                min-height: 1050px !important;
            }
            @media only screen and (min-width: 2000px){
                min-height: 1200px !important;
            }
            @media only screen and (min-width: 2560px){
                min-height: 1300px;
            } 
            @media only screen and (min-width: 3840px){
                min-height: 2120px;
            }
        }
    }
}

.zone.manual-toothbrushes{
    .ob-cb-manual-tb-fitForEveryMouthBlock{
        .ob-background-wrapper{
            @media screen and (min-width: 992px){
                min-height: 730px;
            } 
            @media screen and (min-width: 992px) and (max-width: 1440px){
                min-height: 680px;
            }        
            @media only screen and (min-width: 1800px){
                min-height: 905px;
            }
            @media only screen and (min-width: 2800px){
                min-height: 1400px;
            }
            @media only screen and (min-width: 3840px){
                min-height: 1860px;
            }
        } 
    }
}

.zone.kids-products-by-age-group,
.zone.kids-products-by-character,
.zone.kids-products-by-product-type{
    .ob-healthy-soultion-for-kids{
        .ob-background-wrapper{
            @media screen and (min-width: 992px){
                min-height: 630px;
            } 
            @media screen and (min-width: 992px) and (max-width: 1440px){
                min-height: 525px;
            }     
            @media only screen and (min-width: 1800px){
                min-height: 790px;
            }
            @media only screen and (min-width: 2800px){
                min-height: 1200px;
            }
            @media only screen and (min-width: 3840px){
                min-height: 1580px;
            }
        }
    }
}
.zone.footer{
    .io10-spotlight-waitlistBlock{
        button{
            padding: 0 3rem;
        }
    }
}

//End of Global Equity Viz ID changes

.zone.home-body-zone{
    .ob-perfect-duo{
        .ob-background-wrapper{
            @include media-down(sm){
                min-height: 625px;
            }
            @media screen and (min-width:360px) and (max-width:362px){
                min-height: 525px;
            }
            @media screen and (min-width:390px) and (max-width:392px){
                min-height: 570px;
            }
            @media screen and (min-width: 992px){
                min-height: 840px;
            } 
            @media screen and (min-width: 992px) and (max-width: 1440px){
                min-height: 700px;
            }   
            @media screen and (min-width: 768px) and (max-width: 770px){
                min-height: 430px;
                height: auto;
            }
            @media screen and (min-width: 1024px) and (max-width: 1030px){
                min-height: 565px;
                height: auto;
            }      
            @media only screen and (min-width: 1800px){
                min-height: 1055px;
            }
            @media only screen and (min-width: 2800px){
                min-height: 1590px;
            }
            @media only screen and (min-width: 3200px){
                min-height: 2115px;
            }
            @media only screen and (min-width: 3840px){
                min-height: 2115px;
            }
            .ob-contentBlock-textContent-obLink-container{
                position: absolute;
                bottom: 30%;
                left: -2%;
                @include media-down(sm){
                    bottom: 39%;
                    right: auto;
                    top: auto;
                    width: 100%;
                    left:15%;
                }
                @media screen and (min-width: 992px){
                    left: 5%;
                }
                @media screen and (min-width: 992px) and (max-width: 1440px){
                    left:12%;
                }
                @media screen and (min-width: 768px) and (max-width: 772px) and (max-height: 1024px){
                    bottom: 13%;
                    left: 5%;
                }
                @media screen and (min-width: 1024px) and (max-width: 1028px) and (max-height: 1366px){
                    bottom: 13%;
                    left: 9%;
                }
                @media only screen and (min-width: 1800px){
                    left: -1%;
                }
                @media screen and (min-width: 2000px) and (max-height:1800px){
                    left: -27%;
                }
                @media screen and (min-width: 2400px){
                    left: -15%;
                }
                @media screen and (min-width: 2400px) and (min-height:1800px){
                    left:-22%;
                }
                @media screen and (min-width: 2400px) and (min-height:2160px){
                    left:-15%;
                }
                @media screen and (min-width: 2800px){
                    left: -24%;
                }
                @media screen and (min-width: 2400px) and (min-height:2160px){
                    left:-44%;
                }
                @media screen and (min-width: 3000px){
                    left:-40%;
                }
                @media screen and (min-width: 3500px){
                    left: -39%;
                }
                @media screen and (min-width: 3700px){
                    left: -44%;
                }
            }
        } 
    }
}

.ob-io-experience-gltblock {
    height: 700px;
    position: relative;
    z-index: 89;
    background: linear-gradient(to right, #050819, #070a1b);
    @include media-down(md){
        height: 500px;
    }
    iframe{
        @include media-up(md){
            width: 70% !important;
            display: block !important;
            margin: 0 auto !important;
        }
    }
}

.zone.body-zone-article-landing-why-oral-b {
    .ob-how-it-works {
      svg {
        fill: $base-color-dark-blue !important;
        &:hover {
          fill: $base-color-dark-blue-hover;
        }
      }
    }
  }
  .three-module{
    .ob-contentBlock-textContent-inner {
        @media screen and (min-width: 1435px) {
            width: 42%;
        }
        @include media-down(sm){
            margin-top: 25%;
        }
    }
    &[data-respectimageaspectratio=false] .ob-background-wrapper {
        padding-top: 48.6112% !important;
        height: 100vh;
    }
    .ob-contentBlock-wrapper{
        max-width: unset;
    }
  }
    .second-block{
        .ob-contentBlock-textContent-inner {
            @include media-down(sm){
                margin: 10% 0px 85px 15% !important;
            }
        }
        .ob-button{
            margin-top: 21vw;
            margin-left: 18vw;
            width: 17vw;
            @media screen and (min-width: 1600px) {
                width: 14vw;
            }
            @include media-down(sm){
                margin-top: 0vw;
                margin-left: 4vw;
                width: 57vw;            
            }
        }
        &[data-respectimageaspectratio=false] .ob-background-wrapper {
            padding-top: 48.7805% !important;
            height: 77vh;
            @include media-down(sm){
                height: 102vh;
            }
        }
    }
    .fourth-block{
        &[data-respectimageaspectratio=false] .ob-background-wrapper{
            padding-top: 41.0509% !important;
            height: 77vh;
        }
        .fouth-block-disclaimer{
            margin-left: 13vw;
        }
    }
