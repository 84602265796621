@import "../../static/styles/mixins";

.ob-congrats {
  margin: 0 auto 8rem;
  max-width: 87vw;
  @include media-up(md) {
    margin-top: 5rem;
  }
  @include media-down(md) {
    margin-top: 3rem;
  }
  @include media-up(lg) {
    max-width: 81rem;
  }

  &__title {
    @include display-2-xl;
    @include media-up(lg) {
      font-size: 4.6rem;
    }
  }

  &__body-text {
    @include font-family('hurme-regular');
    color: #4A4A4A;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 30px;
    margin: 15px 0;
  }

  &__print-link {
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
    &:hover, &:focus {
      text-decoration: none;
      .ob-congrats__print-icon {
        transform: translateX(5px);
      }
    }
  }

  &__print-link-text {
    color: $base-color-dark-blue;
    font-size: 1.6rem;
  }

  &__print-icon {
    transition: transform ease 0.35s;
    margin-left: 0.5rem;
    margin-bottom: 0.3rem;
  }

  &__product-info-list {
    padding: 0;
    list-style-type: none;
    margin: 3rem 0;
    line-height: 38px;
    @include media-down(md) {
      margin: 2rem 0;
      line-height: 36px;
    }
  }

  &__product-info-item {
    margin: 1.5rem 0;
    color: #4A4A4A;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;

    @include media-up(sm) {
      font-size: 20px;
      line-height: 28px;
    }
    .ob-bold {
      color: #4A4A4A;
    }
  }

  &__disclaimer {
    color: #4A4A4A;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;

  }

  &__questions {
    color: #4A4A4A;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 26px;
    margin: 3rem 0 1.5rem;
    @include media-down(md) {
      margin: 2rem 0 1rem;
      font-size: 14px;
    }
  }

  &__mailsent-info {
    color: #4A4A4A;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 1.5rem;
    @include media-down(md) {
      margin-bottom: 1rem;
    }
  }

  &__go-to-profile {
    font-size: 1.8rem;
    padding: 3.2rem 4rem;
    @include media-down(md) {
      width: 100%;
      justify-content: center;
      font-size: 13px;
    }
  }

  &__resend-email-link {
    color: $base-color-dark-blue;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 30px;
    @include media-up(sm) {
      margin-left: 2.2rem;
    }
    @include media-down(md) {
      margin-left: 2rem;
      margin-top: 15px;
    }
  }
  &__terms-and-condition {
    height: auto;
    width: 100%;
    color: #4A4A4A;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px;
    b{
      font-weight: bold;
    }
    margin-top: 3rem;
    @include media-down(md) {
      margin-top: 2rem;
    }
  }
  &__terms-and-condition-bottom {
    margin-top: 0;
  }
  &__modal-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ob-profile__modal-cancel-btn.ob-button {
      margin: 0 auto;
    }
  }
  .ob-mainAsset-wrapper {
    .ob-mainAsset-wrapper-img {
      margin: 7px 0;
      width: 9%;
      @include media-down(md) {
        width: 22%;
      }
    }
  }
}

.ob-congrats__modal-overlay.ob-profile__modal-overlay .ReactModal__Content--after-open {
  @include media-up(sm) {
    width: 40rem;
    height: 35rem;
  }
}

@media print {
  div.zone:not(.zone-congratulations),
  .ob-congrats__questions,
  .ob-congrats__go-to-profile,
  .ob-congrats__print-link {
    display: none;
  }

  .optanon-alert-box-wrapper {
    display: none !important;
  }

  div.zone-header {
    display: block !important;
    .zone.announcement, .ob-main-menu__list {
      display: none;
    }
    .ob-main-menu__logo--desktop {
      display: block;
    }
  }
  .congratulations-layout {
    padding: 30px !important;
  }
}
