@import "../../static/styles/mixins";

.ob-brand-guarantee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    padding: 1rem;
    top: 0;
    list-style-type: none;
    letter-spacing: 0;
    text-align: center;
    @include body-4-xs();
    color: $base-color-dark-blue;

    @include media-up(md) {
        padding: 1rem 3rem;
        justify-content: center;
    }

    ul {
        list-style-type: none;
    }

    .ob-brand-guarantee__claim {
        padding: rem(22) rem(40);
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;

        .ob-brand-guarantee__icon {
            font-size: rem(60);
            margin: 0 10px 0 0;
            @include media-down(md) {
                font-size: rem(50);
                margin: 0 0 5px 0;
            }
        }
        .ob-brand-guarantee__statistic {
            font-size: rem(22);
            line-height: rem(30);
            text-decoration: underline;
            text-underline-position: under;
            @include media-down(md) {
                font-size: rem(18);
                line-height: rem(25);
            }
        }

        @include media-down(md) {
            width: 100%;
            flex-direction: column;

            &:before {
                content: '';
                display: block;
                height: 1px;
                position: absolute;
                top: 0;
                background-color: $base-color-secondary-grey;
                left: 26px;
                right: 26px;
            }
    
            &:first-child {
                &:before {
                    display: none;
                }
            }
        }

        @include media-down(sm) { 
            &:first-child {
                &:before {
                    display: block;
                }
            }
        }
    }

    .ob-brand-guarantee__cta-link {
        padding: 1rem 0;
        width: 100%;

        a {
            font-weight: bold;
            color: $base-color-dark-blue;
        }

        .ob-icon {
            margin-left: 0.5rem;
            margin-top: 0.6rem;
        }
    }
}
