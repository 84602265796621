@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-underline-tabs-navigation {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-snap-stop: always;
    padding: 0;
    justify-content: center !important;
    @include no-scrollbar;

    &::after {
        content: '';
        display: block;
        width: 25%;
        flex: none;
        @include media-up(sm) {
            width: 0;
        }
    }
}

.ob-underline-tabs-navigation-tab {
    scroll-snap-align: center;
    cursor: pointer;
    flex: none;
    width: 50%;
    display: block;
    padding: 1rem 1rem 3rem 1rem;
    text-align: center;
    font-size: rem(16);
    @include font-family('semi-bold');

    &:link, &:visited, &:hover, &:active {
        text-decoration: none;
    }

    &:link, &:visited {
        color: $base-color-primary-grey;
    }

    &:hover,
    &:focus {
        color: $base-color-dark-blue;

        .ob-underline-tabs-navigation-tab__content::after {
            content: '';
            display: block;
            position: absolute;
            height: 4px;
            top: 3.8rem;
            background-color: $base-color-dark-blue;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .ob-underline-tabs-navigation &:first-child {
        margin-left: 25%;
        @include media-up(sm) {
            margin-left: 0;
        }
    }

    .ob-underline-tabs-navigation-tab__content {
        position: relative;
    }

    @include media-up(sm) {
        flex: 1;
        width: auto;
    }
}

.ob-underline-tabs-navigation-tab--active {
    color: $base-color-dark-blue;

    &::after {
        background-color: $base-color-dark-blue;
    }
}

//Global Equity
.kids-products-by-character{
    .ob-underline-tabs-navigation{
        .ob-underline-tabs-navigation-tab{
            &:hover{
                color: $base-color-dark-blue;

                .ob-underline-tabs-navigation-tab__content::after{
                    background-color: $base-color-dark-blue;
                }
            }
        }
    }
}

.zone.toothpaste{
    .ob-collection-tabs{
        .ob-underline-tabs-navigation{
            .ob-underline-tabs-navigation-tab{
                @include font-family('hurme-light');
                font-weight: 400;

                &:hover{
                    color: $base-color-dark-blue;
                }
            }
        }
    }
}

.ob-collection-tabs{
    .ob-collection-tabs-block__tabs-wrapper{
        .ob-underline-tabs-navigation{
            .ob-underline-tabs-navigation-tab{
                &::after {
                    content: '\e912';
                    display: block;
                    font-size: 8px;
                    font-family: 'icomoon', monospace !important;
                    color: $base-color__black;
                    padding-top: 9px;
                    background-color: transparent;
                    &:hover,
                    &:focus {
                        color: $base-color-dark-blue;
                        font-size: 8px;
                        font-family: 'icomoon', monospace !important;
                    }
                }
                
                &:hover,
                &:focus {
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        height: 0;
                        top: 3.8rem;
                        background-color: transparent;
                        color: $base-color-dark-blue;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
} 