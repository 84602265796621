@import '../../static/styles/mixins';

.ob-side-by-side {

    &__title-wrapper {
        text-align: center;
        max-width: 80%;
        margin: rem(30) auto;
        @include media-up(sm) {
            margin: rem(50) auto rem(50);
        }
        .ob-heading {
            @include font-family('hurme-light');
            font-size: rem(28);
            @include media-up(sm) {
                font-size: rem(48);
            }
        }
    }
    &__subtitle {
        @include body-1-xl;
    }
    &__wrapper {
        margin: 2rem 0;

        &--has-divider {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        @include media-up(sm) {
            max-width: calc(#{$breakpoint-xlarge} - 4rem);
            margin: 2rem auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .ob-magic-timer-side-by-side & {
                max-width: 1160px;
            }
        }

        .ob-side-by-side__home--ja-jp & {
            @include media-up(sm) {
                max-width: $breakpoint-xlarge;
                margin: 0 auto;
                display: block;
            }
            @include media-up(lg) {
                display: flex;
            }
        }

        .no-spacing & {
            margin: 0;
            max-width: 100%;
        }

        .ob-heading {
            padding-top: 1.8rem;
            @include display-1-xs;

            @include media-down(sm) {
                padding-top: 0.8rem;
            }
        }
    }
    &__column {
        margin: 0 1rem;
        @include media-up(sm) {
            margin: 0;
            width: calc(50% - 1rem);

            .no-spacing & {
                width: 50%;
            }
        }
        @include media-down(sm) {
            .no-spacing & {
                margin: 0;
                width: 100%;
            }
        }
        img {
            max-width: 100%;
        }

        .ob-side-by-side__home--ja-jp & {
            @include media-up(sm) {
                width: 100%;
            }

            @include media-up(lg) {
                width: 50%;
            }
        }
    }

    .ob-disclaimer {
        max-width: 80%;
        margin: 5rem auto -2rem;
    }


    &.ob-side-by-side__magic-timer__features {
        .ob-side-by-side__title-wrapper {
            max-width: 100%;

            @include media-down(sm) {
                width: 85%;
            }

            @include media-up(sm) {
                text-align: left;
                width: 67%;
            }
        }

        .ob-side-by-side__wrapper {
            border-bottom: none;
        }
    }

    &.ob-magic-timer-side-by-side {
        .ob-contentBlock.no-background {
            @include media-down(sm) {
                min-height: 300px;
            }
            @include media-up(xlg) {
                height: 500px;
            }
        }

        .ob-side-by-side__wrapper {
            @include media-down(sm) {
                margin: 2rem 1rem;
            }
        }
    }

    &.side-by-side-flex-start .ob-side-by-side__column {
        align-items: flex-start;
    }

    .ob-side-by-side__faq {
        p {
            font-size: 1.6rem;
        }
    }

    .side-by-side-flex-start {
        align-self: flex-start;
    }

    li >p {
        margin: 0;
    }

    &__coupons {
        max-width: 1440px;
        margin: 0 auto;
    }
}

h1 + .ob-side-by-side {
    @include media-down(sm) {
        margin-top: -4rem;
    }
}

