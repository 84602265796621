@import "../../static/styles/variables";
@import "../../static/styles/mixins";

@keyframes fadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes leftIn {
    0%   { margin-left: -250px; }
    100% { margin-left: 0; }
}

@keyframes topIn {
    0%   { top: 60px; }
    100% { top: 0; }
}

.ob-series-selector {
    background-color: #D6E1E8;
}

.ob-backdrop__img {
    @media screen and (max-width: $breakpoint-medium - 1) and (min-width: $breakpoint-small) {
        .genius-series & {
            left: 67%;
        }
    }
}

.ob-series-selector__content {
    padding: 0 0 rem(50);

    @include media-up(sm) {
        padding: 0 0 rem(40);
    }

    @include media-up(sm){
        .pro-series &, .vitality-series & {
            padding: 0 0 190px;
        }
    }
}

.ob-series-selector__top {
    padding: rem(9) 10px 0;

    @include media-up(sm) {
        padding: rem(50) 10px rem(5);
    }

    @include media-up(lg) {
        white-space: normal;

        .ob-series-selector__heading{
            line-height: 4.2rem;
        }
    }

    .ob-disclaimer {
        text-align: center;
    }
}

.ob-series-selector__heading {
    @include display-2-xs;
    @include font-family('hurme-light');
    max-width: 976px;
    margin: 0 auto;
    color: $base-color__white;
    text-transform: uppercase;
    text-align: center;
    animation: fadeIn 1.3s ease-in-out;

    @include media-up(sm) {
        font-size: rem(36);
        letter-spacing: 0;
    }
}

.ob-series-selector__series {
    margin: 0 0 55px;

    @include media-up(sm) {
        max-width: 976px;
        margin: 0 auto rem(35);
    }

    .ob-series-selector--fi-fi & {
        @include media-up(sm) {
            margin-bottom: 0;
        }

        @include media-up(md) {
            margin-bottom: rem(35);
        }
    }
}

.ob-series-selector__nav {
    background: white;
    animation: fadeIn 1.7s ease-in-out;

    @include media-up(sm) {
        background: none;
    }
}

.ob-series-selector__subtitle {
    @include font-family('hurme-regular');
    margin-bottom: rem(5);
    padding: 0 20px;
    color: $base-color__white;
    text-align: center;
    text-transform: uppercase;
    font-size: rem(16);
    line-height: (24em / 16);
    letter-spacing: (0.44em / 16);
    animation: fadeIn 1.5s ease-in-out;
    font-weight: 400;

    @include media-up(sm) {
        margin-bottom: rem(4);
        font-size: rem(18);
        line-height: (26em / 18);
        letter-spacing: (0.5em / 18);
    }
}

.ob-series-selector__nav-toggle {
    @include font-family('semi-bold');
    position: relative;
    display: block;
    width: 100%;
    padding: 16px rem(40) 8px;
    border: none;
    background: white;
    color: $base-color-dark-blue;
    appearance: none;
    text-align: center;
    font-size: rem(16);

    &:hover, &:focus {
        text-decoration: none;
        border:1px solid #990000;
        .ob-series-selector__nav-link-name, .ob-series-selector__nav-link-price {
            text-decoration: underline;
        }
    }

    &:focus {
        outline: 1px dotted black;
    }

    @include media-up(sm) {
        display: none;
    }
}

.ob-series-selector__nav-chevron {
    position: absolute;
    top: 50%;
    right: rem(15);
    margin-top: rem(-8);
}

.ob-series-selector__nav-collapsible {
    height: 0;
    opacity: 0;
    transform: translateY(-30px);
    pointer-events: none;
    overflow: hidden;

    .ob-series-selector__nav.enter &,
    .ob-series-selector__nav.exit & {
        transition: height 400ms ease-in-out, opacity 400ms ease-in-out, transform 400ms ease-in-out;
    }

    .ob-series-selector__nav.enter-active &,
    .ob-series-selector__nav.enter-done & {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
    }

    .ob-series-selector__nav.enter-done & {
        height: auto !important;
    }

    .ob-series-selector__nav.exit-active &,
    .ob-series-selector__nav.exit-done & {
        height: 0 !important;
    }

    @include media-up(sm) {
        height: auto !important;
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
    }
}

.ob-series-selector__nav-list {
    margin: 0;
    padding: 0 10px;
    list-style: none;

    @include media-up(sm) {
        display: flex;
        justify-content: space-evenly;
        border-top: 1px solid #929292;
    }
}

.ob-series-selector__nav-item {
    flex: none;
    flex-basis: 0;

    @include media-up(sm) {
        min-width: rem(120);
        padding: 0 rem(6);
    }

    @include media-up(md) {
        flex-basis: auto;
        padding: 0;
    }
}

.ob-series-selector__nav-link {
    @include font-family('hurme-regular');
    display: block;
    max-width: none;
    padding: 20px 0 15px;
    text-align: center;
    font-size: rem(16);

    &:hover, &:focus {
        text-decoration: none;
        border:1px solid #990000;
        .ob-series-selector__nav-link-name {
            text-decoration: underline;
        }
        .ob-series-selector__nav-link-price{
            text-decoration: none;
        }
    }

    &:focus{
        border: 2px solid $base-color-link-blue;
    }

    @include media-up(sm) {
        border-bottom: 4px solid transparent;

        &:focus {
            outline: none;
        }

        &:focus, &:hover {
            border-color: rgba($base-color__white, 0.1);
        }
    }

    .ob-series-selector__nav-item--active & {
        border-color: $base-color__white;
    }
}

.ob-series-selector__nav-item--active {
    display: none; // active item is already shown in collapsible toggle button

    @include media-up(sm) {
        display: block;
    }
}

.ob-series-selector__nav-link-name {
    text-transform: uppercase;
    margin-bottom: rem(4);
    color: $base-color__white;
    font-weight: 400;

    .ob-series-selector__nav-toggle &,
    .ob-series-selector__nav-item--active & {
        @include font-family('hurme-regular');
        font-weight: 400;

        @include media-up(sm) {
            letter-spacing: (0.57em / 16);
        }
    }

    @include media-up(sm) {
        white-space: nowrap;
    }
}

.ob-series-selector__nav-link-price {
    @include font-family('hurme-regular');
    color: $base-color__white;

    .ob-series-selector__nav-toggle &,
    .ob-series-selector__nav-item--active & {
        @include font-family('hurme-regular');
    }
}

.ob-series-selector__selected {
    padding: 2rem 1.5rem 0;

    .ob-series-selector--fi-fi & {
        @include media-up(sm) {
            padding-top: 0;
        }

        @include media-up(md) {
            padding-top: 2rem;
        }
    }
}

.ob-series-selector__selected-main {
    display: flex;
    max-width: 976px;
    margin: 0 auto;
}

.ob-series-selector__selected-image {
    flex: 1 1 auto;
    display: flex;
    width: 398px;
}

.ob-series-selector__selected-image-wrap {
    flex: 1 1 auto;
    width: 0;
}

.ob-series-selector__selected-image-pic {
    display: block;
    width: 100%;
    height: 100%;
}

.ob-series-selector__selected-image-img {
    display: block;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    animation: fadeIn 1.3s ease-in-out, leftIn 1s ease-in-out;
}

.ob-series-selector__selected-gap {
    flex: 1 1.6 auto;
    width: 123px;
}

.ob-series-selector__selected-info {
    flex: 1 1 auto;
    width: 455px;
    padding: 5px 0 30px;
    position: relative;
    animation: fadeIn 1.2s ease-in-out, topIn 1s ease-in-out;

    @include media-up(sm) {
        padding-top: 0;
    }
}

.ob-series-selector__selected-name {
    @include display-3-xl;
    margin: 0 0 rem(10);
    text-transform: uppercase;
    font-size: rem(24);
    letter-spacing: 0;
    color: $base-color__white;

    @include media-up(sm) {
        margin: 0 0 rem(14);
        letter-spacing: (0.5em / 48);
    }
}

.ob-series-selector__selected-description {
    @include font-family('hurme-regular');
    box-sizing: border-box;
    max-width: 455px;
    padding-right: 15px;
    font-size: rem(16);
    line-height: (24em / 16);
    color: $base-color__white;

    @include media-up(sm) {
        padding-right: 30px;
        font-size: rem(18);
        letter-spacing: (0.45em / 20);
    }

    p {
        margin: 0 0 rem(15);

        @include media-up(sm) {
            margin: 0 0 rem(22);
        }
    }
}

.ob-series-selector__selected-highlights {
    @include font-family('hurme-regular');
    margin: 0;
    padding: 0;
    list-style: none;
    color: $base-color__white;
    font-size: rem(16);
    line-height: (24em / 16);
    letter-spacing: (0.4em / 16);
}

.ob-series-selector__selected-highlights-item {
    display: flex;
    margin: rem(15) 0 rem(25);

    p {
        margin-bottom: 0;

        &:first-child {
            margin-top: 0;
        }
        a{
            text-decoration: underline;
            &:focus{
                border: 2px solid $base-color-link-blue;
            }
        }
    }
}

.ob-series-selector__selected-highlights-icon {
    flex: none;
    display: flex;
    justify-content: center;
    width: rem(25);
    margin-right: rem(2);
    font-size: rem(10);

    @include media-up(sm) {
        width: rem(31);
    }
}

.ob-series-selector__selected-highlights-bullet {
    flex: none;
    box-sizing: border-box;
    width: rem(25);
    margin-right: rem(2);
    padding-left: 2px;

    @include media-up(sm) {
        width: rem(31);
        padding-left: 8px;
    }
}

.ob-series-selector__selected-highlights-text {
    flex: 1 1 auto;

    a {
        color: $base-color__white;
        text-decoration: underline;

        &:hover {
            color: $base-color-dark-blue-hover;
        }
    }

    .series-explore-all-features {
        a {
            color: $base-color__white;
            text-decoration: underline;

            &:hover {
                color: $base-color-dark-blue-hover;
            }
        }
    }
}

.ob-series-selector__selected-subheading {
    @include text-link-1-xs;
    margin: 0;
    text-transform: uppercase;

    @include media-up(sm) {
        margin: 0 0 rem(12);
    }
}

.ob-series-selector__selected-subheading--options {
    display: none;

    @include media-up(sm) {
        display: block;
    }
}

.ob-series__color {
    display: block;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border: 1px solid #00002E;
    border-radius: 50%;
    text-align: center;
    background: none;
    color: transparent;
    text-indent: -999em;
    overflow: hidden;
    transition: box-shadow 0.2s ease-in-out;

    &:focus,
    &:hover {
        box-shadow: 0 0 5px rgba($base-color-dark-blue, 0.5);
    }

    &.ob-series__color--large-mobile {
        width: 45px;
        height: 45px;
    }

    @include media-up(sm) {
        width: 45px;
        height: 45px;
    }

    &.ob-series__color--active {
        border: 2px solid $base-color-dark-blue;
    }
}

button.ob-series__color {
    &:focus {
        outline: none;
    }
}

.ob-series-selector__selected-options {
    margin-top: rem(10);
    padding-top: rem(25);
    border-top: 1px solid rgba(black, 0.1);

    @include media-up(sm) {
        margin-top: rem(20);
    }

    ul {
        padding: 0;
        list-style: none;
    }

    li {
        @include font-family('semi-bold');
        margin: rem(14) 0;
        font-size: rem(14);
        line-height: (22em / 14);
        letter-spacing: (0.44em / 14);

        @include media-up(sm) {
            font-size: rem(16);
        }
    }
}

.ob-series-selector__selected-options-text {
    @include eyebrow-1-xl;
}

.ob-series-selector__selected-info-buy {
    display: none;

    @include media-up(sm) {
        display: block;
    }
    a:focus{
        border: 2px solid $base-color-link-blue;
    }
    button:focus{
        border: 2px solid $base-color__white;
    }
}

.ob-series-selector__selected-buy {
    padding: 0 14px;
    .ob-series-selector--sv-se & {
        padding: 0 8px;
        @include media-up(sm) {
            padding: 0 14px;
        }
    }

    @include media-up(sm) {
        display: none;
    }
}

.ob-series-selector__selected-buy-heading {
    margin: 0 0 rem(26);
    text-align: center;

    @include media-up(sm) {
        margin: rem(30) 0 rem(15);
        text-align: left;
    }
}

.ob-series-selector__selected-buy-heading-link {
    @include text-link-2-xs;

    &, &:link {
        text-decoration: underline;
        color: $base-color__white;
    }

    &:hover, &:focus, &:active {
        text-decoration: none;
        color: $base-color-dark-blue-hover;
    }

    &:focus{
        border: 2px solid $base-color-link-blue;
    }

    .ob-series-selector--sv-se & {
        @include media-up(md) {
            display: block;
            width: 85%;
        }
    }
}

.ob-series-selector__selected-buy-heading-link--experience {
    font-size: rem(14);
    letter-spacing: (0.44em / 14);
}

.ob-series-selector__selected-buy-heading-icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: rem(-2);
    margin-left: rem(5);
}

.ob-series-selector__selected-buy-btn {
    background: $base-color__white;
    color: $base-color-dark-blue;
    width: 100%;
    height: 48px;
    padding-left: rem(54);
    padding-right: rem(54);
    font-size: 18px;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.5px;
    font-weight: 400;
    
    &:focus{
        border: 2px solid $base-color-link-blue;
    }

    &:link {
        color: $base-color-dark-blue;

        &:hover {
            color: $base-color__white;
        }
    }

    @include media-up(sm) {
        width: auto;
        padding-left: rem(56);
        padding-right: rem(56);
    }
}

.ob-series-comparison {
    display: flex;
    background: $base-color-tertiary-off-white;
    position: relative;

    .ob-disclaimer {
        max-width: 1332px;
        padding: 0 0 3rem 2rem;
        margin: 0 auto;
    }
}

.ob-series-comparison__sizer {
    flex: 1 1 auto;
    width: 0;
}

.ob-series-comparison__title {
    @include display-2-xs;
    margin: 0 auto;
    color: $base-color-primary-grey;
    text-transform: uppercase;
    text-align: center;
    animation: fadeIn 1.3s ease-in-out;
    margin-top: 3rem;

    @include media-up(sm) {
        @include font-family('hurme-light');
        font-size: rem(36);
    }
}

.ob-series-comparison__list {
    display: flex;
    width: 100vw;
    margin: 0;
    padding: 20px 0;
    list-style: none;
    overflow: auto;
    scroll-snap-type: x mandatory;
    scroll-padding-left: 5px;
    -webkit-overflow-scrolling: touch;

    @include media-up(sm) {
        padding: 0 0 20px;
        scroll-padding-left: 0;
    }
}

.ob-series-comparison__item {
    scroll-snap-align: start;
    flex: 0 1 auto;
    display: flex;
    width: 444px;
    margin: 0 5px;
    padding: 20px 0;
    border-radius: rem(15);
    background: $base-color-secondary-off-white;

    @include media-up(sm) {
        scroll-snap-align: center;
        margin: 0;
        background: none;

        &:first-child {
            margin-left: auto;
        }

        &:last-child {
            margin-right: auto;
        }
    }

    @include media-ie() {
        flex: 1 0 auto;
    }
}

.ob-series-comparison__product {
    flex: 0 1 auto;
    display: flex;
    width: 90vw;
}

.ob-series-comparison__product-pic {
    flex: 1 1 auto;
    display: flex;
    width: 118px;
    margin: 0 0 -10px;

    @include media-up(sm) {
        margin-right: 5px;
    }
}

.ob-series-comparison__product-pic-wrap {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 0;
}

.ob-series-comparison__product-pic-image {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;

    img {
        flex: none;
        max-width: 100%;
        max-height: 100%;
    }
}

.ob-series-comparison__product-info {
    flex: 1 1 auto;
    box-sizing: border-box;
    width: 320px;
    padding: 10px 10px 0 10px;
}

.ob-series-comparison__product-name {
    margin: 0 0 rem(18);
}

.ob-series-comparison__product-name-link {
    display: inline-block;
    line-height: (16em / 14);
    border-bottom: 1px solid $base-color-dark-blue;

    html[lang="pt"] & {
        border-bottom: none;
    }

    &, &:link, &:hover {
        text-decoration: none !important;
    }
    &:focus{
        border: 2px solid $base-color-link-blue;
        outline: solid 2px red;
    }
    &:hover {
        color: $base-color-dark-blue-hover;
    }
}

.ob-series-comparison__product-buy-btn {
    width: auto;
    padding: rem(4) rem(25);
    font-size: rem(16);
    text-align: center;
    justify-content: center;
    margin-bottom: 2rem;

    @include media-up(sm) {
        width: auto;
    }
}

.ob-series-comparison__product-subheading {
    @include text-link-1-xs;
    margin: 0;
    text-transform: uppercase;
    margin-top: 1rem;
    @include media-up(sm) {
        margin: 1.5rem 0 .5rem;
    }
}

.ob-series-comparison__product-colors {
    width: 100%;
    margin-top: rem(10);
    padding-top: rem(16);
}

.ob-series-comparison__product-colors-list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 10px);
    margin: 0;
    padding: 0;
    list-style: none;
}

.ob-series-comparison__product-colors-item {
    flex: none;
    margin: 5px 10px 5px 0;
}

.ob-series-comparison__product-options-text {
    @include eyebrow-1-xl;
    @include font-family('hurme-regular');
    margin-top: 0;
    padding-top: rem(5);
    font-size: rem(14);

    @include media-up(sm) {
        font-size: rem(14);
    }

    ul {
        padding: 0;
        list-style: none;
    }

    li {
        margin: rem(15) 0;
        font-weight: 400;
        line-height: 18px;
    }
}

.ob-series-selector [data-bv-show=rating_summary] {
    margin-top: rem(-14);
    margin-bottom: rem(-9);

    @include media-up(sm) {
        margin-bottom: 0;
    }
}

.ob-series-comparison [data-bv-show=rating_summary] {
    margin-top: -2.5rem;
    margin-bottom: 1.5rem;
}

.ob-series-selector.ob-backdrop {
    @include media-up(sm) {
        max-height: 1175px;
    }

    &.ob-collection--tall {
        @include media-up(sm) {
            max-height: 1365px;
        }
        @include media-up(md) {
            max-height: 1200px;
        }
    }
}

/* Global Equity Visual */
.ob-series {
    .ob-series-selector__content.ob-backdrop__content {
        .ob-disclaimer {
            color: $base-color__white;
            @include font-family('hurme-regular');
        }

        .bv_main_container {
            .bv_text {
                color: $base-color__white  !important;
                @include font-family('hurme-regular');
            }
        }

        .bv_incentivized_review_container.bv_text{
            color: $base-color__white  !important;
            @include font-family('hurme-regular');
        }
    }

    .ob-series-comparison__list {
        .bv_averageRating_component_container {
            .bv_text {
                @include font-family('hurme-regular' !important)
            }
        }
    }
}

@media(max-width: 767.98px) {
    .ob-series-selector {
        .ob-series-selector__nav-link-name {
            color: $base-color-dark-blue;
        }

        .ob-series-selector__nav-link-price {
            color: $base-color-primary-grey;
        }
    }
}

.ob-brush-configurator__needs {
    .ob-brush-configurator__needs-list {
        .ob-brush-configurator__needs-item {
            .ob-outline-button {
                &:hover {
                    background: $base-color-dark-blue ;
                    color: $base-color__white;
                    text-decoration: none;
                }
            }
        }
    }
}

.ob-brush-configurator__features {
    .ob-brush-configurator__features-list {
        .ob-brush-configurator__features-item {
            .ob-outline-button {
                &:hover {
                    background: $base-color-dark-blue ;
                    color: $base-color__white;
                    text-decoration: none;
                }
            }
        }
    }
}

.zone.collection-toothbrush-body-zone{
    .ob-series{
        .ob-side-by-side{
            .ob-videoContent-button{    
                svg.ob-icon.ob-icon--play {
                    &:hover,
                    &:focus{
                        fill: $base-color-dark-blue-hover !important;
                    }
                }
            }
        }

        .ob-series-selector{
            .ob-series-selector__selected-info{
                .ob-button.ob-series-selector__selected-buy-btn{
                    height: 48px;

                    &:hover{
                        color:$base-color__white;
                    }
                }
                .ob-series-selector__powered-by-label{
                    color:$base-color__white;
                }
            }
            
            @media(max-width: 767.98px) {
                .ob-series-selector__nav-toggle{
                    .ob-icon{
                        fill: $base-color-dark-blue !important;
                    }
                }
            }
        }
    }

    .ob-collection-replacement-brush-head{
        .ob-contentBlock-textContent-obLink-container{
            a.ob-link{
                text-decoration: underline;
            }
        }
    }
}

.zone.collection-toothbrush-body-zone {
    .ob-series{
        .ob-videoContent-button{
            &:hover,
            &:focus{
                background-color: transparent;
            }
        }
    }
}

.ob-series-product-color-img{
    width: 45px;
    height: 45px;
    border: 1px solid #00002E;
    border-radius: 50%;
    &:hover{
        cursor:pointer;
    }
    &.ob-series__color--active{
        border: 2px solid $base-color-dark-blue;
    }

}

.ob-series-selector.vitality-series{
    .ob-button.ob-series-selector__selected-buy-btn{
        margin-top: 150px;
    }
} 
.ob-series-comparison{
    .ob-series-comparison__item{
        .undefined.ob-series-comparison__product-pic-image{
            min-height: 50rem;
        }
    }
}