@import "../../static/styles/mixins";

.ob-CharacterBlock {
    position: relative;
}

.ob-CharacterBlock__wrapper {
    @include container-wrapper;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .justify-bottom--sm-down & {
        @include media-down(sm) {
            justify-content: flex-end;
        }
    }

    @include media-up(sm) {
        justify-content: center;
        align-items: flex-start;

        .justify-right & {
            align-items: flex-end;
            max-width: 100%;
            padding: 0 2rem;
        }
    }

    &.no-background {
        position: relative;
        margin-top: 3rem;
        align-items: center;
    }
}

.ob-CharacterBlock__content {
    width: 100%;
    text-align: center;
    position: relative;
    @include media-up(sm) {
        text-align: left;
        width: 67%;
    }
    @include media-up(sm) {
        width: 47%;

        .justify-right & {
            width: 40%;

            .ob-bodyText {
                max-width: rem(384);
            }
        }

        .white-text & {
            .ob-bodyText {
                color: $base-color__white;
            }
        }

        #credentials & {
            width: 46%;
            margin-right: 8rem;
        }

        .ob-bodyText {
            letter-spacing: 0.005em;
            max-width: rem(440);
        }
    }

    @include media-down(sm) {
        margin-top: 2rem;
    }

    opacity: 0;
    transition:  opacity 2s ease-out;
    .show & {
        opacity: 1;
    }

    .bg-white--sm-down & {
        @include media-down(sm) {
            background-color: rgba($base-color__white, 0.7);
        }
    }

    .white-text & {
        .ob-bodyText {
            color: $base-color__white;
        }
    }

    &.no-background {
        text-align: center;
    }

    .ob-CharacterBlock__pulsonic--de-de & {
        @include media-down(sm) {
            background: rgba(0, 0, 0, 0.5);
            padding: 1rem 0;
        }
    }
}

.ob-CharacterBlock__content-logo {
    display: none;
    transform: translateY(50px);
    transition: transform 1.5s ease-out;

    .ob-CharacterBlock__pulsonic--de-de & {
        margin-top: 2rem;
    }

    .center-content & {
        img {
            display: block;
            margin: 0 auto;
        }
    }

    @include media-down(sm) {
        &.show-mobile {
            display: block;
            margin-bottom: 1rem;

            img {
                max-width: 35%;
            }
        }
    }

    @include media-up(sm) {
        display: block;
        margin-bottom: 1rem;

        img {
            max-width: 80%;
            max-height: 200px;

            .zone.kids-products-by-age-group & {
                max-width: 15rem;
            }

            .ob-CharacterBlock__pulsonic--de-de & {
                max-width: 55%;
            }
        }
    }

    @include media-up(md) {
        &:not(.no-eyebrow) {
            margin-bottom: 4rem;
        }
        &.no-eyebrow {
            margin-bottom: 1rem;
        }
    }

    @include media-up(xxlg) {
        img {
            max-height: none;
        }
    }

    .show & {
        transform: translateY(0);
    }
}

.ob-CharacterBlock__content-eyebrow {
    @include media-down(sm) {
        margin-top: 3rem;
        margin-bottom: 1.8rem;
    }
}

.ob-CharacterBlock__content-heading {
    @include display-1-xl;
    margin-bottom: .5rem;
    @include media-down(sm) {
        margin-bottom: 2rem;
    }

    .white-text & {
        color: $base-color__white;
    }

    .center-content & {
        text-align: center;
    }
}

.ob-characterBlock--tall .ob-background-wrapper {
    min-height: 660px;
}
html[lang="pt"] .ob-characterBlock--tall .ob-background-wrapper {
  min-height: 700px;
}
