@import "../../static/styles/mixins";

%ob-language-tab-links {
  @include font-family('hurme-bold');
  color: $base-color__white;
  font-size: 2rem;
  line-height: 2;
  text-transform: uppercase;
}

$languageSelectionMobilePaddingRL: 2.4rem;
$languageSelectionDesktopPaddingRL: 1.6rem;

.ob-language-selector-toggle-icon {
  margin-left: rem(8);
  fill:$base-color-dark-blue;
}

.ob-language {
  &-selector {
    @include media-up(md) {
      padding: 0 5.2rem;
    }

    &-toggle {
      font-size: 1.3rem;
      @include media-up(md) {
        letter-spacing: 0.05em;
      }
      @include media-down(md) {
        @include body-4-xl;
        @include font-family('semi-bold');
        font-size: 1.3rem;
        line-height: 1.15;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          fill: $base-color-primary-grey;
          transform: rotate(-90deg);
          margin-left: 0.5rem;
        }
      }
    }
  }

  &-overlay {
    background: rgba(255,255,255,0.95);
    color: $base-color-primary-grey;
    overflow: hidden;
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
    z-index: map-get($zindex, one) ;

    @include media-up(md) {
      top: 0;
      overflow: auto;
      height: calc(100vh - 160px);
    }

    &-inner {
      @include media-up(md) {
        max-height: none;
      }
    }

    ul {
      list-style: none;
      padding: 0;
    }

    &-header {
      padding: 0;

      @include media-up(md) {
        padding: 0 1.6rem;
        @include simple-container-wrapper;
      }
      @include media-down(sm){
        padding: 5.5rem 6rem 3.5rem 2.4rem;
      }
    }

    &-title {
      @include font-family('hurme-light');
      font-size: 2.2rem;
      line-height: 1.363636;

      @include media-up(md) {
        font-size: 4.8rem;
        line-height: 0.9;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        display: block;
      }
    }

    &-tabs {

      &-mobile,
      &-desktop {
        background-color: $base-color-dark-blue;
      }

      &-toggle {
        @extend %ob-language-tab-links;

        @include media-down(md) {
          width: 100%;
          text-align: left;
        }
        &--icon {
          float: right;
        }
      }


      &-mobile {
        padding: 1.7rem $languageSelectionMobilePaddingRL;
        &-inner {
          overflow: hidden;
        }
      }

      .mini-nav-regions li a {
        color: $base-color__white;
      }

      ul {
        margin: 0;

        a {
          @extend %ob-language-tab-links;
          display: block;
          &:focus, &:hover {
            text-decoration: underline;
          }
        }
      }

      @include media-down(md) {
        &-desktop {
          display: none;
        }

        ul {
          li {
            padding: 0.5rem;
          }

          a {
            font-size: 1.6rem;
            padding-left: 2rem;
          }
        }
      }

      @include media-up(md) {

        &-mobile {
          display: none;
        }

        &-desktop {
          padding: 0 $languageSelectionDesktopPaddingRL;
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 1.4rem;
          @include simple-container-wrapper;

          > li {
            margin: 0 3.4rem 0 0;
          }
        }
      }
    }

    &-links {
      overflow: auto;

      ul.market-selection {
        padding: 3.8rem $languageSelectionMobilePaddingRL 9.2rem;
        margin: 0;
        @include media-up(md) {
          padding: 0 1.6rem;
          @include simple-container-wrapper;
        }

        .market-selection-title {
          @include font-family('semi-bold');
          font-size: 2.2rem;
          letter-spacing: 0.067em;
          text-transform: uppercase;
          padding-top: 1rem;
        }

        > li {
          margin: 6.2rem 0 0;

          &:first-child {
            margin: 0;
          }

          > ul {
            margin: 0;

            > li {
              display: block;
              margin: 2.8rem 0 0;

              a {
                color: $base-color-primary-grey;
                font-size: 1.8rem;
                letter-spacing: 0.05em;
                @include font-family('hurme-regular');

                &:hover,
                &:focus {
                  color: $base-color-dark-blue-hover;
                }
              }
            }

            @include media-up(md) {
              margin: 2.5rem 0 0;
              max-width: 85.8rem;
              -webkit-columns: 22rem 3;
              -moz-columns: 22rem 3;
              columns: 22rem 3;

              > li {
                -webkit-column-break-inside: avoid;
                page-break-inside: avoid;
                break-inside: avoid;
                margin: 0 0 1.5rem;
                padding: 0.5rem 0;
              }
            }
          }
        }
      }

      @media (min-height: 850px) {
        max-height: 498px;
      }

      @media (min-height: 1000px) {
        max-height: 527px;
      }

      @media (min-height: 1200px) {
        max-height: 807px;
      }

      @include media-up(md) {
        padding: 5.7rem $languageSelectionDesktopPaddingRL;
      }

    }

    &-close-btn {
      position: absolute;
      right: 2.3rem;
      top: 3.2rem;
      transition: all ease 0.35s;
      display: none;
      &:hover, &:focus {
        transform: scale(1.5);
      }

      @include media-up(md) {
        display: block;
        top: 4.7rem;
      }
    }

    @include media-up(md) {
      left: 0;
      margin: 0 auto;
      max-width: 100vw;
      position: absolute;
      right: 0;
      top: 100%;
      width: 100%;
      z-index: map-get($zindex, one) ;
    }
  }
}
