@import "./mixins";

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
  z-index: map-get($zindex, modal);
  
  .one-page-scroll-wrapper & {
    z-index: map-get($zindex, waitlistModal);
  }
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ob-modal {

  &-close {
    right: 1.5rem;
    position: absolute;
    top: 1.5rem;
    z-index: map-get($zindex, one);
    transition: transform ease 0.35s;

    &:not(.no-shadow) {
      -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .7));
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .7));
    }

    &:focus {
      transform: scale(1.3);
      outline: 1px solid black;
    }
  }
}