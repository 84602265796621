@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-additional-reading {
    padding: 28px 0;
    opacity: 0.8;
    background: $base-color-light-grayish-blue;
    color: $base-color-primary-grey;

    @include media-up(xs) {
        opacity: 1;
    }

    @include media-up(md) {
        padding: 30px 0 22px;
    }

    @include media-up(lg) {
        padding: 52px 0 22px;
    }
}

.ob-additional-reading__heading {
    @include display-3-xl;
    margin-bottom: rem(19);
    padding: 0 24px;
    text-align: center;
    letter-spacing: (0.4em / 28);

    @include media-up(sm) {
        margin-bottom: rem(6);
        padding: 0 10px;
        letter-spacing: 0;
    }

    @include media-up(lg) {
        margin-bottom: rem(22);
    }
}

.ob-additional-reading__articles {
    max-width: $oralb-max-desktop-width;
    margin: 0 auto;
    padding: 0 30px 0 12px;

    @include media-up(xs) {
        padding: 0 24px;  // alignment with site footer
    }

    @include media-up(md) {
        padding: 0 1rem; // alignment with site footer
    }
}

.ob-additional-reading__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-up(xs) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -8px;
    }
}

.ob-additional-reading__item {
    display: flex;
    flex: 0 1 auto;
    margin: 16px auto;

    @include media-up(xs) {
        width: 272px;
        margin: 16px 8px;
    }

    @include media-up(lg) {
        .ob-additional-reading--wide & {
            width: 460px;
            margin: 16px 58px;
        }
    }
}

.ob-additional-reading__item-link {
    flex: none;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: $base-color-primary-grey;
    width: 100%;

    &, &:link, &:hover, :focus {
        text-decoration: none;
    }

    @include media-up(xs) {
        flex-direction: column;

        @media screen {
            box-shadow: 0 0 0 rgba(black, 0.0);
            transition: all 200ms ease-in-out;

            &:hover {
                box-shadow: 0 0 10px rgba(black, 0.2);
                transform: scale(1.02, 1.02);
            }
        }
    }
}

.ob-additional-reading__item-image {
    flex: none;
    position: relative;
    width: 40px;
    width: 124px;
    height: 150px;
    margin-right: 24px;
    overflow: hidden;

    @include media-up(xs) {
        width: 100%;
        margin: 0 0 1.9rem;
    }

    @include media-up(lg) {
        .ob-additional-reading--wide & {
            height: 0px;
        }
    }
}

.ob-additional-reading__item-pic {
    display: block;
    width: 100%;
    height: 100%;
}

.ob-additional-reading__item-img {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
}

.ob-additional-reading__item-info {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 15px;

    @include media-up(xs) {
        padding: 0 0 10px;
        text-align: center;
        max-width: 100%;
    }
}

.ob-additional-reading__item-title {
    @include font-family('semi-bold');
    flex: none;
    color: $base-color-dark-blue;
    font-size: rem(16);
    line-height: rem(24);

    @include media-up(xs) {
        padding: 0 30px;
    }

    .ob-additional-reading__item-link:hover &, .ob-additional-reading__item-link:focus & {
        text-decoration: underline;
        color: $base-color-dark-blue-hover;
    }
}

.ob-additional-reading__item-time {
    @include body-4-xl;
    flex: none;
    margin-top: rem(16);

    @include media-up(xs) {
        margin-top: rem(6);
    }
}
.ob-additional-reading__item{
    a:focus-visible{
        border: 2px solid $base-color__black ;
    }
}