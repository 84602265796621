@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-product-grid-block {
    position: relative;
}

.ob-product-grid-block-wrapper {
    position: relative;
    margin: 0 auto;
    max-width: $oralb-max-desktop-width;
    padding: rem(22) rem(10);

    .zone.kids-products-by-age-group & {
        padding-top: 0;
    }

    @include media-up(md) {
        padding: 2.2rem 1rem 0;
    }

    &.ob-product-grid-block-disclaimer {
        font-size: 1.5rem;
    }

    .ob-disclaimer {
        padding: 0 1rem 1.3rem;
        text-align: center;
    }
}

.ob-product-grid-block__heading,
.ob-product-grid-block__body-text,
.ob-product-grid-block__eyebrow {
    padding: 0 rem(10);
    text-align: center;
    color: $base-color-primary-grey;
}

.ob-product-grid-block__eyebrow {
    @include eyebrow-1-xs;
    margin: rem(8) 0;
}

.ob-product-grid-block__heading {
    @include display-3-xs;
    margin: rem(8) 0;
    @include media-up(sm) {
        font-size: 3.2rem;
    }
}

.ob-product-grid-block__body-text {
    @include body-1-xs;
    font-size: 2.2rem;
    line-height: 3rem;
    margin: rem(20) auto;
    max-width: rem(920);

    .zone.kids-products-by-age-group & {
        margin-top: 0;
    }
}

.ob-product-grid-block__product-grid-wrapper{
    @include media-up(md) {
        padding: 1rem 1rem 2.2rem;
    }
    .has-divider & {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.radial-background {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 73%);
    z-index: 0;
}

// Global Equity
.replacement-brush-heads-io-body{
    .white-text {
        .comming-soon-btn-link {
            color: white !important;
            &:hover, &:focus {
                color: $base-color-dark-blue-hover !important;
                background: transparent !important;
                border: 1px solid white !important;
            }
        }
    }

    .ob-product-grid-block.white-text{
        .ob-product-grid-block__product-grid-wrapper{
            .ob-products-overview__overview--animated{
                .ob-products-overview__overview-lower-content{
                    a {
                        background: $base-color__white;
                        color: $base-color-dark-blue !important;
                        &:hover, &:focus {
                            color:$base-color__white !important;
                            background: $base-color-dark-blue-hover !important;
                        }
                    }
                    .ob-button.comming-soon-btn-link {
                        color:$base-color__white;
                        &:hover{
                            color:$base-color-dark-blue-hover;
                        }
                    }
                }
            }

            .ob-collapsible-products-overview-see-more-wrapper{
                .ob-see-more-button--white{
                    .ob-icon-rounded-container.-blue.ob-see-more-button__icon{
                        background: $base-color__white;
                        .ob-icon{
                            fill: $base-color-dark-blue !important;
                        }
                    }
                    .ob-see-more-button__text{
                        color: $base-color__white;

                        &:hover, &:focus{
                            color: $base-color-dark-blue-hover;
                        }
                    }
                }
            }
        }
    }
}

.replacement-brush-heads-universal-body{
    .ob-product-grid-block__product-grid-wrapper{
        .ob-collapsible-products-overview-see-more-wrapper{
            .ob-see-more-button__text{
                &:hover, &:focus{
                    color: $base-color-dark-blue-hover;
                }
            }
        }
    }

    .ob-mini-nav{
        .ob-mini-nav__title{
            .ob-icon{
                fill: $base-color-dark-blue;
            }
        }
        ul.ob-mini-nav__list{
            @media(max-width: 767.98px) {
                background: #E5E8EC;

                li.ob-mini-nav__list-item{
                    border-top: 1px solid #fff;
                    border-bottom: 0;
                    width: 100%;

                    a{
                        color: $base-color__black;
                        @include font-family('hurme-regular');
                    }

                    .ob-icon.ob-icon--chevronDown {
                        display: none;
                    }
                }
            }
        }
    }
}

.zone.kids-products-by-age-group{
    .ob-product-grid-block{
        .ob-collapsible-products-overview-see-more-wrapper{
            .ob-see-more-button__text{
                color: $base-color-dark-blue;
                &:hover, &:focus{
                    color: $base-color-dark-blue-hover;
                }
            }
        }
    }

    .ob-article-category-hero{
        .ob-article-category-hero-cta-list{
            li a.ob-article-category-hero-cta-link{
                &:hover, &:focus{
                    color: $base-color-dark-blue-hover;
                }
            }
        }
    }

    .ob-mini-nav {
        @media(max-width: 767.98px) {
            .ob-mini-nav__title{
                .ob-mini-nav__icon{
                    .ob-icon{
                        fill: $base-color-dark-blue !important;
                    }
                }
            }

            .ob-mini-nav__list{
                background: #E5E8EC;

                li.ob-mini-nav__list-item{
                    border-top: 1px solid #fff;
                    border-bottom: 0;
                    width: 100%;

                    a{
                        color: $base-color__black;
                        @include font-family('hurme-regular');
                    }

                    .ob-icon.ob-icon--chevronDown {
                        display: none;
                    }
                }
            }
        }
    }

    .ob-collection-tabs-block__tabs-wrapper{
        @media(max-width: 767.98px) {
            .ob-button-tabs-navigation{
                .ob-button-tabs-navigation-toggle{
                    .ob-icon{
                        fill: $base-color-dark-blue !important;
                    }
                }
            }
        }
    }
}

.zone.toothpaste{
    .ob-product-grid-block{
        .ob-see-more-button__text{
            color: $base-color-dark-blue;

            &:hover{
                color: $base-color-dark-blue-hover;
            }
        }  
    }

    .ob-collection-tabs-block__tabs-wrapper{
        @media(max-width: 767.98px) {
            .ob-button-tabs-navigation{
                .ob-button-tabs-navigation-toggle{
                    .ob-button-tabs-navigation-link-name{
                        font-weight: 400;
                    }
                    .ob-icon{
                        fill: $base-color-dark-blue !important;
                    }
                }
            }
        }
    }
}

.zone.waterflosser{
    .ob-mini-nav{
        .ob-mini-nav__list{
            .ob-mini-nav__list-item{
                .ob-icon{
                    fill: $base-color-dark-blue !important;
                }
            }
        }
    }
}

.zone.product-legacy-body-zone{
    .ob-product-sub-nav__container--desktop{
        .ob-product-sub-nav__ob-btn-section{
            .ob-product-sub-nav__find-a-retailer-secondary-btn-style{
                margin-top: 13px;
            }
        }
    }

    .ob-product-highlights{
        .ob-product-highlights__text-content{
            .ob-product-highlights__highlights-ctn{
                .ob-product-highlights__see-more{
                    color: $base-color-dark-blue;

                    &:hover{
                        color: $base-color-dark-blue-hover;
                    }
                }
            }
        }
    }
}

.zone.mouthwash{
    .ob-see-more-button__text{
        color: $base-color-dark-blue;

        &:hover{
            color: $base-color-dark-blue-hover;
        }
    }
}

.zone.floss{
    .ob-see-more-button__text{
        color: $base-color-dark-blue;

        &:hover{
            color: $base-color-dark-blue-hover;
        }
    }
}

.zone.manual-toothbrushes{
    .ob-see-more-button__text{
        color: $base-color-dark-blue;

        &:hover{
            color: $base-color-dark-blue-hover;
        }
    }
}

.zone.kids-products-by-age-group {
    .ob-mini-nav{
        .ob-mini-nav__list-item{
            .chrevonDown-IconAlign{
                .ob-icon.ob-icon--chevronDown {
                    fill: $base-color-dark-blue !important;
                }
            }
        }
    }
    .ob-see-more-button__text{
        color: $base-color-dark-blue;

        &:hover{
            color: $base-color-dark-blue-hover;
        }
    }
}

.sustainablity-class{
    .fact-sheet-btn{
        background-color: $base-color-dark-blue;
    
        &:hover{
            background-color: $base-color-dark-blue-hover;
        }
    }
}

.zone.ob-safety-product{
    .ob-product-safety-ingredients{
        .ob-contentBlock-textContent-obLink-container{
            .ob-button{
                background-color: $base-color-dark-blue;
                color: $base-color__white;
            
                &:hover{
                    background-color: $base-color-dark-blue-hover;
                }
            }
        }
    }
}

.layout.childrens-oral-health{
    .ob-article-category-hero-cta-list{
        .ob-article-category-hero-cta-link{
            color: $base-color-dark-blue;
            
            &:hover{
                color: $base-color-dark-blue-hover;
            }
        }
    }
}

.zone.life-stages-landing-body{
    .ob-link.ob-article-list__see-more-button{
        color: $base-color-dark-blue;
        
        &:hover{
            color: $base-color-dark-blue-hover;
        }
    }
}

.zone.body-zone-article-landing-tartar-plaque{
    @media(max-width: 767.98px) {
        .oral-b-tartar-plaque{
            .ob-contentBlock-textContent-inner {
                .ob-eyebrow{
                    a{
                        color: $base-color__white;
                    }
                }
            }
        }
    }
}

.zone.body-zone-article-landing-gum-health{
    @media(max-width: 767.98px) {
        .oral-b-gum-health{
            .ob-background-wrapper{
                min-height: auto;
                height: auto;
            }
        }
    }
}

.zone.conditions-landing-body{
    .ob-article-list.ob-additional-reading.ob-article-list--landing {
        .ob-link.ob-article-list__see-more-button{
            color: $base-color-dark-blue;
            
            &:hover{
                color: $base-color-dark-blue-hover;
            }
        }
    }
}

.zone.childrens-oral-health-body{
    .ob-article-category-hero{
        background-color: $base-color__white !important;
    }
}

.ob-mini-nav__list{
    .ob-mini-nav__list-item{
        .chrevonDown-IconAlign{
            .ob-icon.ob-icon--chevronDown {
                fill: $base-color-dark-blue !important;
            }
        }
    }
}

.zone.floss {
    .ob-background-wrapper{
        .ob-contentBlock-textContent{
            .ob-heading.ob-display-2-xl{
                color: $base-color__white;
            }
            .ob-bodyText{
                color: $base-color__white;
            }
        }
    }
}

.zone.mouthwash{
    .ob-background-wrapper{
        .ob-contentBlock-textContent{
            .ob-heading.ob-display-2-xl{
                color: $base-color__white;
            }
            .ob-bodyText{
                color: $base-color__white;
            }
        }
    }
}

.zone.kids-products-by-age-group{
    #Kids-Products---Healthy-solutions-for-kids-of-all-ages---Block-1{
        .ob-contentBlock-textContent{
            .ob-contentBlock-textContent-inner{
                width: 46% ;
                    @include media-down(sm) {
                          width: 100%;
                }
            }
        }
    }
}

#Collection-tabs-block---Floss,
#Collection-tabs-block---Manual-Toothbrushes,
#Collection-tabs-block---Mouthwash{
    .ob-collection-tabs-block__tabs-wrapper{
        .ob-icon--chevronDown.ob-button-tabs-navigation-chevron{
            @media(max-width: 767.98px) {
                fill: $base-color-dark-blue !important;
            }
        }
    }
}

.ob-mini-nav{
    .ob-mini-nav__title{
        .ob-mini-nav__icon{
            .ob-icon.ob-icon--chevronDown {
                @media(max-width: 767.98px) {
                    fill: $base-color-dark-blue !important;
                }
            }
        }
    }
}

.replacement-brush-heads-io-layout{
    .replacement-brush-heads-io-body{
        .ob-stacked-products-overview-wrapper{
            .ob-products-overview__overview-image{
                .event_internal_link {
                    .ob-product-link-text{
                        @media(max-width: 767.98px) {
                            color: $base-color__white;
                        }
                    }
                }
            }
        }
    }
}

.zone.floss {
    #Content-Block---Floss---Complement-your-routine-with-an-exceptional-clean-Block-5{
        .ob-background-wrapper{
            .ob-contentBlock-textContent{
                .ob-heading.ob-display-2-xl{
                    color: $base-color-primary-grey;
                }
                .ob-bodyText{
                    color: $base-color-primary-grey;
                }
            }
        }
    }
}

.zone.mouthwash{
    #Content-Block---Mouthwash---Premium-Toothpaste-for-an-Exceptional-Clean-Block-6{
        .ob-background-wrapper{
            .ob-contentBlock-textContent{
                .ob-heading.ob-display-2-xl{
                    color: $base-color-primary-grey;
                }
                .ob-bodyText{
                    color: $base-color-primary-grey;
                }
            }
        }
    }
}

.zone.ob-teeth-whitening{
    #Content-Block---Needs---Teeth-Whitening---Block-1{
        .ob-background-wrapper{
            .ob-contentBlock-textContent{
                .ob-heading.ob-display-1-xl{
                    color: $base-color-primary-grey;
                }
                .ob-bodyText{
                    color: $base-color-primary-grey;
                }
            }
        }
    }
}