@import '../../static/styles/mixins';
.ob-article-dr-biography {
    max-width: 775px;
    box-sizing: content-box;
    margin: 70px auto;    
    @include media-down(sm) {
        margin-right: 26px;
    }

    a {
        font-size: 2.2rem;
        text-decoration: none;
        cursor: pointer;
        display: inline-flex;
        p {
            background-color: #0054bb;
            color: white;
            margin: 75px 22px;
        }
        @include media-down(sm) {
            font-size: 1.8rem;
        }
    }    
    .ob-bio-content-vertical {
        background-color: #0054bb;
        margin-left: 70px;
        height: 200px;
        border-radius: 10px;
        .ob-article-bio-card-image {
            width: 178px;
            position: relative;        
            margin-left: -80px;
            margin-top: 12px;
            @include media-down(sm) {
                img {
                    width: 100%;
                    margin: 0 auto;
                    position: relative;
                    display: inherit;
                }
            }
        }
    }

    @media (max-width: 991.98px) {
        max-width: 90%;
        .ob-bio-content-vertical {
            height: 170px;
            .ob-article-bio-card-image {
                margin-top: 13px;
                img {
                    max-width: 80%;
                }
            }
            p {
                margin: 63px 22px;
                font-size: 1.9rem;
            }
        }
    }
    
    @media (max-width: 767px) {        
            margin-right: auto;
            .ob-bio-content-vertical {
                margin-left: auto;
                height: auto;
                margin-top: 130px;
                a {
                    display: inline-block;
                    font-size: 1.8rem;
                    .ob-article-bio-card-image {
                        margin: 20px auto;
                        width: 100%;
                        img {
                            max-width: 48%;
                            top: -93px;
                        }
                    }
                    p {
                        margin: -88px 22px 22px;
                        font-size: 1.8rem;
                        text-align: center;
                    }
                }                
            }        
    }

}
.ob-bio-modal-content {
    .ob-article-bio-card-image {
        width: 178px;
        position: relative;
        margin: 0 auto;        
        @include media-down(sm) {
            img {
                width: 100%;
                margin: 0 auto;
                position: relative;
                display: inherit;
            }
        }
    }
    ul {
        position: relative;
        left: 18px;
        li {
            font-size: 17px;
            a{
                &:hover{
                    color: $base-color-dark-blue-hover;
                }
            }
        }
        @include media-down(sm) {
            left: 0px;
        }
    }
    .ob-bio-article-see-status {
        cursor: pointer;
        text-align: center;
        label {
            text-transform: uppercase;
            cursor: pointer;
            margin-left: 6px;
            position: relative;
            top: 0.5px;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}
.ob-article-biography-modal-portal {
.ReactModal__Overlay--after-open .ReactModal__Content--after-open {
    .bio-close-button {
        display: inline;
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 22px;
        font-weight: bold;
        .ob-icon {
            fill:$base-color-dark-blue !important;
            @include media-down(sm) {
                position: relative;
                left: -10px;
            }
        }
    }
    .ob-article-bio-description {
        .prof-desc {
            font-size: 17px;
            a{
                &:hover{
                    color: $base-color-dark-blue-hover;
                }
            }
        }
    }
}

.ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar {
    width: 10px;
  }
  
  .ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  .ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar-thumb {
    background: #b4b4b4;
    border-radius: 10px;
  }
  
  .ReactModal__Content.ReactModal__Content--after-open::-webkit-scrollbar-thumb:hover {
    background: #686868; 
  }

    .ReactModal__Overlay.ReactModal__Overlay--after-open{
        .ReactModal__Content.ReactModal__Content--after-open{
            // override inline styles
            inset: revert !important;
            margin-right: 0 !important;
            transform: none !important;
            max-width: none !important;
            max-height: none !important;

            top: 50% !important;
            left: 50% !important;
            height: 620px;
            width: 1000px;
            margin-top: -310px;
            margin-left: -500px;
            border: 1px solid #ccc !important;

            @media (max-width: 991.98px){
                height: 400px;
                width: 750px;
                margin-top: -200px;
                margin-left: -375px;
            }

            @media (max-width: 767px){
                height: 450px;
                width: 390px;
                margin-top: -225px;
                margin-left: -195px;
                top: 44% !important;
            }

            @media only screen
            and (device-width: 844px)
            and (device-height: 390px)
            and (orientation: landscape) {
                height: 360px;
                margin-top:-180px;
            }

            @media only screen
            and (device-width:  927px)
            and (device-height: 428px)
            and (orientation: landscape) {
                height: 360px;
                margin-top:-180px;
            }

            @media screen and (min-width: 920px) and (max-width: 935px) {
                height: 360px !important;
                margin-top:-180px !important;
            }
        }
    }
}