@import "../../static/styles/mixins";

.ob-tabbed-content {
    max-width: $oralb-max-desktop-width;
    margin: 0 auto;
    @include body-3-xl;
    ul {
        list-style-type: none;
    }
    &__tab-list {
        margin: 2rem auto 0;
        @include media-up(md) {
            background: $base-color__white;
            border-radius: 10px;
            border: 1px solid $base-color-isolate-grey;
            display: flex;
            justify-content: space-between;
            max-width: 840px;
        }

    }
    
    &__tab-list-item {
        width: 100%;
        @include media-up(md) {
            &:first-child {
                border-radius: 10px 0 0 10px;
            }
            &:last-child {
                border-radius: 0 10px 10px 0;
            }
        }
        button {
            border: 1px solid transparentize($base-color-primary-grey, 0.9);
            @include font-family('semi-bold');
            font-size: 1.8rem;
            padding: 1.2rem;
            width: 100%;
            text-align: center;
            color: $base-color-dark-blue;

            @include media-up(md) {
                border: none;
            }
        }
        &.is-active {
            .ob-tabbed-content__tab-icon {
                transform: rotate(180deg);
            }
            @include media-up(md) {
                background: $base-color-dark-blue;

                button {
                    color: $base-color__white;
                }
            }
        }
    }

    &__tab-icon {
        float: right;
        transition: all linear 0.35s;
        @include media-up(md) {
            display: none;
        }
    }

    &__content-list-item {
        @include media-down(md) {
            max-height: 0;
            opacity: 0;
            visibility: hidden;
            transition: all ease 0.35s;
            padding: 0 2rem;
            &.is-active {
                opacity: 1;
                visibility: visible;
                max-height: none;
                transition: all ease 0.35s;
                padding: 2rem;
                * {
                    pointer-events: all;
                }
            }

            // we don't know what elements are going to be the children, 
            // so we have to make sure they have no margin and no pointer events
            * {
                text-align: center;
                margin: 0;
                pointer-events: none;
            }
        }
        @include media-up(md) {
            display: none;
            max-height: none;
            &.is-active {
                display: block;
            }
        }
    }
}   