@import '../../static/styles/mixins';

.ob-product-sub-nav {
    background: $base-color-secondary-darker-off-white;
    &__find-a-retailer-secondary-btn-style {
        text-decoration: underline;
        @include media-up(md) {
            align-items: flex-start;
            margin-bottom: 0.5rem !important;
        }
        @include media-down(md) {
            margin-bottom: 0.5rem !important;
        }
    }

    &__ob-btn-section {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-top: 1rem;
        margin-top: 75px;
        @include media-up(md) {
            padding-top: 1.5rem;
        }
        @include media-down(md){
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;
            margin: 0;
            padding: 0 0 7px;
        }
    }

    &__powered-by-label {
        margin-top: 1.2rem;
        font-size: 1.4rem;
        text-align: center;
        @include media-up(md) {
            margin-left: 0.5rem;
        }
        @include media-down(md) {
            margin-bottom: 1rem;
        }
    }

    @include media-up(md) {
        height: 8.7rem;
    }

    &.is-sticky {
        top: auto;
        width: 100%;
        height: 12rem;
        z-index: map-get($zindex, productSubNav);

        + div {

            &.ob-padding-transition {
                transition: padding-top 0.35s ease-in-out;
            }
        }

        &.is-top-false {
            transition: top 0.15s ease-in;
        }

        &.is-top-true {
            transition: top 0.15s ease-out;
        }
    }


    &__container {
        max-width: calc(#{$oralb-max-desktop-width} - 71px);
        margin: 0 auto;
        padding: 0.5rem 1.6rem;
        display: flex;
        justify-content: space-between;
        height: 100%;

        @include media-up(md) {
            padding: 1.6rem;
        }

        &--desktop {
            display: none;
            @include media-up(md) {
                display: flex;
                align-items: center;
                height: 30px;
            }
        }

        &--mobile {
            @include media-up(md) {
                display: none;
            }
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 30px;

        @include media-down(md) {
            justify-content: center;
        }

        @include media-up(md) {
            width: auto;
            flex-direction: column;
            flex-wrap: nowrap;
            height: 5rem;
        }
    }

    &__title {
        font-size: 1.6rem;
        color: $base-color-dark-blue;
        display: flex;
        @include font-family('semi-bold');

        .ob-icon {
            margin-left: 2rem;
        }

        @include media-down(md) {
            padding-bottom: 10px;
        }
    }

    .ob-page-navigation__list {
        justify-content: flex-start;
        width: 100%;
        border-top: 1px solid $base-color-secondary-grey;
        overflow: hidden;
        list-style-type: none;
        padding: 0;
        max-width: $oralb-max-desktop-width;
        margin: 0 auto;
        @include body-4-xl();
        @include media-up(md) {
          display: flex;
        }

        @include media-up(md) {
            border-top: none;
            overflow: visible;
        }
        li {
            margin: 2.8rem 0 0 0;
            @include media-up(md) {
                margin: 0 2.4rem 0 0;
            }
            &:last-child {
                margin: 2.8rem 0;
                @include media-up(md) {
                    margin: 0;
                }
            }

            a {
                text-transform: uppercase;
                @include font-family('semi-bold');

                &:hover {
                    color: $base-color-dark-blue-hover;
                }

                &:focus{
                    text-decoration: none;
                    border: 2px solid $base-color-link-blue ;
                }
            }
        }
    }

    &.ob-product-sub-nav--it-it .ob-page-navigation__list {
        @media screen and (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
            font-size: 1.2rem;
        }
    }

    .ob-button {
        flex-shrink: 0;
        margin: 0;
        @include font-family('semi-bold');
        font-size: 1.8rem;
        align-self: center;
        @include media-down(md) {
            @include font-family('hurme-regular');
            font-size: 1.6rem;
            height: 4.3rem;
        }
    }
}
.ob-page-navigation__list{
    li{
        a:focus-visible{
            border: 2px solid $base-color-link-blue;
            text-decoration: none;
        }
    }
}
.bv_main_container_row_flex:focus-visible{
    border: 2px solid $base-color-link-blue ;
    text-decoration: none;
}
.bv-focusable.event_button_click:focus{
    border: 2px solid $base-color__black ;
}
.bv-content-btn-pages-inactive:focus{
    border: 2px solid $base-color-link-blue ;
}
.ob-product-sub-nav__btn_title {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
}