@import "../../static/styles/mixins";
.ob-gallery {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    top: 0;
    margin: 0 auto;
    list-style-type: none;
    letter-spacing: 0;
    text-align: center;
    @include body-4-xs();
    @include media-up(sm) {
        justify-content: center;
    }
    .is-visible{
        width: 100%;
    }

    line-height: inherit;

    ul {
        width: 100%;
        list-style-type: none;
    }

    .ob-background-wrapper {
        padding-top: 48.6% !important;
        position: relative;
        display: flex;
        justify-content: center;

        @include media-down(md) {
            min-height: 615px;
        }
    }

    .ob-gallery__wrapper {
        max-width: 680px;
        position: absolute;
        top: 0;
        left: 50%;
        bottom: auto;
        transform: translateX(-50%);
        width: auto;
    }

    .ob-gallery__wrapper--video {
        max-width: 100%;
        width: 100%;
        position: relative;
    }

    .ob-gallery__container {
        width: 100%;
    }

    .grid-item {
        overflow: hidden;
    }

    .ob-heading {
        width: 100%;
        margin-top: 5.5rem;
        font-size: 3.2rem;

        @include media-down(md) {
            margin-top: 3rem;

            span {
                padding: 0.2rem 2rem 0;
            }
        }

        &:not([data-white-text='true']) {
            @include media-down(md) {
                span {
                    background-color: rgba($base-color__white, 0.7);
                    border-radius: 0.5rem;
                }
            }
        }
    }

    .ob-gallery__button {
        text-transform: uppercase;
        top: auto;
        bottom: rem(28);
        left: auto;
        right: auto;
        position: absolute;

        @include media-up(md) {
            bottom: rem(38);
        }

        @include media-down(md) {
            padding: 0.2rem 2rem 0;
            background-color: rgba($base-color__white, 0.7);
            border-radius: 0.5rem;
        }

        @include media-ie() {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &.ob-gallery--de-de .ob-gallery__button {
        @include media-up(md) {
            padding: 0.2rem 2rem 0;
            background-color: rgba($base-color__white, 0.7);
            border-radius: 0.5rem;
        }
    }

    .ob-gallery__wrapper--video ~ .ob-gallery__button.see-more-button {
        bottom: 0;
    }

    .ob-gallery__masonry {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        width: 100%;

        /* clearfix */
        &:after {
            content: '';
            display: block;
            clear: both;
        }

        .grid-sizer, .grid-item {
            width: 0.01%;
        }
        .grid-item {
            display: block;
            float: left;

            .ob-mainAsset-wrapper {
                width: 100%;
                height: 100%;
            }

            .ob-mainAsset-wrapper, .ob-video-player {
                display: block;
            }

            @include media-down(sm) {
                width: 100% !important;
            }

            img, video {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }
}

.ob-gallery__container-collapsible-wrapper {
    height: 0;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;

    .ob-gallery__container.enter &,
    .ob-gallery__container.exit & {
        transition: height 400ms ease-in-out;
        visibility: visible;
    }

    .ob-gallery__container.enter-active &,
    .ob-gallery__container.enter-done & {
        pointer-events: all;
        visibility: visible;
    }

    .ob-gallery__container.enter-done & {
        height: auto !important;
    }

    .ob-gallery__container.exit-active &,
    .ob-gallery__container.exit-done & {
        height: 0 !important;
    }

    .ob-gallery__container.exit-active & {
        visibility: visible;
    }
}
