@import "../../static/styles/mixins";

.ob-login-navigation {
    color: $base-color-primary-grey;
    @include media-up(md) {
        margin-right: 30px;
    }
}

li.ob-main-menu__list--item.no-img.ob-main-menu__login-navigation {
    padding: 1rem 0;
}

a.ob-login-navigation__link,
button.ob-login-navigation__link {
    @include font-family('semi-bold');
    color: $base-color-primary-grey;
    font-size: 1.3rem;
    display: block;
    text-transform: uppercase;
    @include media-up(md) {
        @include font-family('hurme-regular');
        text-transform: none;
        display: inline;
        line-height: 1.8rem;
    }
}

.ob-login-navigation__link-login {
    border-bottom: 1px solid $base-color-secondary-grey;
    padding: 0 2rem 1rem;
    @include media-up(md) {
        border-bottom: none;
        padding: 0;
    }
}

.ob-login-navigation__link-register {
    padding: 1rem 2rem 0;
    @include media-up(md) {
        padding: 0;
    }
}

.ob-login-navigation__spacer {
    display: none;
    @include media-up(md) {
        display: inline;
        margin: 0 .7rem;
        font-size: 1.2rem;
    }
}

.ob-link.ob-login-navigation__adchoices {
    font-size: 1.3rem;
    @include font-family('semi-bold');
    color: $base-color-primary-grey;
    margin-top: 0.8rem;

    + a {
        margin-left: 1rem;
    }

    .ob-mainAsset-wrapper {
        position: relative;
        top: -2px;
        width: 15px;

        img {
            max-width: 100%;
        }
    }

    @include media-down(md) {
        position: fixed;
        top: auto;
        left: auto;
        right: .25rem;
        bottom: .25rem;
        background: #fff;
        height: 25px;
        padding: 7px;
        margin-right: 0;
        z-index: map_get($zindex, adChoices);

        + a {
            display: none;
        }
    }
}
