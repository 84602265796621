.double_optin_verification_title {
  color: #006fba;
  font-size: 36px;
  margin: 0 0 21px 0;
  border-bottom: 1px solid #ccc;
  padding-bottom: 21px;
}

.double_optin_verification_success_text {
  color: #666666;
  font-size: 16px;
}

.double_optin_verification_container {
  max-width: 1200px;
  margin: 50px auto 0;
}