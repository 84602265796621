@import "../../../static/styles/variables";
@import "../../../static/styles/mixins";

.ob-carrousel-products-overview {
    position: relative;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-snap-stop: always;
    @include no-scrollbar;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.ob-carrousel-products-overview-item {
    scroll-snap-align: start;
    flex: none;
    width: 100%;
}