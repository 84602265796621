@import "../../static/styles/mixins";

.#{$ob}-icon {
    width: 1.6rem;
    height: 1.6rem;
    vertical-align: middle;
    fill: $base-color__white;

    &--chevronDown {
        transform: rotate(90deg);
    }

    &--chevronLeft {
        transform: rotate(180deg);
    }

    &--chevronTop {
        transform: rotate(-90deg);
    }

    &--arrowRight {
        transform: rotate(180deg);
    }

    &-rounded-container {
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        font-size: 1.6rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: $base-color-dark-blue;

        .#{$ob}-icon {
            fill: $base-color__white;
            width: .35em;
            height: .35em;

            &--play {
                width: .5em;
                height: .5em;
            }

            &--plus6 {
                width: rem(6);
                height: rem(6);
            }

            &--plus {
                width: .5em;
                height: .5em;  
            }

            &--minus6 {
                width: .5em;
                height: .5em;  
            }

            &--chevronRight {
                width: .5em;
                height: .5em;
            }

            &--chevronLeft {
                width: .5em;
                height: .5em;
            }
        }

        &.-blue {
            background: $base-color-dark-blue;

            .#{$ob}-icon {
                fill: $base-color__white;
            }
        }

        &.-white {
            background: $base-color__white;
            .#{$ob}-icon {
                fill: $base-color-dark-blue  !important;
            }
        }

        &.-transparent {
            background: transparent;
            border: 1px solid $base-color-dark-blue;

            .#{$ob}-icon {
                fill: $base-color-dark-blue;
            }
        }
        
    }
}