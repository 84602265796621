@import "../../static/styles/mixins";

$button-size: rem(45);

.ob-faq-block {
    margin: 0 auto;
    min-height: 500px;
    padding: 2.4rem;

    .ob-faq-block__page-title {
        margin-bottom: 5.6rem;
        position: relative;
        text-align: center;

        @include media-down(sm) {
            margin-top: 0;
            margin-bottom: 2.8rem;
        }

        .ob-faq-block__banner {
            position: absolute;
            top: 39%;
            width: 100%;
            margin: 0 auto;
            text-align: center;

            @media(max-width: 767.98px) {
                top: 33%;
                padding: 0 5%;                
            }

            .ob-heading {
                margin-left: auto;
                margin-right: auto;
                text-transform: uppercase;

                @include display-1-xl;
                color: $base-color__white;

                @include media-down(tiny) {
                    font-size: 2.8rem;
                    margin-bottom: 1.6rem;
                    color: $base-color__white;
                }

                @include media-down(xtiny) {
                    font-size: 2rem;
                    line-height: 2.5;
                    margin-bottom: 1rem;
                    color: $base-color__white;
                }
            }

            p {
                color: $base-color__white;
                font-size: 1.8rem;
                @include font-family('hurme-regular');
                line-height: 26px;

                @media(max-width: 767.98px) {
                    font-size: 1.5rem;
                    line-height: 2.2rem;
                    padding: 0 15px 0 0;
                }
            }
        }

        .ob-mainAsset-wrapper {
            img {
                max-width: 100%;

                @media(max-width: 767.98px) {
                    height: 180px;
                }
            }
        }
    }

    .ob-faq-block-description {
        text-align: center;
        max-width: calc(123.6rem - 71px);
        margin: 0 auto;
        padding: 0 1.6rem;

        .ob-heading {
            color: $base-color-dark-blue;
            font-size: 28px;
            @include font-family('hurme-bold');
            margin: 30px 0 40px;
        }

        ul {
            overflow: hidden;
            padding: 0;

            li {
                display: inline-block;
                width: 25%;
                float: left;

                @media(max-width: 767.98px) {
                    display: block;
                    width: 100%;
                    float: none;
                }

                img {
                    max-height: 100px;
                    margin-bottom: 20px;
                }

                .ob-faq-desc-cont {
                    padding: 0 20px;

                    h2.ob-heading {
                        color: $base-color-primary-grey;
                        @include font-family('semi-bold');
                        font-weight: bold;
                        font-style: normal;
                        font-size: 1.6rem;
                        letter-spacing: 0.5px;
                        margin: 20px auto;
                        min-height: 47px;
                        width: 77%;

                        @media(max-width: 767.98px) {
                            margin: 20px 0 10px;
                            min-height: auto;
                            width: 100%;
                        }
                    }

                    p {
                        color: $base-color-primary-grey;
                        @include font-family('hurme-regular');
                        font-size: 1.5rem;
                    }
                }
            }

            li:hover,
            li.qa-active {
                color: $base-color-dark-blue;
                cursor: pointer;

                .ob-faq-desc-cont h2 {
                    border-bottom: 5px solid #002D72;
                    padding-bottom: 5px;

                    span {
                        color: $base-color-dark-blue;
                    }
                }
            }
        }

        .ob-faq-block__qa {
            clear: both;
            border-top: 0;
            margin: 25px 0;

            .ob-heading {
                background: #efefef;
                padding: 45px 0;
            }

            ul li {
                border-top: 1px solid #d8d8d8;
                display: block;
                float: left;
                width: 100%;

                .ob-faq-block__icon-wrapper {
                    float: left;

                    h2.ob-faq-block__question-text {
                        font-size: 1.5rem;

                        @media(max-width: 767.98px) {
                            font-size: 1.5rem;
                        }
                    }

                    h2.ob-faq-block__question-text:hover {
                        text-decoration: underline;
                    }
                }

                h2 {
                    font-size: 1.5rem;
                }

                h2:hover {
                    text-decoration: underline;
                }
            }
        }

        .ob-faq-contactus-block {
            display: block;

            .ob-contact-icon-wrapper {
                background: #efefef;
                overflow: hidden;
                padding: 20px 0 40px;
            }

            .ob-contact-icon-wrapper__contanier>div {
                display: inline-block;
                text-align: center;
                width: 30%;

                @media(max-width: 767.98px) {
                    float: none;
                    margin-bottom: 40px;
                    width: 100%;
                }

                img {
                    max-height: 100px;
                }

                p {
                    color: #3D3D41;
                    @include font-family('hurme-regular');
                    font-weight: normal;
                    font-style: normal;
                    font-size: 1.5rem;
                    color: $base-color-dark-blue;
                }
            }

            iframe {
                margin-top: 40px;
                width: 100%;
            }
        }
    }

    .ob-faq-iframe {
        width: 1022px;
        height: 835px;
        margin: auto;
        border: 0 solid;
        @include media-down(sm) {
            width: auto;
        }
    }
}

.ob-faq-block__search-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: $base-color-dark-grey;
    margin: 1rem 0;
    text-transform: uppercase;
}

.ob-faq-block__search-results {
    margin: 24px 0;
}

.ob-faq-block__popular-topics {
    margin-top: rem(56);

    @include media-down(sm) {
        margin-top: 2.8rem;
    }
}

.ob-faq-block__popular-topics-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: $base-color-dark-grey;
    margin: rem(10) 0 0 0;
    text-transform: uppercase;
}

.ob-faq-block__popular-topics-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 (-(rem(10)));
}

.ob-faq-block__popular-topics__toggle-wrapper {
    margin: rem(10);
}

.ob-faq-block__qas {
    border-bottom: 1px solid $base-color-secondary-grey;
}

.ob-faq-block{
    .ob-search-block{
        min-height: 170px!important;
        padding: 4.4rem;
        @include media-down(sm) {
            padding: 4.4rem 0;
        }
        h2 {
            text-align: left;
            color: #3D3D41;
            @include font-family('semi-bold');
            font-weight: bold;
            font-style: normal;
            font-size: 1.6rem;
            }
            .ob-search-bar-autocomplete-results {
            li {
                 text-align: left;
                 width: 100%;
                }
            }
        &__results-title {
            text-align: left;
        }
        &__search-result {
            text-align: left;
        }
    }
    .ob-paging-wrapper{
        .ob-paging{
            display: flex;
            flex-direction: row;
            justify-content: center;
            .ob-paging__page-numbers{
                padding: 0 1.8rem;
                .ob-paging__page-number{
                    font-size: 2rem;
                    padding: 0 0.8rem;
                    text-decoration: none;
                    &.active{
                        color:$base-color-dark-blue;
                    }
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}