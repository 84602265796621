@import "../../static/styles/mixins";

.ob-product-legacy-body {
    max-width: 93rem;
    margin: 0 auto;
    margin-top: 1rem;
    padding: 0 2.4rem;

    @include media-up(md) {
        margin-top: 4rem;
    }

    @include media-down(sm) {
        >div {
            margin-bottom: 2rem;
        }
    }

    >div >h1:first-child {
        margin-top: 4rem;
    }

    .ob-mainAsset-wrapper {
        text-align: center;
    }

    h1 {
        @include display-3-xl;
        @include media-up(lg) {
            line-height: 4.8rem;
        }
        margin: 0;
    }

    h2 {
        @include font-family('hurme-regular');
        color: $base-color-primary-grey;
        font-size: 2.8rem;
        line-height: 3.4rem;
        letter-spacing: (0.5em / 28);
    }

    p {
        @include body-1-xl;
    }

    .ob-side-by-side__wrapper {
        margin-top: 1rem;
        margin-bottom: 0;
        @include media-up(lg) {
            margin-top: 4rem;
        }
    }

    &.uppercase-title {
        h1 {
            text-transform: uppercase;
        }
    }

    .ob-side-by-side__column {
        margin: 2rem 0 4rem 0;
        display: flex;
        align-items: center;

        .align-top &{
            align-items: flex-start;
        }

        @include media-up(lg) {
            width: calc(50% - 2rem);
        }

        >div {
            flex-shrink: 0;
            width: 100%;
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        &.ob-side-by-side__left-side {

            @include media-down(sm) {
                margin: 6rem 0 2rem 0;
            }

            @include media-down(sm) {
                img {
                    float: none;
                }
            }

            .ob-mainAsset-wrapper {
                text-align: left;
                flex-shrink: 0;

                @include media-down(md) {
                    text-align: center;
                }
            }
        }

        &.ob-side-by-side__right-side {

            @include media-down(sm) {
                margin: 0 0 0 0;
            }

            @include media-down(sm) {
                img {
                    float: none;
                }
            }

            ol {
                font-size: 2rem;
            }

            .ob-mainAsset-wrapper {
                text-align: right;

                @include media-down(md) {
                    text-align: center;
                }
            }
        }

    }

    .ob-side-by-side.ta-left .ob-side-by-side__column.ob-side-by-side__right-side .ob-mainAsset-wrapper {
        text-align: left;

        @include media-down(md) {
            text-align: center;
        }
    }

    img {
        max-width: 100%;
    }
}
