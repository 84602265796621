@import "../../../../static/styles/mixins";

.ob-form {
  &__layout {
    max-width: 100%;
    margin: 0 2rem 2rem;

    @include media-up(sm) {
      margin: 0 auto 5rem;
      max-width: 87vw;
    }

    @include media-up(lg) {
      margin: 10rem auto;
      max-width: 88rem;
    }
  }

  &__title {
    @include display-3-xs;
    color: $base-color-primary-grey;
    margin-top: 0;

    @include media-up(sm) {
      font-size: 4.6rem;
      letter-spacing: 0.5px;
      line-height: 64px;
      @include font-family('hurme-light');
      margin: 0;
    }
  }

  &__subtitle {
    @include font-family('hurme-regular');
    font-size: 1.8rem;
    color: $base-color-dark-grey;
    @include media-up(sm) {
      font-size: 2.2rem;
    }
    &:focus {
      outline: none;
      text-decoration: underline;
    }
  }

  &__wrapper {
    width: 100%;

    @include media-up(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.6rem;
      &.current-password-wrapper {
        margin-bottom: 4.5rem;
      }
    }

    @include media-ie() {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .ob-form__input-wrapper {
        width: 49%;
      }
    }
  }

  &__form {
    @include media-up(md) {
      margin-top: 6rem;
    }
  }

  &__requirements {
    font-size: 1.6rem;
    @include font-family('semi-bold');
    @include media-up(sm) {
      margin-top: 1.2rem;
      max-width: 50%;
    }
  }

  &__global-error {
    display: block;
    font-size: 2.2rem;
    color: $base-color__error;
  }

  &__success {
    min-height: 350px;
    .ob-link {
      margin-top: 2rem;
    }
  }

}

/* Remove Overlay Temporarily */
img[src="http://cdn.rpxnow.com/rel/img/17c96fc4b9c8464d1c95cd785dd3120b.png"], #janrainModalOverlay, #janrainModal, img[src="https://docj27ko03fnu.cloudfront.net/rel/img/17c96fc4b9c8464d1c95cd785dd3120b.png"]{
  z-index: -1 !important;
  width: 0 !important;
  height: 0 !important;
  top: 0 !important;
  left: 0 !important;
  display: none !important;
}
