@import "../../static/styles/mixins";

a.ob-link,
button.ob-link {
    @include font-family(semi-bold);
    color: $base-color-dark-blue;
    background: transparent;
    font-size: 1.8rem;
    letter-spacing: 0.5px;
    line-height: normal;
    text-decoration: none;
    display: inline-flex;
    text-align: left;
    &:hover,
    &:focus {
        text-decoration: underline;
        .ob-icon-rounded-container,
        > .ob-icon {
            transform: translateX(-4px);
        }
    }
    &.primaryGrey {
        color: $base-color-primary-grey;
        font-size: 1.8rem;
        letter-spacing: 0.5px;
    }
    &.white {
        color: $base-color__white;
        font-size: 1.8rem;
        letter-spacing: 0.5px;
    }

    .blackText & {
        color: $base-color__black;
    }

    .textOutline & {
        @include textOutline();
    }

    .ob-icon-rounded-container,
    > .ob-icon {
        width: 1em;
        height: 1em;
        align-items: center;
        display: inline-flex;
        margin-right: 11px;
        top: .12rem;
        position: relative;
        flex-shrink: 0;
        transition: transform .2s ease-out;
    }

    &[aria-disabled=true] {
        opacity: .7;
        pointer-events: none;
    }
}
