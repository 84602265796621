@import "../../static/styles/variables";
@import "../../static/styles/mixins";

$max-image-width: 210px;

@keyframes image-animation {
    from {
        transform: translateY(100px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes background-animation {
    0% {
        opacity: 0.07;
    }
    50% {
        opacity: 0.04;
    }
    100% {
        opacity: 0.07;
    }
}

.ob-products-overview__overview {
    display: table;
    height: 100%;
    width: 100%;
    padding: 1rem 3rem 3rem 3rem;
    box-sizing: border-box;

    @include media-ie() {
        padding: 1rem 0 3rem;

        .ob-products-overview__overview-lower-content {
            .ob-button,
            .ob-products-overview__overview-link {
                margin-left: auto;
                margin-right: auto;
                min-width: 0;

                width: calc(100% - 6rem);
            }
        }
    }
}

.ob-products-overview__overview-image-wrapper-1 {
    display: table-row;
}

.ob-products-overview__overview-image-wrapper-2 {
    display: table-cell;
    vertical-align: bottom;
}

.ob-products-overview__overview-image-wrapper-3 {
    margin: 0 auto;
    width: 100%;
    max-width: unset;
    position: relative;

    .ob-products-overview__overview--full-size-image & {
        max-width: none;
    }

    @include media-down(sm) {

        .ob-products-overview__overview:not(.ob-products-overview__overview--full-size-image) & {
            width: 85%;
        }

        .ob-products-overview__overview:not(.ob-products-overview__overview--auto-height-mode) & {
            max-height: 50vh;
        }
    }
}

.ob-products-overview__overview-image-wrapper-4 {
    @include media-down(sm) {
        .ob-products-overview__overview--auto-height-mode & {
            padding-bottom: 0 !important;
        }
    }
}
.ob-products-overview__overview-image-wrapper-4{
    .ob-products-overview__overview-image{
        .ob-mainAsset-wrapper {
            .is-visible{
                .ob-mainAsset-wrapper-img{
                    width: 100%;
                }
            }
            
        }
    }
}
.ob-products-overview__overview-image {
    .ob-products-overview__overview--is-recently-viewed & {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    @include media-down(sm) {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    a {
        &:focus, &:hover {
            .ob-mainAsset-wrapper{
                transition: 0.2s ease-in;
                transform: scale(.97);
            }
        }

        &:focus {
            .ob-mainAsset-wrapper {
                outline: 1px solid $base-color-dark-blue;
            }
        }
    }

    .ob-products-overview__overview--animated & {
        opacity: 0;
        transform: translateY(100px);
    }

    .ob-products-overview__overview--animated.ob-products-overview__overview--shown & {
        animation-name: image-animation;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    .ob-mainAsset-wrapper {
        width: 100%;
        height: 100%;
        text-align: center;
        position: relative;
        transform: scale(0.95);
        transition: 0.5s ease-in-out;
        .is-visible{
            display: flex;
        }
    }

    .ob-products-overview__overview--auto-height-mode & {
        position: static;
        .ob-mainAsset-wrapper {
            height: auto;
            img {
                width: 100%;
                pointer-events: none;
            }
        }
    }

    .ob-products-overview__overview:not(.ob-products-overview__overview--auto-height-mode) &  {
        .ob-mainAsset-wrapper {
            display: flex;
            flex-direction: column;

            img {
                pointer-events: none;
                margin-top: auto;
                position: relative;
                right: auto;
                top: auto;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                max-width: 100%;
                object-fit: contain;
                width: auto;
                max-height: 500px;

                @include media-down(sm) {
                    position: absolute;
                    max-height: 100%;
                }
            }

            @include media-ie() {
                flex-direction: row;
            }
        }
    }

    .ob-products-overview__overview.ob-products-overview__overview--is-recently-viewed & {
        .ob-mainAsset-wrapper img {
            position: absolute;
            max-height: 100%;
        }
    }
}

.ob-products-overview__overview-image-placeholder {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-bottom: 100%;
    animation-delay: 0s;

    .ob-products-overview__overview--white-text & {
        background-color: rgb(235, 235, 235);
    }
}

.ob-products-overview__overview-content-wrapper-1 {
    display: table-row;
}

.ob-products-overview__overview-content-wrapper-2 {
    display: table-cell;
    height: 100%;
}

.ob-products-overview__overview-content {
    display: table;
    height: 100%;
    width: 100%;
    margin: 0 auto;

    .ob-products-overview__overview--animated & {
        opacity: 0;
    }

    .ob-products-overview__overview--animated.ob-products-overview__overview--shown & {
        animation-name: opacity;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @include media-up(sm) {
        .ob-products-overview__overview--loading & {
            max-width: $max-image-width;
        }
    }
}

.ob-products-overview__overview-lower-content {
    height: 100%;
    vertical-align: bottom;
    padding: rem(15) 0;

    button.ob-button {
        height: rem(48);
        justify-content: center;
        color:$base-color__white;
        background: $base-color-dark-blue;
        &:hover{
            color:$base-color__white;
            background: $base-color-dark-blue-hover;
        }
        @include media-up(sm) {
            min-width: 0;
            padding-left: rem(20);
            padding-right: rem(20);
            max-width: rem(300);
            width: 100%;
        }       
    }

    @media screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
        button.ob-button {
            padding: 0 rem(20);
            min-width: rem(150);
        }
    }
}

.ob-products-overview__overview-upper-content {
    padding-top: rem(20);

    @include body-1-xs;
    @include media-up(sm) {
        @include body-3-xl;
    }
}

.ob-products-overview__overview-upper-content-wrapper,
.ob-products-overview__overview-lower-content-wrapper {
    display: table-row;
}

.ob-products-overview__overview-upper-content,
.ob-products-overview__overview-lower-content {
    display: table-cell;
    text-align: center;
    .ob-products-overview__overview--white-text & {
        color: $base-color__white;
    }
}

.ob-products-overview__overview-upper-content-placeholder {
    display: block;
    width: 50%;
    height: rem(16);
    margin: rem(4) auto;
    animation-delay: 0.4s;

    @include media-up(sm) {
        .ob-products-overview__overview--loading & {
            width: 80%;
        }
    }
}

.ob-products-overview__overview-link {
    display: block;
    width: 100%;
    margin-bottom: rem(25);
    text-align: center;
    @include text-link-2-xl;

    &:link,
    &:visited {
        text-decoration: underline;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: $base-color-dark-blue-hover;
    }

    &:focus {
        outline: 1px solid $base-color-dark-blue;
    }

    .ob-products-overview__overview--white-text & {
        color: $base-color__white;
    }
}

.ob-products-overview__overview-link-placeholder {
    display: block;
    margin-bottom: rem(6);
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    height: rem(14);
    margin-top: rem(6);
    animation-delay: 0.80s;

    @include media-up(sm) {
        .ob-products-overview__overview--loading & {
            width: 60%;
        }
    }
}

.ob-products-overview__overview-button-placeholder {
    display: inline-block;
    margin: rem(24);
    height: rem(14);
    width: 40%;
    position: relative;
    animation-delay: 1.2s;

    @include media-up(sm) {
        .ob-products-overview__overview--loading & {
            width: 65%;
        }
    }
}

.ob-products-overview__overview-image-placeholder,
.ob-products-overview__overview-upper-content-placeholder,
.ob-products-overview__overview-link-placeholder,
.ob-products-overview__overview-button-placeholder {
    opacity: 0.07;
    animation-name: background-animation;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    background: black;
}
.ob-products-overview__overview-btn-section {
    min-height: 8.5rem;
    @include media-down(sm) {
        margin-top: 2rem;
    }
}
.ob-products-overview__overview {
    margin-top: 1.2rem;
    font-size: 1.4rem;
    text-align: center;

    .ob-products-overview__overview-image{
        .event_internal_link{
            text-decoration: underline;
            &:focus{
                text-decoration: none;
                outline: 1px solid $base-color-link-blue;
            }
            .ob-mainAsset-wrapper {
                img{
                    max-width: $max-image-width !important;
                    @include media-down(sm) {
                        width: 70%;
                    }    
                }
            }
            .ob-product-link-text{
                font-size: 1.4rem;
                line-height: 2rem;
                display: block;
                width: 100%;
                text-align: center;
                min-height: 40px;
                @include font-family('semi-bold');
                font-weight: 600;
                font-style: normal;
                letter-spacing: .03125em;
                &:focus{
                    text-decoration: none;
                    outline: 1px solid $base-color-link-blue;
                }
                @include media-down(sm) {
                    font-size: 1.6rem;
                    letter-spacing: .03125em;
                    line-height: 2.4rem;
                } 
            }
        }
    }
}
.ob-products-overview__overview-image{
    text-align: center;
}

// Global Equity
.zone.replacement-brush-heads-io-body{
    .ob-collapsible-products-overview-product-wrapper{
        .ob-products-overview__overview-image{
            .event_internal_link{
                text-decoration: none;
                
                .ob-product-link-text{
                    color: $base-color__white;
                    min-height: auto;
                    margin-bottom: 5px;

                    &:hover,
                    &:focus {
                        color: $base-color-dark-blue-hover;
                    }
                }
            }
        }
    }
}

.zone.kids-products-by-age-group{
    .ob-product-grid-block{
        .ob-products-overview__overview--animated{
            .ob-products-overview__overview-lower-content{
                .ob-button{
                    background: $base-color-dark-blue;
                    color: $base-color__white;
                    &:hover, &:focus {
                        background: $base-color-dark-blue-hover;
                    }
                }
            }
        }
    }
}
.layout.product-etb-full-layout{
    .ob-recently-viewed-block{
        .ob-recently-viewed-block-wrapper{
            .ob-products-overview__overview-lower-content.buy-now-cta-section{
                padding-top: 40px;
            }
        }
    }
    .ob-product-variants-recommander-block{
        .ob-products-overview__overview{
            .ob-products-overview__overview-lower-content{
                .ob-button{
                    padding: 0 11rem;
                    background: $base-color-dark-blue;
                    color: $base-color__white;
                    &:hover, &:focus {
                        background: $base-color-dark-blue-hover;
                    }
                }
            }
        }
    }
    .ob-product-grid-block{
        .ob-products-overview__overview{
            .ob-products-overview__overview-lower-content{
                .ob-button{
                    background: $base-color-dark-blue;
                    color: $base-color__white;
                    &:hover, &:focus {
                        background: $base-color-dark-blue-hover;
                    }
                }
            }
        }
    }
}
