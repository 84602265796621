@import './mixins';
@import './variables';
@import '../../components/Button/Button.scss';

body.ob-body {

    #BVRRSearchContainer {
        margin-bottom: .9rem !important;

        @include media-up(md) {
            max-width: calc(100% * 442 / 1045);
            margin: 0 auto;
        }

        .bv-cleanslate.bv-cv2-cleanslate {
            @include media-ie() {
                width: 440px !important;
                margin: 0 auto !important;
            }
        }

        .bv-content-search .bv-masthead .bv-searchbar {
            width: 100% !important;
        }

        .bv-content-search .bv-masthead .bv-masthead-search .bv-fieldsets .bv-content-search-btn {
            display: none !important;

            @include media-up(md) {
                display: block !important;
                position: absolute !important;
                z-index: 3 !important;
                top: 6px !important;
                right: 6px !important;
                height: auto !important;
                background: transparent !important;
                box-shadow: none !important;
                bottom: 6px !important;
                width: 36px !important;
                left: auto !important;

                &:focus {
                    outline-style: solid !important;
                }

                .bv-search-control-icon {
                    opacity: 0 !important;
                }
            }
        }

        .bv-masthead-product.bv-summary-bar {
            display: none !important;
        }

        .bv-cv2-cleanslate .bv-content-search {
            border: 0 !important;
        }

        #bv-text-field-search-contentSearch1 {
            height: 5.1rem !important;
            width: 100% !important;
            border: 1px solid $base-color-saturn-grey !important;
            box-sizing: border-box !important;
            padding: 0 5.1rem 0 1.5rem !important;
            border-radius: 0 !important;
            color: $base-color-primary-grey !important;
            font-size: 1.6rem !important;
            line-height: 1em !important;
            font-family: $hurmeregular !important;
            font-weight: normal !important;
            font-style: normal !important;
            transition: all linear 0s !important;

            &:focus {
                outline: 1px solid $base-color-dark-blue-hover  !important;
            }

            &::placeholder {
                color: $base-color-primary-grey !important;
                font-size: 1.6rem !important;
                font-family: $hurmeregular !important;
                font-weight: normal !important;
                font-style: normal !important;
            }
        }

        .bv-cv2-cleanslate .bv-content-search .bv-masthead-search .bv-search-clear-icon {
            top: 0 !important;
            bottom: 0 !important;
            margin-top: 20px !important;
            margin-bottom: 20px !important;
            right: 7px !important;
            width: 31px !important;
        }

        .bv-cv2-cleanslate .bv-content-search .bv-expand-filter-button-offset-off .bv-search-control-icon {
            left: auto !important;
            right: 12px !important;
            font-size: 27px !important;
            color: $base-color-dark-blue !important;
            display: block !important;
        }

        .bv-cv2-cleanslate .bv-content-search .bv-masthead-search .bv-fieldsets {
            padding: 0 0 !important;
        }

        .bv-cv2-cleanslate .bv-content-search .bv-masthead {
            background-color: transparent !important;
        }

    }

    #BVRRContainer {
        .bv-cleanslate.bv-cv2-cleanslate {
            font-family: $hurmeregular !important;
            font-weight: normal !important;
            font-style: normal !important;
            color: $base-color-primary-grey !important;
        }

        .bv-content-badges-container {
            display: none !important;
        }

        .bv-author-avatar {
            display: none !important;
        }

        .bv-content-header-meta {
            display: flex !important;
            padding-left: 0 !important;
            min-height: 0 !important;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: .5rem !important;
            flex-direction: column;

            @include media-up(md) {
                margin-top: 25px !important;
            }
        }

        .bv-content-header {
            padding: 0 !important;
        }

        .bv-content-summary {
            padding: 0 !important;
        }

        .bv-content-author-name {
            margin-left: 0 !important;
            margin-top: 4px !important;
            display: flex !important;
            align-items: flex-start;
            flex-direction: column;

            .bv-content-datetime-dot {
                display: none !important;
            }

            .bv-content-datetime {
                margin-top: 1rem !important;
            }

            .bv-content-datetime-stamp {
                font-size: 1.5rem !important;
            }

            span {
                font-family: $hurmeregular !important;
                font-weight: normal !important;
                font-style: normal !important;
                color: $base-color-primary-grey !important;
                font-size: 1.8rem !important;

            }
        }

        .bv-rating-stars-on {
            color: $base-color__yellow !important;
            font-size: 18px !important;
        }

        .bv-rating-stars-off {
            font-size: 18px !important;
        }

        .bv-content-title {
            text-transform: uppercase !important;
            font-family: $hurmesemibold  !important;
            font-weight: normal !important;
            font-style: normal !important;
            color: $base-color-primary-grey !important;
            font-size: 1.6rem !important;
            margin-bottom: 0 !important;
        }

        .bv-content-summary-body-text p {
            font-family: $hurmeregular  !important;
            font-weight: 300 !important;
            color: $base-color-primary-grey !important;
            font-size: 1.6rem !important;
            line-height: 2.7rem !important;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }

        .bv-content-details-container {
            display: none !important;
        }

        .bv-active-feedback {
            display: none !important;
        }

        .bv-content-data-recommend-yes {
            display: none !important;
        }

        .bv-content-product-questions {
            display: none !important;
        }

        .bv-inline-form-container {
            display: none !important;
        }

        .bv-secondary-content-list {
            display: none !important;
        }

        .bv-content-list-reviews {
            display: flex !important;
            flex-direction: column !important;

            @include media-up(md) {
                flex-direction: row !important;
                flex-wrap: wrap;
            }
        }

        .bv-content-review {
            margin: 0 !important;
            flex-grow: 0 !important;
            flex-shrink: 1 !important;
            width: 100% !important;
            border-bottom: 1px solid $base-color-secondary-grey !important;
            margin-top: 1.9rem !important;
            padding-bottom: 1.9rem !important;

            @include media-up(md) {
                margin-top: 2.8rem !important;
            }
        }

        .bv-content-details-offset-on {
            width: 100% !important;
        }

        .bv-content-item-avatar-offset-on {
            padding-left: 0 !important;
        }

        .bv-content-reference-data {
            border: 0 !important;
            padding: 0 !important;
        }

        .bv-content-summary-body-text {
            overflow: hidden !important;
        }

        .bv-content-pagination-container {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }

        .bv-content-pagination .bv-content-pagination-container .bv-content-pagination-buttons-item-next,
        .bv-content-pagination .bv-content-pagination-container .bv-content-pagination-buttons-item-previous {
            .bv-content-btn-pages {
                &:focus {
                    outline-style: solid !important;
                }
            }
        }

        .bv-content-pagination .bv-content-pagination-container .bv-content-btn-pages.bv-content-btn-pages-load-more {
            font-family: $hurmesemibold  !important;
            font-weight: 600 !important;
            font-style: normal !important;

            font-size: 1.4rem !important;
            align-items: center !important;
            background-color: $base-color-dark-blue !important;
            border-radius: 72px !important;
            border: none !important;
            color: $base-color__white !important;
            cursor: pointer !important;
            display: inline-flex !important;
            height: 4.8rem !important;
            padding: 2rem 3rem !important;
            text-decoration: none !important;
            transition: all 0.4s ease !important;
            width: auto !important;

            >* {
                pointer-events: none !important;
            }

            &:link {
                color: $base-color__white !important;
            }

            &:visited {
                background: $base-color-dark-blue !important;
                color: $base-color__white !important;
            }
        }

        .bv-section-summary {
            display: none !important;
        }

        .bv-action-bar-header {
            display: none !important;
        }

        .bv-action-bar {
            display: none !important;
        }

        .bv-cv2-cleanslate .bv-content-list-container .bv-header .bv-write-review-container {
            display: none !important;
        }

        .bv-cv2-cleanslate .bv-content-list-container .bv-header .bv-control-bar {
            display: flex !important;
            background: transparent !important;
            margin: 0 !important;

            .bv-control-bar-count {
                order: 1 !important;
                text-align: left !important;
                border-bottom: 1px solid $base-color-secondary-grey !important;
                font-family: $hurmeregular  !important;
                font-weight: normal !important;
                font-style: normal !important;
                font-size: 1.5rem !important;
                margin-top: 1.5rem !important;
                padding-bottom: 1.2rem !important;
                padding-left: 0 !important;
                width: 100% !important;
            }
        }

        .bv-cv2-cleanslate .bv-content-list-container .bv-header .bv-control-bar.bv-control-bar-filter-offset {
            @include media-ie() {
                max-width: 1024px !important;
                margin: 0 auto !important;
            }
        }

        .bv-cv2-cleanslate .bv-content-list-container .bv-header .bv-expand-filter-button {
            background-color: transparent !important;
            height: 3.2rem !important;
            line-height: 1.4rem !important;
            width: 3.2rem !important;

            &:hover,
            &:focus,
            &.bv-filter-active {
                background-color: $base-color-dark-blue-hover  !important;
                box-shadow: none !important;
                color: $base-color__white !important;

                .bv-filter-control-icon {
                    color: $base-color__white !important;
                }
            }

            @include media-down(md) {
                margin-right: 0 !important;
            }

            @include media-up(md) {
                margin-top: -8.8rem !important;
            }
        }

        .bv-cv2-cleanslate .bv-content-list-container .bv-header .bv-control-bar-sort {
            width: 100% !important;
            order: 0 !important;

            @include media-down(md) {
                flex: 1 1 0 !important;
            }

            @include media-up(md) {
                max-width: calc(100% * 224 / 1045) !important;
                margin-left: auto !important;
                margin-top: -8.8rem !important;
            }

            .bv-dropdown-title {
                max-width: 180px !important;
            }
        }

        // SORT BY BUTTON
        .bv-cv2-cleanslate .bv-content-list-container .bv-header .bv-dropdown {
            height: 5.1rem !important;
            border: 1px solid $base-color-saturn-grey  !important;
            box-sizing: border-box !important;
            color: $base-color-dark-blue !important;
            padding: 0 0 0 1.5rem !important;
            border-radius: 0 !important;
            width: 100% !important;

            font-family: $hurmeregular !important;
            font-weight: normal !important;
            font-style: normal !important;

            button:focus {
                outline: none !important;

                .bv-dropdown-arrow:before {
                    bottom: -5px !important;
                    content: "" !important;
                    left: -5px !important;
                    outline: 1px solid $base-color-dark-blue-hover  !important;
                    position: absolute !important;
                    right: -5px !important;
                    top: -5px !important;
                }
            }

            .bv-dropdown-arrow {

                right: 12px !important;
                font-size: 1.6rem !important;
            }

            @include media-up(md) {
                #bv-dropdown-title-reviews {
                    display: none !important;
                }
            }
        }

        //SELECT OPEN
        .bv-cv2-cleanslate .bv-dropdown-container .bv-dropdown ul .bv-dropdown-item {
            font-family: $hurmeregular !important;
            font-weight: normal !important;
            font-style: normal !important;
            font-size: 1.4rem !important;
        }

        .bv-cv2-cleanslate .bv-content-list-container .bv-control-bar-sort .bv-dropdown-target {
            font-size: 1.4rem !important;
            padding: 16px 25px 13px 0 !important;
            color: $base-color-primary-grey !important;

            @include media-up(md) {
                display: flex !important;
                flex-wrap: nowrap !important;
            }
        }

        // PREVIOUS NEXT CONTAINER
        .bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-list-container {
            .bv-content-pagination {
                margin: 0 !important;
                margin-top: 3.3rem !important;
                padding: 1px !important;
                background: transparent !important;
            }
        }

        .bv-cv2-cleanslate .bv-shared .bv-compat .bv-content-list-container .bv-content-pagination-container {
            display: flex !important;
            flex-direction: column !important;

            .bv-control-bar-count {
                margin: 0 auto !important;
                top: -9px !important;
            }

            .bv-content-pagination-buttons {
                display: flex !important;
                flex-direction: row !important;
                justify-content: space-between !important;
                margin: 0 !important;
                width: 100% !important;
                margin-top: -5rem !important;
            }

            .bv-content-btn-pages {
                font-family: $hurmesemibold  !important;
                font-weight: 600 !important;
                font-style: normal !important;
                font-size: 1.6rem !important;
                align-items: center !important;
                background-color: $base-color-dark-blue !important;
                border-radius: 72px !important;
                border: none !important;
                color: $base-color__white !important;
                cursor: pointer !important;
                padding: 1rem 1rem !important;
                text-decoration: none !important;
                transition: all 0.4s ease !important;
                width: 54px !important;
                box-sizing: border-box !important;
                display: flex !important;
                justify-content: center !important;

                &:after {
                    /* use !important to prevent issues with browser extensions that change fonts */
                    font-family: 'icomoon', monospace !important;
                    speak: none !important;
                    font-style: normal !important;
                    font-weight: normal !important;
                    font-variant: normal !important;
                    text-transform: none !important;
                    line-height: 1 !important;
                    /* Better Font Rendering =========== */
                    -webkit-font-smoothing: antialiased !important;
                    -moz-osx-font-smoothing: grayscale !important;

                    content: "\e90a" !important;
                    font-size: 20px !important;
                }

                &-last {
                    &:after {
                        content: "\e90b" !important;
                    }
                }

                >* {
                    pointer-events: none !important;
                }

                &:link {
                    color: $base-color__white !important;
                }

                &:visited {
                    background: $base-color-dark-blue-hover !important;
                    color: $base-color__white !important;
                }

                &:hover,
                &:focus {
                    text-decoration: none !important;
                    background: $base-color-tertiary-blue !important;
                }

                &-inactive {
                    background: #D3D2D2 !important;

                    &:hover,
                    &:focus {
                        text-decoration: none !important;
                        background: $base-color-dark-blue-hover  !important;
                    }
                }

                .bv-content-btn-pages-prev,
                .bv-content-btn-pages-next {
                    display: none !important;
                }
            }

            .bv-content-pagination-pages-current {
                font-family: $hurmeregular  !important;
                font-weight: 600 !important;
                font-size: 1.5rem !important;
            }
        }

        .bv-content-container .bv-content-core {
            @include media-up(md) {
                display: flex !important;
                flex-direction: row !important;
                justify-content: space-between !important;
                max-width: 960px !important;
                min-height: 135px !important;

                .bv-content-details-offset-on,
                .bv-content-details-offset-off {
                    width: calc(100% * 742 / 1045) !important;
                    flex-grow: 0 !important;
                    flex-shrink: 0 !important;
                    flex-basis: auto !important;
                    margin-left: auto !important;
                    margin-top: 50px !important;
                }

                .bv-content-header {
                    position: relative !important;
                    width: calc(100% * 303 / 1045) !important;
                    box-sizing: border-box !important;
                    padding-right: 10px !important;

                    .bv-content-datetime {
                        position: absolute !important;
                        right: 0 !important;
                        left: auto !important;
                        top: 0 !important;
                        bottom: auto !important;
                        margin-top: 0 !important;
                        transform: translateX(103%);
                        width: 100% !important;
                    }

                    .bv-content-title-container {
                        position: absolute !important;
                        right: auto !important;
                        left: auto !important;
                        top: 25px !important;
                        bottom: auto !important;
                        margin-top: 0 !important;
                        transform: translateX(52%);
                        width: 200% !important;
                    }
                }
            }

            @include media-ie() {
                position: relative !important;
                left: 50% !important;
                transform: translateX(-50%);
            }
        }
    }



    // Little summary rating widget
    [data-bv-show=rating_summary] {

        .bv_stars_component_container {
            padding-right: 1rem !important;
        }

        .bv_percentRecommend_component_container,
        .bv_main_container_row_normal,
        .bv_main_container_line_break {
            display: none !important;
        }

        .bv_main_container_row_flex {
            padding-right: 0 !important;
            margin-bottom: 1.1rem !important;
            @media screen and (min-width: 451px) {
                margin-right: 0 !important;
            }
            &:focus{
                border: 1px solid $base-color-dark-blue !important ;
            }
        }

        .bv_main_container .bv_stars_component_container .bv_stars_button_container svg:not(:last-child) {
            padding-right: 2px !important;
            width: 14px !important;
            height: 14px !important;
        }

        .bv_main_container {
            flex-direction: column !important;
        }

        .bv_numReviews_delimeter {
            margin-right: .2rem !important;
            margin-left: .2rem !important;
        }

        .bv_avgRating,
        .bv_numReviews_delimeter {
            font-family: $hurmeregular !important;
            font-weight: 600 !important;
            color: $base-color-primary-grey !important;
            font-size: 1.6rem !important;
            line-height: 2.7rem !important;
            text-decoration: none !important;

            &:hover,
            &:focus {
                font-family: $hurmeregular !important;
                font-weight: 600 !important;
                color: $base-color-primary-grey !important;
                font-size: 1.6rem !important;
                line-height: 2.7rem !important;
                text-decoration: underline !important;
            }

        }

        .bv_numReviews_text {
            font-family: $hurmeregular !important;
            font-weight: 600 !important;
            color: $base-color-dark-blue !important;
            font-size: 1.6rem !important;
            line-height: 2.7rem !important;
            text-decoration: none !important;
            text-transform: lowercase !important;
            position: relative !important;

            &:hover,
            &:focus {
                font-family: $hurmeregular !important;
                font-weight: 600 !important;
                color: $base-color-dark-blue-hover !important;
                font-size: 1.6rem !important;
                line-height: 2.7rem;
                text-decoration: underline !important;
                text-transform: lowercase !important;
            }

            &:before {
                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: 'icomoon', monospace !important;
                speak: none !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                line-height: 1 !important;
                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased !important;
                -moz-osx-font-smoothing: grayscale !important;

                content: "\e915" !important;
                position: absolute !important;
                left: auto !important;
                right: -10px !important;
                bottom: auto !important;
                top: 9px !important;
                font-size: 7px !important;
            }
        }
        .bv_war_button{
            padding: 10px 25px !important;
        }
    }

    .ob-customer-review-container [data-bv-show=rating_summary] {
        width: 100% !important;

        .bv_button_component_container {
            padding-left: 1px !important;
            position: relative !important;
            z-index: 2 !important;
        }
    }

    [data-bv-show=rating_summary] .bv_main_container_CTA_row {
        display: none !important;
    }

    .ob-customer-review-container [data-bv-show=rating_summary] .bv_main_container_CTA_row {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: 100% !important;

        @include media-up(md) {
            justify-content: center !important;
        }
    }

    .ob-customer-review-container [data-bv-show=rating_summary] .bv_main_container {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        .bv_avgRating,
        .bv_numReviews_text {
            &:focus {
                outline: 1px solid $base-color-dark-blue-hover  !important;
            }
        }
    }

    .ob-customer-review-container [data-bv-show=rating_summary] .bv_main_container_row_flex .bv_button_component_container #WAR {
        font-family: $hurmesemibold !important;
        font-weight: 400 !important;
        font-style: normal !important;
        font-size: 1.6rem !important;
        align-items: center !important;
        background: $base-color-dark-blue !important;
        border-radius: 72px !important;
        border: none !important;
        color: $base-color__white !important;
        cursor: pointer !important;
        display: inline-flex !important;
        height: 48px !important;
        padding: 0 4.4rem !important;
        text-decoration: none !important;
        transition: all 0.4s ease !important;
        text-transform: uppercase !important;
        line-height: 49px !important;

        > * {
            pointer-events: none !important;
        }

        &:link {
            color: $base-color__white !important;
        }

        &:visited {
            background: $base-color-dark-blue-hover  !important;
            color: $base-color__white !important;
        }

        &:hover,
        &:focus {
            background: $base-color-dark-blue-hover  !important;
        }

        &:hover {
            text-decoration: none !important;
        }

        &:focus {
            outline: 1px solid $base-color-dark-blue-hover  !important;
            text-decoration: underline !important;
        }
    }

    // Little inline rating widget

    [data-bv-show=inline_rating] {

        .bv_stars_component_container {
            padding-right: .5rem !important;
        }

        .bv_main_container .bv_stars_component_container .bv_stars_button_container svg:not(:last-child) {
            padding-right: 2px !important;
            width: 14px !important;
            height: 14px !important;
        }

        .bv_averageRating_component_container .bv_text,
        .bv_numReviews_component_container .bv_text {
            font-family: $hurmeregular !important;
            font-weight: 600 !important;
            color: $base-color-primary-grey !important;
            font-size: 1.6rem !important;
            line-height: 2.7rem !important;
            text-decoration: none !important;
        }

    }

    .ob-series-selector__selected-info [data-bv-show=inline_rating] {
        // IN SERIES SELECTOR
        padding-right: 0 !important;
        margin-bottom: 1.1rem !important;
        margin-top: -1.5rem !important;
    }

    .ob-series-comparison__product-info [data-bv-show=inline_rating][data-bv-product-id]{
        // IN SERIES COMPARE
        padding-right: 0 !important;
        margin-bottom: 1rem !important;
        margin-top: -2.9rem !important;
    }

    .ob-product-highlights [data-bv-show=inline_rating][data-bv-product-id]{
        padding-right: 0 !important;
        margin-bottom: 0.6rem !important;
    }
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-mbox {

    // MY REVIEW MODAL

    background: $base-color-dark-blue !important;

    .bv-mbox-breadcrumb span.bv-mbox-breadcrumb-item {
        font-family: $hurmeregular !important;
        font-weight: 600 !important;
        color: $base-color-primary-grey !important;
        font-size: 2rem !important;
        @include media-up(sm) {
            font-size: 3rem !important;
        }
    }

    .bv-required-fields-text {
        font-family: $hurmeregular !important;
        font-weight: normal !important;
        color: $base-color-primary-grey !important;
        font-size: 1.4rem !important;
    }

    .bv-fieldset-label-text,
    .bv-text-link {
        font-family: $hurmeregular !important;
        font-weight: 600 !important;
        color: $base-color-primary-grey !important;
        font-size: 1.8rem !important;
    }

    .bv-text-link {
        color: $base-color-dark-blue !important;
    }

    .bv-rating-helper {
        font-family: $hurmeregular !important;
        font-weight: normal !important;
        color: $base-color-primary-grey !important;
        font-size: 1.8rem !important;
    }

    .bv-helper-label {
        font-family: $hurmeregular !important;
        font-weight: normal !important;
        color: $base-color-primary-grey !important;
        font-size: 1.4rem !important;
    }

    .bv-text {
        font-family: $hurmeregular !important;
        font-weight: normal !important;
        color: $base-color-primary-grey !important;
        font-size: 1.6rem !important;

        &::placeholder {
            font-family: $hurmeregular !important;
            font-weight: normal !important;
            color: $base-color-primary-grey !important;
            font-size: 1.6rem !important;
        }
    }

    #bv-casltext-review {
        font-family: $hurmeregular !important;
        font-weight: normal !important;
        color: $base-color-primary-grey !important;
        font-size: 1.3rem !important;
    }

    #add-photo-button-description {
        font-family: $hurmeregular !important;
        font-weight: normal !important;
        color: $base-color-primary-grey !important;
        font-size: 1.3rem !important;
    }

    .bv-btn-add-video,
    .bv-btn-add-photo {
        font-family: $hurmeregular !important;
        font-weight: 600 !important;
        color: $base-color-primary-grey !important;
        font-size: 1.3rem !important;
    }

    .bv-submission-button-submit:not(.bv-cancel) {
        font-family: $hurmesemibold !important;
        font-weight: 600 !important;
        font-style: normal !important;

        font-size: 1.6rem !important;
        align-items: center !important;
        background-color: $base-color-dark-blue !important;
        border-radius: 72px !important;
        border: none !important;
        color: $base-color__white !important;
        cursor: pointer !important;
        display: inline-flex !important;
        padding: 1rem 3rem !important;
        text-decoration: none !important;
        transition: all 0.4s ease !important;
        width: auto !important;
    }

    .bv-form-actions-submit {
        width: auto !important;
    }

    .bv-subject-info {
        text-align: center !important;
    }

    img.bv-subject-image {
        max-height: 500px !important;
    }

    .bv-subject-name-header {
        font-family: $hurmesemibold !important;
        font-weight: 600 !important;
        font-style: normal !important;
        font-size: 1.8rem !important;
        text-transform: uppercase !important;
    }

    .bv-fieldset-active .bv-fieldset-arrowicon {
        border-left-color: $base-color-dark-blue !important;
    }
}


body.ob-body .ob-customer-review.no-reviews {
    margin-bottom: 1rem!important;
    [data-bv-show=rating_summary] .bv_main_container_CTA_row {
        justify-content: center !important;
    }
}

body.ob-body [data-bv-show=inline_rating].no-reviews .bv_numReviews_component_container .bv_text {
    display:none!important;
}
.bv-focusable:focus{
    border: 2px solid $base-color-link-blue !important ;
}
.bv-expand-filter-button-offset-off{
    border: 2px solid $base-color-primary-grey !important;
}