@import '../../static/styles/mixins';
@import "../../static/styles/variables";
.breadcrumb-main{
    @include media-down(sm) {
	    display: none !important;
	}
    background: #f4f4f4;
    width: 100%;
    z-index: 9;
    display: flex;
    @include font-family('hurme-regular');
    font-size: 16px;
    ul{
        @include media-up(sm){
            padding:0 5.5%
        }
        text-align: left;
       // padding: 17px 20px 11px;
        list-style: none;
        li{
            display: inline;
        }
        li:first-child:before {
            content: '';
            padding: 0px;
        }
        li:before {
            padding: 8px;
            content: "/\00a0";
            color:#a1a1a1
        }
        .op-breadcrumb-loading {
            color: #f4f4f4;
        }
    }
    position: relative;
    a, .activeMenu{
        color:#050505
    }
    a{
        cursor: pointer;
    }
    a:hover{
        text-decoration: none;
    }
    .slash-class{
        padding : 0 5px;
    }
    &.visible-true {
        transition: top 0.15s ease-in;
      }
    
      &.visible-false {
        transition: top 0.15s ease-out;
      }


}
.breadcrumb{
    li{
        a:focus-visible{
            text-decoration: none;
            border: 2px solid $base-color__black ;
        }
    }
}