@import '../../static/styles/mixins';
@import '../../static/styles/variables';

.ob-legacy-product-highlights {

    .ob-product-highlights__img-ctn img {
        max-width: 100%;
    }

    .ob-mobile-legacy-product-highlights-header {
        @include media-down(sm) {
            display: block;
        }

        @include media-up(sm) {
            display: none;
        }
    }

    .ob-product-highlights__text-content {
        @include media-up(md) {
            width: 48.1rem;
            margin-right: 70px;
        }
    }

    .ob-product-highlights__surtitle {
        @include media-up(lg) {
            font-size: 1rem;
        }
    }

    .ob-product-highlights__title {
        @include media-up(lg) {
            font-size: 2.8rem;
            line-height: 3.4rem;
        }
    }

    .ob-product-highlights__description {
        @include media-up(lg) {
            font-size: 1.8rem;
            line-height: 2.6rem;
        }

        button {
            color: $base-color-dark-blue;
            text-decoration: underline;
            transition: color 0.35s ease-in-out;

            &:hover,
            &:focus {
                color: $base-color-secondary-blue;
            }
        }
    }
}

.ob-product-highlights__carousel-btn--prev:focus,
.ob-product-highlights__carousel-btn--next:focus {
    border: 2px solid $base-color-link-blue;
}