@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.beforeAfterContainer,
.beforeAfterContainerSmile {
    width: 50vw;
    height: 50vw;
    margin: auto;
    display: block;

    @include media-up(md) {
        transform: translateX(-7vw);
    }
    @include media-down(md){
        width: 90vw;
        height: 60vw;
    }

    .slick-next {
        @media screen and (max-width:800px) {
            right: 5px;
        }
    }

    .slick-prev {
        @media screen and (max-width:800px) {
            left: 5px;
        }
    }


    .beforeAfterGlider,
    .beforeAfterGliderSmile {
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .afterImageContainer {
        display: block
    }

    .beforeImageContainer,
    .beforeImageContainerSmile {
        position: absolute;
        height: 100%;
        width: 40%;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 2;

        img {
            max-width: unset;
            max-height: 100%;
        }
    }

    .gliderSlider,
    .gliderSliderSmile {
        position: absolute;
        display: flex;
        align-items: center;
        z-index: 5;
        height: 48px;
        width: 48px;
        left: 41%;
        top: 50%;
        margin-left: -30px;
        margin-top: -110px;
        border: 1px solid $base-color-dark-blue;
        border-radius: 50%;
        background: $base-color-dark-blue;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        @include media-down(sm){
            scale: 0.7;
            margin-top: -40px;

        }

        &::before,
        &::after {
            content: " ";
            display: block;
            width: 1px;
            height: 1000vh;
            position: absolute;
            left: 50%;
            background-image: linear-gradient($base-color-dark-blue 33%, rgba(255, 255, 255, 0) 0%);
            background-position: right;
            background-size: 1px 7px;
            background-repeat: repeat-y;
        }

        .arrowLeft {
            position: relative;
            background: $base-color__white;
            height: 2px;
            width: 12px;
            left: 7px;
        }

        .arrowRight {
            position: relative;
            background: $base-color__white;
            height: 2px;
            width: 12px;
            right: -15px;
        }

        /* Head of the arrow */
        .arrowRight::after {
            content: "";
            position: absolute;
            top: -5px;
            right: 0px;
            height: 12px;
            width: 12px;
            border-top: 2px solid $base-color__white;
            border-right: 2px solid $base-color__white;
            transform: rotate(45deg);
        }

        .arrowLeft::after {
            content: "";
            position: absolute;
            top: -5px;
            height: 12px;
            width: 12px;
            border-top: 2px solid $base-color__white;
            border-right: 2px solid $base-color__white;
            transform: rotate(225deg);
        }
    }
}