@import "../../static/styles/mixins";

.zoneHidden {
    position: absolute;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    display: block;
    pointer-events: none;
    z-index: map-get($zindex, negative);
}

.ob-zone__children-oral-health {
    padding-top: 90px!important;

    h1, .ob-html-block {
        max-width: 70%;
        margin: 0 auto;
    }

    h1 {
        @include font-family('hurme-regular');
        text-align: center;
        font-size: 3.2rem;
        color: $base-color-primary-grey;
    }

    .ob-html-block {
        color: $base-color-primary-grey;
        font-size: 1.6rem;
        padding-bottom: 4rem;
    }
}

.ob-zone-adchoices {
    @include media-up(md) {
        display: none;
    }
}

.ob-zone__coupons--netherlands {
    max-width: 1440px;
    margin: 0 auto;

    h1 {
        @include display-2-xl;
        text-align: center;
    }
}
