@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-brush-configurator {
    background: white;
    color: $base-color-primary-grey;
    position: relative;
    z-index: map-get($zindex, brushConfigurator);
}

.ob-brush-configurator__top {
    margin-bottom: rem(10);
    padding: 30px 24px 0;

    @include media-up(sm) {
        padding-top: 50px;
        margin-bottom: rem(5);
    }
}

.ob-brush-configurator__title {
    @include display-3-xl;
    max-width: 1120px;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
}

.ob-brush-configurator__subtitle {
    @include eyebrow-1-xl;
    max-width: 1090px;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;

    @include media-up(sm) {
        font-size: rem(16);
    }
}

.ob-brush-configurator__separator {
    height: 0;
    padding: 0 26px;

    &:before {
        display: block;
        max-width: 1090px;
        margin: 0 auto;
        border-top: 1px solid $base-color-secondary-grey;
        content: ' ';
    }
}

.ob-brush-configurator__toggle-inner {
    display: flex;
    align-items: center;
}

.ob-brush-configurator__toggle-icon {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 50px;
    line-height: 50px;
    font-size: 50px;

    &:before {
        flex: none;
    }
}

.ob-brush-configurator__toggle-text {
    flex: 0 1 auto;
    text-align: left;
}

.ob-brush-configurator__needs-list,
.ob-brush-configurator__features-list {
    margin: rem(12) auto rem(8);
    padding: 0;
    list-style: none;
    text-align: center;

    @include media-up(sm) {
        margin-top: rem(7);
    }
}

.ob-brush-configurator__needs {
    margin-bottom: 32px;
}

.ob-brush-configurator__needs-top {
    padding: 40px 13px 0;

    @include media-up(sm) {
        padding: 32px 13px 0;
    }
}

.ob-brush-configurator__needs-item {
    display: inline-block;
    margin: 8px;

    @include media-up(sm) {
        margin: 10px;
    }
    .ob-brush-configurator__toggle:focus{
        border: 3px solid $base-color-outline-blue;
    }
}

.ob-brush-configurator__features {
    max-width: 1090px;
    margin: 0 auto 36px;
}

.ob-brush-configurator__features-top {
    padding: 0 10px;
}

.ob-brush-configurator__features-item {
    display: inline-block;
    margin: 5px;

    @include media-up(sm) {
        margin: 10px;
    }

    .ob-brush-configurator__toggle {
        width: rem(180);
        height: rem(90);
        padding-left: 0.313rem;
        padding-right: 0.313rem;
        white-space: normal;
        &:focus{
            border: 3px solid $base-color-outline-blue;
        }
        .ob-brush-configurator--spain & {
            height: rem(105);
        }

        .ob-brush-configurator--taller-toggles & {
            height: rem(110);
        }

        .ob-brush-configurator--larger-toggles & {
            width: rem(200);
        }

        &, &:hover, &:focus {
            text-decoration: none;
        }

        &:hover, &:focus {
            .ob-brush-configurator__toggle-text {
                text-decoration: underline;
            }
        }
    }
}

.ob-brush-configurator__matches-top {
    margin-bottom: 15px;
    padding: 0 13px;
}

.ob-brush-configurator__matches-list {
    display: flex;
    box-sizing: content-box;
    width: 100vw;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    @include no-scrollbar;
}

.ob-brush-configurator__matches-item {
    scroll-snap-align: center;
    flex: none;
    display: flex;
    box-sizing: content-box;
    width: 100vw;
    padding: 0 calc(50% - 471px);
    margin-right: calc(-100% + 942px);
    pointer-events: none;

    @media (min-width: 942px) {
        width: 942px; // 900px + 21px + 21px
    }
}

.ob-brush-configurator-product {
    flex: 0 1 auto;
    display: flex;
    box-sizing: border-box;
    width: 100vw;
    padding: 10px 15px;
    pointer-events: all;

    @include media-up(sm) {
        padding: 10px 21px 18px;
    }
}

.ob-brush-configurator-product__box {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 0;
    padding: 10px 0;
    background: $base-color-tertiary-off-white;
    box-shadow: 0 0 5px 0 rgba(black, 0.5);

    @include media-up(sm) {
        height: rem(580);
        padding: 1rem 2rem;
        flex-direction: row;
    }

    @include media-up(md) {
        padding: 1rem 8rem 1rem 4rem;
    }
}

.ob-brush-configurator-product__top {
    flex: none;
    display: flex;

    @include media-up(sm) {
        flex: 2 2 auto;
        width: (171px + 253px); // pic + info
    }
}

.ob-brush-configurator-product__pic {
    display: flex;
    flex-direction: column;
    width: 126px;

    @include media-up(sm) {
        flex: 1 1 auto;
        width: 171px;
    }
}

.ob-brush-configurator-product__pic-image {
    flex: 1 1 auto;
    height: 0;

    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
    }
}

.ob-brush-configurator-product__info {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    box-sizing: content-box;
    width: 200px;
    min-height: rem(402);
    padding: 30px 20px 30px 0;

    @include media-up(md) {
        display: block;
        width: 253px;
        min-height: none;
        padding: 62px 0 30px;
    }
}

.ob-brush-configurator-product__info-start {
    flex: none;
    margin-bottom: auto;
}

.ob-brush-configurator-product__info-name {
    @include eyebrow-1-xl;
    margin: 0 0 rem(20);
    text-transform: uppercase;

    @include media-up(sm) {
        margin: 0 0 rem(4);
        line-height: rem(20);
        font-size: rem(16);
    }
}

.ob-brush-configurator-product__info-surtitle {
    @include font-family('semi-bold');
    margin: 0 0 rem(14);
    font-size: rem(14);

    @include media-up(sm) {
        margin: 0 0 rem(20);
        max-height: 180px;
        overflow-y: auto;
    }
}

.ob-brush-configurator-product__colors {
    width: 100%;
    margin-bottom: rem(25);
    padding-top: rem(20);
    border-top: 1px solid $base-color-secondary-grey;
}

.ob-brush-configurator-product__colors-subheading {
    @include eyebrow-1-xl;
    margin: 0 0 rem(10);
    line-height: rem(20);
    font-size: rem(16);
    letter-spacing: 0;
    text-transform: uppercase;

    @include media-up(sm) {
        margin: 0 0 rem(10);
        line-height: rem(20);
        font-size: rem(16);
    }
}

.ob-brush-configurator-product__colors-list {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 10px);
    margin: 0;
    padding: 0;
    list-style: none;
}

.ob-brush-configurator-product__colors-item {
    flex: none;
    margin: 5px 10px 5px 0;
}

.ob-brush-configurator-product__colors-item .ob-series__color {
    width: 40px;
    height: 40px;
}

.ob-brush-configurator-product__link {
    @include font-family('semi-bold');
    font-size: rem(14);
    text-transform: uppercase;

    &, &:link {
        text-decoration: underline;
    }

    &:hover, &:focus, &:active {
        text-decoration: none;
    }
}

.ob-brush-configurator-product__info-end {
    flex: none;
    margin-top: auto;
}

.ob-brush-configurator-product__buy {
    margin-top: rem(16);
}

.ob-brush-configurator-product__buy-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: rem(4) rem(25);
    font-size: rem(16);
    text-align: center;

    @include media-up(sm) {
        width: auto;
    }
}

.ob-brush-configurator-product__features {
    flex: none;
    padding: 69px 29px 25px;

    @include media-up(sm) {
        order: -1;
        flex: 1 1 auto;
        width: 356px;
        padding: 62px 0 10px;
    }
}

.ob-brush-configurator-product__features-heading {
    @include eyebrow-1-xl;
    margin: 0;
    line-height: rem(20);
    font-size: rem(16);
    letter-spacing: 0;
    text-transform: uppercase;

    @include media-up(sm) {
        width: 312px;
        line-height: rem(20);
        font-size: rem(16);
    }
}

.ob-brush-configurator-product__features-list {
    margin: 20px 0 0;
    padding: 0;
    list-style: none;

    @include media-up(sm) {
        margin-top: 24px;
    }
}

.ob-brush-configurator-product__features-item {
    display: flex;
    align-items: center;
    margin: 16px 0 0;
}

.ob-brush-configurator-product__features-icon {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-right: 14px;
    border: 1px solid #4A4A4A;
    border-radius: 24px;
    color: #4A4A4A;
    line-height: 24px;
    font-size: 24px;

    &:before {
        flex: none;
    }
}

.ob-brush-configurator-product__features-text {
    @include font-family('semi-bold');
    flex: 1 1 auto;
    font-size: rem(14);
}

.ob-brush-configurator__matches-nav-ctn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ob-brush-configurator__matches-nav {
    margin-top: 10px;
    margin-bottom: 5rem;
    @include media-up(sm) {
        margin-top: 0;
    }
}
