@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.post-purchase-write-review-section {
  scroll-margin-top: 10rem;
  .ob-background-wrapper {
    margin: 0 auto;
    width: 100%;
    height: 1100px !important;
    min-height: 60vh !important;
    padding-top: 0 !important;
    @include media-down(md) {
      min-height: 2000px !important;
    }
    @include media-down(sm) {
      min-height: 1400px !important;
    }
  }

  &__textContent {
    width: 50%;
    padding-left: 2.5%;
    @include media-down(md) {
      margin-top: 10%;
      width: 100%;
      justify-content: center;
    }
    align-items: flex-start;
    padding-top: 5%;
    @media only screen and (device-width: 1024px) 
    and (device-height: 1366px) {
      width: 56%;
    }
  }

  &__textContent-inner {
    width: 100%;
    text-align: center;
  }

  &__text-image {
    img {
      width: 80px;
      height: 80px;
      @include media-down(md) {
        width: 80px;
        height: 80px;
      }
    }
    margin-bottom: 3.5rem;
    @include media-down(md) {
      margin-top: 2rem;
    }
    @include media-down(sm) {
      margin-top: 1.9rem;
    }
  }

  &__title {
    width: 100%;
    color: #fff;
    font-weight: bold;
    @include font-family('hurme-regular');
    font-size: 56px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 60px;
    text-align: center;
    text-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
    @include media-down(md) {
      width: 70%;
      margin: 0 auto;
    }
    @include media-down(sm) {
      width: 60%;
      margin: 0 auto;
      font-size: 28px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 28px;
      text-align: center;
    }
  }

  &__body {
    color: #fff;
    width: 77%;
    margin: 0 auto;
    margin-top: 4.4rem !important;
    margin-bottom: 0rem;
    @include font-family('hurme-regular');
    font-size: 22px;
    letter-spacing: 0.5px;
    line-height: 26px;
    text-align: center;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    @include media-down(md) {
      font-size: 16px;
      margin-top: 2.1rem !important;
      width: 70%;
    }
  }

  &__divider {
    width: 90%;
    height: 1px;
    border: 1px solid #fff;
    color: #fff;
    margin-bottom: 3.9rem;
    @include media-down(md) {
      width: 80%;
    }
  }

  &__cta-write-review {
    margin-top: 3.5rem;
    margin-bottom: 4rem;
    width: 220px;
    height: 48px !important;
    padding: 0px 2rem !important;
    span {
      margin: 0 auto;
    }
  }

  &__review-content {
    .review-label {
      color: #ffffff;
      @include font-family('hurme-regular');
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin-top: 2.5rem;
    }
    .review-title {
      color: #ffffff;
      @include font-family('semi-bold');
      font-size: 30px;
      font-style: oblique;
      font-weight: bold;
      letter-spacing: 0.27px;
      line-height: 35px;
      text-align: center;
      @include media-down(md) {
        font-size: 20px;
        line-height: 27px;
      }
    }
    .star-icon {
      width: 214px;
      margin: 0 auto;
    }
    .review-text {
      color: #ffffff;
      @include font-family('hurme-regular');
      font-size: 18px;
      font-style: oblique;
      font-weight: 600;
      letter-spacing: 6px;
      line-height: 30px;
      text-align: center;
      width: 80%;
      margin: 0 auto;
      margin-top: 1rem;
      @include media-down(md) {
        width: 85%;
        font-size: 16px;
      }
    }
  }
}

.post-purchase-bv-widget div::-webkit-scrollbar {
  width: 0.7em;
  @include media-down(sm) {
      width: 0.5em;
  }
}

.post-purchase-bv-widget div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #041337;
  border-radius: 10px;
}

.post-purchase-bv-widget div::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

.post-purchase-bv-widget {
  margin-top: 3rem;
  margin-bottom: 2rem;
  scroll-margin-top: 10rem;
  &__close-modal {
    position: absolute;
    top: 2rem;
    right: 2rem;
    transition: all ease 0.35s;
    .ob-icon {
      width: 1.6rem !important;
      height: 1.6rem !important;
      font-size: 1.6rem !important;
    }
    @include media-down(sm) {
      top: 1.5rem;
      right: 1rem;
    }
    &:hover,
    &:focus {
      transform: scale(1.1);
    }
    &:focus {
      border: 1px solid $base-color-dark-blue;
    }
  }
  .ob-contentBlock-wrapper {
    justify-content: flex-start;
    @include media-down(md) {
      justify-content: center;
    }
  }
  .ob-background-wrapper {
    min-height: 1100px !important;
    width: 100%;
    margin: 0 auto;
    @include media-down(md) {
      min-height: 2000px !important;
    }
    @include media-down(sm) {
      min-height: 2000px !important;
    }
  }
  .ob-contentBlock-textContent {
    align-items: flex-start;
    padding-top: 5%;
    width: 100%;
    @include media-down(md) {
      margin-top: 10%;
      width: 100%;
      justify-content: center;
    }
  }
  .ob-contentBlock-textContent-inner {
    width: 100%;
    text-align: center;
  }
  &__bv-widget-icon {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-top: 6rem;
    padding-bottom: 5rem;
    @include media-down(md) {
      padding-bottom: 2rem;
    }
    img {
      max-width: 115px;
      max-height: 40px;
      @include media-down(md) {
        max-width: 79px;
        height: 27px;
      }
    }
  }
  &__title {
    width: 88%;
    margin: 0 auto;
    color: #fff;
    font-weight: bold;
    font-size: 45px;
    line-height: 49px;
    margin-top: 0.5rem;
    text-align: center;
    @include font-family('hurme-regular');
    @include media-down(md) {
      margin-top: 2.5rem;
      width: 80%;
      font-size: 32px;
      line-height: 40px;
    }
    @include media-down(sm) {
      width: 80%;
    }
    @include media-down(xs) {
      width: 95%;
    }
  }
  &__label-second-step {
    color: #fff;
    width: 80%;
    margin: 0 auto;
    margin-top: 1rem !important;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    text-align: center;
    @include media-down(md) {
      width: 80%;
      font-size: 16px;
    }
    @include media-down(sm) {
      width: 80%;
    }
    @include media-down(xs) {
      width: 80%;
      line-height: 22px;
    }
  }
  &__divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
    box-sizing: border-box;
    height: 1px;
    width: 80%;
    border: 1px solid #FFF;
    @include media-down(md) {
      width: 60%;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }

  &__review-module {
    max-width: 82%;
    margin: 0 auto;
    @include media-down(md) {
      max-width: 82%;
    }
  }

  &__series-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: 7rem;
    width: 100%;
    padding-left: 5rem;
    padding-right: 5rem;
    @include media-down(md) {
      flex-direction: column;
      margin-top: 5rem;
    }
  }

  &__series-item-wrapper {
    min-width: 100px;
    min-height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    @include media-down(md) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 10rem;
    }
  }

  &__series-container:first-child {
    margin-left: 0;
  }

  &__series-container:last-child {
    margin-right: 0;
    @include media-down(md) {
      margin-bottom: 0 !important;
    }
  }

  &__series-item-image {
    img {
      width: 40px;
      height: 230px;
      min-width: 40px;
      min-height: 230px;
      max-width: 40px;
      max-height: 230px;
      @include media-down(md) {
        width: 35px;
        height: 240px;
        min-width: 35px;
        min-height: 240px;
        max-width: 35px;
        max-height: 240px;
      }
    }
  }

  &__down-arrow {
    transform: rotate(45deg);
  }

  &__dropbtn {
    background-color: transparent;
    color: #ffffff;
    padding: 5px;
    padding-left: 14px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    font-size: 20px;
    @include font-family('semi-bold');
    min-width: 170px;
    width: 170px;
    min-height: 35px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    text-align: left;
    @include media-down(md) {
      min-width: 180px;
      width: 180px;
    }
    i {
      border: solid #fff;
      border-width: 0 1.8px 1.8px 0;
      display: inline-block;
      padding: 6px;
      position: absolute;
      right: 1rem;
    }
    span:focus &__dropdown-content {
      display: block;
    }
  }

  &__dropdown {
    position: relative;
    display: inline-block;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  &__dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    @include media-down(md) {
      min-width: 180px;
      width: 180px;
    }
  }

  &__dropdown-link-disable {
    opacity: 0.5;
    pointer-events: none;
  }

  &__dropdown-content button {
    color: $base-color-dark-blue;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 14px;
    width: 170px;
    min-height: 35px;
    border-bottom: 1px solid #3d3d41;
    @include media-down(md) {
      min-width: 180px;
      width: 180px;
    }
    span {
      color: $base-color-dark-blue;
      font-size: 16px;
      @include font-family('semi-bold');
      @include media-down(md) {
        font-size: 20px;
      }
    }
    img {
      width: 13px;
      height: 12.1px;
      @include media-down(md) {
        display: none;
        width: 11px;
        height: 10.1px;
      }
    }
  }

  &__dropdown-content button:hover {
    background-color: #f9f9f9;
  }

  &__dropdown-link:last-child {
    border-bottom: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &__dropdown-link:hover,
  &__dropdown-link:focus {
    text-decoration: underline;
  }

  &__dropdown:hover &__down-arrow,
  &__dropdown:focus &__down-arrow {
    transform: rotate(-135deg);
    margin-top: 0.7rem;
    right: 1.5rem;
    @include media-down(md) {
      border-color: $base-color-dark-blue;
    }
  }

  &__dropdown:hover &__dropdown-content {
    display: block;
  }

  &__dropbtn:hover &__dropdown-content {
    display: block;
  }

  &__dropbtn:focus &__dropdown-content {
    display: block;
  }

  &__dropdown:hover &__dropbtn,
  &__dropdown:focus &__dropbtn {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @include media-down(md) {
      background-color: #ffffff;
      color: $base-color-dark-blue;
    }
  }

  // &__display-dropdown {
  //   @include media-up(md) {
  //     display: block !important;
  //   }
  // }
  &__war {
    display: none;
  }
}
