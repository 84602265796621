@import "../../static/styles/mixins";

.ob-star-rating {
    height: 6rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .ob-icon {
        margin: 0 .53rem;
    }

    @include media-up(sm) {
        height: 10rem;
        .ob-icon {
            padding: 0 .3rem;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
}