@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-recently-viewed-block {
    background-color: $base-color__white;

    .ob-products-overview__overview--full-size-image .ob-products-overview__overview-image-wrapper-3,
    .ob-products-overview__overview-image-wrapper-3 {
        max-height: 500px;
        max-width: 500px;
    }

    .ob-products-overview__overview:not(.ob-products-overview__overview--auto-height-mode) .ob-products-overview__overview-image-wrapper-3 {
        @include media-down(sm) {
            max-height: 200px;
        }
    }
}

.ob-recently-viewed-block-wrapper {
    margin: 0 auto;
    max-width: $oralb-max-desktop-width;
    padding: rem(22) rem(10);
}

.ob-recently-viewed-block__heading {
    padding: 0 rem(10);
    text-align: center;
    color: $base-color-primary-grey;
    @include display-3-xs;
    margin: rem(8) 0;
}

.ob-recently-viewed-block__product-grid-wrapper{
    @include media-up(md) {
        padding: 1rem;
    }
}
