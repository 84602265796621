@import "node_modules/progress-tracker/src/styles/progress-tracker.scss";
@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.progress-tracker-container {
    position: fixed;
    right: 30px;
    top: 15%;
    z-index: 5;
    border-radius: 10px 0 0 10px;
    padding: 0 10px;

    @include media-down(sm) {
        top: 1%;
        right: 10px;
        padding: 0;
    }

    .progress-marker::before {
        width: 19px;
        height: 18px;
        background-color: $base-color__white;
        margin-left: 1px;
        border: 2px solid $base-color-dark-blue;

        @include media-down(sm) {
            background-color: $base-color-dark-blue;
        }
    }

    .progress-marker::after {
        display: none;
    }

    .progress-step:hover {
        .progress-marker::before {
            background-color: $base-color-dark-blue;

        }
    }

    .progress-text {
        font-size: 11px;
        line-height: 14px;
        width: 255px;
        height: 70px;
        color: $base-color-dark-blue;
        text-align: right;
        padding: unset;
        padding-bottom: 45px;
        padding-right: 10px;
        transform: translateX(20px);

        span {
            border: 1px solid $base-color-dark-blue;
            border-right: unset;
            border-radius: 20px;
            width: 250px;
            display: block;
            padding-right: 20px;
            height: 24px;
            padding-top: 5px;
            visibility: hidden;
            text-align: center;
        }

        &:hover {
            text-decoration: none;

            span {
                visibility: visible;
                color: $base-color__white;
                background-color: $base-color-dark-blue;
            }
        }

        @include media-down(sm) {
            color: $base-color-dark-blue;
        }
    }

    .progress-step.is-active {
        .progress-text {
            span {
                color: $base-color__white;
                background-color: $base-color-dark-blue;
                visibility: visible;
            }
        }

        .progress-marker::before {
            background-color: $base-color-dark-blue;
        }
    }

    .progress-tracker.progress-tracker--vertical {
        margin: 10px auto;
    }

    @include media-down(sm) {
        .ob-mainAsset-wrapper {
            img {
                width: 60px;
                height: 60px;
            }
        }
    }

    animation: 1s ease-in 0s 1 slideInTrackerFromLeft;

    @keyframes slideInTrackerFromLeft {
        0% {
            transform: translateX(30%);
            opacity: 0;
        }

        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
}
.progress-header-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .progress-header-text{
        font-size: 7vw;
        line-height: 7.436vw;
        font-weight: 900;
        color: $base-color-dark-blue;
    }
}
.progress-tracker-container.visible {
    @include media-down(sm) {
        width: 100vw;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        border-radius: unset !important;
        right: 0;
        top: 0;
        box-shadow: 0px 0px 17px 2px;
        .ob-mainAsset-wrapper {
            padding: 20px;
            img {
                width: 20px !important;
                height: 20px !important;
            }
        }
        .progress-header-container{
            background-color: $base-color__white;
        }

        .progress-tracker.progress-tracker--vertical {
            margin: 0 auto;
            padding: 0 10px;
            background-color: $base-color__white;
            .progress-step{
                flex: unset;
                justify-content: flex-start;
                .progress-marker{
                    display: none;
                }
                button{
                    width: 90%;
                    text-align: left;
                    border-bottom: 1px solid rgba(0, 32, 114, 0.1);
                    padding: 0;
                    margin: 0 auto;
                    transform: translateX(0);
                    line-height: 55px;
                    height: 55px;
                    @include media-down(sm){
                        font-family: 'Hurme Semi Bold';
                        font-size: 16px;
                    }
                }
            }
            .progress-step:last-child{
                button{
                    border: unset;
                }
            }
        }
    }
}