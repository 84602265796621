@import "../../static/styles/mixins";

.ob-features-tab {
    position: relative;
    ul {
        padding: 0;
        list-style-type: none;
    }

    .ob-mainAsset-wrapper {
        line-height: 0;
    }

    img {
        width: 100%;
    }

    &--desktop {
        display: none;
        @include media-up(sm) {
            display: block;
        }
    }

    &--mobile {
        @include media-up(sm) {
            display: none;
        }
    }

    [data-white-text="true"] {
        color: $base-color__white;
    }

    &__content-wrapper, &__wrapper {
        margin: 0;
    }

    &__tabs-wrapper {
        position: absolute;
        top: 3rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: map-get($zindex, one);
        display: flex;
        background: $base-color__white;
        border-radius: 10px;
        margin: 0;
        max-width: calc(100vw - 60px);

        .ob-features-tab__tabs-item {
            flex: 1 1 auto;
            overflow: hidden;
        }

        @include media-up(md) {
            top: 6rem;
        }

        @include media-ie() {
            max-width: 982px;
            width: 100%;

            .ob-features-tab--desktop & {
                .ob-features-tab__tabs-item {
                    display: flex;
                    flex: 1 1 auto;
                    align-items: stretch;
                }
            }
        }
    }

    &__tabs-item {
        &:first-child {
            .ob-features-tab__tabs-button {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }

        &:last-child {
            .ob-features-tab__tabs-button {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }

    &__item {
        border-bottom: 1px solid $base-color-secondary-grey;
        &.darken-text-background {
            .ob-features-tab__text {
                @include media-down(sm) {
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: map-get($zindex, negative);
                        top: 1.3rem;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0, 0.55);
                        filter: blur(10px);
                    }
                }
            }
        }
        @include media-up(sm) {
            border-bottom: none;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    &__tabs-button {
        width: 100%;
        padding: 1.5rem;
        font-size: 1.6rem;
        white-space: nowrap;
        color: $base-color-dark-blue;
        transition: background-color ease 0.4s;
        overflow: hidden;
        text-overflow: ellipsis;

        @include font-family('hurme-regular');
        font-weight: 400;

        @include media-up(lg) {
            padding: 1.6rem 5.45rem;
        }

        @include media-ie-up(lg) {
            padding: 1.6rem 2.5rem;
            white-space: inherit;
        }

        .ob-features-tab--smaller-buttons & {
            @include media-up(sm) {
                font-size: 1.1rem;
            }
            @include media-up(md) {
                font-size: 1.6rem;
            }
        }

        .ob-features-tab--smallest-buttons & {
            font-size: 1.2rem;
        }

        &:hover {
            text-decoration: none;
        }

        &:focus {
            text-decoration: underline;
        }

        &.is-active {
            @include media-up(sm) {
                color: $base-color__white;
                background-color: $base-color-dark-blue;
            }
        }
    }

    &__tabs-button-icon {
        float: right;
    }

    &__content-item {

        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: opacity ease 1s;

        &.is-active {
            opacity: 1;
            position: relative;
            pointer-events: all;
        }
    }

    &__content-text {
        @include media-down(sm) {
            max-height: 0;
            opacity: 0;
            pointer-events: none;
            transition: all 0.8s ease-in-out;

            &.is-active {
                opacity: 1;
                max-height: 10000px;
                pointer-events: all;
            }
        }
        @include media-up(sm) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            max-width: 584px;

            .ob-features-tab__micro-vibrations & {
                max-width: 490px;
            }

            &[data-text-align="Left"] {
                left: 13%;
                text-align: left;
            }
            &[data-text-align="Center"] {
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }
            &[data-text-align="Right"] {
                right: 16%;
                text-align: right;
            }
        }

        .ob-features-tab__ultimate-clean & {
            @include media-up(sm) {
                max-width: 700px;
                top: 11rem;
                transform: translate(-50%, 0);
                width: 90%;

                .ob-features-tab__subtitle {
                    margin-top: 1.0rem;
                }
            }

            @include media-up(md) {
                max-width: 450px;
                top: 22.5%;

                .ob-features-tab__subtitle {
                    margin-top: 2.6rem;
                }
            }
        }

        .ob-features-tab__micro-vibrations & {
            @include media-up(sm) {
                top: 57%;
            }
            @include media-up(md) {
                top: 50%;
            }
        }

        .ob-heading {
            @include display-3-xl;
        }
    }

    .ob-background-wrapper {
        position: relative;
    }

    &__text {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 3rem 3rem 0;
        text-align: center;
        width: 100%;
    }

    &__subtitle {
        font-size: 2.6rem;
        margin-bottom: 0;
    }

    &__description {
        font-size: 2.4rem;
        margin-top: 0;

        .blackText & {
            color: $base-color__black;
        }

        .textOutline & {
            @include textOutline();
        }
    }
}
