@font-face {
  font-family: 'icomoon';
  src:  url('https://assets.ctfassets.net/t2436bmmnhys/4O5e9Z5aEiw49aUmyTXbPc/c42204cb9150c0a98d0450e953ca2a52/icomoon.eot?ugzznr');
  src:  url('https://assets.ctfassets.net/t2436bmmnhys/4O5e9Z5aEiw49aUmyTXbPc/c42204cb9150c0a98d0450e953ca2a52/icomoon.eot#iefix') format('embedded-opentype'),
    url('https://assets.ctfassets.net/t2436bmmnhys/2KozOiFPo81sSkDSoldN4M/4fbba55d32393d4e39d77975484274b9/icomoon.ttf') format('truetype'),
    url('https://assets.ctfassets.net/t2436bmmnhys/3MX8R1izVS3MGM9RizolCz/44807350a09ce5f7ab161ca43517c9bd/icomoon.woff?ugzznr') format('woff'),
    url('https://images.ctfassets.net/t2436bmmnhys/6wnoq1bgJOO63x14dhJfCR/1c3deaa18f7d0d8371df5110b85d3eb2/icomoon.svg?ugzznr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', monospace !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-littlearrowleft:before {
  content: "\e900";
}
.icon-littlearrowright:before {
  content: "\e901";
}
.icon-io:before {
  content: "\e902";
}
.icon-tag:before {
  content: "\e903";
}
.icon-plus:before {
  content: "\e904";
}
.icon-target:before {
  content: "\e905";
}
.icon-bulle:before {
  content: "\e906";
}
.icon-check:before {
  content: "\e907";
}
.icon-love:before {
  content: "\e908";
}
.icon-star:before {
  content: "\e909";
}
.icon-arrowleft:before {
  content: "\e90a";
}
.icon-arrowright:before {
  content: "\e90b";
}
.icon-hamburger:before {
  content: "\e90c";
}
.icon-eye:before {
  content: "\e90d";
}
.icon-batteryright:before {
  content: "\e90e";
}
.icon-batteryleft:before {
  content: "\e90f";
}
.icon-batteryrightcharged:before {
  content: "\e910";
}
.icon-batteryleftcharged:before {
  content: "\e911";
}
.icon-chevrondown:before {
  content: "\e912";
}
.icon-chevrontop:before {
  content: "\e913";
}
.icon-chevronleft:before {
  content: "\e914";
}
.icon-chevronright:before {
  content: "\e915";
}
.icon-technology:before {
  content: "\e916";
}
.icon-featurestarget:before {
  content: "\e917";
}
.icon-cloud:before {
  content: "\e918";
}
.icon-lightning:before {
  content: "\e919";
}
.icon-featurescut:before {
  content: "\e91a";
}
.icon-pocketwatch:before {
  content: "\e91b";
}
.icon-featuresface:before {
  content: "\e91c";
}
.icon-featuresline:before {
  content: "\e91d";
}
.icon-featuresbrush:before {
  content: "\e91e";
}
.icon-tooth:before {
  content: "\e91f";
}
.icon-5Modes:before {
  content: "\e920";
}
.icon-YearWarranty:before {
  content: "\e921";
}
.icon-Group24:before {
  content: "\e922";
}
.icon-Shield:before {
  content: "\e923";
}

