@import "../../../../../static/styles/mixins";

.ob-register-typecode {
  &__step-title {
    @include font-family('hurme-light');
    margin-top: 12px;
    margin-bottom: 35px;
    height: auto;
    width: 80%;
    color: #3D3D41;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 30px;
    @include media-down(md) {
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 26px !important;
      width: 100%;
      margin: 16px 0;
    }
  }

  &__step-title-link {
    color:  #002D72!important;
    &:hover{
      color: #87A4D2;
    }
  }

  &__register-product {
    @include font-family('hurme-light');
    font-size: 2.8rem;
    line-height: 3.6rem;
  }

  &__wrapper {
    @include media-up(lg) {
      max-width: 90rem;
    }
  }

  &__seperator {
    width: 20px;
  }

  &__goals-text {
    margin: 5rem 0 3rem;
  }
  &__combo-pack {
    width: 90%;
    margin-top: 30px;
    span {
      @include font-family('hurme-light');
      color: #3D3C41;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      font-weight: bold;
      height: 48px;
      @include media-down(md) {
        font-size: 14px;
        height: 45px;
      }
    }
    label {
      display: flex;
    }
    @include media-down(md) { 
      width: 100%;
      font-size: 24px;
      margin-top: 25px;
    }
  }

  &__info-text-bottom {
    @include font-family('hurme-regular');
    width: 80%;
    height: auto;
    color: #3D3C42;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 40px;
    @include media-down(md) {
      width: 100%;
    }
  }
  &__tipimage-text {
    @include font-family('hurme-regular');
    margin-top: 10px;
    height: auto;
    width: 90%;
    color: #3D3D41;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 28px;
    @include media-down(md) {
      margin: 0;
      margin-top: 5px;
      font-size: 14px;
      width: 100%;
      line-height: 20px;
    }
    b {
      font-weight: bold;
    }
  }

  &__sub-title {
    @include font-family('hurme-regular');
    width: 80%;
    color: #3D3D41;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 28px;
    font-weight: 300;
    @include media-down(md) {
      width: 100%;
      font-size: 20px;
      line-height: 24px;
    }
    @include media-up(md) {
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }
  
  &__digit-group {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: row;
    margin-bottom: 10px;
    input {
      width: 55px !important;
      height: 55px;
      @include media-down(md) {
        width: 50px !important;
        height: 50px;
      }
      line-height: 50px;
      text-align: center;
      font-size: 24px;
      font-weight: 200;
      margin: 0 2px;
    }

    img{
      margin-top: 15px;
      @include media-down(md) {
        margin-left: 11px;
      }
    }
  }

  &__invalid-typecode {
    @include font-family('hurme-light');
    width: 86%;
    margin-bottom: 10px;
    height: auto;
    color: #D70000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;

    a {
      color:  #002D72;
      text-decoration: underline;
      &:hover{
        color: #87A4D2;
      }
    }
  }

  &__invalid-typecode-helptext {
    @include font-family('hurme-light');
    height: auto;
    width: 80%;
    color: #3D3D41;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.56px;
    line-height: 24px;

    a {
      color:  #002D72;
      text-decoration: underline;
      &:hover{
        color: #87A4D2;
      }
    }
  }
  &__txtbox-error-style{
    border: 1px solid red !important;
  }
  &__valid-typecode {
    @include font-family('hurme-light');
    height: auto;
    width: 82%;
    padding-top: 10px;
    color: #3D3D41;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.56px;
    line-height: 24px;
    margin-bottom: 10px;
    @include media-down(md) {
      width: 95%;
    }
  }
  &__input-entry-wrapper{
    display: flex;
    flex-direction: row;
    @include media-down(md) {
      flex-direction: column-reverse;
    }
  }

  &__input-box-wrapper{
    display: flex;
    flex-direction: column;
    width: 80%;
    @include media-down(md) {
      width: 100%;
    }
  }

  &__type-code-image {
    width: 80%;
    margin-top: 30px;
    @include media-down(md) {
      width: 100%;
      margin-top: 10px;
    }
    img{
      width: 100%;
      @include media-down(md) {
        width: 100%;
      }
    }
  }

  &__type-code-input-wrapper{
    width: 100%;
    @include media-down(md) {
      width: 100%;
    }
  }

  &__btn-select-from-list {
    @include font-family('hurme-light');
    height: 24px;
    width: auto;
    color: #002D72;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.56px;
    line-height: 24px;
    text-decoration: underline;
    margin: 25px 0;
    &:hover{
      color: #87A4D2;
    }
  }

  &__spinner {
    top: auto;
    right: auto;
    bottom: auto;
    left: 2rem;
    animation: rotate360 1s linear infinite;
    transform: translateZ(0);

    border: 4px solid #474747;
    border-left: 4px solid transparent;
    background: transparent;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-top: 1.3rem;
  }

  @keyframes rotate360 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .ob-form {
    &__layout {
      margin-top: 5rem;
    }
    &__title {
      @include font-family('hurme-light');
      width: 100%;
      height: auto;
      color: #3D3D41;
      font-size: 46px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 64px;
      @include media-down(md) {
        font-size: 28px;
        line-height: 36px;
        margin-top: 10px;
      }
    }
    &__button__submit {
      margin-top: 2rem;
    }
  }

  .ob-checkbox {
    &__custom-box{
      margin-top: -0.5rem;
      @include media-down(md) {
        margin-top: -0.5rem;
      }
      width: 30px;
      height: 30px;
      max-width: 30px;
    }
  }

  &__modal-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ob-profile__modal-cancel-btn.ob-button {
      margin: 0 auto;
    }
    .ob-form__button__submit {
      margin: 1.5rem;
    }
}

&__modal-overlay .ReactModal__Content--after-open {
  @include media-up(sm) {
    width: 50rem;
    height: 55rem;
  }
}
 
&__select-typecode-title {
  @include font-family('hurme-regular');
  text-align: center;
  color: #3D3D41;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 300;
  @include media-down(md) {
    font-size: 20px;
    line-height: 24px;
  }
}

&__modal-container {
  width: 100%;
}

&__no-typecode-error {
  font-size: 1.4rem;
  margin: 2rem;
  text-align: center;
  min-height: 50px;
  color: $base-color-primary-grey;
}

&__list-spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1000;
  animation: rotateanimate360 1s linear infinite;
  transform: translateZ(0);

  border: 4px solid #474747;
  border-left: 4px solid transparent;
  background: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: -50px 0px 0px -50px;
}


&__product-list-title {
  @include font-family('hurme-regular');
  color: #3D3C42;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  width: 80%;
  @include media-down(md) {
    font-size: 14px;
    line-height: 22px;
    width: 90%;
  }
}

@keyframes rotateanimate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

&__select-typecode {
    font-family: sans-serif;
    // padding: 3em;
  
    .react-list-select {
      display: inline-block;
      position: relative;
      outline: none;
      width: 100%;
      overflow-y: scroll;
      overflow-x: auto;
      height: 350px;
      -webkit-overflow-scrolling: touch;
      background: #fafafa;
      border: 1px solid #cacaca;
      margin-right: 0.5em;
      scroll-margin-bottom: 40px;
    }
  
    .react-list-select::-webkit-scrollbar {
      width: 10px;             /* width of the entire scrollbar */
    }
    .react-list-select::-webkit-scrollbar-track {
      background: #fff;        /* color of the tracking area */
    }
    .react-list-select::-webkit-scrollbar-thumb {
      background-color: #D8D8D8;    /* color of the scroll thumb */
      border-radius: 9px;       /* roundness of the scroll thumb */
      border: 3px solid #D8D8D8;  /* creates padding around scroll thumb */
    }
  
    .react-list-select--item {
      padding: 0.3em 0.6em;
      cursor: pointer;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
      outline: none;
    }
  
    .react-list-select--item.is-selected {
      -webkit-user-select: inherit;
      -moz-user-select: inherit;
      -ms-user-select: inherit;
      -o-user-select: inherit;
      user-select: inherit;
    }
  
    .react-list-select--item.is-disabled {
      cursor: default;
      color: #afafaf;
    }
  
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      scroll-behavior: smooth; /* nice smooth movement */
      overflow  : hidden;      /* set to hidden by OP's request */
    }
  
    /* Add some style to the list */
    .react-list-select--item.is-selected,
    .react-list-select--item.is-selected:hover {
      background: #a0ccf0 !important;
      outline: none;
    }

    .react-list-select--item.is-focused {
      background: #E5F3FF;
      outline: none;
    }
    .react-list-select--item.is-focused::before,
    .react-list-select--item.is-focused::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 2px;
      background: #E5F3FF;
    }
    .react-list-select--item.is-focused::before {
      left: 0;
    }
    .react-list-select--item.is-focused::after {
      right: 0;
    }
  
    .context-menu .react-list-select {
      padding: 0.3em 0;
    }
    .context-menu .react-list-select--item {
      @include font-family('hurme-light');
    }
  
    /* Component in list - last example */
  
    .typecode {
      padding: 0.5em;
    }
    .typecode .code {
      @include font-family('hurme-regular');
      color: #3D3D41;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 28px;
      @include media-down(md) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .typecode .name {
      color: #3D3C42;
      @include font-family('hurme-regular');
      line-height: 24px;
      margin-top: 1px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;

      @include media-down(md) {
        line-height: 18px;
        margin-top: 2px;
        font-size: 12px;
      }
    }
  }

}
  