@import "../../static/styles/mixins";
@import "../../static/styles/variables";

$border-width: 1px;
$border-radius: 10px;

.ob-button-tabs-navigation {
    background: $base-color-secondary-darker-off-white;

    @include media-up(sm) {
        background: none;
    }
}

.ob-button-tabs-navigation-toggle {
    position: relative;
    display: block;
    width: 100%;
    padding: 22px 40px;
    border: none;
    background: $base-color__white;
    appearance: none;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.5px;
    @include font-family('semi-bold');

    &:focus {
        outline: 1px dotted black;
    }

    &:hover, &:focus {
        text-decoration: underline;
        color: $base-color-dark-blue;
    }

    @include media-up(sm) {
        display: none;
    }
}

.ob-button-tabs-navigation-chevron {
    position: absolute;
    top: 50%;
    right: rem(15);
    margin-top: rem(-8);
    transition: transform 400ms ease-in-out;

    &.ob-button-tabs-navigation-chevron--rotated {
        transform: rotate(-90deg);
    }
}

.ob-button-tabs-navigation-collapsible {
    height: 0;
    opacity: 0;
    transform: translateY(-30px);
    pointer-events: none;

    .ob-button-tabs-navigation.enter &,
    .ob-button-tabs-navigation.exit & {
        transition: height 400ms ease-in-out, opacity 400ms ease-in-out, transform 400ms ease-in-out;
    }

    .ob-button-tabs-navigation.enter-active &,
    .ob-button-tabs-navigation.enter-done & {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
    }

    .ob-button-tabs-navigation.enter-done & {
        height: auto !important;
    }

    .ob-button-tabs-navigation.exit-active &,
    .ob-button-tabs-navigation.exit-done & {
        height: 0 !important;
    }

    @include media-up(sm) {
        height: auto !important;
        .ob-button-tabs-navigation.exit-done & {
            height: auto !important;
        }
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
    }
}

.ob-button-tabs-navigation-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .white-bg--md-down & {
        @include media-down(md) {
            background: $base-color-light-grayish-blue;
        }
    }

    @include media-up(sm) {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
    }
}

.ob-button-tabs-navigation-item {
    position: relative;

    @include media-up(sm) {
        border-width: $border-width 0;
        border-color: $base-color-dark-blue;
        border-style: solid;

        &:focus, &:hover {
            background-color: $base-color-dark-blue;

            a .ob-button-tabs-navigation-link-name{
                color: $base-color__white;
            }
        }
        min-width: 0;
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: $border-width;
            background-color: $base-color-dark-blue;
            top: 10px;
            bottom: 10px;
            left: -($border-width/ 2);
        }
        &:first-child {
            border-left-width: $border-width;
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            &:before {
                display: none;
            }
        }
        &:last-child {
            border-right-width: $border-width;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }
}

.ob-button-tabs-navigation-item--active {
    display: none; // active item is already shown in collapsible toggle button

    @include media-up(sm) {
        display: block;
        background-color: $base-color-dark-blue;

        .ob-button-tabs-navigation-link, .ob-button-tabs-navigation-link-name {
            color: white;
        }

        &:focus, &:hover {
            background-color: $base-color-dark-blue;
        }
        &:before {
            display: none;
        }
        & + .ob-button-tabs-navigation-item {
            &:before {
                display: none;
            }
        }
    }
}

.ob-button-tabs-navigation-link {
    display: block;
    max-width: none;
    padding: 20px rem(40);
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: $base-color-primary-grey;
    @include font-family('hurme-regular');
    text-decoration: none;

    &:focus {
        outline: 1px dotted black;
    }

    @include media-up(sm) {
        &:focus {
            outline: none;
        }
    }
}


.ob-button-tabs-navigation-link-name {
    display: inline;
    color: $base-color-dark-blue;

    @include media-up(sm) {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.ob-button-tabs-navigation-link-price {
    display: inline;
    margin-left: rem(5);
    font-size: rem(18);
    color: $base-color-primary-grey;

    @include media-up(sm) {
        display: block;
        margin: 0;
    }
}
.ob-button-tabs-navigation-item{
    .ob-button-tabs-navigation-link:focus{
        border: 2px solid $base-color-outline-blue;
    }
}

//Global Equity
.ob-collection-tabs-block__tabs-wrapper {
    .ob-button-tabs-navigation-item {
        border-top: 1px solid $base-color__white;
    }
}

.ob-collection-tabs-dropdown__tabs-wrapper{
    .ob-button-tabs-navigation-list{
        .ob-button-tabs-navigation-item{
            &:before {
                background-color: transparent;
            }
        }
    }
}