@import "../../static/styles/mixins";

.#{$ob}-button {
    @include font-family('semi-bold');
    font-size: 18px;
    font-weight: 400;
    align-items: center;
    background: $base-color-dark-blue;
    border-radius: 72px;
    border: none;
    color: $base-color__white;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    padding: 2rem 3rem;
    letter-spacing: 0.5px;
    text-decoration: none;
    transition: color 0.4s ease, background-color 0.4s ease, border 0.4s ease, opacity 0.4s ease;
    @include media-up(md) {
        padding: 1rem 3.5rem;
        font-size: 18px;
    }
    > * {
        pointer-events: none;
    }
    &:link {
        color: $base-color__white;
    }
    &:visited {
        background: $base-color-dark-blue;
        color: $base-color__white;
    }
    span {
        position: relative;
        top: .1rem;
    }
    &:hover,
    &:focus {
        background: $base-color-dark-blue-hover;
    }

    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: underline;
        @include focusOutline();
    }

    &[data-white-theme='true'] {
        background: $base-color__white;
        color: $base-color-dark-blue;

        &:visited {
            background: $base-color__white;
            color: $base-color-dark-blue;
        }
        &:hover,
        &:focus {
            background: $base-color-dark-blue-hover;
            color: $base-color__white;
        }

        &:hover {
            text-decoration: none;
        }

        &:focus {
            text-decoration: underline;
        }
    }
    &[data-grey-theme='true'] {
        background: $base-color__white;
        color: $base-color-primary-grey;
        border: 1px solid $base-color-isolate-grey;
        @include font-family('semi-bold');

        &:visited {
            background: $base-color-dark-blue-hover;
            color: $base-color-primary-grey;
            border: 1px solid $base-color-isolate-grey;
        }
        &:hover,
        &:focus {
            color: $base-color-dark-blue-hover;
            border: 1px solid $base-color-dark-blue-hover;
        }

        &:hover {
            text-decoration: none;
        }

        &:focus {
            text-decoration: underline;
        }
    }
    &[data-inverted-theme='true'] {
        background: transparent;
        color: $base-color__white;
        border: 1px solid $base-color__white;
        &:visited {
            background: transparent;
            color: $base-color__white;
            border: 1px solid $base-color__white;
        }
        &:hover,
        &:focus {
            background: $base-color-dark-blue-hover;
            color: $base-color-dark-blue;
            border: 1px solid $base-color-dark-blue-hover;
        }

        &:hover {
            text-decoration: none;
        }

        &:focus {
            text-decoration: underline;
        }
    }
    &[data-border-theme='true'] {
        background: transparent;
        color: $base-color-primary-grey;
        border: 1px solid $base-color-isolate-grey;
        &:visited {
            background: transparent;
            color: $base-color-primary-grey;
            border: 1px solid $base-color-isolate-grey;
        }
        &:hover,
        &:focus {
            background: $base-color-dark-blue-hover;
            color: $base-color-dark-blue;
            border: 1px solid $base-color-dark-blue-hover;
        }

        &:hover {
            text-decoration: none;
        }

        &:focus {
            text-decoration: underline;
        }
    }
    &[data-border-theme-blue='true'] {
        background: transparent;
        color: $base-color-dark-blue;
        border: 1px solid $base-color-dark-blue;

        &:visited {
            background: transparent;
            color: $base-color-dark-blue;
            border: 1px solid $base-color-dark-blue;
        }
        &:hover,
        &:focus {
            background: $base-color-dark-blue-hover;
            color: $base-color-dark-blue-hover;
            border: 1px solid $base-color-dark-blue-hover;
        }

        &:hover {
            text-decoration: none;
        }

        &:focus {
            text-decoration: underline;
        }
    }
    &[data-text-theme='true'] {
        background: transparent;
        color: $base-color-dark-blue;
        padding: 0;
        height: auto;
        &:visited {
            background: transparent;
            color: $base-color-dark-blue;
        }
        &:hover,
        &:focus {
            text-decoration: underline;
            color: $base-color-dark-blue-hover;
        }
    }
    &[data-size='large'] {
        height: 6.4rem;
        padding: 0 4rem;
    }
    &[data-size='large-long'] {
        height: 6.4rem;
        padding: 0 9rem;
    }
    &[data-size='medium'] {
        height: 4.8rem;
        padding: 0 3rem;
    }
    &[data-size='medium-long'] {
        height: 4.8rem;
        padding: 0 7rem;
    }
    &[data-size='small'] {
        height: 4.8rem;
        padding: 0 1.5rem;
    }
    &[data-size='small-slim'] {
        height: 3.6rem;
        padding: 0 2.7rem;
    }
    &[data-size='large-slim'] {
        height: 48px;
        width: 212px;
        padding: 0;
        span {
            width: 100%;
            text-align: center;
        }
    }

    &[disabled] {
        background: $base-color-proxima-grey;
        color: $base-color-primary-grey;
        cursor: not-allowed;
    }
}
button.#{$ob}-button {
    padding: 0 5rem;
    height: 6.4rem;
}

.ob-outline-button {
    @include font-family('semi-bold');
    box-sizing: border-box;
    padding: 0.8125rem 1.25rem 0.75rem;
    border: 1px solid $base-color-dark-blue;
    border-radius: rem(20);
    line-height: (18 / 14);
    background: white;
    color: $base-color-dark-blue;
    font-size: rem(14);
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;

    &:focus, &:hover {
        outline: none;
        text-decoration: underline;
    }
}

.ob-outline-button--active,
.ob-outline-button[aria-checked='true'] {
    background: $base-color-dark-blue;
    color: white;
}

.ob-outline-button--disabled, .ob-outline-button:disabled {
    opacity: 0.5;
}
