@import "./mixins";
@import "./variables";


.article-body {
    min-height: 75px;
    max-width: $article-body-width;
    box-sizing: content-box;
    margin: 32px auto;
    @include media-up(md) {
        margin: 70px auto;
    }

    h1 {
        @include display-3-xl;
    }
    p {
        @include body-1-xl;
    }
    > p, > h1, > div {
        padding: 0 2rem;
    }

    .ob-article__youtube-video {
        padding: 0;
        @include media-up(sm) {
            padding: 0 2rem;
        }
    }

    blockquote {
        text-align: center;
        max-width: $breakpoint-small;
        margin: 35px auto;

        &:before, &:after {
            display: block;
            content: '';
            background-color: $base-color-dark-blue;
            height: 2px;
            width: 48px;
            margin: 0 auto;
        }
        &:before {
            margin-bottom: 24px;
        }
        &:after {
            margin-top: 24px;
        }
        p
        {
            padding: rem(8);
            background: #ecf7fe;
            display: block;
            color: #3D3C42;
            font-size: rem(36);
            line-height: rem(44);
            font-style: italic;
            @include media-up(sm) {
                display: inline;
                font-size: rem(40);
                line-height: rem(48);
                box-shadow: 10px 0 0 #ecf7fe, -10px 0 0 #ecf4fe;
            }
        }
    }
}

.article__side-by-side__image {
    max-width: #{((($article-body-width / 3) * 2) + 'px')};
}

.article__side-by-side__pros-cons, .article__choices {
    p {
        @include font-family('hurme-light');
        font-size: rem(22);
        color: rgb(54, 53, 57);
        b {
            @include font-family('semi-bold');
            font-size: rem(16);
            color: rgb(61, 61, 64);
        }
    }
}

.article__side-by-side {
    .ob-side-by-side__wrapper {
        display: flex;
        flex-direction: column;
        @include media-up(md) {
            align-items: center;
            flex-direction: row;
        }
    }
    &.article__side-by-side--reverse {
        .ob-side-by-side__wrapper {
            flex-direction: column-reverse;
            @include media-up(md) {
                flex-direction: row;
            }
        }
        .ob-side-by-side__column {
            &:first-child {
                margin-top: rem(16);
            }
            &:last-child {
                margin: 0;
            }
        }
    }
    .ob-side-by-side__column {
        margin: rem(16) 0 0 0;
        &:first-child {
            margin: 0;
        }
        @include media-up(md) {
            width: calc(50% - 2rem);
        }
    }
}

.article__full-size-image {
    img {
        width: 100%;
    }
}

.ob-zone-body-article-legacy {
    .article-body {
        min-height: 75px;
        max-width: $legacy-article-body-width;
    }
}

.ob-article-landing-ingredients {
    max-width: 984px;
    margin: 3.2rem auto 4.3rem;
    padding: 0 2rem;

    h2 {
        @include display-3-xl;
        letter-spacing: 0;
        margin: 0;
    }

    p {
        @include body-1-xl;
        letter-spacing: 0;
        line-height: 2.6rem;
        margin: 1.8rem 0;

        @include media-up(md) {
            line-height: 3rem;
            margin: 1.2rem 0;
        }
    }
    @include media-up(md) {
        margin: 8rem auto 6.5rem;
    }
}
