@import "../../../../static/styles/mixins";

.ob-birthdate {
    .ob-input__label, .ob-form__wrapper, .ob-form-select__select {
        margin: 0;
    }

    .ob-form__wrapper:first-of-type {
        margin-top: 0.8rem;
    }

    .ob-form-select__select {
        margin: 3rem 0;
        @include media-up(sm) {
            margin: 0;
        }
    }

    @include media-ie() {
        width: 49%;
    }

    &__why-dob {
        @include font-family('semi-bold');
        font-size: 1.6rem;
        display: block;
        margin: 2rem 0;
        color: $base-color-dark-blue;

        &:hover{
            color:$base-color-dark-blue-hover;
        }

        @include media-up(sm) {
            margin: 1rem 0;
        }
    }

    &__legal-text {
        display: none;
        font-size: 1.2rem;

        &.--visible {
            display: block;
        }
    }
}
