@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.post-purchase-layout,
.pre-purchase-layout,
.pre-purchase-result-layout {
  .announcement {
    display: none;
  }
  
  .visually-hidden,
  .ob-skip-navigation {
    display: none !important;
  }
  
  .ob-utilityBar {
    display: none;
  }
  .breadcrumb-main {
    display: none;
  }
  .ob-main-menu__list {
    display: none;
  }
  .ob-main-menu {
    justify-content: center;
    .ob-main-menu__logo--desktop {
      margin-right: 0;
    }
    .ob-main-menu__logo--mobile {
      padding-left: 0;
    }
    .ob-main-menu__open-ctn {
      display: none;
    }
  }
  .main-menu ul {
    display: none;
  }
  .ob-footer__list {
    display: none;
  }
  .utility-footer {
    display: block;
  }
  .zone-Cookie {
    display: none;
  }
  .proactive-chat {
    display: none;
  }
}

.pre-purchase-result-layout {
  @include media-down(sm) {
    padding-top: 66px !important;
  }
}

.pre-purchase-layout {
  padding-top: 75px !important;
  @include media-down(sm) {
    padding-top: 70px !important;
  }
}