@import "../../static/styles/variables";

.ob-backdrop {
    position: relative;
    display: flex;
    width: 100%;
}

.ob-backdrop__image {
    flex: none;
    display: flex;
    position: relative;
    width: 100%;
    margin-right: -100%;
    overflow: hidden;
    pointer-events: none;
}

.ob-backdrop__ratio {
    display: none;
    width: 0;
    height: 0;
}

.ob-backdrop__ratio--xtiny {
    display: block;
}

@each $breakpointKey, $breakpoint in $mediaQueryBreakpoints {
    .ob-backdrop__ratio--#{$breakpointKey} {
        @media (min-width: $breakpoint) {
            display: block;

            & ~ .ob-backdrop__ratio {
                // disables all aspect ratios after the currently active one
                display: none;
            }
        }
    }
}

.ob-backdrop__img {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.ob-backdrop__content {
    position: relative;
    flex: none;
    width: 100%;
}
