@import "../../../static/styles/variables";
@import "../../../static/styles/mixins";

.ob-collapsible-products-overview {
    overflow: hidden;
    width: 100%;
}

.ob-collapsible-products-overview-collapsible, 
.ob-collapsible-products-overview-first-line {
    width: 100%;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    flex-wrap: wrap;
}


.ob-collapsible-products-overview-collapsible-wrapper {
    height: 0;
    opacity: 0;
    transform: translateY(-30px);
    pointer-events: none;
    visibility: hidden;
    width: 100%;

    .ob-collapsible-products-overview.enter &,
    .ob-collapsible-products-overview.exit & {
        transition: height 400ms ease-in-out, 
            opacity 400ms ease-in-out, 
            transform 400ms ease-in-out;
        visibility: visible;
    }

    .ob-collapsible-products-overview.enter-active &,
    .ob-collapsible-products-overview.enter-done & {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
        visibility: visible;
    }

    .ob-collapsible-products-overview.enter-done & {
        height: auto !important;
    }

    .ob-collapsible-products-overview.exit-active &,
    .ob-collapsible-products-overview.exit-done & {
        height: 0 !important;
    }

    .ob-collapsible-products-overview.exit-active & {
        visibility: visible;
    }
}

.ob-collapsible-products-overview-product-wrapper {
    flex: none;
}

.ob-collapsible-products-overview-see-more-wrapper {
    text-align: center;
    padding: 1rem 0;
}
