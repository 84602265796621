@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-product-recommender__content {
    padding: 0 21px;

    @include media-up(sm) {
        padding: 0 24px;
    }
}

.ob-product-recommender__wrapper {
    max-width: $oralb-max-desktop-width;
    margin: 0 auto;
    padding: 40px 0 30px;

    @include media-up(sm) {
        padding: 22px 0 30px;
    }

    .ob-product-recommender--has-divider & {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .has-divider--top & {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.ob-product-recommender__heading,
.ob-product-recommender__body,
.ob-product-recommender__eyebrow {
    text-align: center;
    color: $base-color-primary-grey;
}

.ob-product-recommender__eyebrow {
    @include eyebrow-1-xs;
    margin: rem(8) 0;
}

.ob-product-recommender__heading {
    @include display-3-xs;
    margin: rem(8) 0 4rem;
}

.ob-product-recommender__body {
    @include body-1-xs;
    margin: rem(20) 0;
}

.ob-product-recommender__products {
    @include media-up(md) {
        padding: 10px;
    }
}

.ob-product-recommender__side-by-side {
    @include media-up(sm) {
        display: flex;
    }
}

.ob-product-recommender__side--text {
    @include media-up(sm) {
        flex: 0 1 auto;
        box-sizing: content-box;
        width: 500px;
        margin: 0 0 0 auto;
        padding-top: 60px;

        .ob-product-recommender__side-by-side--product-first & {
            margin: 0 auto 0 0;
            padding-left: 30px;
        }
    }
}

.ob-product-recommender__side--product {
    @include media-up(sm) {
        flex: 0 1 auto;
        box-sizing: content-box;
        width: 450px;
        margin: 0 auto 0 22px;
        padding: 0 30px 0 0;

        .ob-product-recommender__side-by-side--product-centered & {
            margin: 0 auto;
            padding: 0;
        }

        .ob-product-recommender__side-by-side--product-first & {
            margin: 0 22px 0 auto;
            padding: 0 0 0 59px;
            order: -1;
        }
    }
}

.ob-product-recommender__side-eyebrow {
    @include eyebrow-1-xl;
    margin: 0 0 rem(8);
}

.ob-product-recommender__side-heading {
    @include display-2-xl;
    margin: 0 0 rem(8);

    @include media-up(sm) {
        margin: 0 0 rem(14);
    }
}

.ob-product-recommender__side-body {
    @include body-1-xl;
    margin: rem(20) 0;

    @include media-up(sm) {
        margin: 0;
    }

    .ob-product-recommender--de-de & {
        ul {
            text-align: left;
        }
    }
}

.ob-product-recommender__side-disclaimer {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    letter-spacing: (0.5em / 12);
    font-size: rem(16);
    line-height: rem(24);
    margin-top: rem(25);
}

.ob-product-recommender__side-heading,
.ob-product-recommender__side-eyebrow,
.ob-product-recommender__side-body,
.ob-product-recommender__side-disclaimer {
    text-align: center;

    @include media-up(sm) {
        text-align: left;
    }
}

.ob-product-recommender .ob-product-grid__product {
    padding-bottom: 0;
}
