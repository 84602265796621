@import "../../static/styles/mixins";

.ob-announcement-bar {
  background-color: $base-color-dark-blue;
  color: $base-color__white;
  overflow: hidden;

  &-inner {
    @include font-family('semi-bold');
    font-size: 1.8rem;
    line-height: 1.33333;
    margin: 0 auto;
    max-width: $oralb-max-desktop-width;
    padding: 1.9rem 4.4rem 1.2rem 2.4rem;
    position: relative;
    width: 100%;

    .description {
      @include media-down(md) {
        margin-bottom: 0.2rem;
      }

      @include media-up(md) {
        display: inline;

        &:after {
          content: " ";
        }
      }
    }

    a {
      color: $base-color__white;

      &.ob-announcement-bar-cta {
        padding-right: 1.4rem;
        position: relative;
        text-decoration: underline;

        &:hover,
        &:focus {
          text-decoration: none;
          .ob-icon {
            margin-left: 0.5rem;
          }
        }

        .ob-icon {
          margin-left: 0.2rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transition: margin-left 0.3s ease-in-out;
        }
      }
    }

    @include media-up(md) {
      padding: 1.6rem 4.4rem 1.1rem 2.4rem;
    }
  }

  &-close-btn {
    position: absolute;
    top: 50%;
    right: 2.4rem;
    transform: translateY(-50%);
    transition: all ease 0.35s;
    padding: 0 0.3rem;

    &:hover, &:focus {
      transform: translateY(-50%) scale(1.5);
    }

    &:focus {
      outline: 1px solid $base-color__white;
    }
  }
}
.ob-announcement-bar{
  @include media-down(md) {
    min-height: 8.1rem;
  }
  @include media-up(md) {
    min-height: 5.1rem;
  }
}