@import "../../static/styles/mixins";
@import "../../static/styles/variables";

$contest-error-color: #ff0200;

.ob-spotlight-contest {
    overflow-x: hidden;
    top: 0;
    color: $base-color__white;
    background-color: #031136;

    .ob-background-wrapper {
        padding-top: 0 !important;
        height: inherit;
        background-size: cover;
        background-position: center top !important;
        min-height: 100vh;
        max-height: none;
    }

    .ob-spotlight-contest__title-image-buffer {
        display: block;
        height: 43vw;
        @include media-up(sm) {
            height: 0;
        }
    }

    .ob-spotlight-contest__contest-title-container {
        text-align: center;

        @include media-down(sm) {
            padding: 0 4vw;
        }

        .ob-spotlight-contest__contest-title-text,
        .ob-spotlight-contest__contest-title-io-logo {
            display: inline-block;
        }

        .ob-spotlight-contest__contest-title-text {
            @include font-family('hurme-regular');
            font-weight: bold;
            color: $base-color__white;
            margin: 0;
            font-size: 2.3rem;
            line-height: 2.5rem;

            @include media-up(xs) {
                font-size: 2.7rem;
                line-height: 2.5rem;
            }

            @include media-up(sm) {
                font-size: 3rem;
                line-height: 3rem;
            }

            @include media-up(md) {
                font-size: 3rem;
                line-height: 4rem;
            }

            @include media-up(xlg) {
                font-size: 4rem;
            }

        }

        .ob-spotlight-contest__contest-title-io-logo {
            padding-left: 1rem;
            margin-top: .2rem;
            vertical-align: top;

            @include media-down(sm) {
                padding-left: .5rem;
            }

            .ob-mainAsset-wrapper {
                height: .85em;
            }

            .ob-mainAsset-wrapper-img {
                display: block;
                width: auto;
                height: 100%;
            }

            @include media-up(md) {
                margin-top: .65rem;
            }

            @include media-up(xlg) {
                margin-top: .4rem;
            }
        }
    }

    &__title {
        @include display-1-xs;
        @include media-up(sm) {
            @include display-2-xl;
            .ob-spotlight-reduce-footer-waitlist & {
                font-size: 3.6rem;
                line-height: 4.4rem;
            }
        }
        @include media-up(xlg) {
            .ob-spotlight-reduce-footer-waitlist & {
                font-size: 5.6rem;
                line-height: 6rem;
            }
        }
    }

    &__body {
        @include body-2-xl;
        margin-top: 1rem;

        @include media-down(lg) {
            .ob-spotlight-reduce-footer-waitlist & {
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }
    }

    &__wrapper {
        padding: 2rem 3rem 4rem;
        text-align: center;

        @include media-up(xs) {
            width: 100%;
            padding: 2.5rem 4rem 0;
        }

        .ob-spotlight-contest__button-scroll-down-container {
            margin-top: 10rem;
            width: 100%;
            height: 4rem;
            position: relative;

            .ob-spotlight-contest__button-scroll-down {
                color: $base-color__white;
                animation-name: mover;
                animation-duration: 5s;
                animation-iteration-count: infinite;
                animation-timing-function: ease;
                top: auto;
                bottom: .5rem;
                left: 50%;
                transform: translateX(-50%);
                transition: transform 0.8s ease-in-out;
                cursor: pointer;
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;

                >span {
                    @include font-family('hurme-light');
                    transition: transform 0.2s ease-out;
                    margin: 0 auto;
                    font-size: 3.5rem;
                    .icon-io {
                        font-size: 1em;
                        top: .11em;
                        position: relative;
                        margin-left: .4rem;
                    }
                }

                @include media-ie() {
                    animation: none;
                    transition: none;
                }

                @include media-up(md) {
                    bottom: 1rem;
                }

                svg {
                    transition: transform 0.2s ease-out;
                    margin: -0.2rem;
                }

                &:hover,
                &:focus {
                    text-decoration: none!important;
                    svg {
                        @include media-up(md) {
                            transform: scale(1.2);
                        }
                    }
                    >span {
                        @include media-up(md) {
                            transform: scale(1.1);
                        }
                    }
                }

            }
        }

        .ob-spotlight-contest__footer {
            margin-top: 3rem;

            &-social-links {
                display: block;

                &-item {
                    display: inline-block;
                    width: 25px;
                    height: 25px;

                    &:not(:last-child) {
                        margin-right: 0.8rem;
                    }

                    a {
                        display: block;
                        width: 25px;
                        height: 25px;

                        &:hover, &:focus {
                            .ob-mainAsset-wrapper {
                                transform: scale(1.2);
                            }
                        }
                    }

                    .ob-mainAsset-wrapper {
                        width: 25px;
                        height: 25px;
                        transition: transform 0.2s ease-out;

                        img {
                            width: auto;
                            height: 100%;
                        }
                    }
                }
            }

            &-oralb-logo {
                margin-top: 0.8rem;

                .ob-mainAsset-wrapper {
                    height: 2rem;

                    img {
                        width: auto;
                        height: 100%;
                    }
                }
                .ob-link {
                    margin-top: 0;
                    @include media-up(md) {
                        margin-top: 0;
                    }
                }
            }

            &-copyright {
                margin-top: 0.8rem;
                @include font-family('semi-bold');
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__form-list {
        padding: 0;
        list-style-type: none;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;

        .ob-spotlight-reduce-footer-waitlist & {
            margin-top: 0;
        }

        @include media-up(sm) {
            width: 500px;
        }
    }

    &__form-list-item {
        margin-top: 2rem;
        text-align: left;

        &:first-child {
            margin-top: 0;
        }

        @include media-down(sm) {
            width: 100%;
        }
    }

    &__form-label {
        display: block;
        font-size: 2.2rem;
        margin-bottom: 1rem;
        @include media-up(sm) {
            @include body-3-xl;
            margin-bottom: 0;
        }
    }

    &__form-input {
        -webkit-appearance: none;
        background-color: $base-color__contest-input-dark-blue;
        border-radius: 1.2rem;
        display: block;
        padding: 1.2rem;
        border: 1px solid $base-color-isolate-grey;
        width: 100%;
        height: 3.5rem;
        &::placeholder {
            opacity: 1;
            color: #868487;
            font-size: 1.3rem;
        }

        @include eyebrow-1-xl;

        color: $base-color__white;

        @include media-down(sm) {
            font-size: 1.4rem;
            border-radius: 2rem;
        }

        @include media-up(xlg) {
            height: 4rem;
            &::placeholder {
                font-size: 1.5rem;
            }
        }

        .ob-spotlight-uk & {
            line-height: 2rem;
        }

        &.--select {
            padding-right: 4rem;
        }

        &.--error {
            border: 1px solid $contest-error-color;

            &:-internal-autofill-selected {
                -webkit-text-fill-color: $contest-error-color;
            }

            & + .ob-icon {
                fill: $contest-error-color;
            }
        }
    }

    &__form-error,
    &__form-mention {
        font-size: 1.6rem;
        margin: .5rem 0 0;
        body:lang(de) & {
            font-size: 1.4rem;
            margin: .5rem 0 0;
        }
    }

    &__form-error {
        display: inline-block;
        @include textOutline();
        text-align: left;
        color: $contest-error-color;
    }

    .ob-focus-error {
        color: $contest-error-color;
        text-align: left;
        width: 100%;
        margin-bottom: 1rem;
        @include media-up(sm) {
            margin: 0 auto 1rem;
            width: 500px;
        }

        span {
            display: inline-block;
            @include textOutline();
        }
    }

    .ob-button {
        background-color: $base-color__white;
        border: 1px solid $base-color-dark-blue;
        color: $base-color__contest-button-dark-blue;
        display: block;
        width: 100%;
        height: 3.5rem;
        position: relative;
        font-size: 1.6rem;

        @include media-up(sm) {
            width: 52%;
            max-width: 350px;
            border-radius: 1.2rem;
            margin-top: 3rem;
        }

        &:hover,
        &:focus {
            background-color: $base-color-dark-blue-hover;
            color: $base-color__white;
        }

        &[disabled] {
            background: $base-color-proxima-grey;
            color: $base-color-dark-blue;
            cursor: not-allowed;
        }

        &__spinner {
            position: absolute;
            top: 0.6rem;
            right: auto;
            bottom: auto;
            left: 3rem;
            animation: rotate360 1s linear infinite;
            transform: translateZ(0);
            border: 2px solid $base-color-dark-blue;
            border-left: 2px solid transparent;
            background: transparent;
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }

        @keyframes rotate360 {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        span:first-child {
            top: 1px;
            position: relative;
        }
    }

    &__checkboxes-section {
        @include font-family('hurme-regular');
        font-size: 1.3rem;
        line-height: 1.3em;
        margin-top: 1rem;
        width: 460px;
        max-width: 460px;
        color: $base-color__white;
        text-align: left;

        a {
            color: $base-color__white;
            font-weight: bold;
            text-decoration: underline;

            &:hover, &:focus {
                text-decoration: none;
            }
        }

        @include media-down(sm) {
            width: 80%;
            font-size: 1.3rem;
            margin-top: 1rem;
        }

        .contest-input-container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-left: -1.9rem;

            .contest-checkbox {
                cursor: pointer;
                position: absolute;
                opacity: 0;
            }

            .contest-checkbox-fake {
                margin-right: 2.5rem;
                display: inline-block;
                width: 1.5rem;
                height: 1.5rem;
                background: $base-color__contest-input-dark-blue;
                border: 1px solid $base-color__white;
                cursor: pointer;
                position: relative;
                flex: 0 0 auto;
                top: 2px;
                @include media-up(sm) {
                    top: 1px;
                }
            }

            .contest-checkbox:checked + .contest-checkbox-fake:after {
                content: '';
                position: absolute;
                display: inline-block;
                transform: rotate(45deg) translateY(-50%);
                width: .2rem;
                height: .7rem;
                left: 0;
                right: auto;
                top: 50%;
                bottom: auto;
                margin-top: -3px;
                margin-left: 1px;
                border-bottom: 2px solid $base-color__white;
                border-right: 2px solid $base-color__white;
            }

            .contest-checkbox:focus + .contest-checkbox-fake {
                border: 1px solid $base-color-secondary-blue;
            }
        }
    }

    &__checkbox-own-etb {
        @include media-down(sm) {
            display: none;
        }
    }

    &__legal-text {
        @include font-family('hurme-regular');
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin-top: 2rem;
        max-width: 710px;
        color: $base-color__white;
        text-align: left;

        a {
            color: $base-color__white;
            font-weight: bold;
            text-decoration: underline;

            &:hover, &:focus {
                text-decoration: none;
            }
        }

        @include media-down(sm) {
            width: 100%;
            font-size: 1.1rem;
            margin-top: 1rem;
        }
    }

    &__mobile-img {
        margin: 5.2rem 0 3.2rem;
        max-width: 37.6rem;

        img {
            height: auto;
            max-width: 100%;
        }
    }

    &__modal-container,
    &__modal-container-legal {
        width: 90%;
        position: absolute;
        background: $base-color__white;
        top: $ob-sticky-bar-height-mobile;
        left: 50%;
        margin: 15px 0;
        transform: translate(-50%, 0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        max-width: 37.5rem;

        @include media-up(sm) {
            display: flex;
            top: 50%;
            width: 79%;
            margin: 0;
            max-width: 113.7rem;
            max-height: 56rem;
            transform: translate(-50%, -50%);
        }
        img {
            max-width: 100%;
            display: block;
        }
    }

    &__modal-container-legal {
        width: 82%;
        height: 200px;
        padding: 2rem;
        display: flex;
        align-items: center;
        @include media-up(sm) {
            width: 52%;
        }
        p {
            font-size: 1.6rem;
            line-height: 2.2rem;
            @include font-family('hurme-regular');
        }
    }

    &__modal-content {
        @include media-up(sm) {
            width: 50%;
        }
    }

    &__modal-text-container {
        padding: 1rem 2rem;
        .ob-heading {
            @include display-2-xl;
        }
        @include media-down(sm) {
            .ob-heading {
                padding-right: 3rem;
            }
        }

        @include media-up(sm) {
            padding: 5rem 2rem 1rem 2rem;
        }
    }

    &__modal-text-content {
        @include font-family('hurme-light');
        font-size: 1.8rem;
        line-height: 2.6rem;

        @include media-up(md) {
            font-size: 2.2rem;
            line-height: 3rem;
        }
    }



    &__modal-cta {
        align-items: flex-start;
        color: inherit;
        display: flex;
        transition: all 0.4s ease;

        &:link,
        &:visited {
            color: inherit;
        }

        &:hover,
        &:focus {
            color: $base-color-dark-blue-hover;
            text-decoration: none;
        }

        &-content {
            @include font-family('hurme-regular');
            font-size: 1.6rem;
            letter-spacing: 0.05rem;
            line-height: 2.4rem;
            margin-top: 3.6rem;
        }

        .ob-icon {
            flex-shrink: 0;
            margin: 0.3rem 0.8rem 0 0;
        }
    }

    &__close-modal {
        position: absolute;
        top: 2rem;
        right: 2rem;
        transition: all ease 0.35s;

        &:hover, &:focus {
            transform: scale(1.1);
        }
    }

    .ob-spotlight_banner {
        height: 19vw;
        margin-bottom: 1rem;
        z-index: 1;
        position: relative;
        display: none;

        .ob-mainAsset-wrapper {
            text-align: center;
        }

        img {
            max-width: 100%;
        }

        .ob-spotlight_banner-brush-image img {
            @include media-up(xlg) {
                max-width: 1440px;
            }
        }

        @include media-up(sm) {
            display: block;
            height: 13vw;
            margin-bottom: 1rem;
        }
        @include media-up(lg) {
            height: 13vw;
            margin-bottom: 1rem;
        }
        @include media-up(xlg) {
            margin-bottom: 3rem;
        }
    }

    .ob-spotlight_contest-container {
        animation: 2.5s ease 0s 1 translateY;
        z-index: 1;
        position: relative;
    }
}

.ob-contest-layout {

    .ob-SpotlightStickyBar__eyebrow-container  {
        padding-right: .5rem;
    }

    .ob-SpotlightStickyBar__eyebrow-container h1 {
        @include media-down(sm) {
            font-size: 1.5rem;
            line-height: 2.2rem;
        }
    }

    .ob-SpotlightStickyBar__button-zone {
        .ob-button {
            @include media-down(sm) {
                padding: 0 1.25rem;
                font-size: 1.4rem;
            }
        }
    }

    .ob-SpotlightStickyBar__wrapper {
        @include media-down(sm) {
            padding: 0 1rem;
        }
        a:focus{
            border:2px solid $base-color__white;
        }
    }

    .ob-spotlight_language-toggle-container {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
        z-index: 2;
    }

    .ob-spotlight_language-toggle {
        max-width: 1000px;
        margin: 0 auto;
        padding: 0 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        &-item {
            a.ob-spotlight_language-toggle-item-link {
                margin-top: 1.4rem;
                font-size: 1.6rem;
                opacity: .7;
                &.active {
                    opacity: 1;
                }
            }
            &:last-child {
                a:before {
                    content: " | ";
                    padding: 0 .5rem;
                }
            }
        }
    }
}

.ob-spotlight_banner-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}




@keyframes translateY {
    0% {
        opacity: 0;
        transform: translateY(5%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }


@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.ob-spotlight_banner-intro-image {
    width: 14vw;
    max-width: 125px;
    top: 1.5rem;
    position: absolute;
    left: 0;
    right: auto;
    bottom: auto;
    padding-left: 1rem;

    img {
        max-width: 100%;
    }
}

.ob-spotlight_banner-revolution-image {
    margin-top: 5vw;
    width: 46vw;
    max-width: 800px;
    img {
        max-width: 100%;
    }
}


// Contest page for nordic country
.ob-spotlight-se-sv,
.ob-spotlight-ca {

    .ob-spotlight-contest {
        background-color: #010d25;
    }

    .ob-spotlight-contest__form-input {
        &::placeholder {
            color: #bfbfbf;
            font-size: 1.3rem;
        }
        font-size: 1.5rem;
    }

    .ob-spotlight-contest__form-list-item {
        margin-top: 1.5rem;
        &:first-child {
            margin-top:0 ;
        }
    }

    .ob-focus-error,
    .ob-spotlight-contest__form-error {
        font-size: 1.4rem;
    }

    .ob-spotlight-contest__contest-title-text {
        padding: 0 1rem;
        span > span {
            display: block;
        }

        .nowrap {
            display: inline;
        }

        .surtitle {
            @include font-family('semi-bold');
            font-size: 2.4rem;
            margin-bottom: .4rem;
        }

        .main-title {
            @include font-family('hurme-bold');
            font-size: 3.7rem;
            margin-bottom: .6rem;
        }

        .subtitle {
            @include font-family('hurme-regular');
            font-size: 2.5rem;
        }

        .ob-mainAsset-wrapper  {
            display: none;
        }
    }

    .contest-nordic-list-container {
        text-align: left;
        margin: 0 auto;
        margin-top: 3rem;
        padding-left: 1.5rem;
        width: 740px;
        h2 {
            @include font-family('hurme-regular');
            font-size: 2.5rem;
            margin: 0;
            margin-bottom: 2rem;
        }
        ul {
            padding: 0;
            margin-left: 2.5rem;
            list-style: outside;
            @include font-family('hurme-regular');

            li {
                @include font-family('hurme-regular');
                font-size: 1.9rem;
                margin-bottom: .8rem;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .select-css {
        width: 100%;
        display: block;
        color: #bfbfbf;
        @include font-family('semi-bold');
        font-size: 1.3rem;
        line-height: 1;
        letter-spacing: 0.03571em;
        padding-left: 1.2rem;
        height: 35px;
        max-width: 100%;
        box-sizing: border-box;
        margin: 0;
        border-radius: 1.2rem;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: #0f112c;
        background-position: right .7em top 50%, 0 0;
        background-size: .65em auto, 100%;
        &.--error {
            border: 1px solid $contest-error-color;
        }
    }

    .select-css::-ms-expand {
        display: none;
    }

    .select-css:focus {
        border-color: #aaa;
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        outline: none;
    }

    .select-css:not([data-value=''])  {
        color: $base-color__white;
        font-size: 1.6rem;
    }

    .select-css option {
        font-weight:normal;
    }

    .select-container {
        position: relative;
        width: 100%;
        max-width: 500px;
        height: 35px;
        &:after {
            content: '';
            display: block;
            float: right;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;
            bottom: 0;
            left: auto;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 8px 0 8px;
            border-color: #fff transparent transparent transparent;
            pointer-events: none;

            @include media-ie() {
                display: none;
            }
        }
    }

    @include media-up(lg) {
        .ob-spotlight_banner {
            height: 12vw;
        }
        .ob-spotlight-contest__form-input {
            height: 3.5rem;
            &::placeholder {
                color: #bfbfbf;
            }
        }
    }

    @include media-up(md) {
        .ob-spotlight-contest__form .ob-button {
            margin-top: 2.5rem;
        }
    }

    @include media-up(sm) {
        .ob-spotlight-contest__form-list {
            padding-right: 3rem;
        }
        .hide-sm-up {
            display: none !important;
        }

        .hide-sm-down {
            display: inline-block !important;
        }
    }

    @include media-down(lg) {
        .ob-spotlight-contest__contest-title-text {

            .surtitle {
                font-size: 1.9rem;
            }

            .main-title {
                font-size: 3.2rem;
            }

            .subtitle {
                font-size: 2rem;
            }
        }
    }

    @include media-down(md) {
        .ob-spotlight-contest__contest-title-text {

            .surtitle {
                font-size: 1.7rem;
            }

            .main-title {
                font-size: 2.7rem;
            }

            .subtitle {
                font-size: 1.8rem;
            }
        }
        .contest-nordic-list-container h2 {
            font-size: 1.7rem;
        }

        .contest-nordic-list-container ul li {
            font-size: 1.6rem;
        }

        .contest-nordic-list-container {
            width: 615px;
        }

        .select-css:active {
            font-size: 1.6rem!important;
        }
    }

    @include media-down(sm) {

        .ob-spotlight-contest__form-input {
            &::placeholder {
                font-size: 1.1rem;
            }
        }

        .ob-spotlight-contest__wrapper {
            padding: 1.5rem 2rem 0;
        }

        .ob-spotlight-waitlist__form-error {
            margin-top: 0;
        }

        .ob-spotlight-contest__contest-title-text span > span {
            display: inline;
        }

        .ob-spotlight-contest__title-image-buffer {
            height: 35vw;
        }

        .hide-sm-up {
            display: inline-block !important;
        }

        .hide-sm-down {
            display: none !important;
        }

        .ob-spotlight-contest__contest-title-text {

            .surtitle {
                font-size: 1.6rem;
            }

            .main-title {
                font-size: 1.9rem;
            }

            .subtitle {
                font-size: 1.6rem;
                display: block;
                margin-top: 1rem;
            }
        }

        .contest-nordic-list-container {
            max-width: 100%;
            margin-top: 1.3rem;
            h2 {
                font-size: 1.6rem;
                margin-bottom: 1.7rem;
            }
            ul li {
                font-size: 1.2rem;
            }
        }

        .select-container {
            max-width: 100%;

            &:after {
                right: 1.5rem;
                border-width: 10px 6px 0 6px;
            }
        }

        .select-css {
            border-radius: 2rem;
            font-size: 1.2rem;
        }

        .ob-spotlight-contest__button-scroll-down-container {
            margin-top: 9.6rem;
            height: auto;
            button > span {
                white-space: nowrap;
            }
        }

    }


    @media screen and (max-width: 450px) {

        .ob-background-wrapper {
            max-height: 100%;
        }

        .ob-spotlight-contest__form-input {
            &::placeholder {
                font-size: 1rem;
            }
        }
        .select-css {
            border-radius: 2rem;
            font-size: 1rem;
        }
    }

}


.ob-spotlight-ca {

    .ob-SpotlightStickyBar__eyebrow-container h1 {
        padding-right: 1.5rem;
    }

    .ob-spotlight_contest-background {
        height: 100% !important;
    }

    .ob-spotlight-contest__contest-title-text {
        .icon-io {
            display: inline-block;
            font-size: .75em;
        }

        .maintitle {
            padding-bottom: 1rem;
        }

        .ob-mainAsset-wrapper {
            display: none;
        }

        .ob-spotlight-contest__contest-title-io-logo {
            display: none;
        }
    }

    .ob-spotlight-contest__legal-text {
        max-width: 610px;
        font-size: 1.3rem;
        line-height: 1.8rem;
        margin-top: 0;

        p {
            margin: 1rem 0;
        }

        ul {
            list-style-position: outside;
            margin: 0;
            padding: 0;
            padding-left: 1.5rem;
        }
    }

    @include media-up(sm) {

        .ob-spotlight_banner {
            height: 15vw;
        }

        .spotlight-pressure-sensor-red .ob-spotlightContentBlock-textContent .ob-mainAsset-wrapper {
            [lang=fr] & {
                margin-left: -6%;
            }
        }

        .ob-focus-error,
        .ob-spotlight-contest__form-error {
            font-size: 1.6rem;
        }
    }

    @include media-up(lg) {
        .ob-spotlight_banner {
            height: 13.5vw;
        }
    }

    @include media-down(sm) {

        .ob-spotlight-contest__form-input {
            &::placeholder {
                font-size: 1.4rem;
            }
        }
        .ob-focus-error,
        .ob-spotlight-contest__form-error {
            font-size: 1.5rem;
        }
    }

}

.ob-contest-thankyou-layout-ca {
    @include media-up(sm) {
        .spotlight-pressure-sensor-red .ob-spotlightContentBlock-textContent .ob-mainAsset-wrapper {
            [lang=fr] & {
                margin-left: -6%;
            }
        }
    }
}
