@import "../../../../../static/styles/mixins";
@import "../../../../../static/styles/variables";

.ob-forgot-password {
  .ob-form__subtitle {
    margin-bottom: 3.5rem;
    @include media-up(sm) {
      margin-bottom: 6rem;
    }
  }

  &__success-link.ob-link {
    margin-top: 5.6rem;
    font-size: 2.2rem;
  }
}
.ob-form__button__submit:focus{
  border: 2px solid $base-color__black;
}