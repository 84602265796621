@import "../../../static/styles/mixins";
@import "../../../static/styles/variables";

.slide-in-right {
    left: 0;
}

.replacing-brush-heads-gentle-care,
.ultimate-clean-slider {
    position: absolute;
    top: 9px;
    display: flex;
    height: 98.5%;
    z-index: 5;

    @media screen and (max-width: 1440px) {
        top: 5px;
    }

    @media screen and (min-width: 1440px) {
        top: 7px;
    }

    @media screen and (min-width:1730px) and (max-width:1729) {
        top: 9px;
    }

    @media screen and (min-width:1730px) {
        top: 9px;
    }

    @media screen and (min-width:2456px) {
        top: 13px;
    }

    @media screen and (min-width:3260px) {
        top: 18px;
    }

    animation: 0.3s ease-in 0s 1 forwardsIn;

    @keyframes forwardsIn {
        0% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(0%);
        }
    }

    .close-popup {
        width: 20px;
        height: 20px;
        padding: 15px;
        border: solid 2px $base-color__white;
        display: block;
        position: absolute;
        border-radius: 50%;
        right: 0;
        transform: translate(-85%, -20%);
        top: 10%;
        cursor: pointer;
        @include media-down(sm){
            right: auto;
            transform: translate(0);
            margin-left: 89vw;
            top: 0%;
        }
        @media screen and (min-width: 360px) and (max-width: 395px) {
            top: 8%;
        }
        @media screen and (device-width: 412px) {
            top: 2%;
        }
        @media screen and (device-width: 430px) {
            top: 0%;
        }
    }

    .close-popup::before,
    .close-popup::after {
        position: absolute;
        content: '';
        height: 1px;
        width: inherit;
        background: $base-color__white;
        left: 4px;
        padding: 1px;
        top: 50%;
        transition: all .3s ease-in;
    }

    .close-popup::before {
        transform: rotate(45deg);
    }

    .close-popup::after {
        transform: rotate(-45deg);
    }

    @include media-down(sm) {
        width: 100%;

        .slide-in-background {
            width: 100vw;
            position: absolute;
            bottom: -10px;

            img {
                width: 100vw !important;
            }
        }

        .slide-in-text-content {
            position: absolute;
            bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
                font-size: 22px;
                line-height: 21px;
                @include font-family('hurme-bold');
                margin-block-start: 10px;
                margin-block-end: 10px;
            }

            .description {
                text-align: center;
                font-size: 14px;
                line-height: 22px;
                margin-block-start: 10px;
                margin-block-end: 10px;
            }

            .start-guide-cta-link {
                p {
                    max-width: 159px;
                    max-height: 43px;
                    font-size: 14px;
                    line-height: 17px;
                    padding: 15px 45px;
                }
            }
        }

        animation: 0.3s ease-in 0s 1 forwardsIn;

        @keyframes forwardsIn {
            0% {
                transform: translateY(100%);
            }

            100% {
                transform: translateY(0%);
            }
        }
    }
}

.Gum-Bleed-Container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: center;
    align-items: center;

    .ob-mainAsset-wrapper {
        padding: 15% 0 15% 30%;

        @media screen and (min-width: 1280px) and (max-width: 1285px) {
            padding: 15% 0 15% 15%;
        }

        img {
            height: 100%;
            width: 100%;
        }
    }

    .text-content {
        padding: 10%;

        @media screen and (max-width: 1440px) {
            padding: 5%;
        }

        .title {
            color: $io2-base-color-dark-blue;
            font-family: 'Hurme bold';
            font-size: 45px;
            line-height: 55px;
            padding-bottom: 15px;

            @media screen and (max-width: 1440px) {
                font-size: 30px;
                line-height: 40px;
            }
        }

        .description {
            font-family: 'Hurme Regular';
            font-size: 20px;
            color: $io2-base-color-dark-blue;
            line-height: 30px;
            padding-bottom: 20px;

            .bold-text {
                font-family: 'Hurme Bold';
            }
        }

        .subtitle {
            font-family: 'Hurme Regular';
            font-size: 12px;
            line-height: 22px;
            color: $io2-base-color-dark-blue;
        }
    }
}

.Gum-Bleed-Container-mobile {
    @include media-down(sm) {
        padding: 38px 60px 20px;
        display: grid;
        align-items: center;
        justify-content: center;

        .ob-mainAsset-wrapper img {
            height: 252px;
            width: 252px;
        }

        .title {
            height: 52px;
            width: 252px;
            color: $io2-base-color-dark-blue;
            font-family: 'Hurme Bold';
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 26px;
            margin-bottom: 16px;
        }

        .description {
            height: 88px;
            width: 251px;
            color: $io2-base-color-dark-blue;
            font-family: 'Hurme Regular';
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
            margin-bottom: 16px;
        }

        .subtitle {
            height: 22px;
            width: 251px;
            color: $io2-base-color-dark-blue;
            font-family: 'Hurme Regular';
            font-size: 10px;
            letter-spacing: 0;
            line-height: 22px;
        }
    }
}

.gum-bleed-close-button {
    .ob-icon {
        fill: $io2-base-color-dark-blue !important;
    }
}

.z-index-updater {
    position: relative;
    z-index: 99;
}