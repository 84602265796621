@import "../../static/styles/mixins";

.ob-designed-by-dentist {
    $designed-by-dentist-breakpoint-between-lg-and-xlg: 1300px;

    @include media-up(md) {
        height: 700px;
        &.ob-why-switch-designed-by-dentist {
            height: 800px;
        }

        .ob-backdrop {
            height: 100%;
        }
    }

    &--tall {
        @include media-up(md) {
            height: 1050px;
        }
    }

    &__container {
        height: 100%;
        padding: 4rem 0;
        @include media-up(sm) {
            padding: 2rem;
        }
        @include media-up(md) {
            padding: 2rem;
        }
        @media screen and (min-width: $designed-by-dentist-breakpoint-between-lg-and-xlg) {
            padding: 2rem 3rem;
        }
        @include media-up(xlg) {
            padding: 3rem 15rem;
            .ob-why-switch-designed-by-dentist & {
                padding: 3rem 5rem;
            }
        }
    }

    &__title {
        font-size: 2.8rem;
        text-align: center;
        @include font-family('hurme-regular');

        @include media-up(md) {
            @include font-family('hurme-light');
            text-align: left;
            font-size: 4rem;
            letter-spacing: 0.5px;
            width: 40%;
            position: absolute;
            margin: 3rem;
        }
        @include media-up(xlg) {
            width: 30%;
        }
        @include media-down(md) {
            margin: 0 3rem 4rem 3rem;
        }
    }

    &__list-container {
        width: 64%;

        @include media-up(md) {
            float: none;
            width: auto;
            display: flex;
            justify-content: space-between;
        }

        &--desktop {
            display: none;
            @include media-up(md) {
                display: flex;

                .ob-designed-by-dentist__feature-list {
                    margin-top: 24.3rem;
                }
                .ob-designed-by-dentist__feature-list--first-half {
                    margin-top: 19rem;
                }
                .ob-designed-by-dentist__feature-list--second-half {
                    margin-top: 24rem;
                    position: relative;
                    right: 2rem;
                }
            }
        }

        &--mobile {
            display: none;

            @include media-down(md) {
                display: flex;
                width: 95%;
                max-width: 460px;
                margin: 6.5rem auto 0 auto;
                .ob-designed-by-dentist--de-de & {
                    height: 100%;
                }
            }

            .ob-designed-by-dentist--de-de & {
                @include media-up(sm) {
                    margin-top: 23rem;
                }
            }
        }
    }

    &__mainAssetContainer {
        @include media-up(md) {
            &.ob-designed-by-dentist__mainAssetContainer-mobile {
                display: none;
            }
        }

        @include media-down(md) {
            &.ob-designed-by-dentist__mainAssetContainer-desktop {
                display: none;
            }
        }

        &.ob-designed-by-dentist__mainAssetContainer-desktop {
            flex: 1 0 483px;
            height: 608px;
            margin: 0 3rem;
            .ob-why-switch-designed-by-dentist & {
                flex: 1 0 400px;
                height: 636px;
                margin: 0 1rem;
            }
        }

        &.ob-designed-by-dentist__mainAssetContainer-mobile {
            flex: 1 0 119px;
            height: 617px;
            margin: 0 2rem;

            .ob-why-switch-designed-by-dentist & {
                flex: 1 0 141px;
                height: 770px;
                margin: 0;
            }
        }

        .ob-mainAsset-wrapper {
            width: 100%;
            height: 100%;

            img {
                max-width: 100%;
                height: 100%;
            }
        }
    }

    &__feature-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        @include media-up(md) {
            max-width: 37%;
        }
        @include media-up(xlg) {
            max-width: 34%;
        }
        .ob-designed-by-dentist--de-de & {
            @include media-down(md) {
                margin-left: 15rem;
            }
        }

        .ob-designed-by-dentist--da-dk & {
            @include media-down(sm) {
                max-width: 50%;
            }
        }

        &--first-half {
            text-align: right;

            .ob-designed-by-dentist--de-de & {
                text-align: left;
                @include media-up(md) {
                    margin-top: 33rem;
                }
            }
        }

        &--second-half {
            .ob-why-switch-designed-by-dentist & {
                @include media-up(md) {
                    margin-top: 15rem;
                    right: 8rem;
                }
            }
            .ob-designed-by-dentist--de-de & {
                @include media-up(md) {
                    margin-top: 10rem;
                }
            }
            .ob-designed-by-dentist__feature-list-item {
                &:nth-child(2) {
                    margin-top: 4rem;
                    .ob-why-switch-designed-by-dentist & {
                        @include media-up(md) {
                            margin-top: 2rem;
                        }
                    }

                    @include media-up(xlg) {
                        margin-top: 10rem;
                        .ob-why-switch-designed-by-dentist & {
                            margin-top: 4.6rem;
                        }

                        .ob-designed-by-dentist--de-de & {
                            margin-top: 1rem;
                        }
                    }
                }
            }
        }
    }

    &__feature-list-item {
        &:not(:first-child) {
            margin-top: 2.2rem;
            @include media-up(xs) {
                margin-top: 4rem;
            }
            @include media-up(sm) {
                margin-top: 2rem;
            }
            @include media-up(md) {
                margin-top: 2rem;
            }
            @include media-up(xlg) {
                margin-top: 2.4rem;
            }

            .ob-designed-by-dentist--de-de & {
                @include media-down(md) {
                    margin-bottom: 9rem;
                }
            }
        }

        &:last-child {
            @include media-up(sm) {
                margin-bottom: 2rem;
            }
            @include media-up(md) {
                margin-bottom: 0;
            }
        }
    }

    &__feature-title, &__feature-description {
        margin: 0;
        color: $base-color-primary-grey;
    }

    &__feature-title {
        @include font-family('semi-bold');
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        @include media-up(sm) {
            font-size: 1.6rem;
            margin-bottom: 0.5rem;
        }
    }

    &__feature-description {
        @include font-family('hurme-light');
        font-size: 1.4rem;
        line-height: 1.6;
        @include media-up(sm) {
            font-size: 1.8rem;
        }
    }

    &__disclaimer {
        @include font-family('hurme-light');
        font-size: 1.4rem;
        color: $base-color-primary-grey;

        @include media-up(md) {
            font-size: 1.6rem;
        }

        padding-left: 3rem;
    }
}
