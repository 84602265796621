@import "../../static/styles/mixins";

.ob-user-quote {
    position: relative;
}

.ob-user-quote_wrapper {
    @include container-wrapper;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .etb-full-body-zone & {
        border-bottom: none;
    }
    padding: 1rem 2rem;

    @include media-up(sm) {
        padding: 3rem 3rem;
    }

    @include media-up(md) {
        padding: 4rem 4rem;
    }
}

.ob-user-quote_heading {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    letter-spacing: (0.5em / 32);
    line-height: rem(40);
    text-align: center;
    font-size: rem(29);
}

.ob-war-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2.8rem;
    margin-bottom: 3rem;

    @include media-up(sm) {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: center;
    }
}

.ob-link.ob-user-quote_war-btn {
    display: flex;
    transform: translateX(-16px);
    margin-top: 1.5rem;

    @include media-up(sm) {
        margin-top: 0;
    }
}

.ob-link.ob-user-quote_expand-review-btn {
    display: flex;
    margin: 4rem auto 1.5rem auto;
    transform: translateX(-16px);

    @include media-up(sm) {
        display: none;
    }
}

.ob-user-quote_flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-up(sm) {
        flex-direction: row;
    }

    @include media-up(md) {
        margin-top: 2rem;
    }
}

.ob-user-quote_flex-container-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:first-child {
        max-width: 410px;

        @include media-up(sm) {
            padding-right: 3rem;
        }
    }
}

.ob-user-quote_star-rating {
    margin-top: 3rem;
    margin-bottom: 3.8rem;

    @include media-up(md) {
        margin-bottom: 4rem;
    }
}

.ob-user-quote_quote {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    font-size: rem(28);
    letter-spacing: (0.5em / 32);
    line-height: rem(36);
    max-width: 300px;
    text-align: center;
    text-transform: uppercase;
}

.ob-user-quote_author-name {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    font-size: rem(16);
    letter-spacing: (0.5em / 32);
    line-height: rem(24);
    text-align: center;

    @include media-up(md) {
        margin-top: .5rem;
    }
}

.ob-user-quote_image {
    display: none;

    @include media-up(sm) {
        display: block;
        width: 140px;
    }

    @include media-up(md) {
        display: block;
        width: 190px;
    }

    img {
        max-width: 100%;
        max-height: 470px;
    }
}

.ob-customer-review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ob-customer-review {
    overflow: hidden;
    max-width: 1045px;
    margin: 0 auto;
}

.ob-customer-review-title {
    @include font-family('hurme-regular');
    color: $base-color-primary-grey;
    font-size: rem(22);
    letter-spacing: (0.5em / 32);
    line-height: rem(30);
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(61, 61, 65, 0.2);
    margin-top: 2.2rem;
}

body.ob-body .ob-war-summary [data-bv-show=rating_summary] {
    @include media-up(sm) {
        padding-right: 6rem !important;
    }
}

body.ob-body [data-bv-show=reviews] {
    width: auto !important;
    margin-top: 1rem !important;
    position: relative !important;
    z-index: 1 !important;
    @include media-up(md) {
        margin-top: 0 !important;
    }
}

body.ob-body [data-bv-show=reviews] .bv-content-placeholder {
    .bv-rating-stars-container.bv-rating-none,
    .bv-write-review-label.bv-text-link.bv-focusable.bv-submission-button {
        opacity: 0 !important;
        pointer-events: none !important;
    }
}
