@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.ob-collection-tabs-dropdown__eyebrow {
    @include eyebrow-1-xl;
    text-align: center;
    margin: rem(36) 0;
    display: none;
    @include media-up(sm) {
        display: block;
    }
}

.ob-collection-tabs-dropdown__tabs-wrapper {
    @include media-up(sm) {
        margin: rem(36) 0;
    }
}