@import "../../static/styles/mixins";

.ob-heading {
    margin: 0;
    &.ob-display-1-xl {
        @include display-1-xl;
    }
    &.ob-display-1-xs {
        @include display-1-xs;
    }
    &.ob-display-2-xl {
        @include display-2-xl;
    }
    &.ob-display-2-xs {
        @include display-2-xs;
    }
    &.ob-display-3-xl {
        @include display-3-xl;
    }
    &.ob-display-3-xs {
        @include display-3-xs;
    }
    &[data-white-text='true'] {
        color: $base-color__white;
    }

    .blackText & {
        color: $base-color__black;
    }

    .textOutline & {
        @include textOutline();
    }

    b,
    strong {
        @include font-family('hurme-regular');
    }
}