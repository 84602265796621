@import "../../static/styles/mixins";

.ob-spotlight-thankyou {
    .ob-spotlight-contest__title-image-buffer {
        display: block;
        height: 9vw;

        @include media-up(sm) {
            height: 5.5vw;
        }
    }

    .ob-spotlight-contest__contest-title-text {
        font-size: 3rem!important;
        line-height: 1.1em!important;
        @include media-up(md) {
            font-size: 4.5rem!important;
        }
    }

    .ob-background-wrapper {

        @include media-down(xs) {
            background-size: cover;
        }
        @include media-up(md) {
            background-size: cover;
        }
    }

    .ob-spotlight-thankyou_subtitle {
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin-top: 2rem;

        @include media-up(sm) {
            margin-top: 3rem;
        }

        span{
            font-size: 2rem;
            @include media-up(sm) {
                font-size: 2.6rem;
            }
        }
        .icon-io {
            font-size: .75em;
        }
    }

    .ob-spotlight-thankyou_hero {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 2rem;
        box-sizing: border-box;
        padding: 0 1rem;
        @include media-up(sm) {
            margin-top: 3rem;
        }
        .icon-io {
            font-size: .75em;
        }

        >div {
            font-size: 2rem;
            line-height: 1.3em;
            max-width: 425px;
            text-align: center;
            @include media-up(sm) {
                font-size: 2.1rem;     
            }
        }
        
    }

    .ob-spotlight-contest__contest-title-container {
        
        @include media-down(sm) {
            padding: 0 2rem!important;
        }
    }

    .ob-spotlight_banner {
        height: 22vw;
        margin-bottom: 1rem;
        animation: 3s ease-in 0s 1 fadeIn;
        display: block;
        margin-top: 3rem;
        img {
            max-width: 100%;
        }

        @include media-up(sm) {
            height: 13vw;
            margin-bottom: 1rem;
            margin-top: 0;
        }
        @include media-up(lg) {
            height: 13vw;
            margin-bottom: 1rem;
            margin-top: -3rem;
        }
        @include media-up(xlg) {
            margin-bottom: 3rem;
        }
    }

    .ob-spotlight_contest-container {
        animation: 2s ease 0s 1 translateY;
    }
}