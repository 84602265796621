@import "../../static/styles/variables";
@import "../../static/styles/mixins";

$minimum-width-mobile: 40vw;
$chart-mobile-horizontal-padding: 35px;

.ob-comparison-chart {
    width: 100%;

    @include media-up(sm) {
        @include container-wrapper;
    }

    position: relative;
}

.ob-comparison-chart-title {
    @include display-3-xl;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.ob-comparison-chart-jump-to-configurator {
    display: block;
    
    font-size: 1.5rem;
    padding: 0 5rem;
    text-align: center;
    color: $base-color-dark-blue;
    @include font-family('semi-bold');

   
    &:focus {
        outline: dotted 1px;
    }

   
}

.ob-comparison-chart-wrapper-1 {
    position: relative;
}

.ob-comparison-chart-fade-left, .ob-comparison-chart-fade-right{
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20%;
    pointer-events: none;
    @include media-up(sm) {
        display: none;
    }
}

.ob-comparison-chart-fade-left{
    left: 0;
    opacity: 0;
    background-image: linear-gradient(to left, rgba(255,255,255,0) , rgba(255,255,255,1));
}

.ob-comparison-chart-fade-right{
    right: 0;
    background-image: linear-gradient(to right, rgba(255,255,255,0) , rgba(255,255,255,1));
}

.ob-comparison-chart-wrapper-2 {
    padding: rem(18) 0;
    position: relative;
    overflow-x: auto;
    width: 100%;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    @include no-scrollbar;
    @include media-up(sm) {
        width: auto;
    }
    @include media-down(md) {
        overflow-x: hidden;
    }
}

.ob-comparison-chart-snap-hack {
    height: 0;
    scroll-snap-align: start;
    position: absolute;
    top: 0;
    width: $minimum-width-mobile;
}

.ob-comparison-chart-table {
    display: table;
    table-layout: fixed;
    @include media-up(sm) {
        width: 100% !important;
    }
}

.ob-comparison-chart-row {
    display: table-row;
}

.ob-comparison-chart-cell {
    display: table-cell;
    box-sizing: content-box;
    @include media-down(sm) {
        width: $minimum-width-mobile;
        &:first-child {
            padding-right: $chart-mobile-horizontal-padding;
            padding-left: 1.5rem;
        }
        &:last-child {
            padding-right: $chart-mobile-horizontal-padding;
        }
    }
}

.ob-comparison-chart-image {
    padding-bottom: 18px;
    display: flex;
    img {
        margin: 0 auto;
    }
}

.ob-comparison-chart-header {
    font-size: rem(16);
    color: $base-color-dark-blue;
    @include font-family('semi-bold');

    a {
        .ob-comparison-chart-image {
            transform: scale(0.95);
            transition: 0.5s ease-in-out;
            .is-visible{
                width: 100%;
                display: flex !important;
            }
        }
        &:focus, &:hover {
            .ob-comparison-chart-image {
                transform: scale(1) translateX(2%);
            }
        }
        &:focus{
            .ob-comparison-chart-image {
                transform: scale(1) translateX(2%);
                outline: 1px solid black;
            }
        }
    }
}
.ob-comparison-chart-short-title-select{  
    border-bottom: 2px solid;
    display: block;
    border-right: none;
    border-top: none;
    border-left: none;
    font-size: 1.5rem;
    padding: 1rem 0;
    color: $base-color-dark-blue;
    margin: 2rem auto;
    width: 80%;
    @include media-down(md) {  
        width: 60%;
    }
}
.ob-comparison-chart-price-range {
    font-size: rem(18);
    color: $base-color-primary-grey;
    font-weight: bold;
    padding-bottom: rem(30);
}

.ob-comparison-chart-feature-cell {
    border-top: 1px solid $base-color-secondary-grey;
    padding: rem(24) rem(16) rem(24) 0;
    color: $base-color-primary-grey;
    font-size: rem(16);
    @include media-down(md) {  
        padding: 0.1rem 1.6rem 2.4rem 0;
    }
    * {
        display: flex;
        text-align: center;
        flex-direction: column;
        margin: 1rem auto;
        justify-content: center;
    }
}
.find-retailer-cta{ 
   text-align: center;
        @include media-down(md) {  
            padding: 0 1rem;
        }
 .ob-button{
    text-transform: capitalize;
 }
}
.ob-comparison-chart-cell-hidden{
    visibility: hidden;
}
.ob-comparison-chart-table-width{
    width: auto;
}
.ob-comparison-chart-image-height{
    height: 220px;
}

//Global Equity
 .collection-toothbrush-body-zone {
    .ob-collection-replacement-brush-head {
        .ob-contentBlock-textContent-inner {
            .ob-contentBlock-textContent-obLink-container {
                a.ob-link.white {
                    &:hover {
                        color: $base-color-dark-blue-hover;
                    }
                }
            }
        }
    }
}

.ob-comparison-chart{
    margin-top: 9%;
    @media only screen and (min-device-width:375px)
    and (max-device-width:926px){
        margin-top: 90px;
    }
    @media only screen and (max-width:360px){
        margin-top: 30%;
    }
}