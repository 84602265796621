@import "../../static/styles/mixins";

.ob-showcase {
    margin: 0 auto 3.6rem;
    max-width: 90vw;
    @include media-up(sm) {
        margin-bottom: 8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $base-color-secondary-grey;
    }
    @include media-up(md) {
        max-width: 750px;
    }

    .ob-mainAsset-wrapper {
        @include media-up(sm) {
            width: 39%
        }
    }

    .ob-mainAsset-wrapper-img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
    }

    &__content {
        margin-top: 3rem;
        @include media-up(sm) {
            margin-top: 0;
            width: 56%;
        }
    }
    &__title, &__description {
        text-align: center;
        @include media-up(sm) {
            // For IE11
            text-align: left;
            // For Modern Browsers
            text-align: initial;
        }
    }

    &__title {
        @include display-2-xl;
    }

    &__description {
        @include body-1-xl;
        margin: 0;
        @include media-up(sm) {
            margin: 1rem 0 0;
        }
    }
}
