@import "../../static/styles/mixins";
@import "../../static/styles/variables";

.ob-start-guide-layout-new {
    .start-guide-shuffle-block {
        position: relative;

        .start-guide-background {
            height: 100vh;

            img {
                width: 100% !important;
            }
        }
        .ob-videoBlock {
            .ob-videoContent-button {

                &:hover,
                &:focus {
                    background-color: unset;
                }
            }
        }

        .start-guide-content-container {
            display: flex;

            .start-guide-animation-asset {
                width: 50%;

                @include media-down(sm) {
                    width: 100%;
                }

                img {
                    display: block;
                    height: 100vh;
                    padding: 0;
                    margin: 0 auto;
                }
            }

            .start-guide-text-content {
                color: $base-color__white;

                .start-guide-header {
                    @include font-family('hurme-light');
                    font-size: 45px;
                    line-height: 55px;

                    .bold-text {
                        @include font-family('hurme-regular');
                        font-weight: 600;
                        display: block;
                    }

                    @include media-down(sm) {
                        font-size: 28px;
                        line-height: 26px;

                        .bold-text {
                            @include font-family('hurme-regular');
                            font-weight: 600;
                        }
                    }

                    @include media-up(lg) {
                        margin: 0;
                    }
                }

                p.start-guide-subtext {
                    @include font-family('hurme-light');
                    font-size: 21px;
                    line-height: 29px;
                    margin-bottom: 2vw;
                    @include media-down(sm) {
                        font-size: 17px;
                        line-height: 23px;
                    }

                    .bold-text {
                        @include font-family('hurme-regular');
                        font-weight: 600;
                    }
                }

                h3 {
                    font-size: 2rem;
                    line-height: 3rem;
                    .heading{
                        font-size: 45px;
                    }
                }

                .ob-videoBlock {
                    width: 100%;
                    background-color: transparent;
                    .ob-videoContent-button{
                        opacity: 0;
                    }
                }
            }

            .start-guide-text-container {
                display: flex;
                align-items: center;
                justify-content: center;

                @include media-down(sm) {
                    width: 112%;
                }

                @include media-up(sm) {
                    min-width: 52rem;
                    max-width: 65rem;
                }

                img {
                    justify-content: center;
                    margin: 0 auto;
                    display: flex;
                    max-width: 100%;
                    @include media-up(sm) {
                        max-width: 64vw;
                    }
                    @media screen and (min-width: 1528px) and (max-width: 1537px) {
                        max-width: 66vw;
                    }
                    @media screen and (min-width: 1272px) and (max-width: 1280px) {
                        max-width: 80vw;                        
                    }
                    @media screen and (min-width: 1434px) {
                        max-width: 76vw;
                    }
                }

                .start-guide-cta-link p {
                    border-radius: 26px;
                    cursor: pointer;
                    @include font-family('semi-bold');
                    color: $io2-blue;
                    font-size: 18px;
                    line-height: 26px;
                    padding: 15px 45px;
                    padding-left: 25px;
                    background-color: $io2-secondary-blue-color;                    ;
                    display: inline-block;
                    margin-right: 20px;            
                    background-image: url("/static/images/Union_Link.webp");
                    background-repeat: no-repeat;
                    background-position: 92%;
                    background-size: 15px;
                    text-align: center;
                    &::after{
                    }
                    @include media-down(sm) {
                        font-size: 12px;
                        line-height: 14px;
                        padding: 15px 30px;
                        padding-left: 12px;
                        background-position: 95%;
                        margin-right: 0;
                    }
                }
                .low-battery{
                    display: flex;
                }
            }
        }

        &.shuffle-block1 {
            .start-guide-text-container {
                transform: translateX(40%);
                justify-content: flex-end;

                @media screen and (max-width: 1440px) {
                    transform: translate(10%);
                }
            }

            .start-guide-text-content {
                @include media-up(lg) {
                    width: 37rem;
                }

                @media screen and (min-width:1440px) {
                    width: 40rem;
                }
            }
        }

        &.shuffle-block2 {
            &.use-right-pressure-card {
                @media screen and (min-width: 1024px) and (max-width:1030px) {
                    .start-guide-header{
                        transform: translateX(30%);
                        text-align: center;
                    }
                    .start-guide-subtext{
                        transform: translateX(-10%);
                        img{
                            margin-left: 0;
                        }

                    }
                    .brush-blinker{
                        position: absolute;
                        right: -3.3vw;
                        top: 63%;
                        height: 5.5vw;
                        @media screen and (min-width: 991px) and (max-width: 1024px) {
                            right: -15.3vw;
                            top: 56%;
                        }
                    }
                }
                @include media-down(sm){
                    .brush-blinker{
                        position: absolute;
                        right: -27.8vw;
                        top: 56%;
                        height: 12vw;
                        transform: rotate(-8deg);
                        @media screen and (min-width: 430px) {
                            right: -28.7vw;
                        }
                        @media screen and (max-width: 360px) {
                            right: -28.7vw;
                            height: 12vw;
                            top: 56.24%;
                        }
                    }
                }
                @include media-up (lg) {
                    min-height: auto;

                    .start-guide-content-container {
                        min-height: auto;
                        flex-direction: row-reverse;

                        .start-guide-animation-asset {

                            img {
                                min-height: 104vh;
                            }
                        }
                        .image-container{
                            display: block;
                            height: 100%;
                            margin: 0 auto;
                            min-height: auto;
                            .start-guide-animation-asset{
                                transform: translateX(0%);
                                width: 100%;
                                img{
                                    display: block;
                                    height: 100%;
                                    padding: 0;
                                    margin: 0 auto;
                                    width: 100%;
                                }
                            }
                        }
                        .start-guide-text-container{
                            width: 42%;
                            transform: translateX(0%);
                            min-width: auto;
                            max-width: max-content;
                            padding-left: 10%;
                            height: 85vw;
                            .start-guide-subtext{
                                width: 96%;
                                font-size: 36px;
                                line-height: 40px;
                                    .brush-blinker{
                                        position: absolute;
                                        right: -30.9vw;
                                        top: 52.4vw;
                                        height: 7vw;
                                        @media screen and (min-width: 1430px) and (max-width: 1480px) {
                                            right: -31.0vw;
                                        }
                                        @media screen and (min-width: 1272px) and (max-width: 1281px) {
                                            right: -30.18vw;
                                        }
                                        @media screen and (min-width: 1527px) and (max-width: 1537px) {
                                            right: -30.35vw;
                                        }
                                        @media screen and (min-width: 1510px) and (max-width: 1520px) {
                                            right: -30.8vw;
                                            top: 52.4vw;
                                            height: 7vw;
                                        }
                                    }
                                    p{
                                        font-size: 21px;
                                        line-height: 29px;
                                    }
                                    .right-desc{
                                        margin-top: 40px;
                                        width: 29vw;
                                        &:first-child{
                                            margin-top: 0;
                                        }
                                    }
                                    img{
                                        margin: 0 auto 0 0;
                                    }
                                }
                                .save-settings{
                                    color: $io2-blue;
                                    background-color: $io2-secondary-blue-color;                        ;
                                    padding: 15px 20px;
                                    border-radius: 100px;
                                    font-size: 18px;
                                    font-weight: 600;
                                    line-height: 26px;
                                    text-align: left;
                                    font-family: $hurmeregular;
                                    display: flex;
                                    span {
                                        text-align: left;
                                        background-image: url("/static/images/info.svg");
                                        background-repeat: no-repeat;
                                        background-position: right center;
                                        background-size: 25px;
                                        font-size: 12px;
                                        line-height: 15px;
                                        padding: 12px;
                                        margin-right: 10px;
                                    }
                            }
                        }
                        .start-guide-header{
                            transform: translate(50%, 0%);
                            top: 0%;
                            position: absolute;
                            width: 100%;
                            text-align: center;
                            font-size: 56px;
                            line-height: 60px;
                            transform: none;
                            left: 0;
                            width: 100vw;
                            .gentle{
                                width: 567px;
                                margin-left: auto;
                                margin-right: auto;
                            }
                        }
                    }
                }
                .start-guide-content-container {
                    .start-guide-text-container {
                        .start-guide-subtext {
                            .brush-blinker {
                                @media screen and (min-width: 1900px) and (max-width: 1950px) {
                                    right: -30.2vw;
                                }
                            }
                        }
                    }
                }

                @media screen and (min-width: 1160px) and (max-width: 1280px) {
                    .start-guide-content-container {
                        .start-guide-header{
                            font-size: 44px;
                            line-height: 46px;
                        }
                        .start-guide-text-container{
                            .start-guide-subtext{
                                font-size: 30px;
                                line-height: 36px;
                                p{
                                    font-size: 17px;
                                    line-height: 23px;
                                }
                                @media screen and (min-width:1230px) and (max-width: 1240px){
                                    margin-top:60%;
                                }
                            }
                        }
                    }
                }
                
                .start-guide-content-container{ 
                    .start-guide-text-container {
                        .start-guide-subtext{
                            .brush-blinker{                                
                                @-moz-document url-prefix() {
                                    @media screen and (min-width: 1200px) {
                                        right: -30.3vw !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ob-videoBlock {
                .ob-videoContent-button {

                    &:focus,
                    :hover {
                        outline: none;
                    }

                    svg {
                        fill: transparent;

                        &:focus,
                        :hover {
                            fill: transparent;
                        }
                    }
                }
            }

            .start-guide-text-container {
                width: 30rem;
                transform: translate(15%, -10%);

                @media screen and (max-width:1440px) {
                    transform: translate(-15%, -10%);
                }

                @media screen and (min-width:1440px) {
                    transform: translate(0);
                    width: 55rem;
                }

                .start-guide-subtext {
                    width: 85%;
                }
            }

            .start-guide-animation-asset {
                transform: translateX(75%);
            }

            .use-right-pressure-card{
                .start-guide-animation-asset {
                    transform: translateX(5%);
                }
            }
            @include media-down(sm) {
                .start-guide-animation-asset {
                    transform: unset;

                    img {
                        margin: unset;
                    }
                }

                .start-guide-text-container {
                    transform: translate(0%) !important;
                    width: 100%;                    
                }
            }
        }

        &.shuffle-block2.brush-head {
            @media screen and (min-width: 1280px) and (max-width: 1285px) {
                .start-guide-text-container {
                    transform: translate(0%, 0%);
                }
            }

            @media screen and (min-width: 1535px) and (max-width: 1540px) {
                .start-guide-text-container {
                    transform: translate(0);
                }
            }
        }

        &.shuffle-block3 {
            .start-guide-secondary-image {
                img {
                    width: 198px;
                    height: auto;
                }
            }

            .start-guide-text-container {
                width: 70rem;
                max-width: 75rem;

                @media screen and (max-width:1440px) {
                    width: 64rem;
                    transform: translateX(3%);
                }

                @media screen and (min-width: 1400px) {
                    transform: translateX(15%);
                }

                @media screen and (min-width: 3260px) {
                    transform: translateX(45%);
                }
            }

            .start-guide-content-container {
                grid-template-columns: 1fr;

                .start-guide-animation-asset {
                    transform: translateX(35%);
                    width: 100%;

                    img {

                        @include media-down(sm) {
                            margin-top: 8px;
                        }
                    }

                    @include media-down(sm) {
                        transform: unset;
                    }
                }

                .start-guide-header {
                    span {
                        display: inline !important;
                    }
                }
            }

            &.charging-base-card {
                .start-guide-content-container {
                    justify-content: center;
                    @include media-down(sm) {
                        flex-direction: column-reverse;
                    }
                    .start-guide-text-container {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-auto-flow: dense;
                        direction: rtl;
                        width: 50%;
                        max-width: auto;
                        min-width: auto;
                        transform: translateX(0%);
                        padding-left: 10%;
                        flex-basis: 100%;
                        @include media-down(sm) {
                            padding-left: 0%;
                            text-align: center;
                            padding-bottom: 40px;
                        }

                        .start-guide-text-content {
                            color: $io2-blue;
                            width: 100%;
                            direction: ltr;
                            text-align: left;
                            max-width: 82%;
                            margin: 0 auto 0 0;
                            @media screen and (min-width: 1024px) and (max-width: 1030px) {
                                width: 96% !important;
                                max-width: 100%;
                            }
                            @media screen and (max-width:1440px) {
                                width: 85%;                                
                            }
                            @-moz-document url-prefix() { 
                                @media screen and (max-width:1440px) {
                                    width: 81%;
                                }
                            }
                            @media screen and (min-width: 1440px) and (max-width: 1500px) {
                                max-width: 75%;
                            }

                            @include media-down(sm) {
                                width: 100%;
                                max-width: 100%;
                                direction: ltr;
                                padding: 0 20px;
                                text-align: center;
                            }
                            .start-guide-header{
                                font-size: 36px;
                                line-height: 40px;
                                font-weight: 400;
                                margin-bottom: 20px;
                                @include media-down(sm) {
                                    font-size: 28px;
                                    line-height: 36px;
                                }
                            }
                            .start-guide-subtext{
                                font-size: 21px;
                                line-height: 29px;
                                @include media-down(sm) {
                                    font-size: 17px;
                                    line-height: 23px;
                                }
                                p{
                                    margin: 0;
                                    &:nth-child(2){
                                        margin-top: 20px;
                                    }
                                }
                                .bold-text{
                                    margin: 0 0 20px 0;
                                }
                                .low-battery{
                                    @include media-down(sm) {
                                        text-align: left;
                                    }
                                    .low-battery-text{
                                        margin-left: 5px;
                                    }
                                    img{
                                        max-width: 100%;
                                        width: 85px;
                                        margin-top: 5px;
                                        
                                        @include media-down(sm) {
                                            max-width: 48px;
                                            width: 48px;
                                            margin-right: 5px;
                                        }
                                    }
                                }
                            }
                        }

                        @media screen and (min-width: 2056px) and (min-height: 1150px) and (max-height: 1600px) {
                            margin-left: 10rem;
                        }
                    }
                    
                    .image-container{
                        width: 50%;
                        flex-basis: 100%;
                        @include media-down(sm) {
                            width: 100%;
                        }
                        .start-guide-animation-asset{
                            transform: translateX(0%);
                            @include media-up(sm){
                                padding-bottom: 100px;
                            }
                            img{
                                width: 32vw !important;
                                height: 100% !important;
                                @include media-down(sm) {
                                    width: 100% !important;
                                    height: auto;
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.smart-settings-card {
            .start-guide-content-container{
                max-width: 80%;
                margin: 0 auto;
                @media screen and (min-width: 1024px) and (max-width: 1030px) {
                    max-width: 88%;
                }
                @include media-down(sm) {
                    max-width: 90%;
                }
                .start-guide-text-container{
                    width: 41%;
                    flex-basis: 75%;
                    min-width: auto;
                    max-width: 100%;
                    margin-right: auto;
                    justify-content: flex-start;
                    transform: translateX(0%);
                    @include media-down(sm) {
                        justify-content: center;
                        z-index: 1;
                        width: 100%;
                    }
                    .start-guide-header{
                        font-size: 36px;
                        line-height: 40px;
                        margin-bottom: 0;
                        b{
                            @include font-family('hurme-regular');
                            font-weight: 600;
                        }
                        @include media-down(sm) {
                            font-size: 32px;
                            line-height: 36px;
                            padding: 0 15px;
                        }
                        @include media-down(sm) {
                            text-align: center !important;                            
                        }
                    }
                    .start-guide-subtext{
                        font-size: 21px;
                        line-height: 29px;
                        max-width: 515px;    
                        @media screen and (min-width: 1440px) and (max-width: 1500px) {
                            width: 93%;
                        }                  
                        @include media-down(sm) {
                            text-align: center !important;
                            font-size: 17px;
                            line-height: 23px;
                            padding: 0 15px;
                        }
                    }
                    .save-settings{
                        color: $io2-blue;
                        background-color: $io2-secondary-blue-color;
                        padding: 15px 20px;
                        border-radius: 100px;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 26px;
                        text-align: left;
                        font-family: $hurmeregular;
                        display: flex;
                        &:hover,&:focus{
                            text-decoration: none;
                        }
                        @include media-down(sm) {
                            font-size: 12px;
                            line-height: 16px;
                        }
                        span {
                            text-align: left;
                            background-image: url("/static/images/info.svg");
                            background-repeat: no-repeat;
                            background-position: right center;
                            background-size: 25px;
                            font-size: 12px;
                            line-height: 15px;
                            padding: 12px;
                            margin-right: 10px;
                            @include media-down(sm) {
                                display: none;
                            }
                        }
                    }
                }
                .image-container{
                    width: 59%;
                    flex-basis: 109%;
                    position: relative;
                    margin: 0 auto;
                    @include media-down(sm) {
                        margin: 0 auto;
                        margin-top: -15vw;
                    }
                    .vertical-carousel-container{
                        margin-top: 0;
                        transform: translateX(0%);
                        .bg-img{
                            @include media-up(sm){
                                width: 30vw;
                                height: 60vw;
                                img{
                                    height: 100% !important;
                                }
                            }
                            @media screen and (min-width: 1024px) and (max-width: 1030px) {
                                width: 100%;
                                height: 100%;
                            }
                            @include media-down(sm) {
                                height: auto;
                                transform: translateX(0%);
                            }
                        }
                        .blink-container{
                            display: none;
                            box-shadow: 0 0 11.2px 0 #caf700;
                            background-color: #caf700;
                            position: absolute;
                            height: 3.55vw;
                            width: 1.25vw;
                            top: 11.8vw;
                            border-radius: 30px;
                            left: 29.5vw;
                            @include media-down(sm){
                                height: 10vw;
                                width: 3.25vw;
                                top: 34vw;
                                left: 25.8vw;
                                z-index: 3;
                                @media screen and (max-width: 412px) {
                                    height: 11vw;
                                }
                                @media screen and (max-width: 375px) {
                                    top: 27vw;
                                    height: 9vw;
                                }
                                @media screen and (max-width: 360px){
                                    top: 32vw;
                                    height: 10vw;
                                }               
                            }
                            @media screen and (min-width: 380px) and (max-width: 450px) {
                                top: 34vw;
                            }
                            @media screen and (min-width: 1024px) and (max-width: 1030px) {
                                height: 6vw;
                                width: 2.2vw;
                                top: 18.8vw;                            
                            }
                            @media screen and (min-width: 1430px) and (max-width: 1441px) {
                                left: 30vw;
                            }
                            @media screen and (min-width: 1510px) and (max-width: 1520px) {
                                left: 29.9vw;
                            }
                            @media screen and (min-width: 2000px) {
                                left: 29.7vw;
                            }
                        }
                        button{
                            -webkit-transform: translate(-50%, -40%);
                            transform: translate(-50%, -40%);
                            top: 40%;
                            font-size: 14px;
                            line-height: 18px;
                            max-height: 100px;
                            padding: 0;
                            background-image: none;
                            left: 64%;
                            position: absolute;
                            @include media-down(sm){
                                top: 45%;
                                left: 50%;
                            }
                            .vertical-carousel-button{                                
                                width: 25px;
                                height: 25px;
                                min-height: auto !important;
                            }
                            p{
                                width: 100px;
                                font-size: 14px;
                                line-height: 18px;
                                color: $base-color__white;
                                position: absolute;
                                top: -250%;
                                left: -150%;
                            }
                        }
                        .slider-container{
                            width: 100%;
                            @media screen and (min-width:1230px) and (max-width: 1240px){
                                transform: translate(3%, 46%);
                                .text-header,.text-description{
                                    margin-block-start: 5px;
                                    margin-block-end: 5px;
                                }
                            }

                            .slick-slider{
                                max-width: 100%;
                                padding: 0;
                                .slick-list{
                                    padding: 0 !important;
                                    margin-top: 30px;                               
                                    .carousel-item{
                                        .carousel-item-text{
                                            .text-header{
                                                font-size: 22px;
                                                line-height: 26px;
                                                @include font-family("hurme-bold");
                                                margin: 5px 0 0 0;
                                                @media screen and (min-width: 1440px) and (max-width: 1500px) {
                                                    line-height: 23px;
                                                }
                                                @media screen and (min-width: 1024px) and (max-width: 1030px) {
                                                    line-height: 8px;
                                                }
                                            }
                                            .text-description{
                                                font-size: 19px;
                                                line-height: 24px;
                                                margin: 15px 0;
                                                @media screen and (min-width: 1240px) and (max-width: 1285px) {
                                                    font-size: 17px;
                                                    line-height: 20px;
                                                    margin-top: 10px;
                                                }
                                                @media screen and (min-width: 1440px) and (max-width: 1500px) {
                                                    font-size: 18px;
                                                    line-height: 21px;                                                
                                                }
                                            }
                                        }
                                    }
                                }
                                .slick-slide{                                    
                                    &.slick-active{
                                        opacity: 1;  
                                        padding: 0;                               
                                        &.slick-current{
                                            .carousel-item-text{
                                                display: block;
                                                @media screen and (min-width: 1440px) and (max-width: 1500px) {
                                                    width: 280px;
                                                }
                                                @media screen and (min-width: 1024px) and (max-width: 1030px) {
                                                    width: 254px;
                                                }
                                            }
                                            .ob-mainAsset-wrapper {
                                                background-color: $io2-base-color-slight-dark;
                                                border-radius: 50%;
                                                img{
                                                    width: 90px;
                                                    padding: 10px;
                                                    margin: 15px;
                                                }
                                            }
                                        }
                                        .carousel-item-text{
                                            display: none;
                                        }
                                        
                                        .ob-mainAsset-wrapper {
                                            background-color: $io2-lightgrey;
                                            border-radius: 50%;
                                            img{
                                                width: 90px !important;
                                                padding: 10px;
                                                margin: 15px;
                                            }
                                        }
                                    }
                                }
                            }                            
                        }
                    }
                }
            }
            @include media-up(lg) {
                min-height: auto;
                margin-top: -1px;
            }

            @media screen and (max-width:1440px) {
                .start-guide-text-container {
                    transform: translateX(-2%);
                }
            }

            @media screen and (min-width: 1440px) {
                min-height: auto;
            }
        }
    }

    .ready-to-brush-card{
        .start-guide-background {
            height: 14vh;
        }
        @include media-down(sm){
            background-color: $io2-base-color-dark-blue;
        }
        .start-guide-content-container .start-guide-text-content p.start-guide-subtext {
            @include media-down(sm){
                margin: 0px auto 0;
            }
        }
    }

    .brush-head{
        margin-top: 4%;
        @include media-down(sm){
            margin-top: 0;
            height: 100%;
            padding-top: 50px;
        }
        .start-guide-content-container{
            padding-top: 30px;
            .start-guide-text-container{
                transform: translateX(0%);
                width: 100%;
                padding-left: 5%;
                max-width: 42%;
                @media screen and (device-width: 1440px) {
                    transform: translateX(3%) !important;
                    .start-guide-subtext{
                        transform: translateX(10%);
                    }
                    .ob-videoBlock{
                        transform: translateX(19%);
                    }
                }
                @include media-down(sm){
                    margin-left: 0;
                    margin-top: 0;
                    padding-left: 20px;
                    max-width: 58%;
                    position: relative;
                }      
                .start-guide-header{
                    transform: translate(0%, 0%);
                    font-weight: bold;
                    position: absolute;
                    top: -40px;
                    width: 100%;
                    margin-left: 100%;
                    margin-right: 100%;
                    transform: translateX(-75%);
                    left: 50%;
                    text-align: center;
                    font-size: 56px;
                    line-height: 60px;
                    @media screen and (min-width: 1400px) and (max-width: 1500px) {
                        transform: translateX(-83%);
                        width: 107%;
                    }
                    @media screen and (min-width: 1024px) and (max-width:1030px) {
                        transform: translateX(-100%);
                        top: -7rem;
                    }
                    @include media-down(sm){
                        text-align: center !important;
                        transform: translate(0, 0);
                        font-size: 40px;
                        line-height: 44px;
                        margin-left: 8%;
                        margin-right: 0;
                        min-width: 344px;
                        left: 0;
                        right: 0;
                        top: -120px;
                    }
                    @media screen and (max-width:375px) {
                        margin-left: 5%;
                    }                    
                    @media screen and (max-width:390px) {
                        margin-left: 12%;
                    }                    
                    @media screen and (max-width:430px) {
                        margin-left: 17%;
                    }
                    @media screen and (max-width: 361px) {
                        margin-left: 6%;
                    }
                }
                .header1{
                    font-size: 36px;
                    line-height: 40px;
                    margin-bottom: 15px;
                    @include media-down(sm){
                        font-size: 28px;
                        line-height: 36px;
                        width: 100%;
                        margin-top: 0;
                    }
                }                
                .title1{  
                    @include media-down(sm){
                        position: absolute;
                        bottom: -105px;
                    }                                                   
                    @media screen and (max-width: 360px) {
                        bottom: -70px;
                    }
                }
                .start-guide-subtext{
                    max-width: 100%;
                    @media screen and (min-width: 1400px) and (max-width: 1500px) {
                        max-width: 80%;
                    }
                    @include media-down(sm){
                        width: 100% !important;
                        max-width: 100%;
                        transform: translate(0,0);
                    }
                    @media screen and (min-width: 1024px) and (max-width:1030px) {
                        width: 100%;
                        max-width: 100%;
                        margin-top: 10vw;
                    }
                }
                .ob-videoBlock{
                    &.ob-contentBlock{
                        max-width: 90%;
                        .ob-videoContent-image {
                            img {
                                width: 100% !important;
                            }
                        }
                    }                
                    @include media-down(sm){
                        position: absolute;
                        max-width: 100vw !important;
                        width: 100vw !important;
                        right: auto;
                        top: auto;
                        bottom: -275px;
                        left: 0;
                        .ob-videoContent-image{
                            .ob-mainAsset-wrapper-img{
                                width: 100% !important;
                                max-width: unset;
                            }
                        }
                    }                 
                    @media screen and (max-width: 360px) {
                        bottom: -221px;
                    }   
                }                
            }
            .ob-videoContent-button {
                left: 60%;
                @include media-down(sm){
                    left: 50%;
                }
            }
            .ob-mainAsset-wrapper-img{
                margin: 0 !important;
                max-width: 240px;
            }
        }
        .start-guide-content-container{
            .ob-contentBlock-text-image{
                width: 100%;
                max-width: 58%;
                @include media-down(sm) {
					max-width: 42%;
				}
                .first-animation {
                    width: 100%;
                    transform: translateX(0%);
                    @include media-down(sm){
                        transform: translateY(0);
                    }
                    @media screen and (device-width:1440px) {
                        transform: translate(-4%, 4%) !important;

                    }
                    @media screen and (min-width: 1439px) and (max-width: 1450px) {
                        transform: translateX(35%);
                    }
                    @media screen and (min-width: 1527px) and (max-width: 1540px) {
                        transform: translateX(0%);
                    }
                    .ob-mainAsset-wrapper-img{
                        height: 100% !important;
                        width: 100% !important;
                        max-width: 100%;
                        @include media-down(sm){
                            height: 100%;
                            width: 100%;
                            transform: translate(0, 0%);
                            margin-bottom: 1px !important;
                        }
                        @media screen and (device-width:360px) {
                            margin-bottom: 43px !important;
                        }
                        @media screen and (min-width: 390px) and (max-width: 393px) {
                            transform: translate(0, 0%);
                            margin-bottom: 1px !important;
                        }
                        
                        @media screen and (min-width: 400px) and (max-width: 430px) {
                            margin-bottom: 26px !important;
                        }                        
                    }
                }
            }
        }
    }
    .bristles{
        @include media-up(sm){
            margin-bottom: -3px;
        }
        @include media-down(sm){
            margin-top: 230px;
            margin-bottom: 0;
        }
        @media screen and (min-width:1024px) and (max-width: 1030px){
            height: 53vw;
        }
    }
    .ready-to-brush{
        .start-guide-content-container{
        .start-guide-text-content{
        .start-guide-subtext{
            text-align: center;
            font-size: 21px;
            line-height: 29px;
            margin-bottom: 30px;
            margin-top: 0px !important;
            @include media-down(sm){
                font-size: 17px;
                line-height: 23px;
                margin: 20px auto 10px;            
            }
            .header-text{
                font-size: 36px;
                line-height: 40px;
                margin-top: 0;
                margin-bottom: 20px;
                padding-top: 70px;
                @include font-family('hurme-light');
                display: block;
                @include media-down(sm){
                    font-size: 28px;
                    line-height: 36px;
                    max-width: 100%;
                    margin: 0px 15px 10px;            
                    padding-top: 40px;        
                }
            }
            .sub-desc{
                @include media-down(sm){
                    font-size: 17px;
                    line-height: 23px;
                    max-width: 100%;
                    margin: 20px 15px 15px;
                    display: block;
                    .bold-text{
                        @include font-family('hurme-regular');
                    }        
                }
            }
        }}}
    }

    .charging-base-card {
        .start-guide-text-container {
            flex-direction: row-reverse;
        }
    }

    .shuffle-block2 {
        .start-guide-content-container {
            .start-guide-text-container {
                @include media-down(sm) {
                    text-align: center;
                    transform: translate(-30%, 15%);
                    width: 138%;

                    .start-guide-text-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .start-guide-header,
                        .start-guide-subtext {
                            text-align: left;
                            width: 100%;
                        }

                        h3 {
                            font-size: 14px;
                            line-height: 24px;
                            margin: 0 auto;
                            width: 80%;
                        }
                    }
                }
            }

            .ob-contentBlock.ob-videoBlock {
                max-width: 40rem;
                @media screen and (min-width: 1280px) and (max-width: 1285px) {
                    max-width: 100%;
                }
            }
        }
    }

    .Investment {
        .shuffle-block3 {
            .start-guide-background {
                @include media-down(lg) {
                    height: 80rem;
                }

                @media screen and (min-width:1440px) {
                    height: 100vh;
                }
            }

            .start-guide-content-container {
                .start-guide-text-container {
                    @include media-up(sm) {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                    }

                    @media screen and (max-width: 1440px) {
                        grid-template-columns: 1fr 2fr;
                    }

                    @include media-down(sm) {
                        .start-guide-text-content {
                            margin-top: -60%;
                        }
                    }
                }
            }
        }
    }

    .shuffle-block3 {
        .start-guide-content-container {
            .start-guide-text-container {
                @include media-down(sm) {
                    flex-direction: column-reverse;
                    transform: translateX(-12%);
                    width: 110%;
                    margin-top: 10%;

                    img {
                        margin-right: unset;
                        margin-top: 20%;
                    }

                    .start-guide-text-content {
                        width: 85%;
                        padding-left: 10%;
                    }
                }

                @media screen and (max-width: 395px) and (max-height: 670px) {
                    margin-top: 0;
                }

                @media screen and (max-width: 360px) and (max-height: 705px) {
                    img {
                        margin-top: 0;
                    }
                }
            }
        }

        .select-calender {
            .calender-option-list-section {
                background: $io2-dark-bluish;
                padding: 20px 8px 10px 8px;
                color: $base-color__white;
                text-align: center;
                width: 65%;
                max-width: 100%;
                border-radius: 31px;
                margin-top: 15px;
                text-transform: capitalize;
                @media screen and (min-width: 1440px) and (max-width: 1500px) {
                    width: 100%;
                }

                @include media-down(sm) {
                    width: 175px;
                    position: relative;
                    z-index: 5;
                }
                @media screen and (min-width:1528px) and (max-width: 1537px) {
                    width: 81%;
                }
                @media screen and (min-width: 1272px) and (max-width: 1281px) {
                    width: 370px !important;
                    margin-bottom: 11px;
                }
                .calender-title {
                    @include font-family('hurme-regular');
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0;
                    margin-bottom: 15px;

                    @include media-down(sm) {
                        margin-top: 32px;
                    }
                }

                .calender-content {
                    display: flex;
                    justify-content: space-evenly;
                    -webkit-box-align: center;
                    align-items: center;
                    cursor: pointer;
                    @include font-family('hurme-regular');
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0;
                    text-align: center;
                    padding: 2px 0px;

                    .calender-img {
                        img {
                            width: 100%;
                            height: 100%;
                            max-width: 33px;
                            max-height: 33px;
                        }
                    }
                }
            }

            @media screen and (min-width: 1280px) and (max-width: 1285px) {
                position: absolute;
                top: 25%;

                .calender-option-list-section {
                    width: 309px;

                    .calendar-close-button {
                        left: 80%;
                    }
                }
            }

            @include media-down(sm) {
                position: absolute;
            }
        }
    }
    .bristles{
        .start-guide-content-container{ 
            .start-guide-text-container{
                display: flex;
                flex-direction: row;
                width: 100%;
                max-width: 100%;
                margin: 0 auto;
                transform: translateX(0%);

                @include media-down(sm){
                    flex-direction: column;
                    transform: unset;
                }
                img{
                    display: unset;
                    margin: unset;
                    max-width: unset;
                }
                @media screen and (min-width: 1024px) and (max-width:1030px) {
                    margin-top: -10rem;
                }
                .start-guide-text-content{
                    color:$io2-blue;
                    width: 42%;
                    padding-left: 5%;
                    .start-guide-cta-link{
                        p{
                            width: 370px;
                            @media screen and (max-width: 361px) {
                                width: 264px;
                            }
                        }    
                    }
                    @include media-down(sm){
                        width: calc(100% - 30px);
                        text-align: center;
                        padding-left: unset;
                        margin-top: 10%;
                    }
                    .start-guide-header{
                        font-size: 36px;
                        line-height: 40px;
                        @include media-down(sm){
                            font-size: 28px;
                            line-height: 36px;
                        }
                    }
                    p.start-guide-subtext{
                        max-width: 60%;
                        @media screen and (min-width: 1440px) and (max-width: 1500px) {
                            max-width: 79%;
                        }
                        @include media-down(sm){
                            font-size: 17px;
                            line-height: 23px;
                            max-width: 82%;
                            margin-left: 9vw;                    
                        }
                        @media screen and (min-width: 1024px) and (max-width:1030px) {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                    .start-guide-cta-link{
                        p{
                            @include media-down(sm){
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }
                    .select-calender{
                        position: relative;
                        width: 370px;
                        @include media-down(sm) {
                            margin: 0 auto;
                        }
                        .calender-option-list-section{
                            margin-top: 0;
                            margin-bottom: 20px;
                            width: 100%;
                            @media screen and (max-width: 360px) {
                                width: 70%;
                                margin-left: 35px;                        
                            }
                            @media screen and (min-width: 361px) and (max-width: 393px) {
                                width: 65%;
                                margin-left: 60px;
                                .gum-bleed-close-button{
                                    right: 8px !important;
                                }                 
                            }
                            .calendar-close-button{
                                left: auto;
                                right: 20px;
                            }
                            .gum-bleed-close-button{
                                border-radius: 50%;
                                background-color: $base-close-color;
                                right: 20px;
                                top: 20px;
                                width: 40px;
                                height: 40px;
                                .ob-icon{
                                  fill: white !important;
                                  width: 1.4rem !important;
                                }
                                @include media-down(sm){
                                    width: 30px;
                                    height: 30px;
                                    filter: none;
                                    .ob-icon{
                                        width: 1.2rem !important;
                                      }
                                }
                            }
                        }
                    }
                }
                .beforeAfterContainer{
                    width: 58%;
                    height: 100%;
                    transform: translateX(0);
                    @media screen and (min-width: 1440px) and (max-width: 1443px) {
                        transform: translate(-4vw, 3vw);
                    }
                    @include media-down(sm){
                        height: 100vw;
                    }
                    .beforeAfterGlider{
                        max-width: 80%;
                        margin: 0 auto;
                        margin-bottom: -2px;
                        @media screen and (min-width: 1024px) and (max-width: 1030px) {
                            max-width: 75%;
                            transform: translateY(5vw);
                        }
                        @include media-down(sm){
                            scale: 1.5;
                            transform: translateY(15vw);
                        }
                    }
                }
            }
            .ob-drag-slider{
                height: auto;
                width: 500px;
                background: unset;
                transform: translateX(78%);
                @include media-down(sm){
                    transform: unset;
                    width: 70%;
                    margin-bottom: 2%;
                    margin-top: -1%;
                }
                @media screen and (min-width:390px) and (max-width: 393px) {
                    margin-bottom: 39%;
                }
                @media screen and (min-width: 1270px) and (max-width: 1450px) {
                    transform: translateX(25%);
                }
                @media screen and (min-width: 1270px) and (max-width: 1450px) {
                    transform: translateX(25%);
                }
                @media screen and (min-width: 1520px) and (max-width: 1540px) {
                    transform: translateX(51%);
                }
                .mainWrapper{
                    .contentWrapper{
                        width: 100%;
                        img{
                            max-width: 100%;
                            @include media-down(sm){
                                margin-top: unset;
                            }
                        }
                    }
                }
            }
       }
    }

.vertical-carousel-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;

    @include media-up(lg) {
        margin-top: -1rem;
    }

    .slider-container {
        width: 60%;
        transform: translate(0, 80%);
        margin-left: 18.5vw;
        position: absolute;
        @media screen and (min-width: 1024px) and (max-width: 1030px) {
            transform: translate(0, 50%);
            margin-left: 10vw;
        }
        @media screen and (min-width: 1240px) and (max-width: 1285px) {
            margin-left: 19vw;
            transform: translate(2%, 52%);
        }
        @media screen and (min-width: 1400px) and (max-width: 1439px) {
            margin-left: 9vw;
            margin-top: -12vw;
        }
        @media screen and (min-width: 1440px) and (max-width: 1450px) {
            margin-left: 21vw;
        }
        @media screen and (min-width: 1535px) and (max-width: 1540px) {
            transform: translate(1.5%, 60%);
        }
        @media screen and (min-width: 1510px) and (max-width: 1520px) {
            margin-left: 20.2vw;
        }
        .slick-slider {
            .slick-slide {
                visibility: hidden;
                padding: 0.5rem;
                color: white;
                font-size: 16px;
                line-height: 24px;
                max-width: 298px;
                @media screen and (min-width: 2560px) {
                    max-width: unset;
                }
            }

            .slick-active {
                color: transparent;
                opacity: .5;

                &.slick-current {
                    opacity: 1;
                    color: $base-color__white;
                }

                visibility: visible;
            }

        }
    }

    .bg-img {
        height: 100vh;
        padding: unset !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;

        @include media-down(sm) {
            width: 55vw;
            transform: translateX(15%);
        }

        @include media-up(lg) {
            height: 104vh;
            width: 50%;
        }

        button {
            margin: 0 auto;

            @include media-up(sm) {
                .fg-img::before {
                    content: "";
                    max-width: 9vw;
                    width: 100%;
                    display: block;
                    height: 1px;
                    background-color: $base-color__white;
                    left: 18.4%;
                    position: absolute;


                    @media screen and (min-width: 1280px) and (max-width: 1285px) {
                        max-width: 6vw;
                        left: 18%;
                    }

                    @media screen and (min-width: 1440px) and (max-width: 1445px) {
                        max-width: 8vw;
                        left: 19.2%;
                    }

                    @media screen and (min-width: 1535px) and (max-width: 1540px) {
                        max-width: 8.3vw;
                        left: 19%;
                    }

                    @media screen and (min-width: 2456px) {
                        max-width: 8.7vw;
                        left: 18.8%;
                    }
                }
            }
        }

        .fg-img {
            height: 125px;
            width: 125px;
            background-size: contain;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: unset !important;
            min-height: unset !important;

            @media screen and (min-width: 2560px) {
                height: 200px;
                width: 200px;
            }

            p {
                text-align: center;
                font-size: 15px;
                line-height: 17px;
                width: 8rem;
                color: $base-color-dark-blue;
                @include font-family('hurme-bold');
                margin: 0 auto;
            }

            @include media-down(sm) {
                width: 100px;
                height: 100px;

                p {
                    font-size: 12px;
                    line-height: 13px;
                    margin-left: 22px;
                    color: $base-color-dark-blue;
                    margin: 0 auto;
                    width: 70%;
                }
            }

        }
    }

    .carousel-item {
        display: flex !important;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        column-gap: 20px;
        width: 298px;
        cursor: pointer;

        img {
            width: 70px !important;
        }

        .carousel-item-text {
            color: $base-color__white;
            text-align: center;

            .text-header {
                font-family: $hurmesemibold;
            }
            }
    }
}
.use-right-pressure{
    margin-top: 0 !important;
    @include media-up(sm){
        height: 85vw;
    }
}
.use-right-pressure-card{
    @include media-up(sm){
        .start-guide-content-container{
            .image-container{
                transform: translateX(-5vw);
                position: absolute;
                width: 50vw;
                @media screen and (min-width: 1024px) and (max-width:1030px) {
                    transform: translateX(23vw);
                }
                .ob-mainAsset-wrapper-img{
                    height: 85vw !important;
                }
            }
        }
        .start-guide-text-container{
            height: 73vw;
            width: 100% !important;
            transform: translateX(-185%) !important;
            max-width: 35% !important;
            @media screen and (min-width: 1024px) and (max-width:1030px) {
                transform: translateX(20%) !important;
            }
        }
        .image-container{
            transform: translateX(0%);
        }
    }

    @include media-down(sm) {  
        .start-guide-content-container {         
            flex-direction: row-reverse;            
            .start-guide-text-container{
                position: relative;
                width: 63% !important;
                .start-guide-text-content{
                    padding-top: 40px;                    
                }
                .start-guide-header{                      
                    font-size: 38px;
                    line-height: 42px;
                    position: absolute;
                    top: 40px;
                    margin: 0;
                    width: 330px !important;
                    left: 40px;                    
                    text-align: center !important;
                    @media screen and (max-width: 360px){
                        left: 8%;  
                        font-size: 32px;
                    }
                    @media screen and (min-width: 390px) and (max-width: 393px){
                        left: 32px;
                        top: 15px;
                        font-size: 36px;
                        line-height: 1;
                    }
                    
                    .gentle{
                        margin: 0;
                    } 
                }
                .start-guide-subtext{
                    padding-left: 25px;
                    .right-desc{
                        font-size: 32px;
                        line-height: 36px;
                    }
                    p{
                        font-size: 17px;
                        line-height: 23px;
                    }                    
                    .right-desc{
                        margin-top: 0%;                        
                        &:first-child{                            
                            margin-top: 20%;
                            @media screen and (max-width: 393px) {
                                margin-top: 0%;
                            }
                        }
                    }                    
                    @media screen and (max-width: 375px){
                        .break-m{            
                            display: none;                            
                        }                        
                    }
                    @media screen and (max-width:390px) {
                        .break-m{
                            &:nth-of-type(1){
                                display: none;
                            }
                        }                        
                    }
                }                
                .save-settings {
                    color: $io2-blue;
                    background-color: $io2-secondary-blue-color;
                    padding: 10px 20px;
                    border-radius: 100px;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    text-align: left;
                    font-family: "Hurme Regular", sans-serif;
                    display: flex;
                    margin-left: 13px;
                    &:hover,&:focus{
                        text-decoration: none;
                    }
                }
            }
            .image-container{
                width: 44%;
                .start-guide-animation-asset{
                    img{
                        height: 100%;
                    }
                }
            }
        }
    }

    @include media-down(sm) {
        .vertical-carousel-container {
            transform: translate(0%, 0%);
        }

        .slider-container {
            width: 100%;
            transform: translateX(7%);

            .slick-slider {
                width: 50vw;

                .carousel-item {
                    img {
                        width: 70px !important;
                        height: 70px !important;
                        margin-right: unset;
                        margin: 0 auto;
                    }
                }

                .slick-slide {
                    .carousel-item {
                        opacity: 0.5;
                    }

                    .carousel-item-text {
                        display: none;
                    }
                }

                .slick-slide.slick-current {
                    .carousel-item {
                        opacity: 1;
                    }

                    .carousel-item-text {
                        display: block;
                        width: 175%;
                        transform: translateX(-20%);

                        .text-header {
                            @include font-family('hurme-bold');
                            font-size: 12px;
                            line-height: 13px;
                        }

                        .text-description {
                            @include font-family('hurme-light');
                            font-size: 12px;
                            line-height: 13px;
                        }
                    }
                }
            }
        }
    }
}
.smart-settings {
    .start-guide-content-container {
        @include media-down(sm) {   
            flex-direction: column;
        }
    }

    @include media-down(sm) {
        .vertical-carousel-container {
            transform: translate(0%, 0%);
        }

        .slider-container {
            width: 100%;
            transform: translateX(0);
            margin-top: 5rem;
            background-image: linear-gradient(white 80%,transparent);
            .slick-slider {
                width: 100vw;

                .carousel-item {
                    img {
                        width: 70px !important;
                        height: 70px !important;
                        margin-right: unset;
                        margin: 0 auto;
                    }
                }
                .slick-track{
                    padding-top: 0;
                }

                .slick-slide {
                    .carousel-item {
                        opacity: 0.5;
                    }

                    .carousel-item-text {
                        display: none;
                    }
                }

                .slick-slide.slick-current {
                    .carousel-item {
                        opacity: 1;
                        .ob-mainAsset-wrapper {
                            img{
                                background-color: $io2-base-color-slight-dark;
                                border-radius: 50%;
                            }
                        }
                    }

                    .carousel-item-text {
                        display: block;
                        width: 175%;
                        transform: translateX(-23%);
                        color: $base-color-dark-blue;

                        .text-header {
                            @include font-family('hurme-regular');
                            font-weight: 600;
                            font-size: 17px;
                            line-height: 23px;
                        }

                        .text-description {
                            @include font-family('hurme-regular');
                            font-weight: 400;
                            font-size: 17px;
                            line-height: 23px;
                            padding-left: 10vw;
                            padding-right: 10vw;
                        }
                    }
                }
            }
        }
    }
}

.activeShuffleThird {
    animation: 1.5s ease-in 0s 1 transitionThirdFromBottom;

    @keyframes transitionThirdFromBottom {
        0% {
            transform: translateY(200%);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

#start-guide-shuffle-block-second-txt {
    .start-guide-text-content {
        animation: 1s ease-in 0s 1 transitionTwoFromBottom;

        @keyframes transitionTwoFromBottom {
            0% {
                transform: translateY(30%);
                opacity: 0;
            }

            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}

#start-guide-content-container-first {
    .start-guide-header {
        animation: 0.3s ease-in 0s 1 transitionFirstFromBottom;

        @keyframes transitionFirstFromBottom {
            0% {
                transform: translateY(0);
                opacity: 0;
            }

            100% {
                transform: translateY(30%);
                opacity: 1;
            }
        }
    }
}

.shuffle-block2{
    .start-guide-header, .start-guide-subtext{
        color: $io2-blue;
    }
    .start-guide-animation-asset img{
        height: 84vh;
    }
}

.calendar-close-button {
    width: 15px;
    height: 15px;
    padding: 12px;
    border: solid 2px white;
    display: block;
    position: absolute;
    border-radius: 50%;
    left: 81%;
    cursor: pointer;

    @media screen and (min-width: 1280px) and (max-width: 1285px) {
        left: 75%;
    }

    @media screen and (min-width: 1440px) and (max-width: 1535px) {
        left: 75%;
    }

    @media screen and (min-width: 1535px) and (max-width: 1540px) {
        left: 80%;
    }
}

.calendar-close-button::before,
.calendar-close-button::after {
    position: absolute;
    content: '';
    height: 1px;
    width: inherit;
    background: white;
    left: 4px;
    padding: 1px;
    top: 50%;
    transition: all .3s ease-in;
}

.calendar-close-button::before {
    transform: rotate(45deg) translate(-1px, -1px);
}

.calendar-close-button::after {
    transform: rotate(-45deg) translate(1px, -1px);
}

.ready-to-brush {
    margin-top: -15px;
    @include media-down(sm){
        margin-bottom: -15px;
        margin-top: unset;
    }

    .start-guide-text-content {
        margin-left: 50px;
    }

    #Ready-To-Brush-Video-Block {
        max-width: unset;
    }

    .start-guide-content-container {
        grid-template-columns: 1fr !important;
        width: 100% !important;
        align-items: start !important;

        @include media-up(sm){
            width: 98% !important;
            left: 15% !important;
        }
        @include media-down(sm) {
            width: 100% !important;
            align-items: center !important;
        }

        .start-guide-text-container {
            width: 100%;
            max-width: 100% !important;
            min-width: 100% !important;

            .ob-videoContent-button {

                &:hover,
                &:focus {
                    background-color: unset !important;
                    outline: unset !important;
                }

                svg {

                    &:hover,
                    &:focus {
                        fill: transparent !important;
                    }
                }
            }
            img{
                @include media-down(sm){
                max-width: 100%;
                width: 100% !important;
                }
            }

            .start-guide-text-content {
                @include media-up(sm) {
                    display: flex;
                    flex-direction: column-reverse;
                }
                @include media-down(sm) {
                    margin-left: 0px;
                    flex-direction: column-reverse;
                    display: flex
                }
                .ready-to-brush-video{
                    @include media-down(sm){
                        margin-top: 10px;
                    }
                }
            }

            .videoDescription, .videoBelowDescription {
                width: 100%;
                margin: 0 auto;
                text-align: center;
                @include font-family('hurme-light');
                @media screen and (min-width: 1440px) and (max-width: 1540px) {
                    width: 83%;
                }

                @media screen and (min-width: 1270px) and (max-width: 1285px) {
                    width: 100%;
                }

                .bold-text {
                    @include font-family('hurme-regular');
                    font-weight: 600;
                }

                .description {
                    @include font-family('hurme-light');
                    font-size: 21px;
                    line-height: 29px;
                    margin: 20px auto 30px;
                    max-width: 635px;
                }

                .heading{
                    font-size: 56px;
                    line-height: 60px;
                    @include media-down(sm){
                        font-size: 40px;
                        line-height: 44px;
                        text-align: center;
                        font-family: 'Hurme Light';
                        font-weight: 100;                
                    }
                }
                @include media-down(sm) {
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    line-height: 24px;
                    margin-top: 30px;
                    margin-left: 0%;
                    .bold-text {
                        @include font-family('hurme-bold')
                    }

                    .description {
                        @include font-family('hurme-regular');
                        display: block;
                        font-size: 17px;
                        line-height: 23px;
                        max-width: 320px;
                    }
                }
            }
        }
    }
}
}
.Modal-Container {
    background-color: $io2-blue;
    color: $base-color__white;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    &.save-your-setting-popup{
        max-width: 640px;
        background-color: $io2-base-color-slight-dark;
        .text-content{
            padding: 30px;
            .title {
                font-family: 'Hurme regular';
                font-size: 36px;
                line-height: 40px;
                padding-bottom: 20px;
                margin-right: 40px; 
                @include media-down(sm) {
                    font-size: 28px;
                    line-height: 36px;
                    margin-right: 0; 
                    text-align: center;
                    margin-top: 45px;
                }               
            }
            .description{
                font-size: 22px;
                line-height: 32px;
                @include media-down(sm) {
                    font-size: 17px;
                    line-height: 23px;
                } 
                .carosel-desc-list{
                    margin: 0;
                    li{
                        margin: 10px 0;
                        &::before{
                            top: 4px;
                        }
                    }
                }
            }
        }        
        .gum-bleed-close-button{
            border-radius: 50%;
            background-color: $base-close-color;
            right: 30px;
            top: 30px;
            width: 40px;
            height: 40px;
            .ob-icon{
                fill: $base-color__white !important;
                padding: 4px;                
            }
        }
    }
    &.gum-bleed-pop-up{
        max-width: 876px;
        background-color: $io2-base-color-slight-dark;
        display: flex;
        @include media-up(sm){
            height: auto;
            overflow: hidden;
        }
        @include media-down(sm) {
            flex-direction: column-reverse;
        }
        @media screen and (min-width:1200px) and (max-width:1280px) {
            height: auto;
            min-height: 270px;
        }
        .ob-mainAsset-wrapper {
            height: 100%;
            @include media-down(sm) {                
                display: flex;
                align-items: baseline;
            }
            .ob-mainAsset-wrapper-img, img{
                height: 320px;
                min-height: 320px;
                @include media-down(sm) {
                    height: auto;
                    max-width: 100%;
                }
            }
        }
        .text-content{
            padding: 30px;
            @include media-down(sm) {
                text-align: center;
            } 
            .title {
                font-family: 'Hurme regular';
                font-size: 30px;
                line-height: 30px;
                padding-bottom: 20px;
                margin-right: 40px;
                @-moz-document url-prefix() { 
                    @include media-up(sm) {
                        width: 368px;
                    }
                }
                @media screen and (max-width: 1285px) {
                    font-size: 28px;
                    line-height: 32px;
                }
                .bold-text{
                    @include font-family('hurme-regular');
                    font-weight: 600;
                }
                @include media-down(sm) {
                    font-size: 28px;
                    line-height: 36px;
                    margin-right: 0px;
                    margin-top: 45px;
                }        
            }
            .description{
                font-size: 16px;
                line-height: 24px;
                @include media-down(sm) {
                    font-size: 17px;
                    line-height: 23px;
                    margin-bottom: -10px;
                    padding-bottom: 0;
                }
                @media screen and (min-width: 1200px) and (max-width: 1285px) {                     
                    font-size: 15px;
                    line-height: 19px;
                }
                .carosel-desc-list{
                    margin: 0;
                    li{
                        margin: 10px 0;
                        &::before{
                            top: 5px;
                        }
                    }
                }
            }
            .subtitle{
                font-size: 14px;
                line-height: 18px;
                @include media-down(sm) {
                    font-size: 12px;
                    line-height: 16px;
                    margin-top: 20px;
                }  
            }
        }        
        .gum-bleed-close-button{
            border-radius: 50%;
            background-color: $base-close-color;
            right: 30px;
            top: 30px;
            width: 40px;
            height: 40px;
            .ob-icon{
                fill: $base-color__white !important;
                padding: 4px;                
            }
            @-moz-document url-prefix() { 
                @media screen and (min-width: 1280px) and (max-width: 1285px) {
                    top: 20px;
                }
            }            
        }
    }

    .text-content {
        padding: 10%;

        @media screen and (max-width: 1440px) {
            padding: 5%;
        }

        .title {
            font-family: 'Hurme bold';
            font-size: 45px;
            line-height: 55px;
            padding-bottom: 15px;

            @media screen and (max-width: 1440px) {
                font-size: 30px;
                line-height: 40px;
            }
        }

        .description {
            font-family: 'Hurme Regular';
            font-size: 20px;
            line-height: 30px;
            padding-bottom: 20px;

            .bold-text {
                @include font-family('hurme-regular');
                font-weight: 600;
            }
            .carosel-desc-list{
                display: block;
                padding: 0 0 0 35px;
                list-style: none;
                overflow: hidden;
                counter-reset: numList;
    
                li {
                    position: relative;
                    margin: 3px 0;
                }
    
                li:before {
                    counter-increment: numList;
                    content: counter(numList);    
                    float: left;
                    position: absolute;
                    left: -30px;
                    font-size: 16px; 
                    font-family: $hurmebold;
                    font-weight: 600;
                    text-align: center;
                    color: $io2-tertiary-blue-color;
                    line-height: 24px;
                    width: 24px;
                    height: 24px;
                    background: $io2-tertiary-light-blue;
    
                    -moz-border-radius: 999px;
                    border-radius: 999px
                }
            }
        }

        .subtitle {
            font-family: 'Hurme Regular';
            font-size: 12px;
            line-height: 22px;
        }
    }
}

.use-right-pressure-card{
    .image-container{
        .ob-mainAsset-wrapper{
            @include media-down(sm) {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

.body-zone-start-guide {
    .ob-contentBlock {
        &.ob-videoBlock {
            &.use-the-pressure-video {
                @include media-up(md) {
                    background-color: transparent;
                }
            }
        }
    }
}