@import "../../static/styles/mixins";

.ob-eyebrow {
    @include eyebrow-1-xl;
    &[data-white-text='true'] {
        color: $base-color__white;
    }
    &[data-text-outline='true'] {
        @include textOutline();
    }

    .blackText & {
        color: $base-color__black;
    }

    .textOutline & {
        @include textOutline();
    }

    &[data-blue-text='true'] {
        color: $base-color-dark-blue;
    }

    a {
        color: inherit;
    }
}
