@import "../../static/styles/variables";
@import "../../static/styles/mixins";

$search-bar-height: rem(51);

.ob-search-bar {
    height: $search-bar-height;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    input[type="text"] {
        height: 100%;
        width: 100%;
        border: 2px solid $base-color-dark-grey;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        color: $base-color-dark-blue;
        font-size: rem(22);
        padding: 0 $search-bar-height 0 rem(15);
        &:focus {
            border: 2px solid $base-color__black;
        }
        &:-ms-input-placeholder {
            color: $base-color-dark-blue  !important;
        }

        &::placeholder {
            color: $base-color-dark-blue;
        }
    }
}

.ob-search-bar-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    width: $search-bar-height;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .ob-icon{
        fill: $base-color-dark-blue;
    }
}

.ob-search-bar-autocomplete {
    background-color: $base-color__white;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: rem(33) rem(40);
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: $base-color-saturn-grey;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    z-index: map-get($zindex, one);
}

.ob-search-bar-autocomplete-title {
    font-size: rem(16);
    color: $base-color-dark-grey;
    font-weight: bold;
    margin-block-end: 0em!important;
    margin-block-start: 0em!important;
}

.ob-search-bar-autocomplete-results {
    margin: rem(10) 0 0 0;
    list-style-type: none;
    padding: 0;
    li button {
        color: $base-color-dark-blue;
        font-size: rem(16);
        margin: rem(5) 0;
        text-align: left;

        em {
            font-weight: bolder;
            font-style: normal;
            color: $base-color-dark-blue;
        }
    }
}

.ob-search-bar-spinner {
    display: inline-block;
    transform: translateZ(1px);
}

.ob-search-bar-spinner > div {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $base-color-dark-blue;
    animation: ob-search-bar-spinner 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes ob-search-bar-spinner {
    0%, 100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
        transform: rotateY(3600deg);
    }
}
