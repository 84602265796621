@import "../../static/styles/mixins";

.ob-legacy-article-hero {
    max-width: 700px;
    margin: 0 auto;
    padding: 2rem 0 0 2rem;

    @include media-up(sm) {
        padding: 5rem 2rem 0;
    }

    &__heading {
        font-size: 3.7rem;
        margin-top: 1rem;
    }
    &__inner {
        .ob-mainAsset-wrapper img {
            max-width: 100%;
        }
    }
}

.ob-layout-article-legacy--da-dk {
    .ob-legacy-article-hero__heading, .ob-legacy-article-hero__inner a {
        padding: 0 2rem;

        @include media-up(sm) {
            padding: 0;
        }
    }

    .ob-legacy-article-hero img {
        max-width: 100%;
    }
}

// Global Equity
.layout.ob-layout-article-legacy{
    .ob-zone-body-article-legacy{
        .ob-legacy-article-hero {
            @media(max-width: 767.98px) {
                padding: 0 2rem;
            }
        }
    }
}
.ob-legacy-article-hero {
    .geográfica-img {
        img{
            height: 450px;
        
            @include media-down(sm){
                height: 215px;
            }
        } 
    }
}