@import "../../static/styles/variables";
@import "../../static/styles/mixins";

.ob-article-category-hero-content {
    min-height: 700px;
    box-sizing: border-box;
    padding: 10px 24px 24px;
    text-align: center;

    .ob-article-category-hero__by-age-group--da-dk & {
        @include media-down(sm) {
            padding-top: 15px;
        }
    }

    @include media-up(xs) {
        text-align: left;
    }

    @include media-up(sm) {
        padding: 170px 24px 24px;
    }
}

.ob-article-category-hero-wrapper {
    max-width: 1136px;
    height: 100%;
    margin: 0 auto;
    @include media-up(sm) {
        display: flex;
        justify-content: space-between;
    }
    img {
        max-width: 100%;
    }

    .ob-mainAsset-wrapper {
        @include media-up(sm) {
            width: 45%;
        }
    }
}

.ob-article-category-hero-inner {
    width: 47%;

    @include media-down(sm) {
        width: 100%;
    }
}

.ob-article-category-hero-eyebrow {
    margin-bottom: rem(20);
    font-size: rem(16);
    text-transform: uppercase;
}

.ob-article-category-hero-heading {
    @include display-1-xl;
    @include display-3-xs;
    max-width: rem(730);
    margin-bottom: rem(15);
    text-transform: uppercase;
    letter-spacing: (0.5em / 64) !important;

    @include media-up(sm) {
        margin-bottom: rem(8);
    }
}

.ob-article-category-hero-text {
    @include body-1-xl;
    margin-top: 0;
    max-width: rem(730);
    line-height: (26em / 18);
    letter-spacing: 0;
    font-variant-ligatures: common-ligatures;

    @include media-up(sm) {
        letter-spacing: (0.5em / 22);
    }
}

.ob-article-category-hero-time {
    @include body-1-xl;
    margin-top: rem(30);
}

.ob-article-category-hero-cta-list {
    margin: 200px 0 0;
    padding: 0 0;
    list-style: none;
    text-align: left;

    @media screen and (max-width: 768px) {
        margin-bottom: 100px;
    }

    @include media-up(sm) {
        padding: 0;
        margin: 35px 0 0;
    }

    .white-bg--sm-down & {
        @include media-down(sm) {
            margin: 0 0 5px;
            padding: 1px 20px;
            background: rgba($base-color__white, 0.7);
        }
    }
}

.ob-article-category-hero-cta-item {
    margin: 28px 0;

    @include media-up(sm) {
        margin: 22px 0;
    }
}

.ob-article-category-hero-cta-link {
    font-size: rem(16) !important;
    text-transform: uppercase;
}

.ob-article-category-hero-cta-icon {
    margin-right: 8px !important;
}

.-ob-article-category-hero-obLink-container {
    .ob-link,
    .ob-button {
        margin-top: 3rem;
        text-align: center;

        @include media-up(md) {
            margin-top: 3.8rem;
        }
    }
}

.ob-rich-text__children-oral-health-title {
    background-color: rgb(243, 249, 254);
    h2 {
        margin: 0 auto;
        padding: 5rem 0 3rem;
        text-align: center;
        @include display-3-xl;

        @include media-up(sm) {
            max-width: 60%;
        }
    }

    p {
        display: none;
    }

    &+ .ob-article-category-hero .ob-article-category-hero-content {
        padding-top: 90px;
    }
}
